import { CLASS_INFO } from '../../Constants/index';
import axios from 'axios';
import { classInfo } from '../../../utils/Api'
import { getToken } from '../../../utils/token'

export const getClassInfoAction = () => {
	return dispatch => {
        const url = `${classInfo.getClassInfo}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: CLASS_INFO,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: CLASS_INFO,
                    payload: {
                        list : {}
                    }
                })
            }
            return data
        })
    }
}

export const clearSkillByIdAction = (id) => {
	return dispatch => {
        dispatch({
            type: CLASS_INFO,
            payload: {
                list : {}
            }
        })
    }
}