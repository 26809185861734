import React, { Component } from 'react'
import classnames from 'classnames';
import { TabContent, TabPane } from "reactstrap";
import { MDBBtn } from 'mdbreact';
import { getFAQAction, searchFAQAction } from '../../actions/Admin/ManageFAQ/manageFAQAction';
import { connect } from 'react-redux';

class ViewFAQ extends Component {
    constructor(props) {
		super(props);
		this.state = {
			searchFAQ:null,
			faqList:[],
            title:''
		};
	}

    componentDidMount(){
        this.props.getFAQAction()
    }
    createMarkup(data) {
        return {__html: data};
    }
    componentWillReceiveProps(nextProps){
		if(this.state.faqList !== nextProps.faqList.list){
			this.setState({
				faqList: nextProps.faqList
			})
		}
	}
    handleChange=(e)=>{
        this.setState({
            title:e.target.value
        })
    }
    searchHandler =()=>{
		let search = {
			title:this.state.title,
		}
		Object.keys(search).forEach((key) => (search[key] == '') && delete search[key]);
		this.props.getFAQAction(search)
	}
    searchClear =()=>{
		this.setState({
            title:''
		},()=>{
			this.props.getFAQAction()
		})
	}



    render() {
        return (
            <div>
                <div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Manage FAQ</h1>
							</div>
                           
						</div>
					</div>
				</div>
                <div className="section-body mt-4">
					<div className="container-fluid">
                        <div className="">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                        <div className="input-group">
                                            <input type="text" name ='title' value={this.state.title} onChange={this.handleChange} className="form-control" placeholder="Enter the title" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                        <MDBBtn color="primary" className='btn' onClick={this.searchHandler}>Search</MDBBtn> &nbsp;
                                        <MDBBtn color="primary" className='btn' onClick={this.searchClear}>Clear</MDBBtn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-body mt-4">
					<div className="container-fluid">
                       {this.state.faqList && this.state.faqList.length >0 ? 
                       this.state.faqList.map((item,i)=>{
                           return  <div className="">
                                        <div className="card-body">
                                            <div className="row">
                                            <h6>Question {i+1}</h6>
                                            </div>
                                            <div className="row mt-2">
                                                <h6><b> {item.title}</b></h6>
                                            </div>
                                            <div className="row mt-2" dangerouslySetInnerHTML={this.createMarkup(item.content)}>
                                            </div>
                                        </div>
                                    </div>
                       }) :
                        <div className="">
                            <div className="card-body">
                                <div className="row">
                                    No matching records found
                                </div>
                            </div>
                        </div>
                       }
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
	faqList: state.getFAQReducer,
	// searchFAQ: state.searchFAQReducer
})

const mapDispatchToProps = dispatch => ({
	getFAQAction: (e) => dispatch(getFAQAction(e)),
	// searchFAQAction: (e) => dispatch(searchFAQAction(e)),

})
export default connect(mapStateToProps, mapDispatchToProps)(ViewFAQ)
