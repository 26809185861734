

import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { isLogined } from '../../../../utils/token'
// import './style.scss';
import { connect } from 'react-redux';
import { MDBBtn, MDBFormInline, MDBInput} from 'mdbreact';
import { getAssignmenttAction, getCitiesAction, getClassAction, getExamtAction, getSectionAction, getStateAction, getStudentAction, getSubjectAction } from '../../../../actions/institute/Utils/utilsAction';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import DatePicker from "react-datepicker";
import { clearCreateCertificateAction, clearuploadCertificateBadgeAction, clearuploadCertificateLogoAction, clearuploadCertificateSignAction, createCertificateAction, getPrincipalAction, uploadCertificateBadgeAction, uploadCertificateLogoAction, uploadCertificateSignAction } from '../../../../actions/institute/Teacherdashboard/ManageCertificate/manageCertificateAction';

class CreateCertificate extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,

            certificate_type:'',
            top_text:'',
            class_id:'',
            section_id:'',
            subject_id:'',
            ExaminationOrAssignment_id:'',
            student_id:'',
            mid_text:'',
            end_text:'',
            date:'',
            certificate_badge:'',
            certificate_logo:'',
            authorised_signatory:'',

            editId: this.props.match.params.id,
            modal: false,

			classList:[],
            sectionList:[],
            subjectList:[],
            studentList:[],
			utills:{},
            principalDetail:'',
            assignmentList:[],
            examList:[],
            
            logo:null,
            uploadLogoStatus:null,

            badge:null,
            uploadBadgeStatus:null,

            sign:null,
            uploadSignStatus:null,

		};
	}

    componentDidMount(){
		if(localStorage.getItem('school')){
            let school = JSON.parse(localStorage.getItem('school'))
            this.setState({ school_id:school.id },()=>{
                if(this.state.school_id){
                    let search = {
                        school_id: this.state.school_id
                    }
                    this.props.getClassAction(search)
                }
            });
        }
        this.props.getPrincipalAction()
	}

    componentWillReceiveProps(nextProps){
        if(this.state.subjectList !== nextProps.utills.subjectList){
            this.setState({
                subjectList: nextProps.utills.subjectList
            })
        }
        if(this.state.sectionList !== nextProps.utills.sectionList){
            this.setState({
                sectionList: nextProps.utills.sectionList
            })
        }
		if(this.state.classList !== nextProps.utills.classList){
			this.setState({
				classList: nextProps.utills.classList
			})
		}
        if(this.props.utills.examList !== nextProps.utills.examList){
            this.setState({
                examList: nextProps.utills.examList
            })
        }
		if(this.props.utills.assignmentList !== nextProps.utills.assignmentList){
			this.setState({
				assignmentList: nextProps.utills.assignmentList
			})
		}
        if(this.state.principalDetail !== nextProps.certificateState.principalDetail){
			this.setState({
				principalDetail: nextProps.certificateState.principalDetail
			})
		}
        if(this.state.uploadLogoStatus !== (nextProps.certificateState && nextProps.certificateState.uploadLogo && nextProps.certificateState.uploadLogo.file)){
            if(nextProps.certificateState.uploadLogo.file){
                this.setState({
                    uploadLogoStatus:nextProps.certificateState.uploadLogo.file,
                    certificate_logo:nextProps.certificateState.uploadLogo.file
                },()=>{
                     if(nextProps.certificateState && nextProps.certificateState.uploadLogo && nextProps.certificateState.uploadLogo.file){
                        this.props.uploadCertificateBadgeAction(this.state.badge)
                        // this.createSkill()
                     }
                })
            }
        }
        if(this.state.uploadBadgeStatus !== (nextProps.certificateState && nextProps.certificateState.uploadBadge && nextProps.certificateState.uploadBadge.file)){
            if(nextProps.certificateState.uploadBadge.file){
                this.setState({
                    uploadBadgeStatus:nextProps.certificateState.uploadBadge.file,
                    certificate_badge:nextProps.certificateState.uploadBadge.file
                },()=>{
                     if(nextProps.certificateState && nextProps.certificateState.uploadBadge && nextProps.certificateState.uploadBadge.file){
                        this.props.uploadCertificateSignAction(this.state.sign)
                        // this.createSkill()
                     }
                })
            }
        }
        if(this.state.uploadSignStatus !== (nextProps.certificateState && nextProps.certificateState.uploadSign && nextProps.certificateState.uploadSign.file)){
            if(nextProps.certificateState.uploadSign.file){
                this.setState({
                    uploadSignStatus:nextProps.certificateState.uploadSign.file,
                    authorised_signatory:nextProps.certificateState.uploadSign.file
                },()=>{
                     if(nextProps.certificateState && nextProps.certificateState.uploadSign && nextProps.certificateState.uploadSign.file){
                        this.createSkill()
                     }
                })
            }
        }
	}
    componentDidUpdate(prevProp,prevState){
        if(this.state.class_id !== prevState.class_id) {
            if(this.state.class_id){
				let search = {
					class_id: this.state.class_id,
				}
				this.props.getSectionAction(search)
			}
		}
		if(this.state.section_id !== prevState.section_id) {
            if(this.state.class_id){
				let search = {
					section_id: this.state.section_id,
				}
				this.props.getSubjectAction(search)
                this.props.getStudentAction(search).then((res) => this.setState({ studentList: res }));
			}
		}
        if((this.state.subject_id !== prevState.subject_id) || (this.state.certificate_type !== prevState.certificate_type)) {
            let search = {
                subject_id: this.state.subject_id,
            }
            if(this.state.certificate_type === 'Examination'){
                this.props.getExamtAction(search)
            }
            if(this.state.certificate_type === 'Assignment'){
                this.props.getAssignmenttAction(search)
            }
		}
        
	}
    clearData =()=>{
        this.props.clearCreateCertificateAction()
        this.props.clearuploadCertificateLogoAction()
        this.props.clearuploadCertificateBadgeAction()
        this.props.clearuploadCertificateSignAction()
        this.toggleClose()
        this.props.history.push('/teacher/manage-certificate')
    }

    modalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={ this.state.modal }>
                    <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
                    <MDBModalBody>
                        <div className='alertContainer'>
                            <h4> Do you want to {this.state.editId ? 'Update' : 'save'} it ? </h4>
                            <h6> Please check the information before submitting</h6>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggleClose}>No</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.handleSave}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
			</MDBContainer>
		)
	}
    createSkill=()=>{
        let data = {
            certificate_type: this.state.certificate_type,
            top_text:this.state.top_text,
            class_id:this.state.class_id,
            section_id:this.state.section_id,
            subject_id:this.state.subject_id,
            ExaminationOrAssignment_id:this.state.ExaminationOrAssignment_id,
            student_id:this.state.student_id,
            mid_text:this.state.mid_text,
            end_text:this.state.end_text,
            date:this.state.date,
            certificate_badge:this.state.certificate_badge,
            certificate_logo: this.state.certificate_logo,
            authorised_signatory:this.state.authorised_signatory,
        }
        this.props.createCertificateAction(data, this.props.history)
    }
    handleSave=()=>{
        if(this.state.logo){
            this.props.uploadCertificateLogoAction(this.state.logo)
        }
        else{
            this.createSkill()
        }
    }
    submitHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
		if(event.target.checkValidity()) {
            this.setState({
                modal: true,
            })
		}
	}
    changeHandler = event => {
		this.setState({ [event.target.name]:event.target.value });
	};
    handleDate =(value)=>{
		this.setState({ date:value});
    }
    toggleClose = ()=>{
		this.setState({
			modal: false,
		})
	}
    handleLogo=(event)=>{
        this.setState({logo : event.target.files[0]})
    }
    handleBadge=(event)=>{
        this.setState({badge : event.target.files[0]})
    }
    handleSign=(event)=>{
        this.setState({sign : event.target.files[0]})
    }
    render(){
        const { activeTab } = this.state
        // if(!isLogined()) this.props.history.push('/')
        return(
            <>
            {this.modalContainer()}
            <div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">{this.state.editId ? 'Update New School' : 'Create New Certificate'} </h1>
							</div>
						</div>
					</div>
				</div>
                <div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
                                <form  onSubmit={this.submitHandler} noValidate>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Certification For :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select className="form-control show-tick" value = {this.state.certificate_type} name="certificate_type" onChange={this.changeHandler} required>
                                                                        <option value="">Select</option>
                                                                        <option value="Assignment">Assignment</option>
                                                                        <option value="Examination">Examination</option>
                                                                        
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Year:
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    2021
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Certificate Top Text:
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-9 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <textarea type="text" className="form-control show-tick"
                                                                    value = {this.state.top_text} name="top_text" 
                                                                    onChange={this.changeHandler} required placeholder='Enter Top Text'></textarea>
                                                                    
                                                                    <div className="invalid-feedback">
                                                                        required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Class:
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select name="class_id" value={this.state.class_id} 
                                                                    className="form-control show-tick" onChange={this.changeHandler} required
                                                                    >
                                                                        <option value="">Select Class</option>
                                                                        {this.state.classList && this.state.classList.map((item)=>{
                                                                            return <option value={item.id}>{item.name}</option>
                                                                        })}
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required class.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Section : 
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select name="section_id" value={this.state.section_id} 
                                                                    className="form-control show-tick" onChange={this.changeHandler} required
                                                                    disabled={this.state.class_id ? false : true}>
                                                                        <option value="">Select Section</option>
                                                                        {this.state.sectionList && this.state.sectionList.map((item)=>{
                                                                            return <option value={item.id}>{item.name}</option>
                                                                        })}
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required seaction.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Subject : 
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select name="subject_id" value={this.state.subject_id} 
                                                                    className="form-control show-tick" onChange={this.changeHandler} required
                                                                    disabled={this.state.class_id && this.state.section_id ? false : true}>
                                                                        <option value="">Select Subject</option>
                                                                        {this.state.subjectList && this.state.subjectList.map((item)=>{
                                                                            return <option value={item.id}>{item.name}</option>
                                                                        })}
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required subject.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Student : 
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select name="student_id" value={this.state.student_id} 
                                                                    className="form-control show-tick" onChange={this.changeHandler}
                                                                    disabled={ this.state.section_id ? false : true}
                                                                    >
                                                                        <option value="">Select Student</option>
                                                                        {this.state.studentList && this.state.studentList.map((item)=>{
                                                                            return <option value={item.id}>{item.name}</option>
                                                                        })}
                                                                    </select>
                                                                    
                                                                    <div className="invalid-feedback">
                                                                        required student.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Examination/ Assignment for which the certificate is issued
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    {
                                                                        this.state.certificate_type ==='Examination' ? 
                                                                    
                                                                    <select className="form-control show-tick" value = {this.state.ExaminationOrAssignment_id} name="ExaminationOrAssignment_id" onChange={this.changeHandler} required>
                                                                        <option value="">Select</option>
                                                                        {this.state.examList && this.state.examList.map((item)=>{
                                                                            return <option value={item.id}>{item.name}</option>
                                                                        })}
                                                                    </select> 
                                                                    : 
                                                                    <select className="form-control show-tick" value = {this.state.ExaminationOrAssignment_id} name="ExaminationOrAssignment_id" onChange={this.changeHandler} required>
                                                                        <option value="">Select</option>
                                                                        {this.state.assignmentList && this.state.assignmentList.map((item)=>{
                                                                            return <option value={item.id}>{item.name}</option>
                                                                        })}
                                                                    </select>
                                                                }
                                                                    <div className="invalid-feedback">
                                                                        required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Date:
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                <DatePicker
                                                                    selected={this.state.date}
                                                                    onChange={this.handleDate}
                                                                    placeholderText="Select date"
                                                                    className="form-control"
                                                                />
                                                                    <div className="invalid-feedback">
                                                                        requiredf.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                

                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Mid Text:
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-9 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <textarea type="text" className="form-control show-tick"
                                                                    value = {this.state.mid_text} name="mid_text" 
                                                                    onChange={this.changeHandler} required placeholder='Enter Mid Text'></textarea>
                                                                    
                                                                    <div className="invalid-feedback">
                                                                        required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                

                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    End Text:
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-9 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <textarea type="text" className="form-control show-tick"
                                                                    value = {this.state.end_text} name="end_text" 
                                                                    onChange={this.changeHandler} required placeholder='Enter End Text'></textarea>
                                                                    
                                                                    <div className="invalid-feedback">
                                                                        required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Student ID:
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" name="student_id" value={this.state.student_id} disabled/>
                                                                    <div className="invalid-feedback">
                                                                        required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Certificate Badge:
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="file" className="form-control"
                                                                        onChange={this.handleBadge} required={this.state.certificate_badge ? false: true} accept = "image/png, image/gif, image/jpeg"/>
                                                                    <div className="invalid-feedback">
                                                                        required file.
                                                                    </div>
                                                                    <div className="">
                                                                        {this.state.badge ? '' : this.state.certificate_badge}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Certificate Logo:
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="file" className="form-control"
                                                                        onChange={this.handleLogo} required={this.state.certificate_logo ? false: true} accept = "image/png, image/gif, image/jpeg"/>
                                                                    <div className="invalid-feedback">
                                                                        required file.
                                                                    </div>
                                                                    <div className="">
                                                                        {this.state.logo ? '' : this.state.certificate_logo}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Principal Name:
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" disabled name="principal_name" value={this.state.principalDetail && this.state.principalDetail.principal_name}/>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Authorized Signatory:
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="file" className="form-control"
                                                                        onChange={this.handleSign} required={this.state.authorised_signatory ? false: true} accept = "image/png, image/gif, image/jpeg"/>
                                                                    <div className="invalid-feedback">
                                                                        required file.
                                                                    </div>
                                                                    <div className="">
                                                                        {this.state.sign ? '' : this.state.authorised_signatory}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField submitBtn">
                                                    <MDBBtn color="primary" className='btn' onClick= {()=>{
                                                        this.props.history.push('/teacher/manage-certificate')
                                                    }}>Cancel</MDBBtn>
                                                    <MDBBtn color="primary" type={'submit'} className='btn'>{this.state.editId ? 'Update' : 'Save'}</MDBBtn>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            
                                </form>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
	certificateState: state.ManageCertificateReducer,
	utills: state.utillReducer,
})

const mapDispatchToProps = dispatch => ({
	createCertificateAction: (e, history) => dispatch(createCertificateAction(e, history)),
	clearCreateCertificateAction: (e) => dispatch(clearCreateCertificateAction(e)),
	getPrincipalAction: (e) => dispatch(getPrincipalAction(e)),
	
    uploadCertificateLogoAction: (e) => dispatch(uploadCertificateLogoAction(e)),
	clearuploadCertificateLogoAction: (e) => dispatch(clearuploadCertificateLogoAction(e)),
	uploadCertificateBadgeAction: (e) => dispatch(uploadCertificateBadgeAction(e)),
	clearuploadCertificateBadgeAction: (e) => dispatch(clearuploadCertificateBadgeAction(e)),
	uploadCertificateSignAction: (e) => dispatch(uploadCertificateSignAction(e)),
	clearuploadCertificateSignAction: (e) => dispatch(clearuploadCertificateSignAction(e)),
    
	getClassAction: (e) => dispatch(getClassAction(e)),
	getSectionAction: (e) => dispatch(getSectionAction(e)),
	getSubjectAction: (e) => dispatch(getSubjectAction(e)),
	getStudentAction: (e) => dispatch(getStudentAction(e)),
    getAssignmenttAction: (e) => dispatch(getAssignmenttAction(e)),
	getExamtAction: (e) => dispatch(getExamtAction(e)),

})

export default connect(mapStateToProps, mapDispatchToProps)(CreateCertificate)
