import { INSTITUTE_USER_FORGOT_PASSWORD } from '../../../actions/Constants/index'

const initialState = {
    status : null
}

export default (state = initialState, action) => {
	switch (action.type) {
		case INSTITUTE_USER_FORGOT_PASSWORD:
			return {
				...state,
                status : action.payload.status,
			}

        default:
            return state
    }
}
