import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import DateTimePicker from "react-datetime-picker";
import classnames from "classnames";
import Select from "react-select";
import moment from "moment";
import {
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import "./style.scss";
import {
  createOnlineMeetingAction,
  editOnlineMeetingAction,
  getOnlineMeetingEditByIdAction,
} from "../../../../actions/institute/Teacherdashboard/ManageOnlineMeeting/ManageOnlineMeetingAction";
import {
  getClassAction,
  getSubjectAction,
  getSectionAction,
  getStudentAction,
} from "../../../../actions/institute/Utils/utilsAction";

class CreateOnlineMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      input: {
        status: 1,
      },
      scheduleTime: "",
      modal: false,
      editId: this.props.match.params.id,
      dropDownDataClass: [],
      dropDownDataSection: [],
      dropDownDataSubject: [],
      dropDownDataStudent: [],
      message: "",
      selectedOptions:null,
      createStatus:''
    };
  }

  componentDidMount() {
    let localSchoolId = {
      school_id: JSON.parse(localStorage.getItem("school")).id,
    };
    this.props
      .getClassAction(localSchoolId)
      .then((res) => this.setState({ dropDownDataClass: res }));
    if (this.props.match.params.id) {
      this.props
        .getOnlineMeetingEditByIdAction(this.props.match.params.id)
        .then((res) =>{
          this.setState({ input:{...res,start_time : new Date(res.start_time),end_time :new Date(res.end_time)}, scheduleTime: res.shedule_at })
        }
        );
    }
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevState.input.class_id !== this.state.input.class_id) {
      if (this.state.input.class_id) {
        let search = {
          class_id: this.state.input.class_id,
        };
        this.props
          .getSectionAction(search)
          .then((res) => this.setState({ dropDownDataSection: res }));
      }
    }
    if (prevState.input.section_id !== this.state.input.section_id) {
      if (this.state.input.section_id) {
        let search = {
          section_id: this.state.input.section_id,
        };
        this.props
          .getSubjectAction(search)
          .then((res) => this.setState({ dropDownDataSubject: res }));
      }
    }
    if (prevState.input.section_id !== this.state.input.section_id) {
      if (this.state.input.section_id) {
        let search = {
          section_id: this.state.input.section_id,
        };
        this.props
          .getStudentAction(search)
          .then((res) =>{
            let arr = res.map((ele) => ({
              label: ele.name,
              value: ele.id,
            }));
            this.setState({ dropDownDataStudent: [{ label: "Select All", value: "all" }, ...arr] },()=>{
              if(this.state.input.student){
                this.getOptions(this.state.input.student)
              }
            })
          } );
      }
    }
    // {
    //   this.state.input.school_id === "" &&
    //     this.setState({
    //       input: {
    //         ...this.state.input,
    //         ["school_id"]: JSON.parse(localStorage.getItem("school")).id,
    //       },
    //     });
    // }
  }

  clearData = () => {
    this.setState({
      input: {},
      modal: false,
    });
    this.props.history.push("/teacher/manage-online-meeting");
  };

  toggle = () => {
    this.setState({
      modal: true,
    });
  };

  toggleClose = () => {
    this.setState({
      modal: false,
    });
  };

  changeHandler = (name, value) => {
    this.setState({ input: { ...this.state.input, [name]: value } });
    if(name === 'status'){
      this.setState({ message: "Meeting cancelled" });
    }
  };

  submitHandler = (event) => {
  
    event.preventDefault();
    event.target.className += " was-validated";
    if (event.target.checkValidity()) {
      this.setState({
        modal: true,
      });
    }
  };

  modalContainer = () => {
    return (
      <MDBContainer>
        <MDBModal isOpen={this.state.modal === true} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
          <MDBModalBody>
            <div className="alertContainer">
              <h4>
                {" "}
                Do you want to {this.state.editId ? "Update" : "save"} it ?{" "}
              </h4>
              <h6> Please check the information before submitting</h6>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              No
            </MDBBtn>
            <MDBBtn color="secondary" onClick={this.handleSave}>
              Yes
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  };
  getOptions = (options) =>{
    let arr = []
    this.state.dropDownDataStudent.map((item,i)=>{
      if(options.includes(item.value)){
        arr.push({
          label: item.label,
          value: item.value,
        })
      }
    })
    this.setState({selectedOptions:arr})
  }
  handleMultiSelectChange=(selectedOptions)=>{
    this.setState({selectedOptions})
  }
  handleSave = async () => {
    let _data = {
      ...this.state.input,
        start_time:  moment(this.state.input.start_time).format("YYYY-MM-DD hh:mm"),
        end_time:  moment(this.state.input.end_time).format("YYYY-MM-DD hh:mm"),
      student:this.state.selectedOptions && this.state.selectedOptions.map(e => e.value)
    }
    if (this.props.match.params.id) {
      await this.props.editOnlineMeetingAction({
        id: this.props.match.params.id,
        data: _data,
        history: this.props.history
      })
    } else {
      await this.props.createOnlineMeetingAction(_data,this.props.history)
    }
    // this.clearData();
  };

  inputFieldContainer = () => {
    const { activeTab } = this.state;
    return (
      <div className="container-fluid">
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1} className={classnames(["fade show"])}>
            <form onSubmit={this.submitHandler} noValidate>
              <div className="card">
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-group">Meeting Title</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <div className="input-group">
                          <input
                            value={this.state.input.title}
                            placeholder="Meeting Title"
                            onChange={(e) =>
                              this.changeHandler("title", e.target.value)
                            }
                            type="text"
                            className="form-control"
                            required
                          />
                          <div className="invalid-feedback">
                            required title field.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-group">Class Name</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <div className="input-group">
                          <select
                            value={this.state.input.class_id}
                            onChange={(e) =>
                              this.changeHandler("class_id", e.target.value)
                            }
                            required
                            className="form-control show-tick"
                          >
                            <option value="">Select Class</option>
                            {this.state.dropDownDataClass.map((ele) => (
                              <option value={ele.id}>{ele.name}</option>
                            ))}
                          </select>
                          <div className="invalid-feedback">
                            required class name field.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-group">Section Name</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <div className="input-group">
                          <select
                            value={this.state.input.section_id}
                            onChange={(e) =>
                              this.changeHandler("section_id", e.target.value)
                            }
                            disabled={!this.state.input.class_id}
                            required
                            className="form-control show-tick"
                          >
                            <option value="">Select Section</option>
                            {this.state.dropDownDataSection.map((ele) => (
                              <option value={ele.id}>{ele.name}</option>
                            ))}
                          </select>
                          <div className="invalid-feedback">
                            required section name field.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-group">Subject Name</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <div className="input-group">
                          <select
                            value={this.state.input.subject_id}
                            onChange={(e) =>
                              this.changeHandler("subject_id", e.target.value)
                            }
                            disabled={!this.state.input.section_id}
                            required
                            className="form-control show-tick"
                          >
                            <option value="">Select Subject</option>
                            {this.state.dropDownDataSubject.map((ele) => (
                              <option value={ele.id}>{ele.name}</option>
                            ))}
                          </select>
                          <div className="invalid-feedback">
                            required subject name field.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-group">Invite Students</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <div className="input-group">
                          <Select
                            options= {this.state.dropDownDataStudent}
                            isDisabled={!this.state.input.section_id}
                            value={this.state.selectedOptions}
                            isMulti
                            closeMenuOnSelect={false}
                            className="basic-multi-select w-100"
                            classNamePrefix="select"
                            onChange={selected => {
                              selected.find(option => option.value === "all")
                                ? this.handleMultiSelectChange(this.state.dropDownDataStudent.slice(1))
                                : this.handleMultiSelectChange(selected)
                            }}
                             
                          ></Select>
                          <div className="invalid-feedback">
                            required title field.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-group">Start Date and Time</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <div className="input-group">
                          <DateTimePicker
                            onChange={(e) =>
                              this.changeHandler(
                                "start_time",e
                              )
                            }
                            minDate={new Date()}
                            value={this.state.input.start_time }
                            className="form-control "
                          />
                          <div className="invalid-feedback">
                            required Start Date and Time field.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-group">End Date and Time</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <div className="input-group">
                        <DateTimePicker
                            onChange={(e) =>
                              this.changeHandler(
                                "end_time",e
                              )
                            }
                            minDate={this.state.input.start_time}
                            value={this.state.input.end_time }
                            className="form-control "
                          />
                          <div className="invalid-feedback">
                            required End Date and Time field.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.editId && (
                  <div className="col-lg-12 col-md-4 col-sm-6">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div></div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6">
                        <div></div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <div>
                          <MDBBtn
                            color="primary"
                            className="btn"
                            onClick={() => this.changeHandler("status", 0)}
                          >
                            Cancel Meeting
                          </MDBBtn>
                          <div className="imageFlag">{this.state.message}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                    {this.state.createStatus ? (
                        <div className="errorFlag">
                            {this.state.createStatus +' *'}
                        </div>
                    ):''}
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3 submitBtn">
                  <MDBBtn
                    color="primary"
                    className="btn"
                    onClick={this.clearData}
                  >
                    Close
                  </MDBBtn>
                  <MDBBtn color="primary" type="submit">
                    Save
                  </MDBBtn>
                </div>
              </div>
            </form>
          </TabPane>
        </TabContent>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">
                  {" "}
                  {this.state.editId ? "Update" : "Create"} Meeting
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.inputFieldContainer()}</div>
          {this.state.modal && this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createOnlineMeetingAction: (e, history) => dispatch(createOnlineMeetingAction(e, history)),
  editOnlineMeetingAction: (e, history) => dispatch(editOnlineMeetingAction(e, history)),
  getOnlineMeetingEditByIdAction: (e) =>
    dispatch(getOnlineMeetingEditByIdAction(e)),
  getClassAction: (e) => dispatch(getClassAction(e)),
  getSectionAction: (e) => dispatch(getSectionAction(e)),
  getSubjectAction: (e) => dispatch(getSubjectAction(e)),
  getStudentAction: (e) => dispatch(getStudentAction(e)),
});

export default connect(null, mapDispatchToProps)(CreateOnlineMeeting);
