import {SHOW_SNACKBAR} from '../../actions/Constants/index'

const initialState =  {snackbar: {
    message: "",
    open: false,
  }};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SNACKBAR:
        return {
          ...state,
          snackbar: {
            message: action.payload.message,
            open: action.payload.open,
            type: action.payload.type,
          },
        };
    default:
      return state;
  }
};
