import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import moment from "moment";
import Loader from "../../../common/Loader";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import "./style.scss";
import { getCircularAction } from "../../../../actions/institute/ManageCircular/manageCircularAction";
class CircularScreenStudents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      tableBody: { columns: [], rows: [] },
      modalId: null,
      search: {},
    };
  }

  componentDidMount() {
    this.props.getCircularAction().then((res) => {
      this.constructTableData();
    });
  }

  toggle = (institute, i) => () => {
    this.setState({
      modalId: i,
    });
  };

  toggleClose = () => {
    this.setState({
      modalId: null,
    });
  };

  handleSearch = (name, value) => {
    this.setState({ search: { ...this.state.search, [name]: value } });
  };

  searchHandler = async () => {
    let search = {
      title: this.state.search.title,
      description: this.state.search.description,
      created_at: this.state.search.created_at
        ? moment(this.state.search.created_at).format("YYYY-MM-DD")
        : null,
    };
    Object.keys(search).forEach(
      (key) => search[key] == "" && delete search[key]
    );
    await this.props.getCircularAction(search).then((res) => {
      this.constructTableData();
    });
  };

  clearHandler = async () => {
    this.setState({
      search: {},
    });
    await this.props.getCircularAction().then((res) => {
      this.constructTableData();
    });
  };

  tableColumns = () => [
    {
      label: "#",
      field: "id",
    },
    {
      label: "Circular Title",
      field: "title",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Name",
      },
    },
    {
      label: "Description",
      field: "description",
      width: 270,
    },
    {
      label: "Created Date",
      field: "created_at",
      width: 270,
    },
    {
      label: "Action",
      field: "action",
      width: 270,
    },
  ];

  constructTableData = () => {
    let tableData = {
      columns: this.tableColumns().filter((ele) => ele),
      rows: this.actionContainer(),
    };

    this.setState({
      tableBody: tableData,
    });
  };

  actionContainer = () => {
    return this.props.circularList.map((item, i) => {
      let obj = {
        id: i + 1,
        title: item.title,
        description: item.description,
        created_at: item.created_at,
        action: (
          <div className="actionContainer">
          <MDBIcon
            icon="eye"
            size="lg"
            className="icon"
            onClick={this.toggle(item, i)}
          />
        </div>
        ),
      };
      return obj;
    });
  };

  searchContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-4 md-3 col-sm-12">
                <input
                  type="text"
                  value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search.title
                  }
                  onChange={(e) => this.handleSearch("title", e.target.value)}
                  className="form-control"
                  placeholder="Circular Title"
                />
              </div>
              <div className="col-lg-4 md-3 col-sm-12">
                <input
                  type="text"
                  value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search.description
                  }
                  onChange={(e) =>
                    this.handleSearch("description", e.target.value)
                  }
                  className="form-control"
                  placeholder="Description"
                />
              </div>
              <div className="col-lg-4 md-3 col-sm-12">
                <DatePicker
                  selected={this.state.search.created_at}
                  onChange={(value) => this.handleSearch("created_at", value)}
                  placeholderText="Created date"
                  className="form-control "
                />
              </div>
            </div>
            <div className="btn-container">
              <div>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.searchHandler}
                >
                  Search
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.clearHandler}
                >
                  Clear
                </MDBBtn>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </TabPane>
    );
  };

  tableContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body" id="capture">
            <h1 className="page-title">Circular List</h1>
            {this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  searching={false}
                  sortRows={["title"]}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                />
              </div>
            )}
          </div>
        </div>
      </TabPane>
    );
  };

  modalContainer = () => {
    return (
      <div>
        {this.props.circularList.map((item, i) => {
          return (
            <MDBModal
              isOpen={this.state.modalId === i ? true : false}
              toggle={this.toggle}
              className="viewPDF-modal"
            >
              <MDBModalHeader toggle={this.toggleClose}>
                Circular Title
              </MDBModalHeader>
              <MDBModalBody
              className="viewPDF-modal"
              >
                {" "}
                  <iframe
                    style={{ width: "100%", height: "100%" }}
                    class="embed-responsive-item"
                    src={item.url}
                  ></iframe>
              </MDBModalBody>
            </MDBModal>
          );
        })}
      </div>
    );
  };

  pageContainer = () => {
    const { activeTab } = this.state;
    return (
      <TabContent activeTab={activeTab}>
        {this.searchContainer()}
        {this.tableContainer()}
      </TabContent>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">Circular List</h1>
                <ol className="breadcrumb page-breadcrumb">
                  <li className="breadcrumb-item">Search</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.pageContainer()}</div>
          {this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  circularList: state.getCircularReducer.list,
});

const mapDispatchToProps = (dispatch) => ({
  getCircularAction: (e) => dispatch(getCircularAction(e)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CircularScreenStudents);
