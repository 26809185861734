import { GET_SCHOOLS, 
    CREATE_SCHOOLS,
    EDIT_SCHOOLS, 
    GET_SCHOOLS_BY_ID,UPLOAD_SCHOOL } from '../../../actions/Constants/index'

const initialState = {
    list : [],
    editStatus:null,
    createStatus:null,
    detail:null,
    searchedList:[],
    uploadFileStatus: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_SCHOOLS:
        return {
            ...state,
            list : action.payload.list,
        }
        case EDIT_SCHOOLS:
        return {
            ...state,
            editStatus : action.payload.list,
        }
        case CREATE_SCHOOLS:
        return {
            ...state,
            createStatus : action.payload.list,
        }
        case GET_SCHOOLS_BY_ID:
        return {
            ...state,
            detail : action.payload.list,
        }
        case UPLOAD_SCHOOL:
            return {
                ...state,
                uploadFileStatus : action.payload.status,
            }
        default:
            return state
    }
}
