import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { MDBDataTable, MDBBtn, MDBIcon,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
// import './style.scss';
import moment from 'moment'
import DatePicker from "react-datepicker";
import { getAllSchoolAction, getClassAction,getSectionAction, getStudentAction, getSubjectAction, getTeacherAction } from '../../../../actions/institute/Utils/utilsAction';
import { getStudentSkillAction } from '../../../../actions/institute/Teacherdashboard/ManageSkill/ManageSkillAction';

class ViewStudentSkill extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			show: false,
			tableBody : { columns :[], rows: []},

			school_id:'',
			class_id:'',
			section_id:'',
			subject_id:'',
			student_id:'',
			type:'',
			year:'',

			classList:[],
            sectionList:[],
            subjectList:[],
			utills:{},
			skillsList:[]

		};
	}

	componentWillReceiveProps(nextProps){
	
		if(this.state.skillsList !== nextProps.skillState.studentSkillList){
			this.setState({
				skillsList: nextProps.skillState.studentSkillList
			},()=>{
				this.constructTableData()
			})
		}
		if(this.state.subjectList !== nextProps.utills.subjectList){
            this.setState({
                subjectList: nextProps.utills.subjectList
            })
        }
        if(this.state.sectionList !== nextProps.utills.sectionList){
            this.setState({
                sectionList: nextProps.utills.sectionList
            })
        }
		if(this.state.classList !== nextProps.utills.classList){
			this.setState({
				classList: nextProps.utills.classList
			})
		}
		
	}
	componentDidUpdate(prevProp,prevState){
		if (this.state.school_id !== prevState.school_id) {
            if(this.state.school_id){
				let search = {
					school_id: this.state.school_id
				}
				this.props.getClassAction(search)
			}
		}
		if(this.state.class_id !== prevState.class_id) {
            if(this.state.school_id && this.state.class_id){
				let search = {
					school_id: this.state.school_id,
					class_id: this.state.class_id,
				}
				this.props.getSectionAction(search)
			}
		}
		if(this.state.section_id !== prevState.section_id) {
            if(this.state.school_id && this.state.class_id){
				let search = {
					school_id: this.state.school_id,
					class_id: this.state.class_id,
					section_id: this.state.section_id,
				}
				this.props.getSubjectAction(search)
                this.props.getStudentAction(search).then((res) => this.setState({ studentList: res }));
			}
		}
	}
	componentDidMount(){
		if(localStorage.getItem('school')){
            let school = JSON.parse(localStorage.getItem('school'))
            this.setState({ school_id:school.id });
        }
		this.props.getStudentSkillAction()
	}
	constructTableData =()=>{
		let col= [
			{
				label: '#',
				field: 'id',
			},
			{
				label: 'Rule Type',
				field: 'rule_for',
				width: 270,
			},
		]
		col.push(
			{
				label: 'Class',
				field: 'class_name',
				width: 270,
			},
			{
				label: 'Section',
				field: 'section_name',
				width: 270,
			},
			{
				label: 'Subject',
				field: 'subject_name',
				width: 270,
			},
            {
				label: 'Session Year',
				field: 'year',
				width: 270,
			},
            {
				label: 'Student Name',
				field: 'student_name',
				width: 270,
			},
            {
				label: 'Rule For',
				field: 'name',
				width: 270,
			},
            {
				label: 'Rule',
				field: 'rule_condition',
				width: 270,
			},
            {
				label: 'Skill Name',
				field: 'skill_name',
				width: 270,
			},
            {
				label: 'Created Date',
				field: 'created_at',
				width: 270,
			},
            {
				label: 'Status',
				field: 'status',
				width: 270,
			},
		)
		let tableData = {
			columns: col,
			rows: 
				this.state.skillsList.map((item,i)=>{
					let obj = {
						id: i+1,
						rule_for: item.rule_for ? item.rule_for : '-',
						class_name: item.class_name ? item.class_name : '-',
						section_name: item.section_name ? item.section_name : '-',
						subject_name: item.subject_name ? item.subject_name : '-',
						year: item.year ? item.year : '-',
						student_name: item.student_name ? item.student_name : '-',
						name: item.name ? item.name : '-',
						rule_condition: item.rule_condition ? item.rule_condition+' Above' : '-',
						skill_name: item.skill_name ? item.skill_name : '-',
						created_at: moment(item.created_at).format("YYYY-MM-DD") ,
						status: item.status 

					}
					return obj
				})
		}	
		this.setState({
			tableBody: tableData
		})	
	}
	handleChange=(e)=>{
		this.setState({ [e.target.name] : e.target.value})
	}

	handleSearch=()=>{
		let search = {
			class_id:this.state.class_id,
			section_id:this.state.section_id,
			subject_id:this.state.subject_id,
			student_id:this.state.student_id,
			rule_for:this.state.type,
			year:this.state.year ? moment(this.state.year).format('YYYY') : '',
		}
		Object.keys(search).forEach((key) => (search[key] == '') && delete search[key]);
		this.props.getStudentSkillAction(search)
	}

	clearSearch=()=>{
		this.setState({
            class_id:'',
            section_id:'',
            subject_id:'',
			student_id:'',
            type:'',
            year:'',
		})
		this.props.getStudentSkillAction()
	}
	handleYear =(value)=>{
		this.setState({ year:value});
    }

	render() {
		const { activeTab } = this.state
		// if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">View Student Skills</h1>
								
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-lg-2 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.class_id} 
												onChange={ this.handleChange } name="class_id">
                                                    <option value="">Select Class</option>
													{this.state.classList && this.state.classList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
											<div className="col-lg-2 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.section_id} 
												onChange={ this.handleChange } name="section_id" disabled ={this.state.class_id && this.state.school_id ? false : true}>
                                                    <option value="">Select Section</option>
													{this.state.sectionList && this.state.sectionList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
											<div className="col-lg-2 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.subject_id} 
												onChange={ this.handleChange } name="subject_id" disabled ={this.state.section_id ? false : true}>
                                                    <option value="">Select Subject</option>
                                                    {this.state.subjectList && this.state.subjectList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.type} onChange={ this.handleChange } name="type">
                                                    <option value="">Select Type</option>
                                                    <option value="Examination">Examination</option>
                                                    <option value="Assignment">Assignment</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-sm-12">
                                                <DatePicker
                                                    selected={this.state.year}
													onChange={this.handleYear}
													placeholderText="Select Year"
													className="form-control "
													showYearPicker
													dateFormat="yyyy"
												/>
                                            </div> 
											<div className="col-lg-2 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.student_id} 
												onChange={ this.handleChange } name="student_id"
												disabled ={this.state.section_id ? false : true}>
                                                    <option value="">Select Student</option>
													{this.state.studentList && this.state.studentList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div> 
										</div>
                                        <div className={`row`}>
											<div className="card-body cardBodyContainer">
												<div className={`row btnContainer`}>
													<div className="col-lg-4 col-md-4 col-sm-6">
														<MDBBtn color="primary" className='btn' onClick={this.handleSearch}>Search</MDBBtn> &nbsp;
														<MDBBtn color="primary" className='btn' onClick={this.clearSearch}>Clear</MDBBtn>
													</div>
												</div>
											</div>
                                        </div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<MDBDataTable
												noBottomColumns
												className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
												entriesOptions={[5, 10, 20, 25]}
												entries={5}
												paging
												pagesAmount={15}
												data={this.state.tableBody}
												searchTop
												sortRows={['title']}
												searchBottom={false}
												onPageChange={{ activePage: 2, pagesAmount: 5 }}
												disableRetreatAfterSorting={true}
												searching = {false}
											/>
										</div>
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	role: state.settings.role,
	utills: state.utillReducer,
	skillState: state.ManageSkillReducer,
})

const mapDispatchToProps = dispatch => ({
	getAllSchoolAction: (e) => dispatch(getAllSchoolAction(e)),
	getClassAction: (e) => dispatch(getClassAction(e)),
	getSectionAction: (e) => dispatch(getSectionAction(e)),
	getSubjectAction: (e) => dispatch(getSubjectAction(e)),
	getTeacherAction: (e) => dispatch(getTeacherAction(e)),
	getStudentAction: (e) => dispatch(getStudentAction(e)),

	getStudentSkillAction: (e) => dispatch(getStudentSkillAction(e)),
	
})
export default connect(mapStateToProps, mapDispatchToProps)(ViewStudentSkill)
