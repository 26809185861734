import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import moment from "moment";
import Loader from "../../common/Loader";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import {
  searchRewardAction,
} from "../../../actions/institute/ManageReward/manageRewardAction";
import "./style.scss";

class ManageReward extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      tableBody: { columns: [], rows: [] },
      modalId: null,
      search: {},
      dropDownData: [],
    };
  }

  componentDidMount() {
    this.props.searchRewardAction().then((res) => {
      this.constructTableData();
    });
  }

  toggle = (institute, i) => () => {
    this.setState({
      modalId: i,
    });
  };

  toggleClose = () => {
    this.setState({
      modalId: null,
    });
  };

  handleSearch = (name, value) => {
    this.setState({ search: { ...this.state.search, [name]: value } });
  };

  searchHandler = async () => {
    let search = {
      title: this.state.search.title,
      status: this.state.search.status,
      created_at: this.state.search.created_at
        ? moment(this.state.search.created_at).format("YYYY-MM-DD")
        : null,
    };
    Object.keys(search).forEach(
      (key) => search[key] == "" && delete search[key]
    );
    await this.props.searchRewardAction(search);
    this.constructTableData();
  };

  clearHandler = async () => {
    this.setState({ search: {} });
    await this.props.searchRewardAction().then((res) => {
      this.constructTableData();
    });
  };

  constructTableData = () => {
    let tableData = {
      columns: [
        {
          label: "#",
          field: "id",
        },
        {
          label: "Reward Title",
          field: "title",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Reward Description",
          field: "description",
          width: 200,
        },
        {
            label: "Status",
            field: "status",
            width: 270,
          },
        {
          label: "Created Date",
          field: "created_at",
          width: 270,
        },
        {
          label: "Action",
          field: "action",
          width: 270,
        },
      ],
      rows: this.actionContainer(),
    };

    this.setState({
      tableBody: tableData,
    });
  };

  actionContainer = () => {
    return this.props.rewardList.map((item, i) => {
      let obj = {
        id: i + 1,
        title: item.title,
        description: item.description,
        created_at: item.created_at,
        status: item.status,
        action: (
          <div className="actionContainer">
            <MDBIcon
              icon="edit"
              size="lg"
              className="icon"
              onClick={() => {
                this.props.history.push(`/institute/edit-reward/${item.id}`);
              }}
            />
            <MDBIcon
              icon="eye"
              size="lg"
              className="icon"
              onClick={this.toggle(item, i)}
            />
          </div>
        ),
      };
      return obj;
    });
  };

  viewModalDetails = (item) => {
    return (
      <>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Reward Title</h6>
            <h6>{item.title}</h6>
          </div>
        </div>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Description </h6>
            <h6>{item.description}</h6>
          </div>
        </div>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Status</h6>
            <h6>{item.status}</h6>
          </div>
        </div>
        <div className="faq-modal-sub-container p-l-30">
          <div>
          <h6 className="font-weight-bold">Upload Images</h6>
            <img
              src={item.upload_link}
              width="500"
              height="100"
            />
          </div>
        </div>
      </>
    );
  };

  searchContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-sm-6">
                <div className="input-group">
                <input
                    type="text"
                    value={
                        Object.keys(this.state.search).length === 0
                          ? ""
                          : this.state.search["title"]
                      }
                    onChange={(e) => this.handleSearch("title", e.target.value)}
                    className="form-control"
                    placeholder="Reward Title"
                  />
                </div>
              </div>
              <div className="col-lg-4 md-3 col-sm-12">
                <DatePicker
                  selected={this.state.search["created_at"]}
                  onChange={(value) => this.handleSearch("created_at", value)}
                  placeholderText="Created date"
                  className="form-control "
                />
              </div>
              <div className="col-lg-3 md-3 col-sm-12">
                <select
                  value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search["status"]
                  }
                  onChange={(e) => this.handleSearch("status", e.target.value)}
                  className="form-control show-tick"
                >
                  <option value="">Status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
              
            </div>
            <div className={`row`}>
              <div className="card-body cardBodyContainer">
                <div className={`row btnContainer`}>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <MDBBtn
                      color="primary"
                      className="btn"
                      onClick={this.searchHandler}
                    >
                      Search
                    </MDBBtn>{" "}
                    &nbsp;
                    <MDBBtn
                      color="primary"
                      className="btn"
                      onClick={this.clearHandler}
                    >
                      Clear
                    </MDBBtn>{" "}
                  </div>
                  <div className="col-lg-8 col-md-4 col-sm-6 ">
                    <div className="card-body cardBodyContainer">
                      <div className={`row justify-content-end`}>
                        <div className="col-lg-2 col-md-4 col-sm-6 ">
                          <MDBBtn
                            color="primary"
                            className="btn"
                            onClick={() => {
                              this.props.history.push(
                                "/institute/create-reward"
                              );
                            }}
                          >
                            Create
                          </MDBBtn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TabPane>
    );
  };

  tableContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body" id="capture">
            <h1 className="page-title">Reward List</h1>
            {this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  searching={false}
                  sortRows={["title"]}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                />
              </div>
            )}
          </div>
        </div>
      </TabPane>
    );
  };

  modalContainer = () => {
    return (
      <div>
        {this.props.rewardList.map((item, i) => {
          return (
            <MDBModal
              isOpen={this.state.modalId === i ? true : false}
              toggle={this.toggle}
            >
              <MDBModalHeader toggle={this.toggleClose}>
                View Reward
              </MDBModalHeader>
              <MDBModalBody>{this.viewModalDetails(item)}</MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggleClose}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          );
        })}
      </div>
    );
  };

  pageContainer = () => {
    const { activeTab } = this.state;
    return (
      <TabContent activeTab={activeTab}>
        {this.searchContainer()}
        {this.tableContainer()}
      </TabContent>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">Manage Reward</h1>
                <ol className="breadcrumb page-breadcrumb">
                  <li className="breadcrumb-item">Search</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.pageContainer()}</div>
          {this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  rewardList: state.getRewardReducer,
});

const mapDispatchToProps = (dispatch) => ({
  searchRewardAction: (e) => dispatch(searchRewardAction(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageReward);
