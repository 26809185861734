import {
  INSTITUTE_USER_LOGIN,
  INSTITUTE_USER_LOGOUT,
  INSTITUTE_USER_FORGOT_PASSWORD,
  INSTITUTE_USER_CHANGE_PASSWORD,
} from "../../Constants/index";
import axios from "axios";
import { instituteUser } from "../../../utils/Api";
import { getToken } from "../../../utils/token";

export const userLoginAction = (data) => {
  return (dispatch) => {
    let url = `${instituteUser.userLogin}`,
      options = {
        url,
        method: "POST",
        data,
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        data = data.DATA;
        localStorage.setItem("isLogined", true);
        localStorage.setItem("token", data.access_token);
        localStorage.setItem("userId", data.user_id);
        localStorage.setItem("institute_id", data?.institute?.id);
        if (data.school) {
          localStorage.setItem("school", JSON.stringify(data.school));
        }
        if (data.section) {
          localStorage.setItem("section", JSON.stringify(data.section));
        }
        if (data.student_class) {
          localStorage.setItem("student_class", JSON.stringify(data.student_class));
        }

        if (data.institute) {
          localStorage.setItem("institute", JSON.stringify(data.institute));
        }
        if (data.role.role === "principal") {
          localStorage.setItem("instituteName", data.institute.name);
          localStorage.setItem("schoolName", data.school.name);
          localStorage.setItem("school_id", data.school.id);
        }
        dispatch({
          type: INSTITUTE_USER_LOGIN,
          payload: {
            isLogined: true,
            userInfo: null,
            password_state: data.password_state,
            access_token: data.access_token,
            refresh_token: data.refresh_token,
            role: data.role,
          },
        });
      } else {
        localStorage.setItem("isLogined", false);
        dispatch({
          type: INSTITUTE_USER_LOGIN,
          payload: {
            isLogined: false,
            userInfo: null,
            password_state: null,
            access_token: null,
            refresh_token: null,
            role: null,
          },
        });
      }
    });
  };
};

export const userLogoutAction = (e) => (dispatch) => {
  localStorage.clear();
  dispatch({
    type: INSTITUTE_USER_LOGOUT,
  });
};

export const userForgotPasswordAction = (data) => {
  return (dispatch) => {
    const url = `${instituteUser.userForgotPassword}`,
      options = {
        url,
        method: "POST",
        data,
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        data = data.DATA;
        dispatch({
          type: INSTITUTE_USER_FORGOT_PASSWORD,
          payload: {
            status: flag.status.msg,
          },
        });
      } else {
        dispatch({
          type: INSTITUTE_USER_FORGOT_PASSWORD,
          payload: {
            status: flag.status.msg,
          },
        });
      }
    });
  };
};

export const userChangePasswordAction = (data) => {
  return (dispatch) => {
    const url = `${instituteUser.userChangePassword}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "POST",
        data,
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        data = data.DATA;
        dispatch({
          type: INSTITUTE_USER_CHANGE_PASSWORD,
          payload: {
            status: true,
          },
        });
      } else {
        dispatch({
          type: INSTITUTE_USER_CHANGE_PASSWORD,
          payload: {
            status: false,
          },
        });
      }
    });
  };
};
