import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.scss";
class ManageChatScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  tableContainer = () => {
    return (
        <div class="chat-box">
        <div class="message-container">
          <div class="message-header">
            <div class="message-logo">
              <div class="chat-btn"></div>
            </div>
          </div>
          <div class="message-body">
            <h3>Please click the button below to join the chat.</h3>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod, ab laborum culpa ipsam beatae voluptates unde
              accusantium quia molestias totam nihil cupiditate tempora modi quis eaque facere incidunt! Voluptates, quidem.
            </p>
            <div class="message-link"><a href="https://discord.com/login" target="_blank">Join</a></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">Manage Chats Group</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body">
          <div className="container-fluid">{this.tableContainer()}</div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ManageChatScreen);
