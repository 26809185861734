import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import {  MDBBtn, MDBIcon } from 'mdbreact';
import { isLogined } from '../../../utils/token'
import './style.scss';
import moment from 'moment'
import { Link } from 'react-router-dom';
import { clearCategoryWithCountAction, getCategoryWithCountAction } from '../../../actions/Admin/ManageKnowledgeBase/manageKnowledgeBaseAction';


class CategoryList extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			categoryList:[],
			title:''
		};
	}

	componentWillReceiveProps(nextProps){
		if(this.state.categoryList !== nextProps.categoryList.list){
            this.setState({
                categoryList : nextProps.categoryList.list
            })
        }
	}

	componentDidMount(){
		this.props.getCategoryWithCountAction()
	}

	componentWillUnmount(){
		this.props.clearCategoryWithCountAction()
	}

	clearSearch=()=>{
		this.setState({
			title:''
		})
		this.props.getCategoryWithCountAction()
	}

	searchHandler =()=>{
		let arr = this.state.categoryList.filter(item => (item.name).toLowerCase().includes((this.state.title).toLowerCase()))
		this.setState({categoryList:arr})
	}

	handleChange =(event)=>{
		this.setState({ [event.target.name]:event.target.value });
	}

	render() {
		const { activeTab } = this.state
		if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Manage Knowledge Base </h1>
								<ol className="breadcrumb page-breadcrumb">
									<li className="breadcrumb-item">Search</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-lg-4 col-md-4 col-sm-6">
												<div className="input-group">
													<input type="text" className="form-control" placeholder="Title" 
													name ="title" value={this.state.title} onChange={this.handleChange}/>
												</div>
											</div>
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <MDBBtn color="primary" className='btn' onClick={this.searchHandler}>Search</MDBBtn> &nbsp;
                                                <MDBBtn color="primary" className='btn' onClick={this.clearSearch}>Clear</MDBBtn> &nbsp;
                                            </div>
										</div>
									</div>
								</div>
							</TabPane>
                            <section>
                                <div class="main-container">
                                    <ul class="grid-wrapper">
										{
											this.state.categoryList.map((item)=>{
												return <li>
															<div style={{padding :'5px'}}><Link to={`/institute/manage-knowledgebase/${item.name}`} class="link">{item.name}</Link></div>
															<span className='file-name' style={{color:'black'}}>Document count : {item.document_count}</span>
														</li>
											})
										}
                                    </ul>
                                </div>
                            </section>
						</TabContent>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	categoryList:state.getCategoryWithCountReducer
})

const mapDispatchToProps = dispatch => ({
	getCategoryWithCountAction: (e) => dispatch(getCategoryWithCountAction(e)),
	clearCategoryWithCountAction: (e) => dispatch(clearCategoryWithCountAction(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(CategoryList)
