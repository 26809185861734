import { GET_STUDY_MATERIAL} from "../../../../actions/Constants/index";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDY_MATERIAL:
      return action.payload;
    default:
      return state;
  }
};
