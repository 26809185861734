import { GET_ACEDEMICS, 
    CREATE_ACEDEMICS,
    EDIT_ACEDEMICS, 
    GET_ACEDEMICS_BY_ID, 
    SEARCH_ACEDEMICS,
    DELETE_ACEDEMICS,GET_TEACHER,
    UPLOAD_ACEDEMICS,SHOW_SNACKBAR} from '../../Constants/index';
import axios from 'axios';
import { manageAcedemics } from '../../../utils/Api'
import { getToken } from '../../../utils/token'

export const getAcedemicsAction = (params) => {
	return dispatch => {
        const url = `${manageAcedemics.searchAcedemics}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_ACEDEMICS,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_ACEDEMICS,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearGetAcedemicsAction = () => {
	return dispatch => {
        dispatch({
            type: GET_ACEDEMICS,
            payload: {
                list : []
            }
        })
    }
}

export const createAcedemicsAction = (data) => {
	return dispatch => {
        const url = `${manageAcedemics.createAcedemics}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: CREATE_ACEDEMICS,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
            }else {
                dispatch({
                    type: CREATE_ACEDEMICS,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const clearCreateAcedemicsAction = () => {
	return dispatch => {
        dispatch({
            type: CREATE_ACEDEMICS,
            payload: {
                list : null
            }
        })
    }
}

export const clearEditAcedemicsAction = () => {
	return dispatch => {
        dispatch({
            type: EDIT_ACEDEMICS,
            payload: {
                list : null
            }
        })
    }
}

export const editAcedemicsAction = ({id,data}) => {
	return dispatch => {
        const url = `${manageAcedemics.editAcedemics(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PATCH',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: EDIT_ACEDEMICS,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
            }else {
                dispatch({
                    type: EDIT_ACEDEMICS,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const getAcedemicsByIdAction = (id) => {
	return dispatch => {
        const url = `${manageAcedemics.getAcedemicsById(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_ACEDEMICS_BY_ID,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_ACEDEMICS_BY_ID,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearAcedemicsByIdAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_ACEDEMICS_BY_ID,
            payload: {
                list : {}
            }
        })
    }
}

export const searchAcedemicsAction = (params) => {
	return dispatch => {
        const url = `${manageAcedemics.searchAcedemics}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: SEARCH_ACEDEMICS,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: SEARCH_ACEDEMICS,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

// export const deleteAcedemicsAction = (data) => {
// 	return dispatch => {
//         const url = `${manageAcedemics.deleteCircular(data)}`,    
//         options = {
//             url,
//             headers:{
//                 Authorization : getToken(),
//             },
//             method: 'DELETE',
//         };
//         return axios(options)
//         .then(response => {
//             let data = response && response.data ? response.data : false;
//             const flag = data.SUCESS || data.ERROR
//             if (data && (flag.status.errcode === 0)) {
//                 data = data.DATA
//                 dispatch({
//                     type: DELETE_CIRCULAR,
//                     payload: {
//                         status : flag.status
//                     }
//                 })
//             }else {
//                 dispatch({
//                     type: DELETE_CIRCULAR,
//                     payload: {
//                         status : {}
//                     }
//                 })
//             }
//         })
//     }
// }

export const uploadAcedemicsFileAction = (data) => {
	return dispatch => {
        let formData = new FormData();
        formData.append('csv', data);
        const url = `${manageAcedemics.uploadAcedemics}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data : formData
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = flag.status
                dispatch({
                    type: UPLOAD_ACEDEMICS,
                    payload: {
                        status : data
                    }
                })
            }else {
                data = flag.status
                dispatch({
                    type: UPLOAD_ACEDEMICS,
                    payload: {
                        status : data
                    }
                })
            }
        }).catch(err =>{
            dispatch({
                type: UPLOAD_ACEDEMICS,
                payload: {
                    list : "Error in uploading file"
                }
            })
        })
    }
}

export const clearUploadAcedemicsAction = (id) => {
	return dispatch => {
        dispatch({
            type: UPLOAD_ACEDEMICS,
            payload: {
                list : {}
            }
        })
    }
}

export const getTeacherAction = () => {
	return dispatch => {
        const url = `${manageAcedemics.getTeacher}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_TEACHER,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_TEACHER,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}