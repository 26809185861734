import React, { Component } from 'react'
import AnswerDetail from '../../TeacherDashboard/Examination/AnswerDetail'
import { connect } from 'react-redux';
import './style.scss';
import { getSubmittedAssignmentAnswerWithEvaluatedStatusAction } from '../../../../actions/institute/Teacherdashboard/ManageAssignment/ManageAssignmentAction';

class AssignmentResult extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
            
            id: this.props.match.params.id,
            studentId:this.props.match.params.studentId,
            createStatus: null,
            detail:{}
		};
	}
    componentDidMount(){
        this.props.getSubmittedAssignmentAnswerWithEvaluatedStatusAction(this.state.id,this.state.studentId)  
            
    }
    componentWillReceiveProps(nextProps){
        if(this.state.detail !== nextProps.assignment.submittedAnswersWithEvaluatedStatus){
            this.setState({detail: nextProps.assignment.submittedAnswersWithEvaluatedStatus})
        }
	}
    
    render() {
        return (
            <div className="section-body">
                <div className="row">
                    <div className="col-lg-7 col-md-2 col-sm-12" ></div>
                    <div className="col-lg-2 col-md-2 col-sm-12" >
                        <h6><b>Result : </b>{this.state.detail && this.state.detail.mark_evaluation ? this.state.detail.mark_evaluation.result : ''}</h6>
                    </div>
                    <div className="col-lg-3 col-md-2 col-sm-12" >
                        <h6><b>Percentage : </b>
                        {this.state.detail && this.state.detail.mark_evaluation ? this.state.detail.mark_evaluation.percentage : ''}</h6>
                    </div>
                    
                </div>
                <div className="row">
                    <div className="col-lg-10 col-md-2 col-sm-12" >
                        <AnswerDetail questions={this.state.detail && this.state.detail.qeustion_list } title={ this.state.detail && this.state.detail.assignment_info && this.state.detail.assignment_info[0].assignment_title}/>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 result-box" >
                        <div className='marks'>
                            <h6>Total Marks:{this.state.detail && this.state.detail.mark_evaluation ? this.state.detail.mark_evaluation.total_marks : ''}</h6>
                        </div>
                        <div className='marks'>
                            <h6>Total Obtained Marks:
                            {this.state.detail && this.state.detail.mark_evaluation ? this.state.detail.mark_evaluation.obtained_marks : ''}
                            </h6>
                        </div>
                        <div className='marks'>
                            <h6>Remarks:{this.state.detail && this.state.detail.mark_evaluation && this.state.detail.mark_evaluation.remarks ? this.state.detail.mark_evaluation.remarks : '-'}</h6>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    assignment: state.ManageAssignmentReducer,
})

const mapDispatchToProps = dispatch => ({
	getSubmittedAssignmentAnswerWithEvaluatedStatusAction: (e,e1) => dispatch(getSubmittedAssignmentAnswerWithEvaluatedStatusAction(e,e1)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentResult)
