export const adminMenu = [
  {
    id: 1,
    icon: "credit-card",
    label: "Manage Institutes",
    to: "/super-admin/manage-institutes",
  },
  {
    id: 2,
    icon: "credit-card",
    label: "Manage Receipts",
    to: "/super-admin/manage-receipts",
  },
  {
    id: 3,
    icon: "credit-card",
    label: "Knowledge Base",
    to: "/super-admin/knowledge-base",
  },
  {
    id: 4,
    icon: "credit-card",
    label: "Manage FAQ",
    to: "/super-admin/manage-faq",
  },
  {
    id: 5,
    icon: "credit-card",
    label: "Customer Support",
    to: "/super-admin/customer-support",
  },
  {
    id: 6,
    icon: "credit-card",
    label: "Manage profile",
    to: "/super-admin/manage-profile",
  },
];

export const instituteMenu = [
  // School Information for only principal role
  {
    id: 10,
    icon: "credit-card",
    label: "School Information",
    to: "/institute/schoolinformation",
    roles: ["principal"],
  },
  // //Institute and Principal Role
  {
    id: 1,
    icon: "credit-card",
    label: "Institute Information",
    to: "/institute/information",
    roles: ["institute admin"],
  },
  {
    id: 2,
    icon: "credit-card",
    label: "Manage School",
    to: "/institute/manage-school",
    roles: ["institute admin"],
  },
  {
    id: 3,
    icon: "credit-card",
    label: "Manage Principal",
    to: "/institute/manage-principal",
    roles: ["institute admin"],
  },
  {
    id: 4,
    icon: "credit-card",
    label: "Manage Customer Support",
    to: "/institute/manage-customersupport",
    roles: ["institute admin"],
  },
  {
    id: 5,
    icon: "credit-card",
    label: "Manage Knowledge Base",
    to: "/institute/manage-knowledgebase",
    roles: ["institute admin"],
  },
  {
    id: 6,
    icon: "credit-card",
    label: "FAQ",
    to: "/institute/view-faq",
    roles: ["institute admin"],
  },
  {
    id: 7,
    icon: "credit-card",
    label: "Manage User",
    to: "/institute/manage-user",
    roles: ["institute admin", "principal"],
  },
  {
    id: 8,
    icon: "credit-card",
    label: "Manage Acedemics",
    to: "/institute/manage-acedemics",
    roles: ["institute admin"],
  },
  {
    id: 9,
    icon: "credit-card",
    label: "Manage Class",
    to: "/institute/manage-class",
    roles: ["institute admin", "principal"],
  },
  {
    id: 10,
    icon: "credit-card",
    label: "Manage Section",
    to: "/institute/manage-section",
    roles: ["institute admin", "principal"],
  },
  {
    id: 11,
    icon: "credit-card",
    label: "Manage Subject",
    to: "/institute/manage-subject",
    roles: ["institute admin", "principal"],
  },
  {
    id: 12,
    icon: "credit-card",
    label: "Manage Circular",
    to: "/institute/manage-circular",
    roles: ["institute admin", "principal"],
  },
  {
    id: 13,
    icon: "credit-card",
    label: "Newsletter",
    to: "/institute/manage-newsletter",
    roles: ["institute admin", "principal"],
  },
  {
    id: 14,
    icon: "credit-card",
    label: "Manage Announcement",
    to: "/institute/manage-announcement",
    roles: ["institute admin", "principal"],
  },
  {
    id: 15,
    icon: "credit-card",
    label: "Manage Events",
    to: "/institute/manage-events",
    roles: ["institute admin", "principal"],
  },
  {
    id: 16,
    icon: "credit-card",
    label: "Manage Gallery",
    to: "/institute/manage-gallery",
    roles: ["institute admin", "principal"],
  },
  {
    id: 17,
    icon: "credit-card",
    label: "Manage Feedback",
    to: "/institute/manage-feedback",
    roles: ["institute admin", "principal"],
  },
  {
    id: 18,
    icon: "credit-card",
    label: "Manage Achievements",
    to: "/institute/students-achievements",
    roles: ["institute admin", "principal"],
  },
  {
    id: 19,
    icon: "credit-card",
    label: "Manage Reward",
    to: "/institute/manage-reward",
    roles: ["institute admin"],
  },
  {
    id: 20,
    icon: "credit-card",
    label: "My Profile",
    to: "/institute/my-profile",
    roles: ["institute admin", "principal"],
  },

  //Teacher Role
  {
    id: 1,
    icon: "credit-card",
    label: "Class Information",
    to: "/teacher/class-information",
    roles: ["teacher"],
  },
  {
    id: 2,
    icon: "credit-card",
    label: "Manage Student users",
    to: "/teacher/manage-student-users",
    roles: ["teacher"],
  },
  {
    id: 3,
    icon: "credit-card",
    label: "Manage Announcement",
    to: "/teacher/manage-announcement",
    roles: ["teacher"],
  },
  {
    id: 4,
    icon: "credit-card",
    label: "Study Material",
    to: "/teacher/study-material",
    roles: ["teacher"],
  },
  {
    id: 5,
    icon: "credit-card",
    label: "Manage Gallery",
    to: "/teacher/manage_gallery",
    roles: ["teacher"],
  },
  {
    id: 6,
    icon: "credit-card",
    label: "Manage Assignment",
    to: "/teacher/manage-assignment",
    roles: ["teacher"],
  },
  {
    id: 7,
    icon: "credit-card",
    label: "Manage Examination",
    to: "/teacher/manage-examination",
    roles: ["teacher"],
  },
  {
    id: 8,
    icon: "credit-card",
    label: "Manage Chats Group",
    to: "/teacher/manage-chat-screen",
    roles: ["teacher"],
  },
  {
    id: 9,
    icon: "credit-card",
    label: "Manage Feedback",
    to: "/teacher/manage-feedback",
    roles: ["teacher"],
  },
 
  {
    id: 10,
    icon: "credit-card",
    label: "Manage Certificate",
    to: "/teacher/manage-certificate",
    roles: ["teacher"],
  },
  {
    id: 11,
    icon: "credit-card",
    label: "Manage Skill",
    to: "/teacher/manage-skill",
    roles: ["teacher"],
  },
  {
    id: 12,
    icon: "credit-card",
    label: "Manage Goal",
    to: "/teacher/manage-goal",
    roles: ["teacher"],
  },
  {
    id: 13,
    icon: "credit-card",
    label: "Manage Achievement Rule",
    to: "/teacher/manage-achievement-rule",
    roles: ["teacher"],
  },
  {
    id: 14,
    icon: "credit-card",
    label: "Students Achievements",
    to: "/teacher/students-achievements",
    roles: ["teacher"],
  },
  {
    id: 15,
    icon: "credit-card",
    label: "Manage Online Meeting",
    to: "/teacher/manage-online-meeting",
    roles: ["teacher"],
  },
  {
    id: 16,
    icon: "credit-card",
    label: "My Profile",
    to: "/teacher/my-profile",
    roles: ["teacher"],
  },

  //Student Role
  {
    id: 1,
    icon: "credit-card",
    label: "Class Information",
    to: "/student/class-information",
    roles: ["student"],
  },
  {
    id: 2,
    icon: "credit-card",
    label: "Manage Assignment",
    to: "/student/manage-assignment",
    roles: ["student"],
  },
  {
    id: 3,
    icon: "credit-card",
    label: "Study Material",
    to: "/student/study_material",
    roles: ["student"],
  },
  {
    id: 4,
    icon: "credit-card",
    label: "Manage Examination",
    to: "/student/manage-examination",
    roles: ["student"],
  },
  {
    id: 5,
    icon: "credit-card",
    label: "View Circulars",
    to: "/student/view-circulars",
    roles: ["student"],
  },
  {
    id: 6,
    icon: "credit-card",
    label: "Newsletter",
    to: "/student/newsletter",
    roles: ["student"],
  },
  {
    id: 7,
    icon: "credit-card",
    label: "View Announcement",
    to: "/student/view-announcement",
    roles: ["student"],
  },
  {
    id: 8,
    icon: "credit-card",
    label: "View Events",
    to: "/student/view-events",
    roles: ["student"],
  },
  {
    id: 9,
    icon: "credit-card",
    label: "View Gallery",
    to: "/student/manage_gallery",
    roles: ["student"],
  },
  {
    id: 10,
    icon: "credit-card",
    label: "Manage Chats Group",
    to: "/student/manage-chat-screen",
    roles: ["student"],
  },
  {
    id: 11,
    icon: "credit-card",
    label: "Manage Feedback",
    to: "/student/manage-feedback",
    roles: ["student"],
  },
  {
    id: 12,
    icon: "credit-card",
    label: "Manage Task",
    to: "/student/manage_task",
    roles: ["student"],
  },
  {
    id: 13,
    icon: "credit-card",
    label: "View Achievements",
    to: "/student/view-achievements",
    roles: ["student"],
  },
  {
    id: 14,
    icon: "credit-card",
    label: "View Certificates",
    to: "/student/view-certificates",
    roles: ["student"],
  },
  {
    id: 15,
    icon: "credit-card",
    label: "Manage Skill",
    to: "/student/manage-skills",
    roles: ["student"],
  },
  {
    id: 16,
    icon: "credit-card",
    label: "Manage Goal",
    to: "/student/manage-goal",
    roles: ["student"],
  },
  {
    id: 17,
    icon: "credit-card",
    label: "Online Meeting",
    to: "/student/online-meeting",
    roles: ["student"],
  },
  {
    id: 18,
    icon: "credit-card",
    label: "My Profile",
    to: "/student/my-profile",
    roles: ["student"],
  },
  
  //Parent Role
  {
    id: 1,
    icon: "credit-card",
    label: "Class Information",
    to: "/parent/class-information",
    roles: ["parent"],
  },
  {
    id: 2,
    icon: "credit-card",
    label: "Manage Assignment",
    to: "/parent/manage-assignment",
    roles: ["parent"],
  },
  {
    id: 3,
    icon: "credit-card",
    label: "Manage Examination",
    to: "/parent/manage-examination",
    roles: ["parent"],
  },
  {
    id: 4,
    icon: "credit-card",
    label: "View Circulars",
    to: "/parent/view-circulars",
    roles: ['parent'],
  },
  {
    id: 5,
    icon: "credit-card",
    label: "Newsletter",
    to: "/parent/newsletter",
    roles: ['parent'],
  },
  {
    id: 6,
    icon: "credit-card",
    label: "View Announcement",
    to: "/parent/view-announcement",
    roles: ['parent'],
  },
  {
    id: 7,
    icon: "credit-card",
    label: "View Events",
    to: "/parent/view-events",
    roles: ["parent"],
  },
  {
    id: 8,
    icon: "credit-card",
    label: "Manage Feedback",
    to: "/parent/manage-feedback",
    roles: ["parent"],
  },
  {
    id: 9,
    icon: "credit-card",
    label: "View Achievements",
    to: "/parent/view-achievements",
    roles: ["parent"],
  },
  
  {
    id: 10,
    icon: "credit-card",
    label: "View Certificates",
    to: "/parent/view-certificates",
    roles: ["parent"],
  },
  {
    id: 11,
    icon: "credit-card",
    label: "Manage Skill",
    to: "/parent/manage-skills",
    roles: ["parent"],
  },
  {
    id: 12,
    icon: "credit-card",
    label: "Manage Goal",
    to: "/parent/manage-goal",
    roles: ["parent"],
  },
  {
    id: 13,
    icon: "credit-card",
    label: "My Profile",
    to: "/parent/my-profile",
    roles: ["parent"],
  },
];

export const universityMenu = [
  // {
  // 	"id": 1,
  // 	"icon": "dashboard",
  // 	"label": "Dashboard",
  // 	"to": "/"
  // },
  {
    id: 1,
    icon: "dashboard",
    label: "Dashboard",
    to: "/dashboard",
  },
  {
    id: 2,
    icon: "black-tie",
    label: "Professors",
    to: "/professors",
  },
  {
    id: 3,
    icon: "user-circle-o",
    label: "Staff",
    to: "/staff",
  },
  {
    id: 4,
    icon: "users",
    label: "Students",
    to: "/students",
  },
  {
    id: 5,
    icon: "users",
    label: "Departments",
    to: "/departments",
  },
  {
    id: 6,
    icon: "graduation-cap",
    label: "Courses",
    to: "/courses",
  },
  {
    id: 7,
    icon: "book",
    label: "Library",
    to: "/library",
  },
  {
    id: 8,
    icon: "bullhorn",
    label: "Holiday",
    to: "/holiday",
  },
  {
    id: "Extra",
    label: "Extra",
  },
  {
    id: 10,
    icon: "calendar",
    label: "Calender",
    to: "/events",
  },
  {
    id: 11,
    icon: "comments-o",
    label: "Chat App",
    to: "/chat",
  },
  {
    id: 12,
    icon: "address-book",
    label: "Contact",
    to: "/contact",
  },
  {
    id: 13,
    icon: "folder",
    label: "FileManager",
    to: "/fileManager",
  },
  {
    id: 14,
    icon: "map",
    label: "OurCentres",
    to: "/ourCentre",
  },
  {
    id: 15,
    icon: "camera-retro",
    label: "Gallery",
    to: "/gallery",
  },
];
