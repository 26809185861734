import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import DatePicker from "react-datepicker";
import classnames from "classnames";
import moment from "moment";
import {
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import "./style.scss";
import {
  createTaskAction,
  getTaskEditByIdAction,
  editTaskAction,
} from "../../../../actions/institute/StudentDashboard/ManageTask/manageTaskAction";

class CreateTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      input: {
        status: "1",
      },
      modal: false,
      editId: this.props.match.params.id,
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props
        .getTaskEditByIdAction(this.props.match.params.id)
        .then((res) => this.setState({ input: res }));
    }
  }

  clearData = () => {
    this.setState({
      input: {},
      modal: false,
    });
    this.props.history.push("/student/manage_task");
  };

  toggle = () => {
    this.setState({
      modal: true,
    });
  };

  toggleClose = () => {
    this.setState({
      modal: false,
    });
  };

  changeHandler = (name, value) => {
    this.setState({ input: { ...this.state.input, [name]: value } });
  };

  submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (event.target.checkValidity()) {
      this.setState({
        modal: true,
      });
    }
  };

  modalContainer = () => {
    return (
      <MDBContainer>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
          <MDBModalBody>
            <div className="alertContainer">
              <h4>
                {" "}
                Do you want to {this.state.editId ? "Update" : "save"} it ?{" "}
              </h4>
              <h6> Please check the information before submitting</h6>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              No
            </MDBBtn>
            <MDBBtn color="secondary" onClick={this.handleSave}>
              Yes
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  };

  handleSave = async () => {
    const updatedInput = {
      task_title: this.state.input.task_title,
      task_description: this.state.input.task_description,
      status: this.state.input.status,
      deadline: moment(this.state.input.deadline).format("YYYY-MM-DD"),
    };
    if (this.props.match.params.id) {
      await this.props.editTaskAction({
        id: this.props.match.params.id,
        data: updatedInput,
        history: this.props.history
      });
    } else {
      await this.props.createTaskAction(updatedInput,this.props.history);
    }
    this.clearData();
  };

  inputFieldContainer = () => {
    const { activeTab } = this.state;
    return (
      <div className="container-fluid">
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1} className={classnames(["fade show"])}>
            <form onSubmit={this.submitHandler} noValidate>
              <div className="card">
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-group">Task Title</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <div className="input-group">
                          <input
                            value={this.state.input?.task_title}
                            placeholder="Task title"
                            onChange={(e) =>
                              this.changeHandler("task_title", e.target.value)
                            }
                            type="text"
                            className="form-control"
                            required
                          />
                          <div className="invalid-feedback">
                            required Task title field.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-group">Task Description</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <div className="input-group">
                          <textarea
                            value={this.state.input?.task_description}
                            placeholder="Task Description"
                            onChange={(e) =>
                              this.changeHandler(
                                "task_description",
                                e.target.value
                              )
                            }
                            type="text"
                            className="form-control"
                            required
                            id="w3review"
                            name="w3review"
                            rows="4"
                            cols="50"
                            wrap="hard"
                            maxlength="200"
                          />
                          <div className="invalid-feedback">
                            required Task Description field.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-group">Status</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <div className="input-group">
                          <select
                            value={this.state.input.status}
                            onChange={(e) =>
                              this.changeHandler("status", e.target.value)
                            }
                            required
                            className="form-control show-tick"
                          >
                            <option value="0">Pending</option>
                            <option value="1">Inprogress</option>
                            <option value="2">Completed</option>
                          </select>
                          <div className="invalid-feedback">
                            required Status field.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-group">Deadline</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <div className="input-group">
                          <DatePicker
                            selected={
                              this.state.editId
                                ? this.state.input["deadline"]
                                  ? new Date(this.state.input["deadline"])
                                  : new Date()
                                : this.state.input["deadline"]
                            }
                            onChange={(value) =>
                              this.changeHandler("deadline", value)
                            }
                            placeholderText="Select date"
                            className="form-control "
                          />
                          <div className="invalid-feedback">
                            required date field.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3 submitBtn">
                  <MDBBtn
                    color="primary"
                    className="btn"
                    onClick={this.clearData}
                  >
                    Close
                  </MDBBtn>
                  <MDBBtn color="primary" className="btn" type="submit">
                    Save
                  </MDBBtn>
                </div>
              </div>
            </form>
          </TabPane>
        </TabContent>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">
                  {" "}
                  {this.state.editId ? "Update" : "Create"} Task
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.inputFieldContainer()}</div>
          {this.state.modal && this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createTaskAction: (e,history) => dispatch(createTaskAction(e,history)),
  editTaskAction: (e,history) => dispatch(editTaskAction(e,history)),
  getTaskEditByIdAction: (e) => dispatch(getTaskEditByIdAction(e)),
});

export default connect(null, mapDispatchToProps)(CreateTask);
