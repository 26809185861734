import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from "../../../common/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { MDBDataTable, MDBBtn, MDBIcon } from 'mdbreact';
import './style.scss';
import moment from 'moment'
import { getClassAction,getExamtAction,getSectionAction, getSubjectAction, getSubjectForExamAction, getTeacherAction } from '../../../../actions/institute/Utils/utilsAction';
import { getAchievementAction } from '../../../../actions/institute/Teacherdashboard/ManageAchievement/ManageAchievementAction';


class ManageAchievementRule extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			tableBody : { columns :[], rows: []},

			school_id:'',
			class_id:'',
			section_id:'',
			subject_id:'',
			exam_id:'',

			classList:[],
            sectionList:[],
            subjectList:[],
            achievementList:[],
			utills:{},
            examList:[],


		};
	}

	componentWillReceiveProps(nextProps){
	
		
		if(this.state.subjectList !== nextProps.utills.subjectForExam){
            this.setState({
                subjectList: nextProps.utills.subjectForExam
            })
        }
        if(this.state.sectionList !== nextProps.utills.sectionList){
            this.setState({
                sectionList: nextProps.utills.sectionList
            })
        }
		if(this.state.classList !== nextProps.utills.classList){
			this.setState({
				classList: nextProps.utills.classList
			})
		}
		if(this.props.utills.examList !== nextProps.utills.examList){
            this.setState({
                examList: nextProps.utills.examList
            })
        }
		if(this.state.achievementList !== nextProps.achievementState.list){
            this.setState({
                achievementList: nextProps.achievementState.list
            },()=>{
				this.constructTableData()
			})
        }

		
	}
	componentDidUpdate(prevProp,prevState){
		if (this.state.school_id !== prevState.school_id) {
            if(this.state.school_id){
				let search = {
					school_id: this.state.school_id
				}
				this.props.getClassAction(search)
			}
		}
		if(this.state.class_id !== prevState.class_id) {
            if(this.state.school_id && this.state.class_id){
				let search = {
					school_id: this.state.school_id,
					class_id: this.state.class_id,
				}
				this.props.getSectionAction(search)
			}
		}
		if(this.state.section_id !== prevState.section_id) {
            if(this.state.school_id && this.state.class_id){
				let search = {
					school_id: this.state.school_id,
					class_id: this.state.class_id,
					section_id: this.state.section_id,
				}
				this.props.getSubjectForExamAction()
				this.props.getExamtAction(search)

			}
		}
	}
	componentDidMount(){
        if(localStorage.getItem('school')){
            let school = JSON.parse(localStorage.getItem('school'))
            this.setState({ school_id:school.id });
        }
		this.props.getAchievementAction();
		
	}
	constructTableData =()=>{
		let col= [
			{
				label: '#',
				field: 'id',
			},
			{
				label: 'Rule Name',
				field: 'rule_name',
				width: 270,
			},
            {
				label: 'Rule For',
				field: 'rule_for',
				width: 270,
			},
			{
				label: 'Rule Condition',
				field: 'rule_condition',
				width: 270,
			},
						
			{
				label: 'Class',
				field: 'class_name',
				width: 270,
			},
            {
				label: 'Section',
				field: 'section_name',
				width: 270,
			},
            {
				label: 'Subject',
				field: 'subject_name',
				width: 270,
			},
            {
				label: 'Achievement Badge',
				field: 'badge',
				width: 270,
			},
            {
				label: 'Action',
				field: 'action',
				width: 270,
			}
		]
		let tableData = {
			columns: col,
			rows: 
				this.state.achievementList.map((item,i)=>{
					let obj = {
						id: i+1,
						rule_name: item.rule_name ? item.rule_name : '-',
						rule_for: item.rule_for ? item.rule_for : '-',
						rule_condition: item.rule_condition ? item.rule_condition : '-',
						class_name: item.class_name ? item.class_name : '-',
						section_name: item.section_name ? item.section_name : '-',
						subject_name: item.subject_name ? item.subject_name : '-',
						badge:item.badge ? <img src={item.badge} alt="badge" width="20%"/> : '-',
						action: (<div>
							<MDBIcon  icon="edit" size="lg" style={{margin:'5px',cursor:'pointer'}}  onClick={()=>{
								this.props.history.push(`/teacher/edit-new-rule/${item.id}`)
							}}/>
						</div>)
					}
					return obj
				})
		}	
		this.setState({
			tableBody: tableData
		})	
	}
	handleChange=(e)=>{
		this.setState({ [e.target.name] : e.target.value})
	}

	componentWillUnmount(){
		// this.props.clearGetFeedbackDropdownAction()
		// this.props.clearGetFeedbackAction()
	}

	handleSearch=()=>{
		let search = {
			class_id:this.state.class_id,
			section_id:this.state.section_id,
			subject_id:this.state.subject_id,
			exam_id:this.state.exam_id,

		}
		Object.keys(search).forEach((key) => (search[key] == '') && delete search[key]);
		this.props.getAchievementAction(search)
	}

	clearSearch=()=>{
		this.setState({
            exam_id:'',
            class_id:'',
            section_id:'',
            subject_id:''
		})
		this.props.getAchievementAction()
	}

	render() {
		const { activeTab } = this.state
		// if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Manage Achievements Rules</h1>
								<ol className="breadcrumb page-breadcrumb">
								</ol>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-lg-3 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.class_id} 
												onChange={ this.handleChange } name="class_id">
                                                    <option value="">Select Class</option>
													{this.state.classList && this.state.classList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
											<div className="col-lg-3 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.section_id} 
												onChange={ this.handleChange } name="section_id" disabled ={this.state.class_id ? false : true}>
                                                    <option value="">Select Section</option>
													{this.state.sectionList && this.state.sectionList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
											<div className="col-lg-3 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.subject_id} 
												onChange={ this.handleChange } name="subject_id" disabled ={this.state.section_id ? false : true}>
                                                    <option value="">Select Subject</option>
                                                    {this.state.subjectList && this.state.subjectList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            {/* <div className="col-lg-3 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.exam_id} 
												onChange={ this.handleChange } name="exam_id" disabled ={this.state.section_id ? false : true}>
                                                    <option value="">Select Exam</option>
													{this.state.examList && this.state.examList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div> */}
										    </div>
                                           
                                            <div className="btn-container">
												<div>
                                                <MDBBtn color="primary" className='btn' onClick={this.handleSearch}>Search</MDBBtn>
                                                <MDBBtn color="primary" className='btn' onClick={this.clearSearch}>Clear</MDBBtn>
												</div>
												<div>
												<MDBBtn color="primary" className='btn' onClick={()=>{this.props.history.push('/teacher/add-new-rule')}}>Add New Rule</MDBBtn> 
												</div>
										</div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
									<h1 className="page-title">Achievement Rules List</h1>
									{this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={['title']}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching = {false}
                />
              </div>
            )}
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	utills: state.utillReducer,
	achievementState: state.ManageAchievementReducer,

})

const mapDispatchToProps = dispatch => ({
	getClassAction: (e) => dispatch(getClassAction(e)),
	getSectionAction: (e) => dispatch(getSectionAction(e)),
	// getSubjectAction: (e) => dispatch(getSubjectAction(e)),
	getSubjectForExamAction: (e) => dispatch(getSubjectForExamAction(e)),
	getTeacherAction: (e) => dispatch(getTeacherAction(e)),
	getExamtAction: (e) => dispatch(getExamtAction(e)),

	getAchievementAction: (e) => dispatch(getAchievementAction(e)),
	
})
export default connect(mapStateToProps, mapDispatchToProps)(ManageAchievementRule)
