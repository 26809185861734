import { GET_CLASS,SHOW_SNACKBAR } from "../../Constants/index";
import axios from "axios";
import { manageClass } from "../../../utils/Api";
import { getToken } from "../../../utils/token";

export const createClassAction = (data,history) => {
  return (dispatch) => {
    const url = `${manageClass.createClass}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "POST",
        data,
      };
      return axios(options).then((response) => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR;
        if (data && flag.status.errcode === 0) {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "success", message: data.SUCESS.status.msg, open: true },
          });
         history.push("/institute/manage-class");
        } else {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "error", message: data.ERROR.status.msg, open: true },
          });
        }
        return data;
      });
  };
};

export const getClassEditByIdAction = (id) => {
  return (dispatch) => {
    const url = `${manageClass.getEditClassById(id)}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        return data.DATA;
      }
      return {};
    });
  };
};

export const editClassAction = ({ id, data,history }) => {
  return (dispatch) => {
    const url = `${manageClass.EditClassById(id)}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "PATCH",
        data,
      };
      return axios(options).then((response) => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR;
        if (data && flag.status.errcode === 0) {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "success", message: data.SUCESS.status.msg, open: true },
          });
         history.push("/institute/manage-class");
        } else {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "error", message: data.ERROR.status.msg, open: true },
          });
        }
        return data;
      });
  };
};

export const searchClassAction = (params) => {
  let params2= localStorage.getItem("school") ?{
    ...params,
    school_id:JSON.parse(localStorage.getItem("school")).id
  }
 : params 
  return (dispatch) => {
    const url = `${manageClass.searchClass}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params2,
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_CLASS,
          payload: data.DATA,
        });
      } else {
        dispatch({
          type: GET_CLASS,
          payload: [],
        });
      }
    });
  };
};
