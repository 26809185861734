import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import Loader from "../../../common/Loader";
import moment from "moment";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import "./style.scss";
import {
  deleteStudyMaterialAction,
  getStudyMaterialDataAction,
  searchStudyMaterialAction,
  GoLiveStudyMaterialAction,
  deletAllStudyMaterialAction,
} from "../../../../actions/institute/Teacherdashboard/ManageStudyMaterial/ManageStudyMaterialAction";
import {
  getClassAction,
  getSectionAction,
  getSubjectAction,
} from "../../../../actions/institute/Utils/utilsAction";
import UploadStudy from "./UploadStudy";

class StudyMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      show: false,
      tableBody: { columns: [], rows: [] },
      modal: false,
      selectedImage: null,
      deleteModal: false,
      deleteId: {
        ids: []
      },
      openCreateModal: false,
      dropDownDataClass: [],
      dropDownDataSection: [],
      dropDownDataSubject: [],
      file: "",
      file_type: "",
      gallery_type: "",
      search: {
        school_id: "",
      },
      view: "list",
      deleteAllModal: false,
    };
  }

  fetchStudyMaterialDataAction = () => {
    this.props.getStudyMaterialDataAction().then((res) => {
      this.constructTableData();
    });
  }

  componentDidMount() {
    this.fetchStudyMaterialDataAction()
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevState.search.school_id !== this.state.search.school_id) {
      if (this.state.search.school_id) {
        let search = {
          school_id: this.state.search.school_id,
        };
        this.props
          .getClassAction(search)
          .then((res) => this.setState({ dropDownDataClass: res }));
      }
    }
    if (prevState.search.class_id !== this.state.search.class_id) {
      if (this.state.search.class_id) {
        let search = {
          class_id: this.state.search.class_id,
        };
        this.props
          .getSectionAction(search)
          .then((res) => this.setState({ dropDownDataSection: res }));
      }
    }
    if (prevState.search.section_id !== this.state.search.section_id) {
      if (this.state.search.section_id) {
        let search = {
          section_id: this.state.search.section_id,
        };
        this.props
          .getSubjectAction(search)
          .then((res) => this.setState({ dropDownDataSubject: res }));
      }
    }
    {
      this.state.search.school_id === "" &&
        this.setState({
          search: {
            ...this.state.search,
            ["school_id"]: JSON.parse(localStorage.getItem("school")).id,
          },
        });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  GoliveHandler = async (id) => {
    await this.props.GoLiveStudyMaterialAction(id);
   await this.fetchStudyMaterialDataAction()
  };

  toggle = (value) => {
    this.setState({
      modal: true,
      selectedImage: value,
    });
  };

  toggleClose = () => {
    this.setState({
      modal: false,
      selectedImage: null,
      deleteModal: false,
      openCreateModal: false,
      deleteAllModal: false,
    });
  };

  openDeleteModal = (value) => {
    this.setState({
      deleteModal: true,
      deleteId: {...this.state.deleteId,['ids']:[value.id]},
    });
  };

  deleteGallery = async () => {
    await this.props.deleteStudyMaterialAction(this.state.deleteId);
    this.setState({
      deleteModal: false,
    });
    this.toggleClose();
    await this.fetchStudyMaterialDataAction()
  };

  tableColumns = () => [
    {
      label: "#",
      field: "id",
    },
    {
      label: "Class Name",
      field: "class_name",
      width: 270,
    },
    {
      label: "Section Name",
      field: "section_name",
      width: 270,
    },
    {
      label: "Subject Name",
      field: "subject_name",
      width: 270,
    },
    {
      label: "File Name",
      field: "file_name",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Name",
      },
    },
    {
      label: "file Type",
      field: "file_type",
      width: 270,
    },
    {
      label: "Session Year",
      field: "created_at",
      width: 270,
    },
    {
      label: "Status",
      field: "status",
      width: 270,
    },
    {
      label: "Action",
      field: "action",
      width: 300,
    },
  ];

  constructTableData = () => {
    let tableData = {
      columns: this.tableColumns().filter((ele) => ele),
      rows: this.actionContainer(),
    };

    this.setState({
      tableBody: tableData,
    });
  };

  actionContainer = () => {
    return this.props.studyMaterialList.map((item, i) => {
      let obj = {
        id: i + 1,
        class_name: item.class_name,
        section_name: item.section_name,
        subject_name: item.subject_name,
        file_name: item.file_name,
        file_type: item.file_type,
        created_at: item.session_year,
        status: item.status === 0 ? "Draft" : "Golive",
        action: (
          <div
            className="actionContainer"
            style={{ padding: "0px !important" }}
          >
            {item.status === 0 && (
              <MDBIcon
                icon="upload"
                size="lg"
                  style={{cursor:'pointer' }}
                onClick={() => {
                  this.GoliveHandler(item.id);
                }}
              />
            )}
            <MDBIcon
              icon="eye"
              size="lg"
              style={{ margin: "5px" ,cursor:'pointer'}}
              onClick={() => {
                this.toggle(item);
              }}
            />
            <MDBIcon
              icon="trash"
              size="lg"
              style={{ margin: "5px",cursor:'pointer' }}
              onClick={() => {
                this.openDeleteModal(item);
              }}
            />
          </div>
        ),
      };
      return obj;
    });
  };

  handleSearch = (name, value) => {
    this.setState({ search: { ...this.state.search, [name]: value } });
  };

  searchHandler = async () => {
    let search = {
      class_id: this.state.search.class_id,
      section_id: this.state.search.section_id,
      subject_id: this.state.search.subject_id,
      student_id: this.state.search.student_id,
      year: moment(this.state.search.year).format("YYYY"),
      status: this.state.search.status,
    };
    Object.keys(search).forEach(
      (key) => search[key] == "" && delete search[key]
    );
    await this.props.searchStudyMaterialAction(search);
    this.constructTableData();
  };

  clearHandler = async () => {
    this.setState({
      search: {},
      dropDownDataClass: [],
    });
    await this.fetchStudyMaterialDataAction()
    {
      this.setState({
        search: {
          ...this.state.search,
          ["school_id"]: JSON.parse(localStorage.getItem("school")).id,
        },
      });
    }
  };

  getCircularDetail = () => {
    return (
      <div className="card-body">
        <div className="row">
          <div className="col-lg-12 col-md-4 col-sm-6">
            {this.state.selectedImage &&
            (this.state.selectedImage.file_type === "JPEG" ||
              this.state.selectedImage.file_type === "PNG") ? (
              <img src={this.state.selectedImage.file} alt="imageurl" />
            ) : (
              <i class="fa fa-file-pdf-o fa-5x" aria-hidden="true"></i>
            )}
          </div>
        </div>
      </div>
    );
  };
  deleteAllGallery = async () => {
    await this.props.deletAllStudyMaterialAction();
    this.setState({
      deleteModal: false,
    });
    this.toggleClose();
    await this.fetchStudyMaterialDataAction();
  };
  deleteAll = () => {
    return (
      <MDBContainer>
        <MDBModal isOpen={this.state.deleteAllModal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggleClose}>
            Delete All Study Material
          </MDBModalHeader>
          <MDBModalBody>
            <div className="">Are you sure to delete all Study Material?</div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.deleteAllGallery}>
              Delete
            </MDBBtn>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  };

  forceDownload = (blob, filename) => {
    var a = document.createElement("a");
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
  downloadResource = (url, filename) => {
    if (!filename) filename = url.split("\\").pop().split("/").pop();
    fetch(url, {
      headers: new Headers({
        Origin: window.location.origin,
      }),
      mode: "cors",
    })
      .then((response) => response.blob())
      .then((blob) => {
        let blobUrl = window.URL.createObjectURL(blob);
        this.forceDownload(blobUrl, filename);
      })
      .catch((e) => console.error(e));
  };

  searchContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-2 col-md-4 col-sm-6">
                <div className="input-group">
                  <select
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search.class_id
                    }
                    onChange={(e) =>
                      this.handleSearch("class_id", e.target.value)
                    }
                    className="form-control show-tick"
                  >
                    <option value="">Select Class</option>
                    {this.state.dropDownDataClass.map((ele) => (
                      <option value={ele.id}>{ele.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <div className="input-group">
                  <select
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search.section_id
                    }
                    onChange={(e) =>
                      this.handleSearch("section_id", e.target.value)
                    }
                    disabled={!this.state.search.class_id}
                    className="form-control show-tick"
                  >
                    <option value="">Select Section</option>
                    {this.state.dropDownDataSection.map((ele) => (
                      <option value={ele.id}>{ele.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <div className="input-group">
                  <select
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search.subject_id
                    }
                    onChange={(e) =>
                      this.handleSearch("subject_id", e.target.value)
                    }
                    disabled={!this.state.search.section_id}
                    className="form-control show-tick"
                  >
                    <option value="">Select Subject</option>
                    {this.state.dropDownDataSubject.map((ele) => (
                      <option value={ele.id}>{ele.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <select
                  value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search.status
                  }
                  onChange={(e) => this.handleSearch("status", e.target.value)}
                  className="form-control show-tick"
                >
                  <option value="">Status</option>
                  <option value="0">Draft</option>
                  <option value="1">Go Live</option>
                </select>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
              <DatePicker
                    selected={this.state.search.year}
                    onChange={(value) => this.handleSearch("year", value)}
                    placeholderText="Select Year"
                    className="form-control "
                    showYearPicker
                    dateFormat="yyyy"
                  />
              </div>
            </div>
            <div className={`row`}>
              <div className="card-body cardBodyContainer">
                <div className={`row btnContainer`}>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <MDBBtn
                      color="primary"
                      className="btn"
                      onClick={this.searchHandler}
                    >
                      Search
                    </MDBBtn>{" "}
                    &nbsp;
                    <MDBBtn
                      color="primary"
                      className="btn"
                      onClick={this.clearHandler}
                    >
                      Clear
                    </MDBBtn>
                  </div>

                  <div className="col-lg-8 col-md-4 col-sm-6 ">
                    <div className="card-body cardBodyContainer">
                      <div className={`row justify-content-end`}>
                        <div className="col-lg-2 col-md-4 col-sm-6 ">
                          <MDBBtn
                            color="primary"
                            className="btn"
                            onClick={() => {
                              this.setState({ openCreateModal: true });
                            }}
                          >
                            Upload
                          </MDBBtn>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 ">
                          <MDBBtn
                            color="primary"
                            className="btn"
                            onClick={() => {
                              this.setState({ deleteAllModal: true });
                            }}
                          >
                            Delete All
                          </MDBBtn>
                        </div>
                        <div className="col-lg-3 col-md-2 col-sm-12">
                          <select
                            className="form-control show-tick"
                            name="view"
                            value={this.state.view}
                            onChange={this.handleChange}
                          >
                            <option value="list" selected>
                              List View
                            </option>
                            <option value="grid">Grid View</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TabPane>
    );
  };

  tableContainer = () => {
    if (this.state.view === "list") {
      return (
        <TabPane tabId={1} className={classnames(["fade show"])}>
          <div className="card">
            <div className="card-body">
            <h1 className="page-title">Study Material List</h1>
            {this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={["title"]}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching={false}
                />
              </div>
            )}
            </div>
          </div>
        </TabPane>
      );
    } else {
      return (
        <section>
          <div class="main-container">
            <ul class="grid-wrapper">
              {this.props.studyMaterialList.map((item) => {
                if (item.file_type === "JPEG" || item.file_type === "PNG") {
                  return (
                    <li
                      onClick={() => {
                        this.downloadResource(item.file);
                      }}
                    >
                      <img src={item.file} alt="imageurl" />
                    </li>
                  );
                } else {
                  return (
                    <li
                      onClick={() => {
                        this.downloadResource(item.file);
                      }}
                    >
                      <i class="fa fa-file-pdf-o fa-5x" aria-hidden="true"></i>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </section>
      );
    }
  };

  pageContainer = () => {
    const { activeTab } = this.state;
    return (
      <TabContent activeTab={activeTab}>
        {this.searchContainer()}
        {this.tableContainer()}
      </TabContent>
    );
  };

  modalContainer = () => {
    return (
      <div>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggleClose}></MDBModalHeader>
          <MDBModalBody>
            <div className="">{this.getCircularDetail()}</div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              Close
            </MDBBtn>
            <MDBBtn
              color="secondary"
              onClick={() => {
                this.downloadResource(
                  this.state.selectedImage ? this.state.selectedImage.file : ""
                );
              }}
            >
              Download
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal isOpen={this.state.deleteModal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggleClose}>
            Delete Study Material
          </MDBModalHeader>
          <MDBModalBody>
            <div className="">Are you sure to delete this Study Material?</div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.deleteGallery}>
              Delete
            </MDBBtn>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">Study Material</h1>
                <ol className="breadcrumb page-breadcrumb">
                  <li className="breadcrumb-item">Search</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.pageContainer()}</div>
          {this.state.modal && this.modalContainer()}
          {this.state.deleteModal && this.modalContainer()}
          {this.state.openCreateModal && (
            <UploadStudy
              toggleClose={this.toggleClose}
              fetchStudyMaterialDataAction={this.fetchStudyMaterialDataAction}
            />
          )}
        </div>
        {this.state.deleteAllModal && this.deleteAll()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  studyMaterialList: state.getManageStudyMaterialReducer,
});

const mapDispatchToProps = (dispatch) => ({
  GoLiveStudyMaterialAction: (e) => dispatch(GoLiveStudyMaterialAction(e)),
  searchStudyMaterialAction: (e) => dispatch(searchStudyMaterialAction(e)),
  getStudyMaterialDataAction: (e) => dispatch(getStudyMaterialDataAction(e)),
  deleteStudyMaterialAction: (e) => dispatch(deleteStudyMaterialAction(e)),
  deletAllStudyMaterialAction: (e) => dispatch(deletAllStudyMaterialAction(e)),
  getSubjectAction: (e) => dispatch(getSubjectAction(e)),
  getSectionAction: (e) => dispatch(getSectionAction(e)),
  getClassAction: (e) => dispatch(getClassAction(e)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StudyMaterial);
