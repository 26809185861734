import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { MDBDataTable, MDBBtn } from 'mdbreact';
import Loader from "../../../common/Loader";
// import './style.scss';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { cleargetExaminationListForStudentAction, getExaminationListForParentAction, getExaminationListForStudentAction } from '../../../../actions/institute/Teacherdashboard/ManageExamination/ManageExaminationAction';
import { Link } from 'react-router-dom';
import InstructionModal from './InstructionModal'

class List extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			show: false,
			tableBody : { columns :[], rows: []},

            examinationList:[],
			examination:{},

			school_id:'',
			class_id:'',
			section_id:'',

			status:'',
			evaluation_status:'',
			deadline:'',
			session_year:'',

			role:this.props.role,
			instructionPopup:false,
			examId:''
		};
	}

	componentWillReceiveProps(nextProps){
		if(this.state.role === 'parent'){
			if(this.props.examination.parentList !== nextProps.examination.parentList){
				this.setState({
					examinationList: nextProps.examination.parentList
				},()=>{
					this.constructTableData()
				})
			}
		}
		else{
			if(this.props.examination.studentList !== nextProps.examination.studentList){
				this.setState({
					examinationList: nextProps.examination.studentList
				},()=>{
					this.constructTableData()
				})
			}
		}
		
	}
	componentDidMount(){
		this.getApi()
	}
	getApi=()=>{
		if(this.state.role === 'parent'){
			if(localStorage.getItem('school') && localStorage.getItem('student_class') && localStorage.getItem('section')){
				this.setState({
					school_id:JSON.parse(localStorage.getItem('school')).id,
					class_id:JSON.parse(localStorage.getItem('student_class')).id,
					section_id:JSON.parse(localStorage.getItem('section')).id,
				},()=>{
					this.props.getExaminationListForParentAction({
						school_id : this.state.school_id,
						class_id :this.state.class_id,
						section_id : this.state.section_id
					})
				})
			}
		}
		else{
			if(localStorage.getItem('school') && localStorage.getItem('student_class') && localStorage.getItem('section')){
				this.setState({
					school_id:JSON.parse(localStorage.getItem('school')).id,
					class_id:JSON.parse(localStorage.getItem('student_class')).id,
					section_id:JSON.parse(localStorage.getItem('section')).id,
				},()=>{
					this.props.getExaminationListForStudentAction({
						school_id : this.state.school_id,
						class_id :this.state.class_id,
						section_id : this.state.section_id
					})
				})
			}
		}
	}
	openModal=(id)=>{
		this.setState({
			examId:id,
			instructionPopup:true
		})
	}
	constructTableData =()=>{
		let tableData = {
			columns: [
				{
					label: '#',
					field: 'id',
				},
				{
					label: 'Exam Title',
					field: 'examination_title',
					width: 150,
					attributes: {
						'aria-controls': 'DataTable',
						'aria-label': 'Name',
					},
				},
				{
					label: 'Class Name',
					field: 'class_name',
					width: 200,
				},
				{
					label: 'Section Name',
					field: 'section_name',
					width: 270,
				},
				{
					label: 'Subject',
					field: 'subject_name',
					width: 270,
				},
                {
					label: 'Tutor Name',
					field: 'teacher_name',
					width: 270,
				},
				
                {
					label: 'Exam Date',
					field: 'deadline',
					width: 270,
				},
				{
					label: 'Status',
					field: 'status',
                    width: 270,
				},
                {
					label: 'Evaluation Status',
					field: 'evaluation_status',
					width: 270,
				},
				{
					label: 'Marks Obtained',
					field: 'obtained_marks',
					width: 270,
				},
				{
					label: 'Result',
					field: 'result',
					width: 270,
				},
				{
					label: 'Percentage',
					field: 'percentage',
					width: 270,
				},
				{
					label: 'Action',
					field: 'action',
					width: 300,
				},
			],
			rows: 
				this.state.examinationList.map((item,i)=>{
					let obj = {
						id: i+1,

						examination_title: item.examination_title ? item.examination_title : '-',
						class_name: item.class_name ? item.class_name : '-',
						section_name: item.section_name ? item.section_name : '-',
						subject_name: item.subject_name ? item.subject_name : '-',
						teacher_name: item.teacher_name ? item.teacher_name : '-',
						status: item.status ? item.status : '-',
						evaluation_status: item.evaluation_status ? item.evaluation_status : '-',
						obtained_marks: item.mark_info && item.mark_info.obtained_marks ? item.mark_info.obtained_marks : '-',
						result: item.mark_info && item.mark_info.result? item.mark_info.result : '-',
						percentage: item.mark_info && item.mark_info.percentage? item.mark_info.percentage : '-',
						deadline: item.deadline ? moment(item.deadline).format('YYYY-MM-DD') : '-',
						
						action: (<>
						{
							this.state.role === 'parent' ? 
							<>
								{
									item.evaluation_status === 'Evaluated' ?  
									<div>
										<Link to={`/parent/exam-result/${item.id}/${item.mark_info.submitted_student_map_id}`}>Result</Link>
									</div> : ''
								}
							</>
							:
							<>
								{
									item.evaluation_status === 'Evaluated' ?  
									<div>
										<Link to={`/student/exam-result/${item.id}/${item.mark_info.submitted_student_map_id}`}>Result</Link>
									</div> : ''
								}
								{
									item.status === 'Not submitted' ? 
									<div>
										<MDBBtn color="primary" onClick={()=>{this.openModal(item.id)}}>Take Exam</MDBBtn>
									</div>: ''
								}
							</>
						}
						
						
					</>)
					}
					return obj
				})
		}	
		this.setState({
			tableBody: tableData
		})	
	}
	
	handleChange=(e)=>{
		this.setState({ [e.target.name] : e.target.value})
	}
	
	clearSearch=()=>{
		this.setState({
			session_year:'',
			deadline:'',
			evaluation_status:'',
			status:'',
		})
		this.getApi()
	}
	handleDate =(value)=>{
		this.setState({ deadline:value});
    }
	handleYear =(value)=>{
		this.setState({ session_year:value});
    }
	handleSearch=()=>{
		let search = {
            session_year:this.state.session_year ? moment(this.state.session_year).format('YYYY') :'',
            deadline: this.state.deadline ? moment(this.state.deadline).format('YYYY-MM-DD') : '',
            status: this.state.status ,
            evaluation_status: this.state.evaluation_status ,
		}
		Object.keys(search).forEach((key) => (search[key] == '') && delete search[key]);
		let _arr = this.state.role === 'parent' ? this.props.examination.parentList : this.props.examination.studentList
		if(search.status){
			const arr = _arr.filter(item => item.status === search.status)
			this.setState({examinationList:arr},()=>{
				this.constructTableData()
			})
		}
		if(search.evaluation_status){
			const arr = _arr.filter(item => item.evaluation_status === search.evaluation_status)
			this.setState({examinationList:arr},()=>{
				this.constructTableData()
			})
		}
		if(search.deadline){
			const arr = _arr.filter(item => item.deadline === search.deadline)
			this.setState({examinationList:arr},()=>{
				this.constructTableData()
			})
		}
		if(search.session_year){
			const arr = _arr.filter(item => moment(item.createdAt).format('YYYY') === search.session_year)
			this.setState({examinationList:arr},()=>{
				this.constructTableData()
			})
		}
	}
	render() {
		const { activeTab } = this.state
		// if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Manage Examination</h1>
							</div>
						</div>
					</div>
				</div>

				<div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-lg-3 col-md-2 col-sm-12" >
                                                <select className="form-control show-tick" value={ this.state.status} onChange={ this.handleChange } name="status">
													<option value="">Status</option>
													<option value="Submitted">Submitted</option>
													<option value="Not submitted">Not Submitted</option>
												</select>
                                            </div>
											<div className="col-lg-3 col-md-2 col-sm-12" >
												<select className="form-control show-tick" value={ this.state.evaluation_status} onChange={ this.handleChange } 
												name="evaluation_status">
													<option value="">Select Evaluation Status</option>
													<option value="Evaluated">Evaluated</option>
													<option value="Pending">Pending</option>
														
												</select>
											</div>
                                            <div className="col-lg-3 col-md-2 col-sm-12">
                                                <DatePicker
                                                    selected={this.state.deadline}
                                                    onChange={this.handleDate}
                                                    placeholderText="Select Exam Date"
                                                    className="form-control"
                                                />
											</div>
                                            <div className="col-lg-3 col-md-2 col-sm-12">
                                                <DatePicker
                                                    selected={this.state.session_year}
                                                    onChange={this.handleYear}
                                                    placeholderText="Select Year"
                                                    className="form-control"
                                                    showYearPicker
                                                    dateFormat="yyyy"
                                                />
											</div>
											<div className="btn-container">
												<div>
												<MDBBtn color="primary" className='btn' onClick={()=>{this.handleSearch() }}>Search</MDBBtn> 
												<MDBBtn color="primary" className='btn' onClick={this.clearSearch}>Clear</MDBBtn>
											</div>
											</div>
										</div>
                                        <div className={`row`}>
											<div className="card-body cardBodyContainer">
												<div className={`row btnContainer`}>
													
												</div>
											</div>
                                        </div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
									<h1 className="page-title">Examination List</h1>
									{this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={['title']}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching = {false}
                />
              </div>
            )}
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>
				</div>
				
				{this.state.examId ? <InstructionModal examId={this.state.examId}/> : ''}
				
			</>
		);
	}
}

const mapStateToProps = state => ({
	examination: state.ManageExaminationReducer,
	role: state.settings.role,
})

const mapDispatchToProps = dispatch => ({
	getExaminationListForStudentAction: (e) => dispatch(getExaminationListForStudentAction(e)),
	getExaminationListForParentAction: (e) => dispatch(getExaminationListForParentAction(e)),
	cleargetExaminationListForStudentAction: (e) => dispatch(cleargetExaminationListForStudentAction(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(List)
