

import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
// import './style.scss';
import { connect } from 'react-redux';
import { MDBBtn,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon } from 'mdbreact';
import { clearUploadSchoolAction, uploadSchoolFileAction } from '../../../actions/institute/ManageSchool/manageSchoolAction';
import { CSVLink } from 'react-csv';

class UploadExcel extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
            file: null,
            uploadStatus:null,
            url:'',
            uploadFileStatus :null,
			role:this.props.role,
            
            modal:false,
            createModal:true
		};
	}

    componentWillReceiveProps(nextProps){
        if(this.state.uploadFileStatus !== nextProps.uploadFileStatus){
			this.setState({
				uploadFileStatus : nextProps.uploadFileStatus
			},()=>{
				if(this.state.uploadFileStatus && this.state.uploadFileStatus.errcode === 0){
                    this.props.getApi()
                    this.setState({
                        createModal:false,
                    })
				}
			})
		}
	}
    toggle=()=>{
        this.props.toggleClose()
        this.clearData()
    }
    clearData =()=>{
        this.props.clearUploadSchoolAction()
        this.props.toggleClose()
    }
    componentWillUnmount(){
        this.clearData()
    }
    
    handleSave=()=>{
        if(this.state.file){
            this.props.uploadSchoolFileAction(this.state.file)
        }
    }
    submitHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
        if(this.state.file){
            this.props.uploadSchoolFileAction(this.state.file)
        }
	}
    modalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={ this.state.modal }>
                    <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
                    <MDBModalBody>
                        <div className='alertContainer'>
                            <h4> Do you want to {this.state.editId ? 'Update' : 'save'} it ? </h4>
                            <h6> Please check the information before submitting</h6>
                        </div>
                   
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggleClose}>No</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.handleSave}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
			</MDBContainer>
		)
	}
    openCategoryModal = e =>{
        this.setState({
            modal:true
        })
    }
    toggleClose = ()=>{
		this.setState({
			modal: false,
            createModal:true
		})
	}

    handleFile=(event)=>{
        this.setState({file : event.target.files[0]})
    }

    render(){
        const { activeTab } = this.state
        // if(!isLogined()) this.props.history.push('/')
        return(
            <>
             {this.modalContainer()}
            <MDBContainer>
                <MDBModal isOpen={ this.state.createModal } >
                    <MDBModalHeader toggle={this.toggle}>Upload Excel</MDBModalHeader>
                    <form onSubmit={this.submitHandler} noValidate>
                        <MDBModalBody>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Upload Files
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                :
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="">
                                                <input type="file" className="form-control"
                                                    onChange={this.handleFile} required={this.state.url ? false: true} accept = "application/pdf,.csv"/>
                                                <div className="invalid-feedback">
                                                    required file.
                                                </div>
                                                <div className="">
                                                    {this.state.file ? '' : this.state.url}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                                <div style={{display:'flex',justifyContent:'end','color':'red', marginBottom: '10px'}}>
                    {this.state.uploadFileStatus ? this.state.uploadFileStatus.msg +' *' : ''}
								</div>
                                <div style={{display:'flex',justifyContent:'end'}}>
                                <MDBBtn color="primary">
								<CSVLink {...this.props.sampleCsv}>Download Sample Template
                                <MDBIcon  icon="download" size="lg" className='icon' /></CSVLink>
								</MDBBtn>
                                </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="primary" type="submit">Upload</MDBBtn>
                            <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn>
                        </MDBModalFooter>
                    </form>
                   
                </MDBModal>
            </MDBContainer>
            </>
        )
    }
}

const mapStateToProps = state => ({
    schoolState: state.schoolReducer,
	uploadFileStatus: state.schoolReducer.uploadFileStatus,
})

const mapDispatchToProps = dispatch => ({
	uploadSchoolFileAction: (e) => dispatch(uploadSchoolFileAction(e)),
	clearUploadSchoolAction: (e) => dispatch(clearUploadSchoolAction(e)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadExcel)
