import { GET_SKILL_RULE, 
    CREATE_SKILL_RULE,
    EDIT_SKILL_RULE, 
    GET_SKILL_RULE_BY_ID, SHOW_SNACKBAR} from '../../../Constants/index';
import axios from 'axios';
import { manageSkillRule } from '../../../../utils/Api'
import { getToken } from '../../../../utils/token'

export const getSkillRuleAction = (params) => {
	return dispatch => {
        const url =  `${manageSkillRule.getSkillRule}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_SKILL_RULE,
                    payload: {
                        list : data.data
                    }
                })
            }else {
                dispatch({
                    type: GET_SKILL_RULE,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearGetSkillRuleAction = () => {
	return dispatch => {
        dispatch({
            type: GET_SKILL_RULE,
            payload: {
                list : []
            }
        })
    }
}

export const createSkillRuleAction = (data, history) => {
	return dispatch => {
        const url = `${manageSkillRule.createSkillRule}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: CREATE_SKILL_RULE,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                 history.push("/teacher/manage-skill");
            }else {
                dispatch({
                    type: CREATE_SKILL_RULE,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const clearCreateSkillRuleAction = () => {
	return dispatch => {
        dispatch({
            type: CREATE_SKILL_RULE,
            payload: {
                list : null
            }
        })
    }
}

export const clearEditSkillRuleAction = () => {
	return dispatch => {
        dispatch({
            type: EDIT_SKILL_RULE,
            payload: {
                list : null
            }
        })
    }
}

export const editSkillRuleAction = ({id,data, history}) => {
	return dispatch => {
        const url = `${manageSkillRule.editSkillRule(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PATCH',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: EDIT_SKILL_RULE,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                 history.push("/teacher/manage-skill");
            }else {
                dispatch({
                    type: EDIT_SKILL_RULE,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const getSkillRuleByIdAction = (id) => {
	return dispatch => {
        const url = `${manageSkillRule.getSkillRuleById(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_SKILL_RULE_BY_ID,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_SKILL_RULE_BY_ID,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearSkillByIdAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_SKILL_RULE_BY_ID,
            payload: {
                list : {}
            }
        })
    }
}
