import { GET_CLASS } from "../../../actions/Constants/index";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CLASS:
      return action.payload;
    default:
      return state;
  }
};
