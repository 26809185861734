import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { isLogined } from '../../../utils/token'
import './style.scss';
import { connect } from 'react-redux';
import { clearCreateReceiptsAction, clearEditReceiptsAction, clearReceiptsByIdAction, createReceiptsAction, editReceiptsAction, getInvoiceNumAction, getReceiptsByIdAction } from '../../../actions/Admin/ManageReceipts/manageReceiptsAction';
import { getInstitutesAction } from '../../../actions/Admin/ManageInstitutes/manageInstitutesAction';
import { MDBBtn, MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { getCitiesAction, getStateAction } from '../../../actions/institute/Utils/utilsAction';


class CreateReceipts extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			show: false,
            input:{
                instituteId:'',
                amount:'',
                itemDescription:'',
                receiptNumber:''
            },
            editId: this.props.match.params.id,
            selectedInstitute:{},
            institutes:[],
            invoiceNum:null,
            modal: false,
            stateList:[],
			cityList:[],
		};
	}

    changeHandler = event => {
        let input = {...this.state.input}
        input[event.target.name] = event.target.value
		this.setState({ input:input });
	};

    handleSave =()=>{
        if(this.state.editId){
            this.props.editReceiptsAction({
                id : this.state.editId,
                data:{
                    instituteId: parseInt(this.state.input.instituteId),
                    amount: Number(this.state.input.amount),
                    itemDescription: this.state.input.itemDescription,
                    receiptNumber:this.state.input.receiptNumber,
                },
                history:this.props.history
            })
        }else{
            this.props.createReceiptsAction({
                data:{
                    instituteId: parseInt(this.state.input.instituteId),
                    amount: Number(this.state.input.amount),
                    itemDescription: this.state.input.itemDescription,
                    receiptNumber:this.state.input.receiptNumber,
                },
                history:this.props.history
            })
        }
    }

    submitHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
        
		if(event.target.checkValidity()) {
            if(event.target.checkValidity()) {
                this.setState({
                    modal: true,
                })
            }
		}
	}

    componentDidMount(){
        this.props.getStateAction()
		this.props.getCitiesAction()
        this.props.getInstitutesAction()
        if(!this.props.match.params.id){
            this.props.getInvoiceNumAction()
        }
        if(this.props.match.params.id){
            this.props.getReceiptsByIdAction(parseInt(this.state.editId))
        }
    }

    componentDidUpdate(prevProp,prevState){
		if (this.state.input.instituteId !== prevState.input.instituteId) {
            let found = this.state.institutes.find(item => item.id === parseInt(this.state.input.instituteId))
            this.setState({
                selectedInstitute : found
            })
		}
	}

    toggleClose = ()=>{
		this.setState({
			modal: false,
		})
	}

    modalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={ this.state.modal } toggle={this.toggle}>
                    <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
                    <MDBModalBody>
                        <div className='alertContainer'>
                            <h4> Do you want to {this.state.editId ? 'Update' : 'save'} it ? </h4>
                            <h6> Please check the information before submitting</h6>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggleClose}>No</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.handleSave}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
			</MDBContainer>
		)
	}

    componentWillReceiveProps(nextProps){
        if(this.state.stateList !== nextProps.stateList){
            this.setState({
                stateList: nextProps.stateList
            })
        }

        if(this.state.cityList !== nextProps.cityList){
            this.setState({
                cityList: nextProps.cityList
            })
        }
		if(this.state.institutes !== nextProps.institutes){
			this.setState({
				institutes: nextProps.institutes.list
			})
		}
        let input = {...this.state.input} 
        if(this.props.receiptsById.list !== nextProps.receiptsById.list){
            let data = {...nextProps.receiptsById.list.receipt}
            input = data
        }
        if(nextProps.invoiceNum.number && (this.state.invoiceNum !== nextProps.invoiceNum.number)){
            input.receiptNumber= nextProps.invoiceNum.number
        }
        this.setState({ input:input });
	}
    getName=(id,arr)=>{
		let name = ''
		arr.map((item)=>{
			if(item.id === id){
				name = item.name
			}
		})
		return name
	}
    clearData =()=>{
        this.props.clearCreateReceiptsAction()
        this.props.clearEditReceiptsAction()
        this.props.clearReceiptsByIdAction()
        this.props.history.push('/super-admin/manage-receipts')
    }

    render(){
        const { activeTab } = this.state
        if(!isLogined()) this.props.history.push('/')
        return(
            <>
            <div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">{this.state.editId ? 'Update Receipt' : 'Create Receipt'}</h1>
							</div>
						</div>
					</div>
				</div>
                <div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
                                <form  onSubmit={this.submitHandler} noValidate>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-4 col-sm-6">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    Invoice Number
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    {this.state.input && this.state.input.receiptNumber ? this.state.input.receiptNumber : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6">
                                                <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    Institute
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    <select className="form-control show-tick" onChange={this.changeHandler} name= "instituteId" value = {this.state.input['instituteId']} required>
                                                                        
                                                                        <option value="">Select Institute</option>
                                                                        {this.state.institutes && this.state.institutes.map(item => <option value={item.id}>{item.institute_name}</option>)}
                                                                        
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required institute.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6">
                                                <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    Address 1
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    {this.state.selectedInstitute ? this.state.selectedInstitute.address1 : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6">
                                                <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    Mobile
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                {this.state.selectedInstitute ? this.state.selectedInstitute.mobile : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    State
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                {this.state.selectedInstitute ? this.state.selectedInstitute.state_name  : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    Amount
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    <input type="number" className="form-control" placeholder="Enter Amount" 
                                                                    name= "amount" 
                                                                    value = {this.state.input['amount']} 
                                                                    onChange={this.changeHandler}
                                                                    required/>
                                                                    <div className="invalid-feedback">
                                                                        required amount.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6">
                                                <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    City
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6">
                                                                {this.state.selectedInstitute ?  this.state.selectedInstitute.city_name : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6">
                                                <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                Item Description
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6">
                                                                <textarea  name="itemDescription" required value = {this.state.input['itemDescription']} placeholder="Enter Description" className="form-control" onChange={this.changeHandler}></textarea>
                                                                <div className="invalid-feedback">
                                                                    required description.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6">
                                                <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    Zip
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6">
                                                            {this.state.selectedInstitute ? this.state.selectedInstitute.zip : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6">
                                                <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                Email
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6">
                                                            {this.state.selectedInstitute ? this.state.selectedInstitute.email : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                                {this.modalContainer()}
                                                <div className="col-lg-12 col-md-4 col-sm-6 submitBtn">
                                                    <MDBBtn color="primary" className='btn' onClick= {()=>{
                                                        this.props.history.push('/super-admin/manage-receipts')
                                                    }}>Cancel</MDBBtn>
                                                    <MDBBtn color="primary" className='btn' type={'submit'}>{this.state.editId ? 'Update' : 'Save'}</MDBBtn>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </>
        )
    }
}


const mapStateToProps = state => ({
    receiptsById: state.getReceiptsByIdReducer,
    editReceiptsStatus: state.editReceiptsReducer,
    institutes: state.getInstitutesReducer,
    invoiceNum : state.getInvoiceNumReducer,
    stateList :state.utillReducer.stateList,
    cityList: state.utillReducer.cityList,
})

const mapDispatchToProps = dispatch => ({
	createReceiptsAction: (e,history) => dispatch(createReceiptsAction(e,history)),
	clearEditReceiptsAction: (e) => dispatch(clearEditReceiptsAction(e)),
	editReceiptsAction: (e,history) => dispatch(editReceiptsAction(e,history)),
	clearCreateReceiptsAction: (e) => dispatch(clearCreateReceiptsAction(e)),
	getReceiptsByIdAction: (e) => dispatch(getReceiptsByIdAction (e)),
	getInstitutesAction: (e) => dispatch(getInstitutesAction (e)),
	clearReceiptsByIdAction: (e) => dispatch(clearReceiptsByIdAction(e)),
	getInvoiceNumAction: (e) => dispatch(getInvoiceNumAction(e)),
    getCitiesAction: (e) => dispatch(getCitiesAction()),
	getStateAction: (e) => dispatch(getStateAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateReceipts)
