import { SUPER_USER_LOGIN,
	SUPER_USER_LOGOUT } from '../Constants/index';
import axios from 'axios';
import { superUser } from '../../utils/Api'

export const superUserLoginAction = (data) => {
	return dispatch => {
        let url = `${superUser.superUserLogin}`,    
        options = {
                url,
                method: 'POST',
                data,
            };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                localStorage.setItem('isLogined', true)
                localStorage.setItem('token', data.access_token)
                dispatch({
                    type: SUPER_USER_LOGIN,
                    payload: {
                        isLogined : true,
                        userInfo : null,
                        password_state: data.password_state,
                        access_token: data.access_token,
                        refresh_token: data.refresh_token
                    }
                })
            }else {
                localStorage.setItem('isLogined', false)
                dispatch({
                    type: SUPER_USER_LOGIN,
                    payload: {
                        isLogined : false,
                        userInfo : null,
                        password_state: null,
                        access_token: null,
                        refresh_token: null
                    }
                })
            }

        })
    }
}

export const superUserLogoutAction = (e) => dispatch => {
	localStorage.clear()
	dispatch({
		type: SUPER_USER_LOGOUT
	})
}
