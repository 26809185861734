import { GET_PRINCIPAL,CREATE_PRINCIPAL,UPDATE_PRINCIPAL,
    GET_PRINCIPAL_BY_ID,
    SEARCH_PRINCIPAL,
    UPLOAD_PRINCIPAL_FILE,
    PRINCIPAL_NAME_LIST,
    SCHOOL_LIST_FOR_PRINCIPAL} from '../../../actions/Constants/index'


const initialState = {
    list : [],
    createStatus : null,
    editStatus : null,
    getById : {},
    uploadFileStatus: null,
    principalNameList: [],
    schoolList:[]
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_PRINCIPAL:
			return {
				...state,
                list : action.payload.list,
			}

        case CREATE_PRINCIPAL:
            return {
                ...state,
                createStatus : action.payload.list,
            }

        case UPDATE_PRINCIPAL:
            return {
                ...state,
                editStatus : action.payload.list,
            }

        case GET_PRINCIPAL_BY_ID:
            return {
                ...state,
                getById : action.payload.list,
            }

        case UPLOAD_PRINCIPAL_FILE:
            return {
                ...state,
                uploadFileStatus : action.payload.status,
            }

        case PRINCIPAL_NAME_LIST:
            return {
                ...state,
                principalNameList : action.payload.list,
            }

        case SCHOOL_LIST_FOR_PRINCIPAL:
            return {
                ...state,
                schoolList : action.payload.list,
            }
   
        default:
            return state
    }
}
