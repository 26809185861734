import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import './style.scss';
import { connect } from 'react-redux';
import { MDBBtn, MDBFormInline, MDBInput} from 'mdbreact';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import AssignmentDetail from './AssignmentDetail';
import moment from 'moment'
import { getExaminationByIdAction } from '../../../../actions/institute/Teacherdashboard/ManageExamination/ManageExaminationAction';
import { Link } from 'react-router-dom';

class ViewExamination extends Component {
    constructor(props) {
		super(props);
		this.state = {
            editId: this.props.match.params.id,
            examination:{},
			activeTab: 1,
            examDetail:{},
		};
	}

    componentDidMount(){
        if(this.state.editId){
            this.props.getExaminationByIdAction({id:this.state.editId})
        }
	}


    componentWillReceiveProps(nextProps){
        if(this.props.examination.detail !== nextProps.examination.detail){
            let data = nextProps.examination.detail[0]
            this.setState({examDetail:data})
        }
	}
    
    render(){
        const { activeTab } = this.state
        // if(!isLogined()) this.props.history.push('/')
        return(
            <>
            <div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
                                 <div className="row">
                                    <div className="col-lg-6 col-md-4 col-sm-6">
                                        <h1 className="page-title">
                                            Submitted Examination
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-4 col-sm-6">
                                        <Link to="/teacher/manage-examination">Back To Manage Examination</Link>
                                    </div>
                                </div>
							</div>
						</div>
					</div>
				</div>
                <div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Examination Title :
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                {this.state.examDetail ? this.state.examDetail.examination_title : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Class Name :
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.examDetail ? this.state.examDetail.class_name : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Section Name :
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.examDetail ? this.state.examDetail.section_name : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Subject Name :
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.examDetail ? this.state.examDetail.subject_name : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Deadline Date :
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.examDetail ? moment(this.state.examDetail.deadline).format('YYYY-MM-DD') : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Year :
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                {moment().format('YYYY')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                                                    
                                                </div>
                                            </div>
                                            <div className="row">
                                                <AssignmentDetail editId={this.state.editId} title="Examination Details"/>
                                            </div>
                                        </div>
                                    </div>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
	examination: state.ManageExaminationReducer,

})

const mapDispatchToProps = dispatch => ({
	getExaminationByIdAction: (e) => dispatch(getExaminationByIdAction(e)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewExamination)
