import { 
    GET_PRINCIPAL,
    CREATE_PRINCIPAL,
    UPDATE_PRINCIPAL,
    GET_PRINCIPAL_BY_ID,
    UPLOAD_PRINCIPAL_FILE,
    PRINCIPAL_NAME_LIST,
    SCHOOL_LIST_FOR_PRINCIPAL,SHOW_SNACKBAR } from "../../Constants/index";
import axios from "axios";
import { managePrincipal } from "../../../utils/Api";
import { getToken } from "../../../utils/token";

export const clearCreatePrincipalAction = () => {
	return dispatch => {
        dispatch({
            type: UPDATE_PRINCIPAL,
            payload: {
                list : null
            }
        })
    }
}

export const createPrincipalAction = (data,history) => {
	return dispatch => {
        const url = `${managePrincipal.createPrincipal}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: CREATE_PRINCIPAL,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                 history.push("/institute/manage-principal");
            }else {
                dispatch({
                    type: CREATE_PRINCIPAL,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const clearEditPrincipalAction = () => {
	return dispatch => {
        dispatch({
            type: UPDATE_PRINCIPAL,
            payload: {
                list : null
            }
        })
    }
}

export const editPrincipalAction = ({id,data,history}) => {
	return dispatch => {
        const url = `${managePrincipal.editPrincipalById(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PATCH',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: UPDATE_PRINCIPAL,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                 history.push("/institute/manage-principal");
            }else {
                dispatch({
                    type: UPDATE_PRINCIPAL,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const clearPrincipalByIdAction = () => {
	return dispatch => {
        dispatch({
            type: GET_PRINCIPAL_BY_ID,
            payload: {
                list : {}
            }
        })
    }
}

export const getPrincipalByIdAction = (id) => {
	return dispatch => {
        const url = `${managePrincipal.getPrincipalById(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_PRINCIPAL_BY_ID,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_PRINCIPAL_BY_ID,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const searchPrincipalAction = (params) => {
	return dispatch => {
        const url = `${managePrincipal.searchPrincipal}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_PRINCIPAL,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_PRINCIPAL,
                    payload: {
                        list : null
                    }
                })
            }
        })
    }
}

export const clearUploadPrincipalFileAction = () => {
	return dispatch => {
        dispatch({
            type: UPLOAD_PRINCIPAL_FILE,
            payload: {
                status : null
            }
        })
    }
}

export const uploadPrincipalFileAction = (data) => {
	return dispatch => {
        let formData = new FormData();
        formData.append('csv', data);
        const url = `${managePrincipal.uploadPrincipalFile}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
                "Content-type": "multipart/form-data",
            },
            method: 'post',
            data: formData
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = flag.status
                dispatch({
                    type: UPLOAD_PRINCIPAL_FILE,
                    payload: {
                        status : data
                    }
                })
            }else {
                data = flag.status
                dispatch({
                    type: UPLOAD_PRINCIPAL_FILE,
                    payload: {
                        status : data
                    }
                })
            }
        })
    }
}

export const getPrincipalNameAction = () => {
    return (dispatch) => {
      const url = `${managePrincipal.principalNameList}`,
        options = {
          url,
          headers: {
            Authorization: getToken(),
          },
          method: "GET",
        };
      return axios(options).then((response) => {
        let data = response?.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR;
        if (data && flag.status.errcode === 0) {
          dispatch({
            type: PRINCIPAL_NAME_LIST,
            payload:{
              list : data.DATA
              }
          });
        } else {
          dispatch({
            type: PRINCIPAL_NAME_LIST,
            payload: [],
          });
        }
      });
    };
  };

export const getPrincipalSchoolAction = () => {
return (dispatch) => {
    const url = `${managePrincipal.schoolForPrincipal}`,
    options = {
        url,
        headers: {
        Authorization: getToken(),
        },
        method: "GET",
    };
    return axios(options).then((response) => {
    let data = response?.data ? response.data : false;
    const flag = data.SUCESS || data.ERROR;
    if (data && flag.status.errcode === 0) {
        dispatch({
        type: SCHOOL_LIST_FOR_PRINCIPAL,
        payload:{
            list : data.DATA
            }
        });
    } else {
        dispatch({
        type: SCHOOL_LIST_FOR_PRINCIPAL,
        payload: [],
        });
    }
    });
};
};