import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { clearKnowledgeBaseByIdAction, getCategoryAction, getKnowledgeBaseByIdAction } from '../../../actions/Admin/ManageKnowledgeBase/manageKnowledgeBaseAction'
import { MDBBtn } from 'mdbreact';

class ViewKnowledgebase extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
            viewId: this.props.match.params.viewId,
            id: this.props.match.params.id,
            input:null,
			categories:[],
		};
	}
    componentDidMount(){
		this.props.getCategoryAction()
        if(this.props.match.params.viewId){
            this.props.getKnowledgeBaseByIdAction(this.props.match.params.viewId)
        }
	}
    createMarkup() {
        return {__html: this.state.input && this.state.input.context};
    }
    componentWillReceiveProps(nextProps){
        if(this.state.input !== nextProps.knowledgeBaseByIdStatus.list){
            this.setState({
                input : nextProps.knowledgeBaseByIdStatus.list
            })
        }
        if(this.state.categories !== nextProps.categories.list){
            this.setState({
                categories : nextProps.categories.list
            })
        }
	}
    componentWillUnmount(){
        this.props.clearKnowledgeBaseByIdAction()
    }
    getCatName=(id)=>{
        let name = ''
        this.state.categories.map((item)=>{
            if(item.id === id){
                name = item.name
            }
        })
        return name
    }
    render() {
        const { activeTab } = this.state

        return (
            <div>
                <div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">View Knowledge Base</h1>
							</div>
						</div>
					</div>
				</div>
                <div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                <b>Category</b>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    {this.state.input && this.state.input.category ? this.getCatName(this.state.input.category) : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                <b>Doc Title</b>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    {this.state.input && this.state.input.title ? this.state.input.title : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                <b>Sub Title</b>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    {this.state.input && this.state.input.subTitle ? this.state.input.subTitle : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                <b>Slug Url</b>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    {this.state.input && this.state.input.slugUrl ? <a href={this.state.input.slugUrl} target="_blank">{this.state.input.slugUrl}</a> : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <b>Content</b>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group" dangerouslySetInnerHTML={this.createMarkup()}>
                                                                </div> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField submitBtn">
                                                    <MDBBtn color="primary" className='btn' onClick= {()=>{
                                                        this.props.history.push(`/institute/manage-knowledgebase/${this.state.id}`)
                                                    }}>Cancel</MDBBtn>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    knowledgeBaseByIdStatus: state.getKnowledgeBaseByIdReducer,
    categories: state.getCategoryReducer,
})

const mapDispatchToProps = dispatch => ({
	getKnowledgeBaseByIdAction: (e) => dispatch(getKnowledgeBaseByIdAction(e)),
	clearKnowledgeBaseByIdAction: (e) => dispatch(clearKnowledgeBaseByIdAction(e)),
    getCategoryAction: (e) => dispatch(getCategoryAction(e))
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewKnowledgebase)
