import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { MDBBtn, MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { isLogined } from '../../../utils/token'
import './style.scss';
import Button from 'reactstrap/lib/Button';
import {getProfileInfoAction, updateProfileInfoAction, getLogoAndImageAction, updateLogoAndImageAction, uploadLoginImageAction, uploadLoginVideoAction} from '../../../actions/Admin/ManageProfile/manageProfileAction'
import {uploadImageAction} from '../../../actions/Admin/ManageInstitutes/manageInstitutesAction'
import { superUserChangePasswordAction } from '../../../actions/Authentication/superUserChangePasswordAction';

class ManageProfile extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			passwordInput : {
				password:'',
				confirm_password:''
			},
			profileInfo:{
				first_name:'',
				contact_no:'',
				email:''
			},
			logoAndImage : {
				logo: "",
				imageUrl: "",
				youtubeUrl: "",
				type: "",
				videoUrl:""
			},
			openModal:false,
			openLogoModal: false,
			uploadImageUrl: null,
			openPasswordModal: false,
			changePasswordFlag: null,
			updateProfileFlag: null,
			loginImageUrl: null,
			updateLogoAndImageFlag : null,
			logoImage : null,
			loginVideoUrl: null
		};
	}

	componentWillReceiveProps(nextProps){
		if(this.state.profileInfo !== nextProps.profileInfo.list){
			this.setState({
				profileInfo: nextProps.profileInfo.list
			})
		}

		if(this.state.logoAndImage !== nextProps.logoAndImage.list){
			this.setState({
				logoAndImage: nextProps.logoAndImage.list
			})
		}

		if(this.state.uploadImageUrl !== nextProps.uploadImageUrl.status){
            this.setState({
                uploadImageUrl: nextProps.uploadImageUrl.status
            },()=>{
				if(this.state.uploadImageUrl !== null){
					let logoAndImage = this.state.logoAndImage
					logoAndImage['logo'] = this.state.uploadImageUrl
					this.setState({
						logoAndImage : logoAndImage
					})
				}
			})
        }

		if(this.state.loginImageUrl !== nextProps.loginImageUrl.status){
            this.setState({
                loginImageUrl: nextProps.loginImageUrl.status
            },()=>{
				if(this.state.loginImageUrl !== null){
					let logoAndImage = this.state.logoAndImage
					logoAndImage['imageUrl'] = this.state.loginImageUrl
					this.setState({
						logoAndImage : logoAndImage
					})
				}
			})
        }

		if(this.state.loginVideoUrl !== nextProps.loginVideoUrl.status){
            this.setState({
                loginVideoUrl: nextProps.loginVideoUrl.status
            },()=>{
				if(this.state.loginVideoUrl !== null){
					let logoAndImage = this.state.logoAndImage
					logoAndImage['videoUrl'] = this.state.loginVideoUrl
					this.setState({
						loginVideoUrl : logoAndImage
					})
				}
			})
        }

		// if(this.state.changePasswordFlag !== nextProps.changePasswordFlag.status){
		// 	this.setState({
		// 		changePasswordFlag : nextProps.changePasswordFlag.status
		// 	})
		// }

		// if(this.state.updateProfileFlag !== nextProps.updateProfileFlag.status){
		// 	this.setState({
		// 		updateProfileFlag : nextProps.updateProfileFlag.status
		// 	})
		// }

		// if(this.state.updateLogoAndImageFlag !== nextProps.updateLogoAndImageFlag.status){
		// 	this.setState({
		// 	updateLogoAndImageFlag: nextProps.updateLogoAndImageFlag
		// 	})
		// }

	}

	componentDidMount(){
		this.props.getProfileInfoAction()
		this.props.getLogoAndImageAction()
	}

	ProfileChangeHandler = event => {
        let profileInfo = this.state.profileInfo
        profileInfo[event.target.name] = event.target.value
		this.setState({ profileInfo:profileInfo });
	};

	changePasswordHandler = event => {
        let passwordInput = this.state.passwordInput
        passwordInput[event.target.name] = event.target.value
		this.setState({ passwordInput:passwordInput });
	};

	handleLogoImage =(event)=>{
		const image = event.target.files[0]
		if(image['type'].includes('image')){
			this.props.uploadImageAction(image)
		}
	}
	 

	handleLoginImage =(event)=>{
		const image = event.target.files[0]
		if(image['type'].includes('image')){
			let logoAndImage = this.state.logoAndImage
			logoAndImage['type']= 'imageurl'
			logoAndImage['youtubeUrl']= ''
			logoAndImage['videoUrl']= ''
			this.setState({
				logoAndImage: logoAndImage
			},()=>{
				this.props.uploadLoginImageAction(image)
			})
		}
	}

	handleLoginVideoUrl =(event)=>{
		const image = event.target.files[0]
		if(image['type'].includes('video/mp4')){
			let logoAndImage = this.state.logoAndImage
			logoAndImage['type']= 'videoUrl'
			logoAndImage['youtubeUrl']= ''
			logoAndImage['videoUrl']= ''
			logoAndImage['imageUrl']= ''
			this.setState({
				logoAndImage: logoAndImage
			},()=>{
				this.props.uploadLoginVideoAction(image)
			})
		}
	}

	handleLoginYoutubeUrl = (event)=>{
		let logoAndImage = this.state.logoAndImage
		logoAndImage['imageUrl']= ''
		logoAndImage['videoUrl']=  ''
		logoAndImage['youtubeUrl']= event.target.value
		logoAndImage['type']= 'youtubeurl'
		this.setState({
			logoAndImage: logoAndImage
		})
	}
	 
	toggleClose = ()=>{
		this.setState({
			openModal: false,
		})
	}

	logoToggleClose = ()=>{
		this.setState({
			openLogoModal: false,
		})
	}

	passwordToggleClose = ()=>{
		this.setState({
			openPasswordModal: false,
		})
	}

	infoSubmitHandler = () => {
		const status = this.props.updateProfileInfoAction(this.state.profileInfo)
		this.setState({
			updateProfileFlag: status
		},()=>{
			this.toggleClose()
		})
	}

	handleProfileInfoAlert = (event)=>{
		event.preventDefault();
		event.target.className += " was-validated";
		if(event.target.checkValidity()) {
			this.setState({
				openModal : true
			})
		}	
	}

	handleProfileLogoAlert = (event)=>{
		event.preventDefault();
		this.setState({
			openLogoModal : true
		})
	}

	handleProfilePasswordAlert = (event) =>{
		event.preventDefault();
		event.target.className += " was-validated";
		if(event.target.checkValidity()) {
			this.setState({
				openPasswordModal : true
			})
		}
	}

	logoSubmitHandler = () => {
		this.logoToggleClose()
		const status = this.props.updateLogoAndImageAction(this.state.logoAndImage)  
		this.setState({
			updateLogoAndImageFlag: status
		})
	}

	changePasswordSubmitHandler = () => {
		const status = this.props.superUserChangePasswordAction(this.state.passwordInput)
		this.setState({
			changePasswordFlag: status
		},()=>{
			this.passwordToggleClose()
		})
	}

	openDialog = ()=> {
		document.getElementById('fileid').click();
	}

	openLoginDialog = ()=> {
		document.getElementById('loginImage').click();
	}
	openVideoDialog = ()=> {
		document.getElementById('video').click();
	}


	handleLoginVideo = (event)=>{
		let logoAndImage = this.state.logoAndImage
		logoAndImage['image']= ''
		logoAndImage['youtubeUrl']=  event.target.value
		logoAndImage['videoUrl']= ''
		logoAndImage['type']= 'videoUrl'
		this.setState({
			logoAndImage: logoAndImage
		})
	}

	tableContainer =()=>{
		return(
			<TabPane tabId={1} className={classnames(['fade show'])}>
				<div className="card" >
					<div className="card-body"  id="capture" >
					<h1 className="page-title">Profile information</h1>
						<div className="table-responsive">
						<form  onSubmit={this.handleProfileInfoAlert} noValidate>
                            <div className="card-body">
                                <div className="row">
									<div className="col-lg-3 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											Name
										</div>
									</div>
									<div className="col-lg-1 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											:
										</div>
									</div>
									<div className="col-lg-7 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											<input onChange={this.ProfileChangeHandler} value={this.state.profileInfo['first_name']} name= "first_name"  type="text" className="form-control" placeholder="Name" required />
											<div className="invalid-feedback">
												required name.
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-3 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											Mobile Support
										</div>
									</div>
									<div className="col-lg-1 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											:
										</div>
									</div>
									<div className="col-lg-7 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											<input onChange={this.ProfileChangeHandler} value={this.state.profileInfo['contact_no']} name= "contact_no"  type="text" className="form-control" placeholder="Mobile Support" required />
											<div className="invalid-feedback">
												required mobile number.
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-3 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											Email Support
										</div>
									</div>
									<div className="col-lg-1 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											:
										</div>
									</div>
									<div className="col-lg-7 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											<input onChange={this.ProfileChangeHandler} value={this.state.profileInfo['email']} name= "email"  type="text" className="form-control" placeholder="Email Support" required />
											<div className="invalid-feedback">
												required email.
											</div>
										</div>
									</div>
								</div>
								<div className="row justify-content-end">
									<div className="col-lg-3 col-md-4 col-sm-6 ">
										<MDBBtn color="primary" type ="submit" >Update</MDBBtn>
									</div>
								</div>
								{this.state.updateProfileFlag &&(
									<div className='imageFlag'>
										 Profile information changed successfully
									 </div>
									)}
							</div>
							</form>
						</div>
					</div>
				</div>
			</TabPane>
		)
	}

	logoAndImageContainer(){
		return(
			<TabPane tabId={1} className={classnames(['fade show'])}>
				<div className="card" >
					<div className="card-body"  id="capture" >
					<h1 className="page-title">Image and Logo</h1>
						<div className="table-responsive">
						<form  onSubmit={this.handleProfileLogoAlert} noValidate>
                            <div className="card-body">
                                <div className="row">
									<div className="col-lg-4 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											Logo
										</div>
									</div>
									<div className="col-lg-3 col-md-4 col-sm-6 inputField">
										<div className="input-group">
										<img src={this.state.logoAndImage['logo']}  width="50" height="60" />
										</div>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-6 inputField">
										<input id='fileid'  accept=".png,image/*, image/jpeg" type='file' onChange={this.handleLogoImage} hidden/>
										<input id='buttonid' onClick={this.openDialog} type='button' value='Upload/Change' />
										<p style={{color:'green'}}>Only accept Png, Jpeg file</p>
										<div className="invalid-feedback">
											required Image.
										</div>
										{/* {this.state.uploadImageUrl &&(
										<div className='imageFlag'>
											image upload successfully
										</div>
									)} */}
									</div>
								</div>
								<div className="row">
									<div className="col-lg-4 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											Login Image
										</div>
									</div>
									<div className="col-lg-3 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											{this.state.logoAndImage['imageUrl'] !== '' ?(
												<img src={this.state.logoAndImage['imageUrl']}  width="50" height="60" />
											) : 'No image'}
										</div>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-6 inputField">
										<input id='loginImage' accept=".png,image/*, image/jpeg" onChange={this.handleLoginImage} type='file' hidden/>
										<input id='buttonid' onClick={this.openLoginDialog} type='button' value='Upload/Change' />
										<p style={{color:'green'}}>Only accept Png, Jpeg file</p>
										<div className="invalid-feedback">
											required Image.
										</div>
										{/* {this.state.loginImageUrl &&(
										<div className='imageFlag'>
											image upload successfully
										</div>
									)} */}
									</div>
								</div>
								<div className="row">
									<div className="col-lg-4 col-md-4 col-sm-6 inputField">
									(Or	)
									</div>
								</div>
								<div className="row">
									<div className="col-lg-4 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											Login video
										</div>
									</div>
									<div className="col-lg-8 col-md-4 col-sm-6 inputField">
									<div className="input-group">
										<input type="text" name ='vedio' onChange={this.handleLoginYoutubeUrl} value={this.state.logoAndImage['youtubeUrl']}  className="form-control" placeholder="Youtube video url" />
									</div>
									<p style={{color:'green',marginTop:'5px'}}>Only accept Embed Url</p>
								</div>
								</div>
								<div className="row">
									<div className="col-lg-4 col-md-4 col-sm-6 inputField">
									(Or	)
									</div>
								</div>
								<div className="row">
									<div className="col-lg-4 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											Login MP4 video
										</div>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-6 inputField">
										<input id='video' onChange={this.handleLoginVideoUrl} accept="video/mp4,video/x-m4v,video/*" type='file' hidden/>
										<input id='buttonid' onClick={this.openVideoDialog} type='button' value='Upload/Change' />
										
										{this.state.loginVideoUrl &&(
										<div className='imageFlag'>
											MP4 vedio upload successfully
										</div>
									)}
									</div>
								</div>
								<div className="row justify-content-end">
									<div className="col-lg-3 col-md-4 col-sm-6 ">
									<MDBBtn color="primary" type="submit">Save</MDBBtn>
									</div>
								</div>
								{this.state.updateLogoAndImageFlag &&(
										 <div className='imageFlag'>
										 updated logo and image successfully
									 </div>
									)}
							</div>
							</form>
						</div>
					</div>
				</div>
			</TabPane>
		)
	}

	changePasswordContainer = ()=>{
		return(
			<TabPane tabId={1} className={classnames(['fade show'])}>
				<div className="card" >
					<div className="card-body"  id="capture" >
					<h1 className="page-title">Change Password</h1>
						<div className="table-responsive">
						<form  onSubmit={this.handleProfilePasswordAlert} noValidate>
                            <div className="card-body">
								<div className="row">
									<div className="col-lg-3 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											New Password
										</div>
									</div>
									<div className="col-lg-1 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											:
										</div>
									</div>
									<div className="col-lg-7 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											<input value={this.state.passwordInput['password']} onChange={this.changePasswordHandler} name= "password"  type="password"  className="form-control" placeholder="New Password" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$"
											required  />
											<div className="invalid-feedback">
												Min 8 char,Atleast one numaric, special char, alphabet.
											</div>
										</div>
									</div>
								</div>
									<div className="row">
										<div className="col-lg-3 col-md-4 col-sm-6 inputField">
											<div className="input-group">
												Confirm Password
											</div>
										</div>
										<div className="col-lg-1 col-md-4 col-sm-6 inputField">
											<div className="input-group">
												:
											</div>
										</div>
										<div className="col-lg-7 col-md-4 col-sm-6 inputField">
											<div className="input-group">
												<input  value={this.state.passwordInput['confirm_password']} onChange={this.changePasswordHandler} name= "confirm_password"  type="password"  className="form-control" placeholder="Confirm Password" 	pattern={this.state.passwordInput['password']} required />
												<div className="invalid-feedback">
												Password are not matching.
											</div>
										</div>
										</div>
									</div>
									<div className="row justify-content-end">
										<div className="col-lg-6 col-md-4 col-sm-6 ">
											<MDBBtn color="primary" type='submit' >Change Password</MDBBtn>
										</div>
										
									</div>
									{this.state.changePasswordFlag &&(
										<div className='imageFlag'>
											password changed successfully
										</div>
										)}
										{this.state.changePasswordFlag === false &&(
											<div >
											password changed Failed
										</div>
									)}
								</div>
							</form>
						</div>
					</div>
				</div>
			</TabPane>
		)
	}
	modalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={this.state.openModal} toggle={this.toggleClose}>
					<MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
					<MDBModalBody>
						<h5>Are you sure you want to update profile information .?</h5>
					</MDBModalBody>
					<MDBModalFooter>
					<MDBBtn color="secondary" onClick={this.infoSubmitHandler}>Confirm</MDBBtn>
						<MDBBtn color="secondary" onClick={this.toggleClose}>Close</MDBBtn>
					</MDBModalFooter>
				</MDBModal>

				<MDBModal isOpen={this.state.openLogoModal} toggle={this.logoToggleClose}>
					<MDBModalHeader toggle={this.logoToggleClose}>Alert</MDBModalHeader>
					<MDBModalBody>
						<h5>Are you sure you want to update logo and image.?</h5>
					</MDBModalBody>
					<MDBModalFooter>
					<MDBBtn color="secondary" onClick={this.logoSubmitHandler}>Confirm</MDBBtn>
						<MDBBtn color="secondary" onClick={this.logoToggleClose}>Close</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
				<MDBModal isOpen={this.state.openPasswordModal} toggle={this.passwordToggleClose}>
					<MDBModalHeader toggle={this.passwordToggleClose}>Alert</MDBModalHeader>
					<MDBModalBody>
						<h5>Are you sure you want to change password.?</h5>
					</MDBModalBody>
					<MDBModalFooter>
					<MDBBtn color="secondary" onClick={this.changePasswordSubmitHandler}>Confirm</MDBBtn>					
						<MDBBtn color="secondary" onClick={this.passwordToggleClose}>Close</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
			</MDBContainer>
		)
	}

	pageContainer =()=> {
		const { activeTab } = this.state
		return(
			<TabContent activeTab={activeTab}>
				<div className="card" >
					<div className="card-body">
						<div className="row">
							<div className="col-lg-6 col-md-4 col-sm-6 inputField">
								{this.tableContainer()}
								<hr />
								{this.logoAndImageContainer()}
							</div>
							<div className="col-lg-6 col-md-4 col-sm-6 inputField">
								{this.changePasswordContainer()}
							</div>
						</div>
					</div>
				</div>
			</TabContent>
		)
	}

	render() {
		if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Manage Profile</h1>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-4">
					<div className="container-fluid">
						{this.pageContainer()}
					</div>
					{this.state.openModal && this.modalContainer()}
					{ this.state.openLogoModal && this.modalContainer()}
					{this.state.openPasswordModal&& this.modalContainer()}
				</div>
			</>
		);
	}
}


const mapStateToProps = state => ({
	profileInfo: state.getProfileInfoReducer,
	updateProfileFlag: state.updateProfileInfoReducer,
	logoAndImage: state.getLogoAndImageReducer,
	updateLogoAndImageFlag: state.updateLogoAndImageReducer,
	uploadImageUrl: state.uploadImageReducer,
	changePasswordFlag : state.superUserChangePasswordReducer,
	loginImageUrl : state.profileLoginImageReducer,
	loginVideoUrl : state.uploadVideoReducer
})

const mapDispatchToProps = dispatch => ({
	getProfileInfoAction: (e) => dispatch(getProfileInfoAction(e)),
	updateProfileInfoAction: (e) => dispatch(updateProfileInfoAction(e)),
	getLogoAndImageAction: (e) => dispatch(getLogoAndImageAction(e)),
	updateLogoAndImageAction: (e) => dispatch(updateLogoAndImageAction(e)),
	uploadImageAction: (e) => dispatch(uploadImageAction(e)),
	superUserChangePasswordAction: (e) => dispatch(superUserChangePasswordAction(e)),
	uploadLoginImageAction: (e) => dispatch(uploadLoginImageAction(e)),
	uploadLoginVideoAction:  (e) => dispatch(uploadLoginVideoAction(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ManageProfile)
