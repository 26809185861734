import { GET_PROFILE_INFO } from '../../../actions/Constants/index'

const initialState = {
    list : {
        first_name:'',
        contact_no:'',
        email:''
    }
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_PROFILE_INFO:
			return {
				...state,
                list : action.payload.list,
			}
        default:
            return state
    }
}
