import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane, } from "reactstrap";
import classnames from 'classnames';
import { MDBFormInline, MDBInput, MDBBtn, MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { isLogined } from '../../../utils/token'
import {createInstitutesAction,
    getInstitutesByIdAction, 
    clearInstitutesByIdAction, 
    editInstitutesAction,
    uploadImageAction,
    clearUploadImageAction,
    clearCreateInstitutesAction,
    clearEditInstitutesAction } from '../../../actions/Admin/ManageInstitutes/manageInstitutesAction'
import './style.scss';
import { getCitiesAction, getStateAction } from '../../../actions/institute/Utils/utilsAction';

class CreateInstitutes extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
            input:{},
            expiryDateValidation: false,
            modal: false,
            editId: this.props.match.params.id,
            uploadImageUrl: null,

            stateList:[],
			cityList:[],

            url:'',
		};
	}

    componentDidMount(){
        this.props.getStateAction()
        if(this.props.match.params.id){
            this.props.getInstitutesByIdAction(this.props.match.params.id)
        }
	}

    componentDidUpdate(prevProp, prevState) {
        if (prevState.input.state_id !== this.state.input.state_id) {
          if (this.state.input.state_id) {
            this.props
              .getCitiesAction(this.state.input.state_id)
          }
        }
      }

    componentWillReceiveProps(nextProps){
		if(this.state.stateList !== nextProps.stateList){
            this.setState({
                stateList: nextProps.stateList
            })
        }

        if(this.state.cityList !== nextProps.cityList){
            this.setState({
                cityList: nextProps.cityList
            })
        }
       
        if(this.props.InstitutesById.list !== nextProps.InstitutesById.list){
            let data = {...this.state.input}
            data.institute_Name = nextProps.InstitutesById.list.institute_name ? nextProps.InstitutesById.list.institute_name : '' 
            data.address1= nextProps.InstitutesById.list.address1
            data.contact_Person= nextProps.InstitutesById.list.contact_person
            data.address2= nextProps.InstitutesById.list.address2
            data.email= nextProps.InstitutesById.list.email
            data.mobile= nextProps.InstitutesById.list.mobile
            data.city_id= nextProps.InstitutesById.list.city_id ? nextProps.InstitutesById.list.city_id : ''
            data.state_id= nextProps.InstitutesById.list.state_id ? nextProps.InstitutesById.list.state_id : ''
            data.username= nextProps.InstitutesById.list.username
            data.zip= nextProps.InstitutesById.list.zip 
            data.licency_expiry= nextProps.InstitutesById.list.licency_expiry
            data.domain_url= nextProps.InstitutesById.list.domain_url
            data.status= nextProps.InstitutesById.list.status
            data.id= nextProps.InstitutesById.list.id

            this.setState({
                input:data
            })
            this.setState({url:nextProps.InstitutesById.list.institute_image})
            
        }
        if(this.state.uploadImageUrl !== nextProps.uploadImageUrl.status){
            this.setState({
                uploadImageUrl: nextProps.uploadImageUrl.status
            })
        }
        if(this.state.stateList !== nextProps.stateList){
            this.setState({
                stateList: nextProps.stateList
            })
        }

        if(this.state.cityList !== nextProps.cityList){
            this.setState({
                cityList: nextProps.cityList
            })
        }
	}

    clearData =()=>{
        this.props.clearCreateInstitutesAction()
        this.props.clearEditInstitutesAction()
        this.props.clearInstitutesByIdAction()
        this.props.clearUploadImageAction()
        this.toggleClose()
        this.props.history.push('/super-admin/manage-institutes')
    }
    componentWillUnmount(){
        this.clearData()   
    }
    toggleClose = ()=>{
		this.setState({
			modal: false,
		})
	}

    changeHandler = event => {
        let input = {...this.state.input}
        input[event.target.name] = event.target.value
		this.setState({ input:input });
	};

    handleStatus = (event)=>{
        let input = {...this.state.input}
        let value = event.target.value === "1" ? 1 : event.target.value === "0" ? 0 : null
        input[event.target.name] = value
		this.setState({ input:input });
    }

    handleExpiryDate =(value)=>{
        let input = {...this.state.input}
        input['licency_expiry'] = moment(value).format("YYYY-MM-DD");
		this.setState({ input:input,expiryDateValidation: false });
    }

    handleImage =(event)=>{
       const image = event.target.files[0]
       if(image['type'].includes('image')){
        this.props.uploadImageAction(image)
       }
    }

    submitHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
        if(!this.state.input.licency_expiry){
            this.setState({
                expiryDateValidation : true
            })
        }
		if(event.target.checkValidity()) {
            this.setState({
                modal: true,
            })
		}
	}

    handleSave =()=>{
        let img = ''
        if(this.state.uploadImageUrl){
            img = this.state.uploadImageUrl;
        }
        else{
            img = this.state.url;
        }
        
        this.state.input['time'] = new Date();
        this.setState({
            input:this.state.input
        },()=>{
            let data = {
                ...this.state.input,
                institute_image: img,
                state_id : Number(this.state.input.state_id),
                city_id : Number(this.state.input.city_id),
            }
            
            if(this.state.editId){
                this.props.editInstitutesAction({
                    id : this.state.input.id,
                    data:data,
                    history:this.props.history
                })
            }else{
                this.props.createInstitutesAction(data,this.props.history)
            }
        })
    }

    inputFieldContainer =()=> {
        const { activeTab } = this.state
        return(
            <TabContent activeTab={activeTab}>
                <TabPane tabId={1} className={classnames(['fade show'])}>
                    <form  onSubmit={this.submitHandler} noValidate>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Institutes name
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    <input onChange={this.changeHandler} name= "institute_Name" value = {this.state.input['institute_Name']} type="text" className="form-control" placeholder=" Institutes name" required />
                                                    <div className="invalid-feedback">
                                                        required Institutes name.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Contact person
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    <input value = {this.state.input['contact_Person']} name ={'contact_Person'} onChange={this.changeHandler} type="text" className="form-control" placeholder="Contact person" required />
                                                    <div className="invalid-feedback">
                                                        required Contact person.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Address 1
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    <input value = {this.state.input['address1']} name ={'address1'} onChange={this.changeHandler} type="text" className="form-control" placeholder=" Address 1" required />
                                                    <div className="invalid-feedback">
                                                        required Address 1.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    email
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                <input  value = {this.state.input['email']} name ={'email'} type="email"  className="form-control" placeholder="Email" onChange={this.changeHandler} required />
                                                    <div className="invalid-feedback">
                                                        required valid email.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                Address 2
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    <input  value = {this.state.input['address2']} name ={'address2'} onChange={this.changeHandler} type="text" className="form-control" placeholder=" Address 2"  />
                                                    <div className="invalid-feedback">
                                                        required Address 2.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Mobile
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    <input value = {this.state.input['mobile']} name ={'mobile'} onChange={this.changeHandler} type="text" className="form-control" placeholder="Mobile" required />
                                                    <div className="invalid-feedback">
                                                        required Mobile.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    State
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <select name={'state_id'} value={this.state.input['state_id']} className="form-control show-tick" onChange={this.changeHandler} required>
                                                    <option value="">Select State</option>
                                                    {this.state.stateList.map((item,i)=>{
                                                        return (<option value={item.id}>{item.name}</option>)
                                                    })}
                                                </select>
                                                <div className="invalid-feedback">
                                                        required State.
                                                </div>
                                            </div>

                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    User name
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    <input value = {this.state.input['username']} name ={'username'} type="text" className="form-control"  onChange={this.changeHandler}  placeholder="User name" required />
                                                    <div className="invalid-feedback">
                                                        required User name.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    City
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    <select name={'city_id'} value={this.state.input['city_id']} className="form-control show-tick" onChange={this.changeHandler} disabled={!this.state.input.state_id} required>
                                                        
                                                        <option value="">Select City</option>
                                                        {this.state.cityList.map((item,i)=>{
                                                            return (<option value={item.id}>{item.name}</option>)
                                                        })}
                                                    </select>
                                                    <div className="invalid-feedback">
                                                        required City.
                                                    </div>
                                                </div>
                                            </div>

                                            {this.state.editId ? 
                                               ''
                                                :  <>
                                                <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                    <div className="input-group">
                                                        Password
                                                    </div>
                                                </div>
                                                <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                    <div className="input-group">
                                                        :
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                    <div className="input-group">
                                                        <div className="input-group">
                                                            <input value = {this.state.input['password']} name ={'password'} onChange={this.changeHandler} type="password" className="form-control" placeholder="Password" required />
                                                            <div className="invalid-feedback">
                                                                required Password.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            }
                                                

                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Zip
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    <input value = {this.state.input['zip']} name ={'zip'} type="text" className="form-control" onChange={this.changeHandler} placeholder="Zip" required />
                                                    <div className="invalid-feedback">
                                                        required Zip.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    License Expiry
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <DatePicker
                                           selected={this.state.input['licency_expiry'] ? new Date(this.state.input['licency_expiry']) : new Date() }
                                                    name='licency_expiry'
                                                    placeholderText="License Expiry"
                                                    className="form-control"
                                                    onChange={this.handleExpiryDate}
                                                    required
                                                />   
                                                {! this.state.editId && this.state.expiryDateValidation && (
                                                    <div className='expiryDateValidation'>
                                                        required License Expiry.
                                                    </div>
                                                )}
                                            </div>
 
                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Image upload
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <input ref="file"   onChange={this.handleImage} type="file" className="form-control" placeholder="upload Image" 
                                                    // required = {this.state.url ? false : true}
                                                />
                                                <div className="invalid-feedback">
                                                    required Image.
                                                </div>
                                                {this.state.uploadImageUrl ?(
                                                <div className='imageFlag'>
                                                    image upload successfully
                                                </div>
                                            ) : this.state.url}

                                            </div>
                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Sub domain
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                <input  value = {this.state.input['domain_url']} name ={'domain_url'} onChange={this.changeHandler} type="text" className="form-control" placeholder="Sub domain"  required />
                                                {/* pattern="http(s?)(:\/\/)((www.)?)([a-zA-z0-9\-_]+)(.com)" */}
                                                    <div className="invalid-feedback">
                                                        required Sub domain(http://example.com).
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Status
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    <MDBFormInline>
                                                        <MDBInput
                                                            value = {1}
                                                            checked= {this.state.input['status'] === 1}
                                                            name ={'status'}
                                                            onChange =  {this.handleStatus}
                                                            label='Active'
                                                            type='radio'
                                                            id='radio1'
                                                            containerClass='mr-5'
                                                            required
                                                        />
                                                        <MDBInput
                                                            checked= {this.state.input['status'] === 0}
                                                            value = {0}
                                                            name ={'status'}
                                                            onChange =  {this.handleStatus}
                                                            label='Inactive'
                                                            type='radio'
                                                            id='radio2'
                                                            containerClass='mr-5'
                                                            required
                                                        />
                                                        <div className="invalid-feedback">
                                                            required Status.
                                                        </div>
                                                    </MDBFormInline>
                                                </div>
                                            </div>
                                          
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-4 col-sm-6 inputField submitBtn">
                                        <MDBBtn color="primary" className='btn' onClick= {this.clearData}>Cancel</MDBBtn>
                                        <MDBBtn color="primary" type={'submit'} className='btn'>{this.state.editId ? 'Update' :'Save'}</MDBBtn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </TabPane>
            </TabContent>
        )
    }

    modalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={ this.state.modal } toggle={this.toggle}>
                    <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
                    <MDBModalBody>
                        <div className='alertContainer'>
                            <h4> Do you want to {this.state.editId ? 'Update' : 'save'} it ? </h4>
                            <h6>Please check the information before submitting</h6>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggleClose}>No</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.handleSave}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
			</MDBContainer>
		)
	}
    
    render(){
        if(!isLogined()) this.props.history.push('/')
        return(
            <>
                <div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title"> {this.state.editId ? 'Update' : 'Create'} Institutes</h1>
							</div>
						</div>
					</div>
				</div>
                <div className="section-body mt-4">
					<div className="container-fluid">
                        {this.inputFieldContainer()}
				    </div>
                    <div>
                    {this.modalContainer()}
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
	institutes: state.getInstitutesReducer,
    InstitutesById: state.getInstitutesByIdReducer,
    editInstitutesStatus: state.editInstitutesReducer,
    uploadImageUrl: state.uploadImageReducer,
    stateList :state.utillReducer.stateList,
    cityList: state.utillReducer.cityList,
})

const mapDispatchToProps = dispatch => ({
	createInstitutesAction: (e, history) => dispatch(createInstitutesAction(e, history)),
    getInstitutesByIdAction: (e) => dispatch(getInstitutesByIdAction(e)),
    clearInstitutesByIdAction: (e) => dispatch(clearInstitutesByIdAction(e)),
    editInstitutesAction: (e, history) => dispatch(editInstitutesAction(e, history)),
    clearCreateInstitutesAction: (e) => dispatch(clearCreateInstitutesAction(e)),
    clearEditInstitutesAction: (e) => dispatch(clearEditInstitutesAction(e)),
    uploadImageAction: (e) => dispatch(uploadImageAction(e)),
    clearUploadImageAction: (e) => dispatch(clearUploadImageAction(e)),
    getCitiesAction: (e) => dispatch(getCitiesAction(e)),
	getStateAction: (e) => dispatch(getStateAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateInstitutes)