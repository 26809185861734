import React, { Component } from "react";
import { connect } from "react-redux";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import {
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import "./style.scss";
import {
  createSupportAction,
  getSupportEditByIdAction,
  editSupportAction,
} from "../../../actions/institute/ManageCustomerSupport/manageCustomerSupportAction";

class RaiseCustomerSupportQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      input: {
        status: 0
      },
      modal: false,
      editId: this.props.match.params.id,
      createStatus:''
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props
        .getSupportEditByIdAction(this.props.match.params.id)
        .then((res) => this.setState({ input: res }));
    }
  }

  clearData = () => {
    this.toggleClose();
    this.props.history.push("/institute/manage-customersupport");
  };

  toggle = () => {
    this.setState({
      modal: true,
    });
  };

  toggleClose = () => {
    this.setState({
      modal: false,
      input: {},
    });
  };

  changeHandler = (name, value) => {
    this.setState({ input: { ...this.state.input, [name]: value } });
  };

  submitHandler = (event) => {
    this.state.input["user_id"] = localStorage.getItem("userId");
    this.state.input["institute_id"] = localStorage.getItem("institute_id");
    event.preventDefault();
    event.target.className += " was-validated";
    if (event.target.checkValidity()) {
      this.setState({
        modal: true,
      });
    }
  };

  modalContainer = () => {
    return (
      <MDBContainer>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
          <MDBModalBody>
            <div className="alertContainer">
              <h4>
                {" "}
                Do you want to {this.state.editId ? "Update" : "save"} it ?{" "}
              </h4>
              <h6> Please check the information before submitting</h6>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              No
            </MDBBtn>
            <MDBBtn color="secondary" onClick={this.handleSave}>
              Yes
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  };

  handleSave = async () => {
    if (this.props.match.params.id) {
      await this.props.editSupportAction({
        id: this.props.match.params.id,
        data: this.state.input,
        history:this.props.history
      })
    } else {
      await this.props.createSupportAction(this.state.input,this.props.history)
    }
  };

  inputFieldContainer = () => {
    const { activeTab } = this.state;
    return (
      <div className="container-fluid">
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1} className={classnames(["fade show"])}>
            <form onSubmit={this.submitHandler} noValidate>
              <div className="card">
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-group">Subject</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <div className="input-group">
                          <input
                            value={this.state.input.subject}
                            placeholder="Subject"
                            onChange={(e) =>
                              this.changeHandler("subject", e.target.value)
                            }
                            type="text"
                            className="form-control"
                            required
                          />
                          <div className="invalid-feedback">
                            required subject field.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-group">Description</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <div className="input-group">
                          <textarea
                            value={this.state.input.description}
                            placeholder="description"
                            onChange={(e) =>
                              this.changeHandler("description", e.target.value)
                            }
                            id="w3review"
                            name="w3review"
                            rows="4"
                            cols="50"
                            wrap="hard"
                            className="form-control"
                            maxlength="200"
                            required
                          />
                          <div className="invalid-feedback">
                            required description field.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                        {this.state.createStatus ? (
                            <div className="errorFlag">
                                {this.state.createStatus +' *'}
                            </div>
                        ):''}
                    </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3 submitBtn">
                  <MDBBtn
                    color="primary"
                    className="btn"
                    onClick={this.clearData}
                  >
                    Close
                  </MDBBtn>
                  <MDBBtn color="primary" className="btn" type="submit">
                    Save
                  </MDBBtn>
                </div>
              </div>
            </form>
          </TabPane>
        </TabContent>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title"> Raise Query</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.inputFieldContainer()}</div>
          {this.state.modal && this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createSupportAction: (e,history) => dispatch(createSupportAction(e,history)),
  editSupportAction: (e,history) => dispatch(editSupportAction(e,history)),
  getSupportEditByIdAction: (e) => dispatch(getSupportEditByIdAction(e)),
});

export default connect(null, mapDispatchToProps)(RaiseCustomerSupportQuery);
