import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { MDBDataTable, MDBBtn, MDBIcon,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import './style.scss';
import moment from 'moment'
import CreateCircular from './CreateCircular';
import { clearGetCircularAction, deleteCircularAction, getCircularAction } from '../../../actions/institute/ManageCircular/manageCircularAction';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../common/Loader";


class ManageCircular extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			show: false,
			ciculars : [],
			tableBody : { columns :[], rows: []},
			modal: false,
			selectedCircular: null,
			deleteModal:false,
			deleteStatus:null,
			deleteId:'',
			openCreateModal:false,
			editId:'',
			searchCirculars:[],

			title:'',
			description:'',
			status:'',
			createdAt:'',

			role:''

		};
	}

	componentWillReceiveProps(nextProps){
		if(this.state.ciculars !== nextProps.ciculars.list){
			this.setState({
				ciculars: nextProps.ciculars.list
			},()=>{
				this.constructTableData()
			})
		}
		
		if(this.state.deleteStatus !== nextProps.deleteStatus.status){
			this.setState({
				deleteStatus:nextProps.deleteStatus.status
			},()=>{
				this.props.getCircularAction()
				this.setState({
					deleteModal:false
				})
			})
		}
		if(this.state.role !== nextProps.role){
			this.setState({
				role : nextProps.role
			})
		}
	}

	componentDidMount(){
		this.props.getCircularAction()
	}

	toggle = (value) =>{
		this.setState({
		  modal: true,
		  selectedCircular : value
		});
	}

	
	toggleClose = ()=>{
		this.setState({
			modal: false,
			selectedCircular: null,
			deleteModal: false,
			openCreateModal: false
		})
	}
	openEditModal=(value)=>{
		this.setState({
			editId : value.id,
			openCreateModal:true
		})
	}
	openDeleteModal=(value)=>{
		this.setState({
			deleteModal: true,
			deleteId:value.id
		})
	}
	deleteKnowledgeBase=()=>{
		this.props.deleteCircularAction(this.state.deleteId)
		
	}
	constructTableData =()=>{
		let col = [
			{
				label: '#',
				field: 'id',
			},
			{
				label: 'Circular Title',
				field: 'title',
				width: 150,
				attributes: {
					'aria-controls': 'DataTable',
					'aria-label': 'Name',
				},
			},
			{
				label: 'Description',
				field: 'circular_description',
				width: 270,
			}
		]
		if(this.state.role === 'institute admin'){
			col.push({
					label: 'School Name',
					field: 'school_name',
					width: 270,
			})
		}
		col.push(
			{
				label: 'Status',
				field: 'status',
				width: 270,
			},
			{
				label: 'created Date',
				field: 'createdDate',
				width: 270,
			},
			{
				label: 'Action',
				field: 'action',
				width: 300,
			}
		)
		let tableData = {
			columns:col,
			rows: 
				this.state.ciculars.map((item,i)=>{
					let obj = {
						id: i+1,
						title: item.title ? item.title : '-',
						circular_description: item.description ? item.description : '-' ,
						school_name: item.school_name ? item.school_name: '-',
						createdDate: item.created_at,
						status: item.status ? 'Sent' : 'Draft',
						action: (<div>
							{
								item.status ? 
									<MDBIcon  icon="eye" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{
										this.toggle(item)
									}} />:
								<>
									<MDBIcon  icon="edit" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{
										this.openEditModal(item)
									}} />
									<MDBIcon  icon="eye" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{
										this.toggle(item)
									}} />
								</>
							}
							{
								this.state.role === 'institute admin' && !item.status ? 
								<MDBIcon  icon="trash" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{
									this.openDeleteModal(item)
								}}/>:''
							}
						</div>)

					}
					return obj
				})
		}	
		this.setState({
			tableBody: tableData
		})	
	}
	getCirculars=()=>{
		this.props.getCircularAction()
	}
	handleChange=(e)=>{
		this.setState({ [e.target.name] : e.target.value})
	}
	getCircularDetail = ()=>{
		return(
			<div className="card-body">
				{
					this.state.role === 'institute admin' ? 
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								School Name
							</div>
						</div>
						<div className="col-lg-2 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								: 
							</div>
						</div>
						<div className="col-lg-6 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								{this.state.selectedCircular && this.state.selectedCircular.school_name ? this.state.selectedCircular.school_name : '-'}                                  
							</div>
						</div>
					</div>:''
				}
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Circular Title
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							{this.state.selectedCircular && this.state.selectedCircular.title ? this.state.selectedCircular.title : '-'}                                  
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Description
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							{this.state.selectedCircular && this.state.selectedCircular.description ? this.state.selectedCircular.description : '-'}                                  
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Uploaded Files
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="" >
					
							{this.state.selectedCircular && this.state.selectedCircular.url ? 
							<a href={this.state.selectedCircular.url} target="_blank">{this.state.selectedCircular.url}</a> 
							: '-'}                                  
						</div>
					</div>
				</div>
			</div>		
		)
	}

	handleSearch=()=>{
		let search = {
			title:	this.state.title,
			description:this.state.description,
			status:	this.state.status,
			created_at: this.state.createdAt ? moment(this.state.createdAt).format('YYYY-MM-DD') : '',
		}
		Object.keys(search).forEach((key) => (search[key] == '') && delete search[key]);
		this.props.getCircularAction(search)
	}
	clearSearch=()=>{
		this.setState({
			title:'',
			description:'',
			status:'',
			createdAt:''
		})
		this.props.getCircularAction()
	}
	handleDate =(value)=>{
		this.setState({ createdAt:value});
    }
	render() {
		const { activeTab } = this.state
		// if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Manage Circular</h1>
								<ol className="breadcrumb page-breadcrumb">
									<li className="breadcrumb-item">Search</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-lg-4 col-md-4 col-sm-6">
												<div className="input-group">
													<input type="text" className="form-control" placeholder="Circular title" value={ this.state.title} onChange={ this.handleChange } name="title"/>
												</div>
											</div>
											<div className="col-lg-4 col-md-4 col-sm-6">
												<div className="input-group">
													<input type="text" className="form-control" placeholder="Description" 
													value={ this.state.description} onChange={ this.handleChange } name="description"/>
												</div>
											</div>
                                            <div className="col-lg-4 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.status} onChange={ this.handleChange } name="status">
                                                    <option value="">Select Status</option>
                                                    <option value="0">Draft</option>
                                                    <option value="1">Sent</option>
                                                </select>
                                            </div>
											{
												this.state.role === 'principal' ? 
												<div className="col-lg-3 col-md-2 col-sm-12 mt-10">
														<DatePicker
															selected={this.state.createdAt}
															onChange={this.handleDate}
															placeholderText="Select date"
															className="form-control"
														/>
												</div> : ''
											}
										</div>
											<div className="btn-container">
													<div>
														<MDBBtn color="primary" className='btn' onClick={this.handleSearch}>Search</MDBBtn> 
														<MDBBtn color="primary" className='btn' onClick={this.clearSearch}>Clear</MDBBtn>
													</div>
																<div>
																	<MDBBtn color="primary" className='btn' onClick={()=>{
																		this.setState({openCreateModal : true,editId:''})
																	}}>Create</MDBBtn>
																</div>
												</div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
									<h1 className="page-title">Circular List</h1>
									{this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={['title']}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching = {false}
                />
              </div>
            )}
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>

					{this.state.openCreateModal ?  <CreateCircular editId={ this.state.editId } toggleClose ={ this.toggleClose } getCirculars={ this.getCirculars }/> : ''}
					{this.state.modal &&<MDBContainer>
						<MDBModal isOpen={this.state.modal} toggle={this.toggle}>
							<MDBModalHeader toggle={this.toggleClose}>View Circular</MDBModalHeader>
								<MDBModalBody>
									<div className="card">
										{this.getCircularDetail()}
									</div>
								</MDBModalBody>
								<MDBModalFooter>
									<MDBBtn color="secondary" onClick={this.toggleClose}>Close</MDBBtn>
								</MDBModalFooter>
						</MDBModal>
					</MDBContainer>}
					{this.state.deleteModal &&<MDBContainer>
						<MDBModal isOpen={this.state.deleteModal} toggle={this.toggle}>
							<MDBModalHeader toggle={this.toggleClose}>Delete Circular</MDBModalHeader>
								<MDBModalBody>
									<div className="">
										Are you sure to delete this circular?
									</div>
								</MDBModalBody>
								<MDBModalFooter>
									<MDBBtn color="secondary" onClick={this.deleteKnowledgeBase}>Delete</MDBBtn>
									<MDBBtn color="secondary" onClick={this.toggleClose}>Close</MDBBtn>
								</MDBModalFooter>
						</MDBModal>
					</MDBContainer>}
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	ciculars: state.getCircularReducer,
	deleteStatus:state.deleteCircularReducer,
	role: state.settings.role
})

const mapDispatchToProps = dispatch => ({
	getCircularAction: (e) => dispatch(getCircularAction(e)),
	deleteCircularAction: (e) => dispatch(deleteCircularAction(e)),
	// searchCircularAction: (e) => dispatch(searchCircularAction(e)),
	clearGetCircularAction: (e) => dispatch(clearGetCircularAction(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ManageCircular)
