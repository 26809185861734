import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
// import './style.scss';
import { connect } from "react-redux";
import {
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import {
  clearCreateGalleryAction,
  clearUploadGalleryction,
  createGAlleryAction,
  uploadGalleryFileAction,
} from "../../../../actions/institute/ManageGallery/manageGallery";

class UploadGalleryTeacher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      category: "",
      input: {
        gallery_type: "",
        school_id: "",
        file_type: "",
        file_name: "",
      },
      file: null,
      circularData: {},
      createStatus: null,
      uploadStatus: null,
      editStatus: null,
      url: "",
      school: [],
      modal: false,
      createModal: true,
      role: this.props.role,
    };
  }
  componentDidMount() {
    if (localStorage.getItem("school")) {
      let school = JSON.parse(localStorage.getItem("school"));
      let input = { ...this.state.input };
      input.school_id = school.id;
      this.setState({ input: input });
    }
  }
  componentWillReceiveProps(nextProps) {
    let input = { ...this.state.input };
    if (this.state.school !== nextProps.schoolState.list) {
      this.setState({
        school: nextProps.schoolState.list,
      });
    }
    if (
      this.state.uploadStatus !==
      (nextProps.uploadStatus &&
        nextProps.uploadStatus.status &&
        nextProps.uploadStatus.status.file)
    ) {
      this.setState(
        {
          uploadStatus: nextProps.uploadStatus.status.file,
        },
        () => {
          if (
            nextProps.uploadStatus &&
            nextProps.uploadStatus.status &&
            nextProps.uploadStatus.status.file
          ) {
            this.uploadGAllery(nextProps.uploadStatus.status.file);
          }
        }
      );
    }

    if (this.state.createStatus !== nextProps.createStatus.status) {
      this.setState(
        {
          createStatus: nextProps.createStatus.status,
        },
        () => {
          if (this.state.createStatus.errcode === 0) {
            this.clearData();
            this.props.getGallery();
          }
          else{
            this.toggleClose()  
          }
        }
      );
    }
    this.setState({ input: input });
  }
  modalContainer = () => {
    return (
      <MDBContainer>
        <MDBModal isOpen={this.state.modal}>
          <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
          <MDBModalBody>
            <div className="alertContainer">
              <h4>
                {" "}
                Do you want to {this.state.editId ? "Update" : "save"} it ?{" "}
              </h4>
              <h6> Please check the information before submitting</h6>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              No
            </MDBBtn>
            <MDBBtn color="secondary" onClick={this.handleSave}>
              Yes
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  };
  toggle = () => {
    this.props.toggleClose();
    this.clearData();
  };
  clearData = () => {
    this.props.clearCreateGalleryAction();
    this.props.toggleClose();
  };
  componentWillUnmount() {
    this.props.clearCreateGalleryAction();
    this.props.clearUploadGalleryction();
  }
  uploadGAllery = (url) => {
    let _url = url ? url : this.state.url;
    let data = {
      gallery_type: this.state.input.gallery_type,
      school_id: Number(this.state.input.school_id),
      file_type: this.state.input.file_type,
      file: _url,
      file_name: this.state.input.file_name,
    };
    this.props.createGAlleryAction(data);
  };
  handleSave = async () => {
    if (this.state.file) {
      this.props.uploadGalleryFileAction(this.state.file);
    } else {
      this.uploadGAllery("");
    }
  };
  submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (event.target.checkValidity()) {
      this.setState({
        createModal: false,
        modal: true,
      });
    }
  };
  onContentChange = (value) => {
    this.setState({ context: value });
  };
  changeHandler = (event) => {
    let input = { ...this.state.input };
    input[event.target.name] = event.target.value;
    this.setState({ input: input });
  };
  openCategoryModal = (e) => {
    this.setState({
      modal: true,
    });
  };
  toggleClose = () => {
    this.setState({
      modal: false,
      createModal: true,
    });
  };

  handleFile = (event) => {
    this.setState({ file: event.target.files[0] });
  };

  render() {
    const { activeTab } = this.state;
    // if(!isLogined()) this.props.history.push('/')
    return (
      <>
        {this.modalContainer()}
        <MDBContainer>
          <MDBModal isOpen={this.state.createModal}>
            <MDBModalHeader toggle={this.toggle}>Upload Gallery</MDBModalHeader>
            <form onSubmit={this.submitHandler} noValidate>
              <MDBModalBody>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                        <div className="input-group">Year</div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                        {new Date().getFullYear()}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                        <div className="input-group">Select Type</div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                        <div className="input-group">
                          <select
                            name="gallery_type"
                            value={this.state.input["gallery_type"]}
                            className="form-control show-tick"
                            onChange={this.changeHandler}
                            required
                          >
                            <option value="">Select Type</option>
                            <option value="Event Name">Event name</option>
                            <option value="School Gallery">
                              School Gallery
                            </option>
                            <option value="Misc Images">Misc</option>
                          </select>
                          <div className="invalid-feedback">required type.</div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                        <div className="input-group">File Name</div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.input["file_name"]}
                            name="file_name"
                            onChange={this.changeHandler}
                            placeholder=""
                            required
                          />
                          <div className="invalid-feedback">
                            required file name.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                        <div className="input-group">File Type</div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                        <div className="input-group">
                          <select
                            name="file_type"
                            value={this.state.input["file_type"]}
                            className="form-control show-tick"
                            onChange={this.changeHandler}
                            required
                          >
                            <option value="">Select File Type</option>
                            <option value="doc">Doc</option>
                            <option value="Jpeg">JPEG</option>
                            <option value="pdf">PDF</option>
                          </select>
                          <div className="invalid-feedback">
                            required file type.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                        <div className="input-group">Upload Files</div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                        <div className="">
                          <input
                            type="file"
                            className="form-control"
                            onChange={this.handleFile}
                            required={this.state.url ? false : true}
                            disabled={!this.state.input.file_type}
                                  accept={
                                    (this.state.input.file_type === "Jpeg" &&
                                      ".png,image/gif, image/jpeg") ||
                                    (this.state.input.file_type === "doc" &&
                                      ".doc, .docx") ||
                                    (this.state.input.file_type === "pdf" &&
                                      "application/pdf,application/vnd.ms-excel") 
                                  }
                          />
                          <div className="invalid-feedback">required file.</div>
                          <div className="">
                            {this.state.file ? "" : this.state.url}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                          {this.state.createStatus && this.state.createStatus.errcode !== 0 &&(
                              <div className="errorFlag">
                                  {this.state.createStatus.msg +' *'}
                              </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggle}>
                  Close
                </MDBBtn>
                <MDBBtn color="secondary" type="submit">
                  Save
                </MDBBtn>
              </MDBModalFooter>
            </form>
          </MDBModal>
        </MDBContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  createStatus: state.createGalleryReducer,
  uploadStatus: state.uploadGalleryFileReducer,
  schoolState: state.schoolReducer,
  role: state.settings.role,
});

const mapDispatchToProps = (dispatch) => ({
  createGAlleryAction: (e) => dispatch(createGAlleryAction(e)),
  clearCreateGalleryAction: (e) => dispatch(clearCreateGalleryAction(e)),
  uploadGalleryFileAction: (e) => dispatch(uploadGalleryFileAction(e)),
  clearUploadGalleryction: (e) => dispatch(clearUploadGalleryction(e)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadGalleryTeacher);
