import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { MDBDataTable, MDBBtn, MDBIcon,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
// import './style.scss';
import Loader from "../../../common/Loader";
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import { getSkillAction } from '../../../../actions/institute/Teacherdashboard/ManageSkill/ManageSkillAction';


class ManageSkill extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			show: false,
			skillsList : [],
			tableBody : { columns :[], rows: []},
			modal: false,
			selectedCircular: null,
			deleteModal:false,
			deleteStatus:null,
			deleteId:'',

			title:'',
			description:'',
			status:'',
			createdAt:'',

			role:''

		};
	}

	componentWillReceiveProps(nextProps){
		if(this.state.skillsList !== nextProps.skillState.list){
			this.setState({
				skillsList: nextProps.skillState.list
			},()=>{
				this.constructTableData()
			})
		}
		
		// if(this.state.deleteStatus !== nextProps.deleteStatus.status){
		// 	this.setState({
		// 		deleteStatus:nextProps.deleteStatus.status
		// 	},()=>{
		// 		this.props.getCircularAction()
		// 		this.setState({
		// 			deleteModal:false
		// 		})
		// 	})
		// }
		// if(this.state.role !== nextProps.role){
		// 	this.setState({
		// 		role : nextProps.role
		// 	})
		// }
	}

	componentDidMount(){
		this.constructTableData()
		this.props.getSkillAction()
	}

	toggle = (value) =>{
		this.setState({
		  modal: true,
		  selectedCircular : value
		});
	}

	constructTableData =()=>{
		let col = [
			{
				label: '#',
				field: 'id',
			},
			{
				label: 'Skill Image',
				field: 'skill_image_url',
				width: 150,
			},
			{
				label: 'Skill Name',
				field: 'skill_title',
				width: 270,
			},
			{
				label: 'Date',
				field: 'createdDate',
				width: 270,
			},
            {
				label: 'Status',
				field: 'status',
				width: 270,
			},
            {
				label: 'Action',
				field: 'action',
				width: 300,
			}
		]
		let tableData = {
			columns:col,
			rows: 
				this.state.skillsList.map((item,i)=>{
					let obj = {
						id: i+1,
						skill_image_url:<img src={item.skill_image_url} alt="image" style={{height:"60px"}}/>,
						skill_title: item.skill_title ? item.skill_title : '-',
						circular_description: item.description ? item.description : '-' ,
						school_name: item.school ? item.school.name: '-',
						createdDate: moment(item.date).format("YYYY-MM-DD") ,
						status: item.status ? 'Active' : 'Inactive',
						action: (<div>
							<MDBIcon  icon="edit" size="lg" style={{margin:'5px',cursor:'pointer'}}  onClick={()=>{
								this.props.history.push(`/teacher/edit-new-skill/${item.id}`)
							}}/>
						</div>)

					}
					return obj
				})
		}	
		this.setState({
			tableBody: tableData
		})	
	}
	getCirculars=()=>{
		this.props.getCircularAction()
	}
	handleChange=(e)=>{
		this.setState({ [e.target.name] : e.target.value})
	}
	handleSearch=()=>{
		let search = {
			title:	this.state.title,
			description:this.state.description,
			status:	this.state.status,
			created_at: this.state.createdAt ? moment(this.state.createdAt).format('YYYY-MM-DD') : '',
		}
		Object.keys(search).forEach((key) => (search[key] == '') && delete search[key]);
		this.props.clearGetCircularAction()
		this.props.getCircularAction(search)
	}
	clearSearch=()=>{
		this.setState({
			title:'',
			description:'',
			status:'',
			createdAt:''
		})
		this.props.clearGetCircularAction()
		this.props.getCircularAction()
	}
	handleDate =(value)=>{
		this.setState({ createdAt:value});
    }
	render() {
		const { activeTab } = this.state
		// if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Manage Skills</h1>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
                                        <div className={`row`}>
											<div className="card-body cardBodyContainer">
												<div className={`row btnContainer`}>
													<div className="col-lg-8 col-md-4 col-sm-6 ">
														<div className="card-body cardBodyContainer">
															<div className={`row justify-content-end`}>
																<div className="col-lg-12 col-md-4 col-sm-6 ">
																	<MDBBtn color="primary" className='btn' onClick={()=>{this.props.history.push('/teacher/add-new-skill')}}>Add New Skill</MDBBtn>&nbsp;
                                                                    <MDBBtn color="primary" className='btn' onClick={()=>{this.props.history.push('/teacher/manage-skill-rule')}}>Manage Skill Rules</MDBBtn>&nbsp;
                                                                    <MDBBtn color="primary" className='btn' onClick={()=>{this.props.history.push('/teacher/view-student-skill')}}>view Students Skill</MDBBtn>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
                                        </div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
									<h1 className="page-title">Skill List</h1>
									{this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={['title']}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching = {false}
                />
              </div>
            )}
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	skillState: state.ManageSkillReducer,
})

const mapDispatchToProps = dispatch => ({
	getSkillAction: (e) => dispatch(getSkillAction(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ManageSkill)
