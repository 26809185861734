import { GET_ACHEIVEMENTS } from "../../Constants/index";
import axios from "axios";
import { studentAchievements } from "../../../utils/Api";
import { getToken } from "../../../utils/token";

export const searchStudentAchievementsAction = (params) => {
  let params2= localStorage.getItem("school") ?{
    ...params,
    school_id:JSON.parse(localStorage.getItem("school")).id
  }
 : params 
  return (dispatch) => {
    const url = `${studentAchievements.getStudentAchievements}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params2,
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_ACHEIVEMENTS,
          payload: data.DATA.response,
        });
      } else {
        dispatch({
          type: GET_ACHEIVEMENTS,
          payload: [],
        });
      }
      return data.DATA.response
    });
  };
};

