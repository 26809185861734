import { SUPER_USER_LOGIN,
    SUPER_USER_LOGOUT } from '../../actions/Constants/index'

const initialState = {
    isLogined : null,
    userInfo : null,
    password_state: null,
    access_token: null,
    refresh_token: null
}

export default (state = initialState, action) => {
	switch (action.type) {
		case SUPER_USER_LOGIN:
			return {
				...state,
                isLogined : action.payload.isLogined,
                userInfo : action.payload.userInfo,
                password_state: action.payload.password_state,
                access_token: action.payload.access_token,
                refresh_token: action.payload.refresh_token
			}
        case SUPER_USER_LOGOUT:
            return {
                ...state,
                isLogined : null,
                userInfo : null
            }
        default:
            return state
    }
}
