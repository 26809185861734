import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { MDBDataTable, MDBBtn } from 'mdbreact';
import Loader from "../../../common/Loader";
import './style.scss';
import DatePicker from "react-datepicker";
import moment from 'moment'
import { getSubjectAction } from '../../../../actions/institute/Utils/utilsAction';
import { getStudentGoalAction } from '../../../../actions/institute/Teacherdashboard/ManageGoal/ManageGoalAction';


class ManageGoal extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			tableBody : { columns :[], rows: []},

			subject_id:'',
			status:'',
			goal_type:'',

            goalsList:[],

            subjectList:[],

			utills:{}

		};
	}

	componentWillReceiveProps(nextProps){
	

		if(this.state.subjectList !== nextProps.utills.subjectList){
            this.setState({
                subjectList: nextProps.utills.subjectList
            })
        }
		if(this.state.goalsList !== nextProps.goalState.studentList){
			this.setState({
				goalsList: nextProps.goalState.studentList
			},()=>{
				this.constructTableData()
			})
		}
	}
	componentDidMount(){
		let search = {
            section_id: JSON.parse(localStorage.getItem('section')).id
        }
        this.props.getSubjectAction(search)
		this.props.getStudentGoalAction();

	}
	constructTableData =()=>{
		let col= [
			{
				label: '#',
				field: 'id',
			},
			{
				label: 'Subject',
				field: 'subject_name',
				width: 270,
			},
			
			{
				label: 'Goal Type',
				field: 'goal_type',
				width: 270,
			},
            {
				label: 'Goal For',
				field: 'goal_for',
				width: 270,
			},
            {
				label: 'Goals',
				field: 'score_rule',
				width: 270,
			},
            {
				label: 'Year',
				field: 'year',
				width: 270,
			},
			{
				label: 'Percentage Score',
				field: 'percentage',
				width: 270,
			},
            {
				label: 'Created Date',
				field: 'created_at',
				width: 270,
			},
            {
				label: 'Status',
				field: 'goal_status',
				width: 270,
			}
            
		]
		let tableData = {
			columns: col,
			rows: 
				this.state.goalsList.map((item,i)=>{
					let obj = {
						id: i+1,
						goal_for: item.name ? item.name.join(','): '-',
						goal_type: item.goal_type ? item.goal_type: '-',
						score_rule:item.score_rule ? item.score_rule : '-',
						subject_name: item.subject_name ? item.subject_name: '-',
						percentage:item.percentage ? item.percentage: '-',
						year: item.year ? item.year: '-',
						created_at: moment(item.created_at).format('YYYY-MM-DD'),
						goal_status: item.goal_status,
					}
					return obj
				})
		}	
		this.setState({
			tableBody: tableData
		})	
	}
	handleChange=(e)=>{
		this.setState({ [e.target.name] : e.target.value})
	}

	componentWillUnmount(){
		// this.props.clearGetFeedbackDropdownAction()
		// this.props.clearGetFeedbackAction()
	}

	handleSearch=()=>{
		let search = {
			subject_id:this.state.subject_id,
			rule_for:this.state.goal_type,
			status:this.state.status
		}
		Object.keys(search).forEach((key) => (search[key] == '') && delete search[key]);
		this.props.getStudentGoalAction(search)
	}

	clearSearch=()=>{
		this.setState({
            subject_id:'',
			status:'',
			goal_type:'',
		})
		this.props.getStudentGoalAction()
	}

	render() {
		const { activeTab } = this.state
		// if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">View Goals</h1>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
											<div className="row">
												<div className="col-lg-4 col-md-2 col-sm-12">
													<select className="form-control show-tick" value={ this.state.goal_type} 
													onChange={ this.handleChange } name="goal_type" >
														<option value="">Select Goal Type</option>
														<option value="Examination">Examination</option>
														<option value="Assignment">Assignment</option>
													
													</select>
												</div>
												<div className="col-lg-4 col-md-2 col-sm-12">
													<select className="form-control show-tick" value={ this.state.subject_id} 
													onChange={ this.handleChange } name="subject_id">
														<option value="">Select Subject</option>
														{this.state.subjectList && this.state.subjectList.map((item)=>{
															return <option value={item.id}>{item.name}</option>
														})}
													</select>
												</div>
												<div className="col-lg-4 col-md-2 col-sm-12">
													<select className="form-control show-tick" value={ this.state.status} 
													onChange={ this.handleChange } name="status" >
														<option value="">Select Status</option>
														<option value="Achieved">Achieved</option>
														<option value="Not Achieved">Not Achieved</option>
														<option value="Pending">Pending</option>
													</select>
												</div>
                                            </div>
											<div className="btn-container">
												<div>
													<MDBBtn color="primary" className='btn' onClick={this.handleSearch}>Search</MDBBtn>
													<MDBBtn color="primary" className='btn' onClick={this.clearSearch}>Clear</MDBBtn>
												</div>
										</div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
									<h1 className="page-title">Goal List</h1>
									{this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={['title']}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching = {false}
                />
              </div>
            )}
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	goalState: state.ManageGoalReducer,
	utills: state.utillReducer

})

const mapDispatchToProps = dispatch => ({
	getSubjectAction: (e) => dispatch(getSubjectAction(e)),
	getStudentGoalAction: (e) => dispatch(getStudentGoalAction(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ManageGoal)
