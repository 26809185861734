import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import './style.scss';
import { connect } from 'react-redux';
import AssignmentDetail from './AssignmentDetail';
import moment from 'moment'
import { Link } from 'react-router-dom';
import { getAssignmentByIdAction } from '../../../../actions/institute/Teacherdashboard/ManageAssignment/ManageAssignmentAction';

class ViewAssignment extends Component {
    constructor(props) {
		super(props);
		this.state = {
            editId: this.props.match.params.id,
            assignment:{},
			activeTab: 1,
            assignmentDetail:{},
		};
	}

    componentDidMount(){
        if(this.state.editId){
            this.props.getAssignmentByIdAction({id:this.state.editId})
        }
	}


    componentWillReceiveProps(nextProps){
        if(this.props.assignment.detail !== nextProps.assignment.detail){
            let data = nextProps.assignment.detail[0]
            this.setState({assignmentDetail:data})
        }
	}
    
    render(){
        const { activeTab } = this.state
        // if(!isLogined()) this.props.history.push('/')
        return(
            <>
            <div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
                                 <div className="row">
                                    <div className="col-lg-6 col-md-4 col-sm-6">
                                        <h1 className="page-title">
                                            Submitted Assignment
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 col-md-4 col-sm-6">
                                        <Link to="/teacher/manage-assignment">Back To Manage Assignment</Link>
                                    </div>
                                </div>
							</div>
						</div>
					</div>
				</div>
                <div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Assignment Title :
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                {this.state.assignmentDetail ? this.state.assignmentDetail.assignment_title : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Class Name :
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.assignmentDetail ? this.state.assignmentDetail.class_name : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Section Name :
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.assignmentDetail ? this.state.assignmentDetail.section_name : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Subject Name :
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.assignmentDetail ? this.state.assignmentDetail.subject_name : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Deadline Date :
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.assignmentDetail ? moment(this.state.assignmentDetail.deadline).format('YYYY-MM-DD') : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Year :
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                {moment().format('YYYY')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                                                    
                                                </div>
                                            </div>
                                            <div className="row">
                                                <AssignmentDetail editId={this.state.editId} title="Assignment Details"/>
                                            </div>
                                        </div>
                                    </div>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    assignment: state.ManageAssignmentReducer,
})

const mapDispatchToProps = dispatch => ({
	getAssignmentByIdAction: (e) => dispatch(getAssignmentByIdAction(e)),    
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewAssignment)
