import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import Ckeditor from "../../common/ckeditor";
import {
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import "./style.scss";
import {
  getFAQCategoryAction,
  addFAQCategoryAction,
  createFAQAction,
  getFAQByIdAction,
  editFAQAction,
} from "../../../actions/Admin/ManageFAQ/manageFAQAction";
class CreateFAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      input: {
        status: true,
      },
      newCategory: "",
      modal: false,
      categoryModal: false,
      editId: this.props.match.params.id,
      dropDownDataCategory: [],
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props
        .getFAQByIdAction(this.props.match.params.id)
        .then((res) =>{
          let input = {...this.state.input}
          input = res
          input.status = res.status ? true : false
          this.setState({ input: input })
        });
    }
    this.props
      .getFAQCategoryAction()
      .then((res) => this.setState({ dropDownDataCategory: res }));
  }

  clearData = () => {
    this.toggleClose();

    this.props.history.push("/super-admin/manage-faq");
  };

  toggle = () => {
    this.setState({
      modal: true,
    });
  };

  addCategoryToggle = () => {
    this.setState({
      categoryModal: true,
      newCategory:''
    });
  };

  categoryToggleClose = () => {
    this.setState({
      categoryModal: false,
    });
  };

  toggleClose = () => {
    this.setState({
      modal: false,
    });
  };

  changeHandler = (name, value) => {
    this.setState({ input: { ...this.state.input, [name]: value } });
  };

  submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (event.target.checkValidity()) {
      this.setState({
        modal: true,
      });
    }
  };

  handleCategory = (e) => {
    this.setState({
      newCategory: e.target.value,
    });
  };

  submitSaveCategory = async (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (event.target.checkValidity()) {
      await this.props.addFAQCategoryAction({
        category_name: this.state.newCategory,
      });
    }
    await this.props
      .getFAQCategoryAction()
      .then((res) => this.setState({ dropDownDataCategory: res }));
    this.categoryToggleClose();
  };

  modalContainer = () => {
    return (
      <MDBContainer>
        <MDBModal
          isOpen={this.state.categoryModal}
          toggle={this.categoryToggleClose}
        >
          <form onSubmit={this.submitSaveCategory} noValidate>
            <MDBModalHeader toggle={this.categoryToggleClose}>
              Alert
            </MDBModalHeader>
            <MDBModalBody>
              <div className="alertContainer">
                <h4> Add Category </h4>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-5 col-md-4 col-sm-6 inputField">
                            <div className="input-group">Category Name</div>
                          </div>
                          <div className="col-lg-7 col-md-4 col-sm-6 inputField">
                            <div className="input-group">
                              <input
                                type="text"
                                value={this.state.newCategory}
                                placeholder="Category Name"
                                onChange={this.handleCategory}
                                className="form-control"
                                required
                              />
                              <div className="invalid-feedback">
                                required Category field.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={this.categoryToggleClose}>
                Close
              </MDBBtn>
              <MDBBtn color="secondary" type="submit">
                Save
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
          <MDBModalBody>
            <div className="alertContainer">
              <h4>
                {" "}
                Do you want to {this.state.editId ? "Update" : "save"} it ?{" "}
              </h4>
              <h6>Please check the information before submitting</h6>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              No
            </MDBBtn>
            <MDBBtn color="secondary" onClick={this.handleSave}>
              Yes
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  };

  handleSave = async () => {
    if (this.props.match.params.id) {
      await this.props.editFAQAction({
        id: this.props.match.params.id,
        data: this.state.input,
        history: this.props.history
      })
    } else {
      await this.props.createFAQAction(this.state.input, this.props.history)
    }
  };

  inputFieldContainer = () => {
    const { activeTab } = this.state;
    return (
      <div className="container-fluid">
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1} className={classnames(["fade show"])}>
            <form onSubmit={this.submitHandler} noValidate>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6 col-md-4 col-sm-6 inputField row">
                      <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                        <div className="input-group">Category</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6 inputField">
                        <div className="input-group">
                          <select
                            className="form-control show-tick"
                            value={this.state.input.category_id}
                            onChange={(e) =>
                              this.changeHandler("category_id", e.target.value)
                            }
                            required
                          >
                            <option value="">Select Category</option>
                            {this.state.dropDownDataCategory.map((ele) => (
                              <option value={ele.id}>
                                {ele.category_name}
                              </option>
                            ))}
                          </select>
                          <div className="invalid-feedback">
                            required Category field.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-6 inputField mt-20">
                      <div
                        className="input-group addCategoryLabel"
                        onClick={this.addCategoryToggle}
                      >
                        + Add Category
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-6 inputField row">
                      <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                        <div className="input-group">Question</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6 inputField">
                        <div className="input-group">
                          <input
                            value={this.state.input.title}
                            placeholder="Question"
                            onChange={(e) =>
                              this.changeHandler("title", e.target.value)
                            }
                            type="text"
                            className="form-control"
                            required
                          />
                          <div className="invalid-feedback">
                            required question field.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-6 inputField row"></div>
                    <div className="col-lg-6 col-md-4 col-sm-6 inputField row">
                      <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                        <div className="input-group">Status</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6 inputField">
                        <div className="input-group">
                          <select
                            value={this.state.input.status}
                            onChange={(e) =>
                              this.changeHandler(
                                "status",
                                e.target.value.toLowerCase() === "true"
                              )
                            }
                            required
                            className="form-control show-tick"
                          >
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                          <div className="invalid-feedback">
                            required status field.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-4 col-sm-6 inputField row">
                      <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                        <div className="input-group">Add Content</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-9 col-md-4 col-sm-6 inputField">
                        <div className="input-group">
                          <Ckeditor
                            content={this.state.input.content}
                            html={this.state.input.content}
                            onChange={(value) => {
                              this.changeHandler("content", value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                        {this.state.createStatus ? (
                            <div className="errorFlag">
                                {this.state.createStatus +' *'}
                            </div>
                        ):''}
                    </div>
                    <div className="col-lg-12 col-md-4 col-sm-6 inputField submitBtn">
                      <MDBBtn
                        color="primary"
                        className="btn"
                        onClick={this.clearData}
                      >
                        Cancel
                      </MDBBtn>
                      <MDBBtn color="primary" className="btn" type="submit">
                        Save
                      </MDBBtn>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </TabPane>
        </TabContent>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">
                  {" "}
                  {this.state.editId ? "Update" : "Create"} FAQ
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.inputFieldContainer()}</div>
          {this.state.modal && this.modalContainer()}
          {this.state.categoryModal && this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addFAQCategoryAction: (e) => dispatch(addFAQCategoryAction(e)),
  getFAQByIdAction: (e) => dispatch(getFAQByIdAction(e)),
  editFAQAction: (e,history) => dispatch(editFAQAction(e,history)),
  getFAQCategoryAction: (e) => dispatch(getFAQCategoryAction(e)),
  createFAQAction: (e,history) => dispatch(createFAQAction(e,history)),
});

export default connect(null, mapDispatchToProps)(CreateFAQ);
