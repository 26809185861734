import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import DatePicker from "react-datepicker";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import Loader from "../../common/Loader";
import {
  searchSectionAction,
} from "../../../actions/institute/ManageSection/manageSectionAction";
import {
  getAllSchoolAction,
  getClassAction,
  getSectionAction,
} from "../../../actions/institute/Utils/utilsAction";
import "./style.scss";

class ManageSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      tableBody: { columns: [], rows: [] },
      modalId: null,
      search: {},
      dropDownDataClass: [],
      dropDownDataSection: [],
      dropDownDataSchool: [],
      isLoggedInRole:
        localStorage.getItem("loginedRole") === "principal" ? true : false,
    };
  }

  componentDidMount() {
    this.props.searchSectionAction().then((res) => {
      this.constructTableData();
    });
    this.props
      .getAllSchoolAction()
      .then((res) => this.setState({ dropDownDataSchool: res }));
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevState.search.school_id !== this.state.search.school_id) {
      if (this.state.search.school_id) {
        let search = {
          school_id: this.state.search.school_id,
        };
        this.props
          .getClassAction(search)
          .then((res) => this.setState({ dropDownDataClass: res }));
      }
    }
    if (prevState.search.class_id !== this.state.search.class_id) {
      if (this.state.search.class_id) {
        let search = {
          class_id: this.state.search.class_id,
        };
        this.props
          .getSectionAction(search)
          .then((res) => this.setState({ dropDownDataSection: res }));
      }
    }
    if (
      this.state.isLoggedInRole &&
      this.state.search.school_id === undefined
    ) {
      this.setState({
        search: {
          ...this.state.search,
          ["school_id"]: localStorage.getItem("school_id"),
        },
      });
    }
  }

  toggle = (institute, i) => () => {
    this.setState({
      modalId: i,
    });
  };

  toggleClose = () => {
    this.setState({
      modalId: null,
    });
  };

  handleSearch = (name, value) => {
    this.setState({ search: { ...this.state.search, [name]: value } });
  };

  searchHandler = async () => {
    let search = {
      school_id: this.state.search.school_id,
      class_id: this.state.search.class_id,
      section_id: this.state.search.section_id,
      created_at: this.state.search.created_at
        ? moment(this.state.search.created_at).format("YYYY-MM-DD")
        : null,
      status: this.state.search.status,
    };
    Object.keys(search).forEach(
      (key) => search[key] === "" && delete search[key]
    );
    await this.props.searchSectionAction(search);
    this.constructTableData();
  };

  clearHandler = async () => {
    this.setState({
      search: {},
      dropDownDataClass: [],
      dropDownDataSection: [],
      dropDownDataSchool: [],
    });
    await this.props.searchSectionAction().then((res) => {
      this.constructTableData();
    });
    await this.props
      .getAllSchoolAction()
      .then((res) => this.setState({ dropDownDataSchool: res }));
  };

  tableColumns = () => [
    {
      label: "#",
      field: "id",
    },
    !this.state.isLoggedInRole && {
      label: "School Name",
      field: "school_name",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Name",
      },
    },
    {
      label: "Class Name",
      field: "class_name",
      width: 270,
    },
    {
      label: "Section Name",
      field: "section_name",
      width: 270,
    },
    {
      label: "Created Date",
      field: "created_at",
      width: 270,
    },
    {
      label: "Status",
      field: "status",
      width: 270,
    },
    {
      label: "Action",
      field: "action",
      width: 270,
    },
  ];

  constructTableData = () => {
    let tableData = {
      columns: this.tableColumns().filter((ele) => ele),
      rows: this.actionContainer(),
    };

    this.setState({
      tableBody: tableData,
    });
  };

  actionContainer = () => {
    return this.props.sectionList.map((item, i) => {
      let obj = {
        id: i + 1,
        school_name: item.school_name,
        class_name: item.class_name,
        section_name: item.section_name,
        created_at: item.created_at,
        status: item.status === 1 ? "Active" : "Inactive",
        action: (
          <div className="actionContainer">
            <MDBIcon
              icon="edit"
              size="lg"
              className="icon"
              onClick={() => {
                this.props.history.push(`/institute/edit-section/${item.id}`);
              }}
            />
            <MDBIcon
              icon="eye"
              size="lg"
              className="icon"
              onClick={this.toggle(item, i)}
            />
          </div>
        ),
      };
      return obj;
    });
  };

  viewModalDetails = (item) => {
    return (
      <>
        {!this.state.isLoggedInRole && (
          <div className="faq-modal-sub-container p-l-30">
            <div>
              <h6 className="font-weight-bold">School Name </h6>
              <h6>{item.school_name}</h6>
            </div>
          </div>
        )}
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Class Name </h6>
            <h6>{item.class_name}</h6>
          </div>
        </div>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Section Name </h6>
            <h6>{item.section_name}</h6>
          </div>
        </div>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Status</h6>
            <h6>{item.status === 1 ? "Active" : "Inactive"}</h6>
          </div>
        </div>
      </>
    );
  };

  searchContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              {!this.state.isLoggedInRole && (
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="input-group">
                    <select
                      value={
                        Object.keys(this.state.search).length === 0
                          ? ""
                          : this.state.search.school_id
                      }
                      onChange={(e) =>
                        this.handleSearch("school_id", e.target.value)
                      }
                      className="form-control show-tick"
                    >
                      <option value="">Select School</option>
                      {this.state.dropDownDataSchool.map((ele) => (
                        <option value={ele.id}>{ele.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <div
                className={` md-3 col-sm-12 ${
                  !this.state.isLoggedInRole ? "col-lg-2" : "col-lg-3"
                }`}
              >
                <div className="input-group">
                  <select
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search.class_id
                    }
                    onChange={(e) =>
                      this.handleSearch("class_id", e.target.value)
                    }
                    disabled={!this.state.search.school_id}
                    className="form-control show-tick"
                  >
                    <option value="">Select Class</option>
                    {this.state.dropDownDataClass.map((ele) => (
                      <option value={ele.id}>{ele.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div
                className={` md-3 col-sm-12 ${
                  !this.state.isLoggedInRole ? "col-lg-2" : "col-lg-3"
                }`}
              >
                <div className="input-group">
                  <select
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search.section_id
                    }
                    onChange={(e) =>
                      this.handleSearch("section_id", e.target.value)
                    }
                    disabled={!this.state.search.class_id}
                    className="form-control show-tick"
                  >
                    <option value="">Section</option>
                    {this.state.dropDownDataSection.map((ele) => (
                      <option value={ele.id}>{ele.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div
                className={` md-3 col-sm-12 ${
                  !this.state.isLoggedInRole ? "col-lg-2" : "col-lg-3"
                }`}
              >
                <select
                  value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search["status"]
                  }
                  onChange={(e) => this.handleSearch("status", e.target.value)}
                  className="form-control show-tick"
                >
                  <option value="">Status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>

              <div className="col-lg-3 md-3 col-sm-12">
                <DatePicker
                  selected={this.state.search.created_at}
                  onChange={(value) => this.handleSearch("created_at", value)}
                  placeholderText="Created date"
                  className="form-control "
                />
              </div>
            </div>
            <div className={`row`}>
              <div className="card-body cardBodyContainer">
                <div className={`row btnContainer`}>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <MDBBtn
                      color="primary"
                      className="btn"
                      onClick={this.searchHandler}
                    >
                      Search
                    </MDBBtn>{" "}
                    &nbsp;
                    <MDBBtn
                      color="primary"
                      className="btn"
                      onClick={this.clearHandler}
                    >
                      Clear
                    </MDBBtn>{" "}
                  </div>
                  <div className="col-lg-8 col-md-4 col-sm-6 ">
                    <div className="card-body cardBodyContainer">
                      <div className={`row justify-content-end`}>
                        <div className="col-lg-2 col-md-4 col-sm-6 ">
                          <MDBBtn
                            color="primary"
                            className="btn"
                            onClick={() => {
                              this.props.history.push(
                                "/institute/create-section"
                              );
                            }}
                          >
                            Create
                          </MDBBtn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TabPane>
    );
  };

  tableContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body" id="capture">
            <h1 className="page-title">Section List</h1>
            {this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  searching={false}
                  sortRows={["title"]}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                />
              </div>
            )}
          </div>
        </div>
      </TabPane>
    );
  };

  modalContainer = () => {
    return (
      <div>
        {this.props.sectionList.map((item, i) => {
          return (
            <MDBModal
              isOpen={this.state.modalId === i ? true : false}
              toggle={this.toggle}
            >
              <MDBModalHeader toggle={this.toggleClose}>
                View Section
              </MDBModalHeader>
              <MDBModalBody>{this.viewModalDetails(item)}</MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggleClose}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          );
        })}
      </div>
    );
  };

  pageContainer = () => {
    const { activeTab } = this.state;
    return (
      <TabContent activeTab={activeTab}>
        {this.searchContainer()}
        {this.tableContainer()}
      </TabContent>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">Manage Section</h1>
                <ol className="breadcrumb page-breadcrumb">
                  <li className="breadcrumb-item">Search</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.pageContainer()}</div>
          {this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sectionList: state.getSectionReducer,
});

const mapDispatchToProps = (dispatch) => ({
  searchSectionAction: (e) => dispatch(searchSectionAction(e)),
  getAllSchoolAction: (e) => dispatch(getAllSchoolAction(e)),
  getClassAction: (e) => dispatch(getClassAction(e)),
  getSectionAction: (e) => dispatch(getSectionAction(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageSection);
