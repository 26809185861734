import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.scss";
import {
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import {
  subscribeEmailAction,
  UnsubscribeEmailAction,
  getSubscribeEmailFromAPIAction,
} from "../../../../actions/institute/ManageNewsLetter/manageNewsLetterAction";

class NewsletterScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {},
      getEmail: "",
      modal: false,
    };
  }

  componentDidMount() {
    this.props
      .getSubscribeEmailFromAPIAction()
      .then((res) => this.setState({ getEmail: res?.email }));
  }

  changeHandler = (name, value) => {
    this.setState({ input: { ...this.state.input, [name]: value } });
  };

  toggle = () => {
    this.setState({
      modal: true,
    });
  };

  toggleClose = () => {
    this.setState({
      modal: false,
    });
  };

  submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (event.target.checkValidity()) {
      this.setState({
        modal: true,
      });
    }
  };

  handleSave = async () => {
    if (this.state.getEmail) {
      this.setState({
        getEmail: "",
      });
      await this.props.UnsubscribeEmailAction();
    } else {
      this.props.subscribeEmailAction(this.state.input);
      this.setState({
        getEmail: this.state.input.email,
      });
    }
    this.clearData();
  };

  clearData = () => {
    this.setState({
      modal: false,
    });
    {
      localStorage.getItem("loginedRole") === "student" &&
        this.props.history.push("/student/newsletter");
    }
    {
      localStorage.getItem("loginedRole") === "parent" &&
        this.props.history.push("/parent/newsletter");
    }
  };

  modalContainer = () => {
    return (
      <MDBContainer>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
          <MDBModalBody>
            <div className="alertContainer">
              <h4>
                {" "}
                Do you want to{" "}
                {this.state.getEmail ? "Unsubscribe" : "Subscribe"} it ?{" "}
              </h4>
              <h6> Please check the information before submitting</h6>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              No
            </MDBBtn>
            <MDBBtn color="secondary" onClick={this.handleSave}>
              Yes
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  };

  tableContainer = () => {
    return (
      <div className="card">
        <div className="card-body" id="capture">
          <h1 className="page-title text-center">Like to Know more Stuff ?</h1>
          <div className="table-responsive">
            <form onSubmit={this.submitHandler} noValidate>
              <div className="card-body">
                <div className="row justify-content-center ">
                  <div className="w-50">
                    <input
                      value={
                        this.state.getEmail
                          ? this.state.getEmail
                          : this.state.input.email
                          ? this.state.input.email
                          : ""
                      }
                      onChange={(e) =>
                        this.changeHandler("email", e.target.value)
                      }
                      type="email"
                      className="form-control"
                      placeholder="Please enter your email address"
                      required
                    />
                  </div>
                  <div className="invalid-feedback">
                    required email address.
                  </div>
                </div>
                <div className="row justify-content-center mt-20">
                  {!this.state.getEmail ? (
                    <MDBBtn color="primary" className="btn" type="submit">
                      Subscribe
                    </MDBBtn>
                  ) : (
                    <MDBBtn color="primary" className="btn" type="submit">
                      UnSubscribe
                    </MDBBtn>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">Newsletter</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4" style={{ height: "400px" }}>
          <div className="container-fluid">{this.tableContainer()}</div>
          {this.state.modal && this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  subscribeEmailAction: (e) => dispatch(subscribeEmailAction(e)),
  UnsubscribeEmailAction: () => dispatch(UnsubscribeEmailAction()),
  getSubscribeEmailFromAPIAction: () =>
    dispatch(getSubscribeEmailFromAPIAction()),
});

export default connect(null, mapDispatchToProps)(NewsletterScreen);
