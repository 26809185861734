import { GET_CUSTOMER_SUPPORT, 
    GET_USER_AND_INSTITUTE,
    REPLY_BY_ID, 
    SEARCH_CUSTOMER_SUPPORT
   } from '../../Constants/index';
import axios from 'axios';
import { customerSupport } from '../../../utils/Api'
import { getToken } from '../../../utils/token'

export const getCustomerSupportAction = () => {
	return dispatch => {
        const url = `${customerSupport.getCustomerSupport}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_CUSTOMER_SUPPORT,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_CUSTOMER_SUPPORT,
                    payload: {
                        list : null
                    }
                })
            }
        })
    }
}

export const getUserAndInstituteAction = () => {
	return dispatch => {
        const url = `${customerSupport.getUserAndInstitute}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_USER_AND_INSTITUTE,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_USER_AND_INSTITUTE,
                    payload: {
                        list : {
                            username:[],
                            institute:[]
                        }
                    }
                })
            }
        })
    }
}

export const replyByIdAction = (data) => {
    const reply = {'reply':data.reply, 'image_url': data.image_url,'status': data.status}
	return dispatch => {
        const url = `${customerSupport.replyById(data.id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PATCH',
            data : reply
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: REPLY_BY_ID,
                    payload: {
                        list : flag.status
                    }
                })
            }else {
                dispatch({
                    type: REPLY_BY_ID,
                    payload: {
                        list : flag.status
                    }
                })
            }
        })
    }
}

export const clearsearchCustomerSupportAction = () => {
	return dispatch => {
        dispatch({
            type: SEARCH_CUSTOMER_SUPPORT,
            payload: {
                list : null
            }
        })
    }
}

export const searchCustomerSupportAction = (params) => {
	return dispatch => {
        const url = `${customerSupport.searchCustomerSupport}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: SEARCH_CUSTOMER_SUPPORT,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: SEARCH_CUSTOMER_SUPPORT,
                    payload: {
                        list : null
                    }
                })
            }
        })
    }
}

export const getInstituteNameAction = () => {
    return () => {
      const url = `${customerSupport.getInstitute}`,
        options = {
          url,
          headers: {
            Authorization: getToken(),
          },
          method: "GET",
        };
      return axios(options).then((response) => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR;
        if (data && flag.status.errcode === 0) {
          return data.DATA;
        }
        return {};
      });
    };
  };
  
  export const getUserNameAction = () => {
    return () => {
      const url = `${customerSupport.getUserName}`,
        options = {
          url,
          headers: {
            Authorization: getToken(),
          },
          method: "GET",
        };
      return axios(options).then((response) => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR;
        if (data && flag.status.errcode === 0) {
          return data.DATA;
        }
        return {};
      });
    };
  };
  