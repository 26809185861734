import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { isLogined } from '../../../../utils/token'
import './style.scss';
import { connect } from 'react-redux';
import { MDBBtn, MDBFormInline, MDBInput} from 'mdbreact';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import AnswerDetail from './AnswerDetail';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { assignmentEvaluateMarkAction, clearEvaluateMarkAction, getSubmittedAssignmentAnswerWithPendingStatusAction } from '../../../../actions/institute/Teacherdashboard/ManageAssignment/ManageAssignmentAction';

class ViewPendingAnswer extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
            
            createStatus: null,
            modal: false,
            detail:{},
            remarks:'',

            id: this.props.match.params.id,
            studentId:this.props.match.params.studentId,

            answerWithMark:[]
		};
	}

    componentDidMount(){
      this.props.getSubmittedAssignmentAnswerWithPendingStatusAction(this.state.id,this.state.studentId)      
	}
    componentWillUnmount(){
        this.props.clearEvaluateMarkAction()
    }
    componentWillReceiveProps(nextProps){
        if(this.state.detail !== nextProps.assignment.submittedAnswersWithPendingStatus){
            this.setState({detail: nextProps.assignment.submittedAnswersWithPendingStatus})
        }
        if(this.state.createStatus !== nextProps.assignment.evaluatedMarkStatus){
            this.setState({
                createStatus: nextProps.assignment.evaluatedMarkStatus
            },()=>{
                if(this.state.createStatus.errcode === 0){
                    this.clearData()
                }
            })
        }
	}
    
    clearData =()=>{
        this.toggleClose()
        this.props.history.push('/teacher/manage-assignment')
    }

    modalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={ this.state.modal }>
                    <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
                    <MDBModalBody>
                        <div className='alertContainer'>
                            <h4> Do you want to save it ? </h4>
                            <h6> Please check the information before submitting</h6>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggleClose}>No</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.handleSave}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
			</MDBContainer>
		)
	}
    setMark=(markArr)=>{
        this.setState({answerWithMark:markArr})
    }
    handleSave=()=>{
        let descriptive_questions = []

        if(this.state.answerWithMark.length >0){
            this.state.answerWithMark.map((item,i)=>{
                if(item.is_descriptive){
                    descriptive_questions.push({
                        id:item.id,
                        student_answer_option:item.student_answer_option
                    })
                }
            })
        }
        else{
            this.state.detail && this.state.detail.qeustion_list.map((item,i)=>{
                if(item.is_descriptive){
                    descriptive_questions.push({
                        id:item.id,
                        student_answer_option:item.student_answer_option
                    })
                }
            })
        }
        
        let data = {
            remarks:this.state.remarks,
            descriptive_questions:descriptive_questions,
            project_questions:[]
        }
        this.props.assignmentEvaluateMarkAction({
            id:this.state.id,
            id1:this.state.studentId,
            data:data,
        })
    }
    submitHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
		if(event.target.checkValidity()) {
            this.setState({
                modal: true,
            })
		}
	}
    
    changeHandler = event => {
		this.setState({ remarks:event.target.value });
	};
   
    toggleClose = ()=>{
		this.setState({
			modal: false,
		})
	}
   
    render(){
        const { activeTab } = this.state
        // if(!isLogined()) this.props.history.push('/')
        return(
            <>
            {this.modalContainer()}
            <div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action" style={{width:"60%"}}>
                                <div className="row">
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <h1 className="page-title">
                                            {this.state.detail && this.state.detail.assignment_info && this.state.detail.assignment_info[0] 
                                                                        ? this.state.detail.assignment_info[0].student_name : '-'}
                                        </h1>
                                    </div>
                                    <div className="col-lg-5 col-md-4 col-sm-6">
                                        <Link to="/teacher/manage-assignment">Back To Manage Assignment</Link>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                        <MDBBtn color="primary" >Pending</MDBBtn> 
                                    </div>
                                </div>
							</div>
						</div>
					</div>
				</div>
                <div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
                                <form  onSubmit={this.submitHandler} noValidate>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Assignment Title:
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                {this.state.detail && this.state.detail.assignment_info && this.state.detail.assignment_info[0] 
                                                                ? this.state.detail.assignment_info[0].assignment_title : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Class Name:
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.detail && this.state.detail.assignment_info && this.state.detail.assignment_info[0] 
                                                                ? this.state.detail.assignment_info[0].class_name : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Section Name:
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.detail && this.state.detail.assignment_info && this.state.detail.assignment_info[0] 
                                                                ? this.state.detail.assignment_info[0].section_name : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Subject Name:
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.detail && this.state.detail.assignment_info && this.state.detail.assignment_info[0] 
                                                                ? this.state.detail.assignment_info[0].subject_name : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Deadline Date:
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.detail && this.state.detail.assignment_info && this.state.detail.assignment_info[0] 
                                                                ? this.state.detail.assignment_info[0].deadline : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Remarks
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                <textarea  name="itemDescription" value = {this.state.remarks} placeholder="Enter Remarks" 
                                                                className="form-control" 
                                                                onChange={this.changeHandler}></textarea>
                                                            </div>
                                                            <div className="invalid-feedback">
                                                                required description.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <AnswerDetail questions={this.state.detail && this.state.detail.qeustion_list } 
                                                status="pending" setMark={ this.setMark }/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div className="col-lg-12 col-md-4 col-sm-6 inputField submitBtn">
                                            <MDBBtn color="primary" className='btn' onClick= {()=>{
                                                this.props.history.push('/teacher/manage-assignment')
                                            }}>Cancel</MDBBtn>
                                            <MDBBtn color="primary" type={'submit'} className='btn'>Update</MDBBtn>
                                        </div>
                                    </div>
                                </form>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    assignment: state.ManageAssignmentReducer,
})

const mapDispatchToProps = dispatch => ({
	getSubmittedAssignmentAnswerWithPendingStatusAction: (e,e1) => dispatch(getSubmittedAssignmentAnswerWithPendingStatusAction(e,e1)),
	assignmentEvaluateMarkAction: (e) => dispatch(assignmentEvaluateMarkAction(e)),
	clearEvaluateMarkAction: (e) => dispatch(clearEvaluateMarkAction(e)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewPendingAnswer)
