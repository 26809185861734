import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane} from "reactstrap";
import classnames from 'classnames';
import { MDBDataTable, MDBBtn, MDBIcon } from 'mdbreact';
import Loader from "../../common/Loader";
import { isLogined } from '../../../utils/token'
import './style.scss';
import moment from 'moment'
import { clearGetReceiptsAction, getReceiptsAction } from '../../../actions/Admin/ManageReceipts/manageReceiptsAction';
import { CSVLink } from "react-csv";
import { getInstitutesAction } from '../../../actions/Admin/ManageInstitutes/manageInstitutesAction';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

class ManageReceipts extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			show: false,
			Receipts : [],
			tableBody : { columns :[], rows: []},
			selectedReceipts: null,
			exportCsv:null,
			search:{
				instituteId:'',
				receiptNumber:'',
				amount:'',
				createdAt:'',
				fromDate:'',
			},
			institutes:[],
			pdfSuccess:false
		};

	}

	exportCsv =()=> {
		const exportCsvHeaders = [
			{ label: "Invoice Number", key: "invoiceNum" },
			{ label: "Institute", key: "institute" },
			{ label: "Item Description", key: "receipt_itemDescription" },
			{ label: "Created Date", key: "createdAt" },
			{ label: "Amount", key: "receipt_amount" }	
		];
		const exportCSVData = this.state.Receipts.map((item)=>{
			let obj = {
				institute: this.getInstituteName(item.instituteId),
				invoiceNum: item.receiptNumber,
				receipt_itemDescription: item.itemDescription,
				receipt_amount:  item.amount ,
				createdAt:item.createdAt,
			}
			return obj
		})
		let csv = {
			data: exportCSVData,
			headers: exportCsvHeaders,
			filename: 'receipt-report.csv',
			style: {color:'white'}
		};
		this.setState({
			exportCsv: csv
		})
	}

	clearSearch=()=>{
		let search = this.state.search
        search.instituteId = ''
        search.receiptNumber = ''
        search.amount = ''
        search.createdAt = ''
        search.fromDate = ''
		this.setState({ search:search });
		this.setState({
			search:search
		})
		this.props.getReceiptsAction()
	}

	searchHandler =()=>{
		let search = {
			receiptNumber:this.state.search && this.state.search.receiptNumber ? this.state.search.receiptNumber :"",
			instituteId: this.state.search && this.state.search.instituteId ? this.state.search.instituteId :"",
			amount: this.state.search && this.state.search.amount ? this.state.search.amount :"",
			createdAt: this.state.search.createdAt ? moment(this.state.search.createdAt, 'DD/MM/YYYY').format("YYYY-MM-DD") : '',
			fromDate: this.state.search.fromDate ? moment(this.state.search.fromDate, 'DD/MM/YYYY').format("YYYY-MM-DD") : '',
		}
		Object.keys(search).forEach((key) => (search[key] == '') && delete search[key]);
		this.props.getReceiptsAction(search)
	}

	componentWillReceiveProps(nextProps){
		if(this.state.Receipts !== nextProps.Receipts.list){
			this.setState({
                Receipts : nextProps.Receipts.list
            },()=>{
				this.constructTableData()
				this.exportCsv(nextProps.Receipts.list)
			})
		}
		if(this.state.institutes !== nextProps.institutes){
			this.setState({
				institutes: nextProps.institutes.list
			})
		}
	}

	componentDidMount(){
        this.props.getInstitutesAction()
		this.props.getReceiptsAction()
	}

	handleSearch =(event)=>{
		let search = this.state.search
        search[event.target.name] = event.target.value
		this.setState({ search:search });
	}

	getInstituteName=(id)=>{
		let name = '-'
		this.state.institutes.map(item =>{
			if(item.id === id){
				name = item.institute_name
			}
		})
		return name
	}
	componentWillUnmount(){
		this.props.clearGetReceiptsAction()
	}

	constructTableData =()=>{
		let tableData = {
			columns: [
				{
					label: '#',
					field: 'id',
				},
				{
					label: 'Invoice Number',
					field: 'invoiceNum',
					width: 150,
					attributes: {
						'aria-controls': 'DataTable',
						'aria-label': 'Name',
					},
				},
				{
					label: 'Institute',
					field: 'institute',
					width: 270,
				},
				{
					label: 'Item Description',
					field: 'receipt_itemDescription',
					width: 270,
				},
				{
					label: 'Created Date',
					field: 'createdAt',
					width: 270,
				},
				{
					label: 'Amount',
					field: 'receipt_amount',
					width: 270,
				},
				{
					label: 'Action',
					field: 'action',
					width: 300,
				},
			],
			rows: 
			this.state.Receipts.map((item,i)=>{
					let obj = {
						id: i+1,
						institute: this.getInstituteName(item.instituteId),
						invoiceNum: item.receiptNumber,
						receipt_itemDescription: item.itemDescription,
						receipt_amount:  item.amount ,
						createdAt:item.createdAt,
						status: item.isActive ? (<div style={{color:'green'}}>Active</div>) : <div style={{color:'red'}}>Inactive</div>,
						action: (<div>
							<MDBIcon  icon="edit" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{
								this.props.history.push(`/super-admin/edit-receipts/${item.id}`)
							}} />
							<MDBIcon  icon="download" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{this.exportDetailPdf(item)}  }/>
                            <MDBIcon  icon="print" size="lg" style={{margin:'5px',cursor:'pointer'}}  onClick={()=>{this.print(item )}}/>
						</div>)

					}
					return obj
				})
		}	
		this.setState({
			tableBody: tableData
		})	
	}

	onDocumentLoadSuccess=()=>{
	}

	print=(item)=>{
		this.setState({
			selectedReceipts:item
		},()=>{
			var w = window.open('','');
			w.document.body.innerHTML = `
			<h2>Receipt Detail</h2>
			<table style="text-align: left;border-collapse: collapse;width: 100%;">
			<tr>
				<td style="padding: 15px;text-align: left;">Institutes name</td>
				<td>:</td>
				<td>${this.state.selectedReceipts ? this.state.selectedReceipts.institute_name : ''}</td>
			</tr>
			<tr>
				<td style="padding: 15px;text-align: left;">Invoice Number</td>
				<td>:</td>
				<td>${this.state.selectedReceipts ? this.state.selectedReceipts.receiptNumber:''}</td>
			</tr>
			<tr>
				<td style="padding: 15px;text-align: left;">Description</td>
				<td>:</td>
				<td>${this.state.selectedReceipts ? this.state.selectedReceipts.itemDescription:''}</td>
			</tr>
			<tr>
				<td style="padding: 15px;text-align: left;">Amount</td>
				<td>:</td>
				<td>${this.state.selectedReceipts ? this.state.selectedReceipts.amount:''}</td>
			</tr>
			<tr>
				<td style="padding: 15px;text-align: left;">Created Date</td>
				<td>:</td>
				<td>${this.state.selectedReceipts ? this.state.selectedReceipts.createdAt:''}</td>
			</tr>
			</table>`
			w.print();
		})
	}

	getReceiptDetails = ()=>{
		return(
			<div id="receiptDetail">
				<div className="card-body" >
					<h1 className="page-title">Receipt Detail</h1>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								Institutes name
							</div>
						</div>
						<div className="col-lg-2 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								:
							</div>
						</div>
						<div className="col-lg-6 col-md-4 col-sm-6 inputField">
							<div className="input-group">
							{this.state.selectedReceipts ? this.state.selectedReceipts.institute_name : ''}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								Invoice Number
							</div>
						</div>
						<div className="col-lg-2 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								:
							</div>
						</div>
						<div className="col-lg-6 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								{this.state.selectedReceipts ? this.state.selectedReceipts.receiptNumber:''}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								Description
							</div>
						</div>
						<div className="col-lg-2 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								:
							</div>
						</div>
						<div className="col-lg-6 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								{this.state.selectedReceipts ? this.state.selectedReceipts.itemDescription:''}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								Amount
							</div>
						</div>
						<div className="col-lg-2 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								:
							</div>
						</div>
						<div className="col-lg-6 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								{this.state.selectedReceipts ? this.state.selectedReceipts.amount :''}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								Created Date
							</div>
						</div>
						<div className="col-lg-2 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								:
							</div>
						</div>
						<div className="col-lg-6 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								{this.state.selectedReceipts ? this.state.selectedReceipts.createdAt :''}
							</div>
						</div>
					</div>
				</div>		
			</div>		
		)
	}

	handleToDate =(value)=>{
        let search = this.state.search
        search['createdAt'] = value
		this.setState({ search:search});
    }

	handleFromDate =(value)=>{
        let search = this.state.search
        search['fromDate'] = value
		this.setState({ search:search});
    }

	exportPdf = () => {
		html2canvas(document.querySelector("#capture")).then(canvas => {
		   const imgData = canvas.toDataURL('image/png');
		   const pdf = new jsPDF();
		   pdf.addImage(imgData, 'PNG', 0, 0,200,0);
		   pdf.save("Receipt.pdf"); 
	   }); 
	}

	exportDetailPdf = (item) => {
		this.setState({
			selectedReceipts:item,
			pdfSuccess:true
		},()=>{
			html2canvas(document.querySelector("#receiptDetail")).then(canvas => {
				this.setState({pdfSuccess:false})
				const imgData = canvas.toDataURL('image/png');
				const pdf = new jsPDF();
				pdf.addImage(imgData, 'PNG', 0, 0,200,0);
				pdf.save("Receipt.pdf"); 
			}).catch((e)=>{
				this.setState({pdfSuccess:false})
			}); 
		})
	}

	render() {
		const { activeTab } = this.state
		if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Manage Receipts</h1>
								<ol className="breadcrumb page-breadcrumb">
									<li className="breadcrumb-item">Search</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-lg-3 col-md-4 col-sm-6">
												<div className="input-group">
													<input type="text" className="form-control" placeholder="Receipt No" name ='receiptNumber' value={this.state.search['receiptNumber']} onChange={this.handleSearch} />
												</div>
											</div>
											<div className="col-md-3 col-sm-12">
                                                <select className="form-control show-tick" value={this.state.search['instituteId']} name="instituteId" onChange={this.handleSearch}>
                                                    <option value="">Select Institute</option>
													{this.state.institutes && this.state.institutes.map((item,i)=>{
														return (<option value={item.id}>{item.institute_name}</option>)
													})}
                                                </select>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
												<input type="text" className="form-control" placeholder="Amount" name ='amount' value={this.state.search['amount']} onChange={this.handleSearch} />
                                            </div>
											<div className="col-lg-3 col-md-4 col-sm-6">
												<div className="input-group">
												<DatePicker
													selected={this.state.search['createdAt']}
													onChange={this.handleToDate}
													placeholderText="Created date"
													className="form-control"
												/>
												</div>
											</div>
										</div>
											<div className="btn-container">
												<div>
												<MDBBtn color="primary" className='btn' onClick={this.searchHandler}>Search</MDBBtn> 
														<MDBBtn color="primary" className='btn' onClick={this.clearSearch}>Clear</MDBBtn> 
												</div>
												<div>
												<MDBBtn color="primary" className='btn' onClick={()=>{
																		this.props.history.push('/super-admin/create-receipts')
																	}}>Create</MDBBtn>
																	<MDBBtn color="primary" className='btn' onClick={this.exportPdf}>PDF <MDBIcon  icon="download" size="lg" className='icon' /></MDBBtn>
																	<MDBBtn color="primary" className='btn'>
																	{this.state.exportCsv &&(
																		<CSVLink {...this.state.exportCsv}>CSV
																		<MDBIcon  icon="download" size="lg" className='icon' /></CSVLink>
																	)}
																	</MDBBtn>
												</div>
												</div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body" id="capture">
									<h1 className="page-title">Receipts List</h1>
									{this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={['title']}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching = {false}
                />
              </div>
            )}
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>
				</div>
				{this.state.pdfSuccess ? this.getReceiptDetails() : ``}
			</>
		);
	}
}

const mapStateToProps = state => ({
	Receipts: state.getReceiptsReducer,
    institutes: state.getInstitutesReducer,
})

const mapDispatchToProps = dispatch => ({
	getReceiptsAction: (e) => dispatch(getReceiptsAction(e)),
	clearGetReceiptsAction: (e) => dispatch(clearGetReceiptsAction(e)),
	getInstitutesAction: (e) => dispatch(getInstitutesAction(e)),

})

export default connect(mapStateToProps, mapDispatchToProps)(ManageReceipts)
