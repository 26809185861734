import { GET_CERTIFICATE, 
    CREATE_CERTIFICATE,
    GET_CERTIFICATE_BY_ID,
    GET_PRINCIPAL_DETAIL,
    UPLOAD_CERTIFICATE_LOGO,
    UPLOAD_CERTIFICATE_BADGE,
    UPLOAD_CERTIFICATE_SIGN, SHOW_SNACKBAR} from '../../../Constants/index';
import axios from 'axios';
import { manageCertificate } from '../../../../utils/Api'
import { getToken } from '../../../../utils/token'

export const getCertificateAction = (params) => {
	return dispatch => {
        const url = `${manageCertificate.getCertificate}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_CERTIFICATE,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_CERTIFICATE,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearGetCertificateAction = () => {
	return dispatch => {
        dispatch({
            type: GET_CERTIFICATE,
            payload: {
                list : []
            }
        })
    }
}

export const createCertificateAction = (data, history) => {
	return dispatch => {
        const url = `${manageCertificate.createCertificate}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: CREATE_CERTIFICATE,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                 history.push("/teacher/manage-certificate");
            }else {
                dispatch({
                    type: CREATE_CERTIFICATE,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const clearCreateCertificateAction = () => {
	return dispatch => {
        dispatch({
            type: CREATE_CERTIFICATE,
            payload: {
                list : null
            }
        })
    }
}

export const getCertificateByIdAction = (id) => {
	return dispatch => {
        const url = `${manageCertificate.getCertificateById(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_CERTIFICATE_BY_ID,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_CERTIFICATE_BY_ID,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearCertificateByIdAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_CERTIFICATE_BY_ID,
            payload: {
                list : {}
            }
        })
    }
}

export const getPrincipalAction = () => {
	return dispatch => {
        const url = `${manageCertificate.getPrincipal}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_PRINCIPAL_DETAIL,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_PRINCIPAL_DETAIL,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const uploadCertificateLogoAction = (data) => {
	return dispatch => {
        let formData = new FormData();
        formData.append('logo', data);
        const url = `${manageCertificate.uploadCertificateLogo}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data : formData
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: UPLOAD_CERTIFICATE_LOGO,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: UPLOAD_CERTIFICATE_LOGO,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearuploadCertificateLogoAction = () => {
	return dispatch => {
        dispatch({
            type: UPLOAD_CERTIFICATE_LOGO,
            payload: {
                list : {}
            }
        })
    }
}

export const uploadCertificateBadgeAction = (data) => {
	return dispatch => {
        let formData = new FormData();
        formData.append('badge', data);
        const url = `${manageCertificate.uploadCertificateBadge}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data : formData
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: UPLOAD_CERTIFICATE_BADGE,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: UPLOAD_CERTIFICATE_BADGE,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearuploadCertificateBadgeAction = () => {
	return dispatch => {
        dispatch({
            type: UPLOAD_CERTIFICATE_BADGE,
            payload: {
                list : {}
            }
        })
    }
}

export const uploadCertificateSignAction = (data) => {
	return dispatch => {
        let formData = new FormData();
        formData.append('signature', data);
        const url = `${manageCertificate.uploadCertificateSign}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data : formData
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: UPLOAD_CERTIFICATE_SIGN,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: UPLOAD_CERTIFICATE_SIGN,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearuploadCertificateSignAction = () => {
	return dispatch => {
        dispatch({
            type: UPLOAD_CERTIFICATE_SIGN,
            payload: {
                list : {}
            }
        })
    }
}
