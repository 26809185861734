import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import moment from "moment";
import Loader from "../../../common/Loader";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import "./style.scss";
import {
  searchAnnouncementAction,
} from "../../../../actions/institute/ManageAnnouncement/manageAnnouncementAction";

class ViewAnnouncement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      tableBody: { columns: [], rows: [] },
      modalId: null,
      search: {},
    };
  }

  componentDidMount() {
    this.props.searchAnnouncementAction().then((res) => {
      this.constructTableData();
    });
  }

  toggle = (institute, i) => () => {
    this.setState({
      modalId: i,
    });
  };

  toggleClose = () => {
    this.setState({
      modalId: null,
    });
  };

  handleSearch = (name, value) => {
    this.setState({ search: { ...this.state.search, [name]: value } });
  };

  searchHandler = async () => {
    let search = {
      title: this.state.search.title,
      content: this.state.search.content,
      created_at: this.state.search.created_at
        ? moment(this.state.search.created_at).format("YYYY-MM-DD")
        : null,
    };
    Object.keys(search).forEach(
      (key) => search[key] == "" && delete search[key]
    );
    await this.props.searchAnnouncementAction(search);
    this.constructTableData();
  };

  clearHandler = async () => {
    this.setState({
      search: {},
    });
    await this.props.searchAnnouncementAction().then((res) => {
      this.constructTableData();
    });
  };

  tableColumns = () => [
    {
      label: "#",
      field: "id",
    },
    {
      label: "Announcement Title",
      field: "title",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Name",
      },
    },
    {
      label: "Content",
      field: "content",
      width: 270,
    },
    {
      label: "Subject",
      field: "subject",
      width: 270,
    },
    {
      label: "Teacher Name",
      field: "teacher_name",
      width: 270,
    },
    {
      label: "Created Date",
      field: "created_at",
      width: 270,
    },
    {
      label: "Action",
      field: "action",
      width: 270,
    },
  ];

  constructTableData = () => {
    let tableData = {
      columns: this.tableColumns().filter((ele) => ele),
      rows: this.actionContainer(),
    };

    this.setState({
      tableBody: tableData,
    });
  };

  extractContent = (html) => {
    return new DOMParser().parseFromString(html, "text/html").documentElement
      .textContent;
  };
  
  actionContainer = () => {
    return this.props.announcementList.map((item, i) => {
      let obj = {
        id: i + 1,
        title: item.title,
        content: this.extractContent(item.content),
        subject: item.subject_name,
        teacher_name: item.teacher_name,
        created_at: item.created_at,
        action: (
          <div className="actionContainer">
            <MDBIcon
              icon="eye"
              size="lg"
              className="icon"
              onClick={this.toggle(item, i)}
            />
          </div>
        ),
      };
      return obj;
    });
  };

  viewModalDetails = (item) => {
    return (
      <>
      <h3 style={{textAlign: "center"}}>{item.title}</h3><br />
        <p dangerouslySetInnerHTML={{ __html: item.content }}>
        </p>
      </>
    );
  };

  searchContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-4 md-3 col-sm-12">
                <input
                  type="text"
                  value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search.title
                  }
                  onChange={(e) => this.handleSearch("title", e.target.value)}
                  className="form-control"
                  placeholder="Announcement Title"
                />
              </div>
              <div className="col-lg-4 md-3 col-sm-12">
                <input
                  type="text"
                  value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search.content
                  }
                  onChange={(e) => this.handleSearch("content", e.target.value)}
                  className="form-control"
                  placeholder="Content"
                />
              </div>
              <div className="col-lg-4 md-3 col-sm-12">
                <DatePicker
                  selected={this.state.search.created_at}
                  onChange={(value) => this.handleSearch("created_at", value)}
                  placeholderText="Created date"
                  className="form-control "
                />
              </div>
            </div>
            <div className="btn-container">
              <div className="col-lg-4 col-md-4 col-sm-6">
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.searchHandler}
                >
                  Search
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.clearHandler}
                >
                  Clear
                </MDBBtn>
              </div>
            </div>
          </div>
        </div>
      </TabPane>
    );
  };

  tableContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body" id="capture">
            <h1 className="page-title">Announcement List</h1>
            {this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  searching={false}
                  sortRows={["title"]}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                />
              </div>
            )}
          </div>
        </div>
      </TabPane>
    );
  };

  modalContainer = () => {
    return (
      <div>
        {this.props.announcementList.map((item, i) => {
          return (
            <MDBModal
              isOpen={this.state.modalId === i ? true : false}
              toggle={this.toggle}
            >
              <MDBModalHeader toggle={this.toggleClose}>
                View Announcement
              </MDBModalHeader>
              <MDBModalBody>{this.viewModalDetails(item)}</MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggleClose}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          );
        })}
      </div>
    );
  };

  pageContainer = () => {
    const { activeTab } = this.state;
    return (
      <TabContent activeTab={activeTab}>
        {this.searchContainer()}
        {this.tableContainer()}
      </TabContent>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title"> View Announcement</h1>
                <ol className="breadcrumb page-breadcrumb">
                  <li className="breadcrumb-item">Search</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.pageContainer()}</div>
          {this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  announcementList: state.getAnnouncementReducer,
});

const mapDispatchToProps = (dispatch) => ({
  searchAnnouncementAction: (e) => dispatch(searchAnnouncementAction(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewAnnouncement);
