import moment from 'moment';
import React, { Component } from 'react';
import './style.scss';
import SideImage from '../../../../assets/images/side_strip.png'

export default class ViewCertificate extends Component {
    constructor(props) {
		super(props);
		this.state = {
			selectedCertificate:this.props.selectedCertificate
		};
	}
    render() {
    return <div>
            <div className='certificate-main-div'>
                <img src={SideImage} alt="logo" className='certificate-img' />
                <p className='certificate-p'></p>
                <div className='certificate-div'>
                    <h1 className='certificate-h1'>CERTIFICATE</h1>
                    <h5 className='certificate-h5'>OF STUDENT ACHIEVEMENT</h5>
                    <p className='certificate-p1'>THIS CERTIFICATE IS PROUDLY PRESENTED TO</p>
                    <p className='certificate-p2'>{this.state.selectedCertificate && this.state.selectedCertificate.student_name}</p>
                    <p className='certificate-p3'>{`for outstanding efforts in ` +
                    (this.state.selectedCertificate && this.state.selectedCertificate.subject_name) + ` of class `+
                    (this.state.selectedCertificate && this.state.selectedCertificate.class_name) + ' section ' +
                    (this.state.selectedCertificate && this.state.selectedCertificate.section_name)+ '.'} </p>
                    <div className='certificate-div2'>
                        <div>
                            <p className='certificate-p4'>Date</p>
                            <p style={{margin: "0"}}><b><i>{moment(this.state.selectedCertificate && this.state.selectedCertificate.created_at).format('DD MMM YYYY')}</i></b></p>
                        </div>
                        <div>
                            <p className='certificate-p4'>Kenya Brooks-Jones</p>
                            <p style={{margin: "0"}}><b><i>Chief Innovation Officer</i></b></p>
                        </div>
                        <div>
                            <p className='certificate-p4'>Jacob Cooper</p>
                            <p style={{margin: "0"}}><b><i>Chief of Education Technology</i></b></p>
                        </div>
                    </div>
                </div>
                <p className='certificate-p5'></p>
            </div>
        </div>;
  }
}
