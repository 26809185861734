import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { isLogined } from "../../../utils/token";
import Loader from "../../common/Loader";
import "./style.scss";
import moment from "moment";
import {
  clearGetKnowledgeBaseAction,
  deleteKnowledgeBaseAction,
  getCategoryAction,
  getKnowledgeBaseAction,
  getKnowledgeBaseByIdAction,
} from "../../../actions/Admin/ManageKnowledgeBase/manageKnowledgeBaseAction";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

class KnowledgeBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      show: false,
      knowledgeBases: [],
      tableBody: { columns: [], rows: [] },
      modal: false,
      selectedKnowledgeBase: null,
      deleteModal: false,
      deleteStatus: null,
      deleteId: "",
      categories: [],
      title: "",
      subTitle: "",
      category: "",
      exportCsv: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.deleteStatus !== nextProps.deleteStatus.status) {
      this.props.getKnowledgeBaseAction();
      this.setState(
        {
          deleteStatus: nextProps.deleteStatus.status,
          deleteModal: false,
        },
        () => {
          this.props.getCategoryAction();
        }
      );
    }

    if (this.state.knowledgeBases !== nextProps.knowledgeBases.list) {
      this.setState(
        {
          knowledgeBases: nextProps.knowledgeBases.list,
        },
        () => {
          this.constructTableData();
          this.exportCsv();
        }
      );
    }
    if (this.state.categories !== nextProps.categories.list) {
      this.setState({
        categories: nextProps.categories.list,
      });
    }
  }

  componentDidMount() {
    this.props.getCategoryAction();
    this.props.getKnowledgeBaseAction();
  }

  componentWillUnmount() {
    this.props.clearGetKnowledgeBaseAction();
  }

  toggle = (value) => {
    this.setState({
      modal: true,
      selectedKnowledgeBase: value,
    });
  };

  toggleClose = () => {
    this.setState({
      modal: false,
      selectedKnowledgeBase: null,
      deleteModal: false,
    });
  };

  openDeleteModal = (value) => {
    this.setState({
      deleteModal: true,
      deleteId: value.id,
    });
  };

  deleteKnowledgeBase = () => {
    this.props.deleteKnowledgeBaseAction(this.state.deleteId);
  };

  exportCsv = () => {
    const exportCsvHeaders = [
      { label: "Title", key: "title" },
      { label: "Sub Title", key: "subTitle" },
      { label: "Category", key: "cat_name" },
    ];
    const exportCSVData = this.state.knowledgeBases.map((item) => {
      let obj = {
        title: item.title,
        subTitle: item.subTitle,
        cat_name: item.cat_name,
      };
      return obj;
    });
    let csv = {
      data: exportCSVData,
      headers: exportCsvHeaders,
      filename: "knowledgebase-report.csv",
      style: { color: "white" },
    };
    this.setState({
      exportCsv: csv,
    });
  };

  constructTableData = () => {
    let tableData = {
      columns: [
        {
          label: "#",
          field: "id",
        },
        {
          label: "Title",
          field: "title",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Subtitle",
          field: "subTitle",
          width: 270,
        },
        {
          label: "Category",
          field: "cat_name",
          width: 270,
        },
        {
          label: "Action",
          field: "action",
          width: 300,
        },
      ],
      rows: this.state.knowledgeBases.map((item, i) => {
        let date = new Date(item.time);
        let obj = {
          id: i + 1,
          title: item.title,
          subTitle: item.subTitle,
          cat_name: item.cat_name ? item.cat_name : "-",
          createdDate: moment(date, "DD/MM/YYYY").format("DD MMM YYYY"),
          expiryDate: item.amount,
          status: item.isActive ? (
            <div style={{ color: "green" }}>Active</div>
          ) : (
            <div style={{ color: "red" }}>Inactive</div>
          ),
          action: (
            <div>
              <MDBIcon
                icon="edit"
                size="lg"
                style={{ margin: "5px",cursor:'pointer' }}
                onClick={() => {
                  this.props.history.push(
                    `/super-admin/edit-knowledge-base/${item.id}`
                  );
                }}
              />
              <MDBIcon
                icon="eye"
                size="lg"
                style={{ margin: "5px",cursor:'pointer' }}
                onClick={() => {
                  this.toggle(item);
                }}
              />
              <MDBIcon
                icon="trash"
                size="lg"
                style={{ margin: "5px",cursor:'pointer' }}
                onClick={() => {
                  this.openDeleteModal(item);
                }}
              />
            </div>
          ),
        };
        return obj;
      }),
    };
    this.setState({
      tableBody: tableData,
    });
  };

  removeTag = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  };

  clearSearch = () => {
    let search = {
      title: "",
      subTitle: "",
      category: "",
    };
    this.setState({
      title: "",
      subTitle: "",
      category: "",
    });
    Object.keys(search).forEach(
      (key) => search[key] == "" && delete search[key]
    );
    this.props.getKnowledgeBaseAction();
  };

  searchHandler = () => {
    let search = {
      title: this.state.title,
      subTitle: this.state.subTitle,
      category: this.state.category,
    };
    Object.keys(search).forEach(
      (key) => search[key] == "" && delete search[key]
    );
    this.props.getKnowledgeBaseAction(search);
  };

  handleSearch = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  exportPdf = () => {
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0, 200, 0);
      pdf.save("knowledgeBase.pdf");
    });
  };

  getKnowledgeBasesDetails = () => {
    return (
      <div className="card-body">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">Title</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
              {this.state.selectedKnowledgeBase.title}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">Category</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
              {this.state.selectedKnowledgeBase.cat_name}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">SubTitle:</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
              {this.state.selectedKnowledgeBase.subTitle}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">Slug Url:</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
            <a href={this.state.selectedKnowledgeBase.slugUrl} target="_blank">{this.state.selectedKnowledgeBase.slugUrl}</a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">Content :</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group " dangerouslySetInnerHTML={this.createMarkup()}>
              {/* {this.removeTag(this.state.selectedKnowledgeBase.context)} */}
            </div>
          </div>
        </div>
      </div>
    );
  };
  createMarkup() {
    return {__html: this.state.selectedKnowledgeBase.context};
  }
  searchContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Title"
                    name="title"
                    value={this.state.title}
                    onChange={this.handleSearch}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Subtitle"
                    name="subTitle"
                    value={this.state.subTitle}
                    onChange={this.handleSearch}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-2 col-sm-12">
                <select
                  className="form-control show-tick"
                  name="category"
                  value={this.state.category}
                  onChange={this.handleSearch}
                >
                  <option value="">Category</option>
                  {this.state.categories.map((item) => (
                    <option value={item.name}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="btn-container">
              <div>
              <MDBBtn
                      color="primary"
                      className="btn"
                      onClick={this.searchHandler}
                    >
                      Search
                    </MDBBtn>
                    <MDBBtn
                      color="primary"
                      className="btn"
                      onClick={this.clearSearch}
                    >
                      Clear
                    </MDBBtn>
              </div>
              <div>
              <MDBBtn
                            color="primary"
                            className="btn"
                            onClick={() => {
                              this.props.history.push(
                                "/super-admin/create-knowledge-base"
                              );
                            }}
                          >
                            Create
                          </MDBBtn>
                          <MDBBtn
                            color="primary"
                            className="btn"
                            onClick={this.exportPdf}
                          >
                            PDF{" "}
                            <MDBIcon
                              icon="download"
                              size="lg"
                              className="icon"
                            />
                          </MDBBtn>
                          <MDBBtn color="primary" className="btn">
                            {this.state.exportCsv && (
                              <CSVLink {...this.state.exportCsv}>
                                CSV
                                <MDBIcon
                                  icon="download"
                                  size="lg"
                                  className="icon"
                                />
                              </CSVLink>
                            )}
                          </MDBBtn>
              </div>
              </div>
          </div>
        </div>
      </TabPane>
    );
  };

  tableContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body" id="capture">
            <h1 className="page-title">Knowledge Base List</h1>
            {this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={["title"]}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching={false}
                />
              </div>
            )}
          </div>
        </div>
      </TabPane>
    );
  };

  pageContainer = () => {
    const { activeTab } = this.state;
    return (
      <TabContent activeTab={activeTab}>
        {this.searchContainer()}
        {this.tableContainer()}
      </TabContent>
    );
  };

  modalContainer = () => {
    return (
      <div>
        <MDBContainer>
          <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
            <MDBModalHeader toggle={this.toggleClose}>
              Knowledge Base Details
            </MDBModalHeader>
            <MDBModalBody>
              <div className="card">
                {this.state.selectedKnowledgeBase &&
                  this.getKnowledgeBasesDetails()}
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={this.toggleClose}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.deleteModal} toggle={this.toggle}>
            <MDBModalHeader toggle={this.toggleClose}>
              Delete Knowledge Base
            </MDBModalHeader>
            <MDBModalBody>
              <div className="">
                Are you sure to delete this knowledge base?
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={this.deleteKnowledgeBase}>
                Delete
              </MDBBtn>
              <MDBBtn color="secondary" onClick={this.toggleClose}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>
    );
  };

  render() {
    const { activeTab } = this.state;
    if (!isLogined()) this.props.history.push("/");
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">Manage Knowledge Base </h1>
                <ol className="breadcrumb page-breadcrumb">
                  <li className="breadcrumb-item">Search</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.pageContainer()}</div>
          {this.state.modal && this.modalContainer()}
          {this.state.deleteModal && this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  knowledgeBases: state.getKnowledgeBaseReducer,
  knowledgeBaseByIdStatus: state.getKnowledgeBaseByIdReducer,
  deleteStatus: state.deleteKnowledgeBaseReducer,
  categories: state.getCategoryReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getKnowledgeBaseAction: (e) => dispatch(getKnowledgeBaseAction(e)),
  getKnowledgeBaseByIdAction: (e) => dispatch(getKnowledgeBaseByIdAction(e)),
  deleteKnowledgeBaseAction: (e) => dispatch(deleteKnowledgeBaseAction(e)),
  getCategoryAction: (e) => dispatch(getCategoryAction(e)),
  clearGetKnowledgeBaseAction: (e) => dispatch(clearGetKnowledgeBaseAction(e)),
});
export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeBase);
