import { GET_CERTIFICATE, 
    CREATE_CERTIFICATE,
    GET_CERTIFICATE_BY_ID,
    GET_PRINCIPAL_DETAIL,
    UPLOAD_CERTIFICATE_LOGO,
    UPLOAD_CERTIFICATE_BADGE,
    UPLOAD_CERTIFICATE_SIGN } from '../../../../actions/Constants/index'

const initialState = {
    list : [],
    createStatus:null,
    detail:null,
    uploadLogo:null,
    uploadBadge:null,
    uploadSign:null,
    principalDetail:null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_CERTIFICATE:
        return {
            ...state,
            list : action.payload.list,
        }
        case CREATE_CERTIFICATE:
        return {
            ...state,
            createStatus : action.payload.list,
        }
        case GET_CERTIFICATE_BY_ID:
        return {
            ...state,
            detail : action.payload.list,
        }
        case GET_PRINCIPAL_DETAIL:
        return {
            ...state,
            principalDetail : action.payload.list,
        }
        case UPLOAD_CERTIFICATE_LOGO:
        return {
            ...state,
            uploadLogo : action.payload.list,
        }
        case UPLOAD_CERTIFICATE_BADGE:
        return {
            ...state,
            uploadBadge : action.payload.list,
        }
        case UPLOAD_CERTIFICATE_SIGN:
        return {
            ...state,
            uploadSign : action.payload.list,
        }
        default:
            return state
    }
}
