import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { MDBDataTable, MDBBtn, MDBIcon,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { isLogined } from '../../../utils/token'
import './style.scss';
import moment from 'moment'
import { clearGetKnowledgeBaseAction, getKnowledgeBaseAction } from '../../../actions/Admin/ManageKnowledgeBase/manageKnowledgeBaseAction';
import { CSVLink } from "react-csv";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Loader from "../../common/Loader";


class KnowledgeBase extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			show: false,
			knowledgeBases : [],
			tableBody : { columns :[], rows: []},
			title:'',
			subTitle:'',
			exportCsv:null,
			id:this.props.match.params.id,
			pdfSuccess:false,
			selectedKnowledgeBase:{}
		};
	}

	componentWillReceiveProps(nextProps){
		if(this.state.knowledgeBases !== nextProps.knowledgeBases.list){
			this.setState({
                knowledgeBases : nextProps.knowledgeBases.list,
            },()=>{
				this.constructTableData()
				this.exportCsv()
			})
		}
	}

	componentDidMount(){
		this.searchHandler()
	}

	componentWillUnmount(){
		this.props.clearGetKnowledgeBaseAction()
	}
	exportCsv =()=> {
		const exportCsvHeaders = [
			{ label: "Title", key: "title" },
			{ label: "Sub Title", key: "subTitle" },
			{ label: "Category", key: "cat_name" }	
		];
		const exportCSVData = this.state.knowledgeBases.map((item)=>{
			let obj = {
				title:item.title,
				subTitle: item.subTitle,
				cat_name: item.cat_name
			}
			return obj
		})
		let csv = {
			data: exportCSVData,
			headers: exportCsvHeaders,
			filename: 'knowledgebase-report.csv',
			style: {color:'white'}
		};
		this.setState({
			exportCsv: csv
		})
	}
	print=(item)=>{
		this.setState({
			selectedKnowledgeBase:item
		},()=>{
			var w = window.open('','');
			w.document.body.innerHTML = `
			<h2>Knowledge Base Detail</h2>
			<table style="text-align: left;border-collapse: collapse;width: 100%;">
			<tr>
				<td style="padding: 15px;text-align: left;">Title</td>
				<td>:</td>
				<td>${this.state.selectedKnowledgeBase ? this.state.selectedKnowledgeBase.title : ''}</td>
			</tr>
			<tr>
				<td style="padding: 15px;text-align: left;">Category</td>
				<td>:</td>
				<td>${this.state.selectedKnowledgeBase ? this.state.selectedKnowledgeBase.cat_name:''}</td>
			</tr>
			<tr>
				<td style="padding: 15px;text-align: left;">SubTitle</td>
				<td>:</td>
				<td>${this.state.selectedKnowledgeBase ? this.state.selectedKnowledgeBase.subTitle:''}</td>
			</tr>
			<tr>
				<td style="padding: 15px;text-align: left;">Slug Url</td>
				<td>:</td>
				<td>${this.state.selectedKnowledgeBase ? this.state.selectedKnowledgeBase.slugUrl:''}</td>
			</tr>
			<tr>
				<td style="padding: 15px;text-align: left;">Content</td>
				<td>:</td>
				<td>${this.state.selectedKnowledgeBase ? this.removeTag(this.state.selectedKnowledgeBase.context):''}</td>
			</tr>
			</table>`
			w.print();
		})
	}
	constructTableData =()=>{
		let tableData = {
			columns: [
				{
					label: '#',
					field: 'id',
				},
				{
					label: 'Title',
					field: 'title',
					width: 150,
					attributes: {
						'aria-controls': 'DataTable',
						'aria-label': 'Name',
					},
				},
				{
					label: 'Subtitle',
					field: 'subTitle',
					width: 270,
				},
				{
					label: 'Category',
					field: 'cat_name',
					width: 270,
				},
				{
					label: 'Action',
					field: 'action',
					width: 300,
				},
			],
			rows: 
				this.state.knowledgeBases.map((item,i)=>{
					let date = new Date(item.time)
					let obj = {
						id: i+1,
						title: item.title,
						subTitle: item.subTitle,
						cat_name: item.cat_name ? item.cat_name : '-',
						createdDate: moment(date, 'DD/MM/YYYY').format("DD MMM YYYY"),
						expiryDate: item.amount,
						status: item.isActive ? (<div style={{color:'green'}}>Active</div>) : <div style={{color:'red'}}>Inactive</div>,
						action: (<div>
							<MDBIcon  icon="eye" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{
								this.props.history.push(`/institute/manage-knowledgebase/detail/${this.state.id}/${item.id}`)
							}}/>
							<MDBIcon  icon="download" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{this.downloadPdf(item)}}/>
                            <MDBIcon  icon="print" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{this.print(item )}} />
						</div>)

					}
					return obj
				})
		}	
		this.setState({
			tableBody: tableData
		})	
	}

	removeTag =(str)=>{
		if ((str===null) || (str===''))
        	return false;
    	else
        	str = str.toString();
    		return str.replace( /(<([^>]+)>)/ig, '');
	}
	clearSearch=()=>{
		let search = {
			category: this.state.id
		}
		this.setState({
			title:'',
			subTitle:''
		})
		Object.keys(search).forEach((key) => (search[key] == '') && delete search[key]);
		this.props.getKnowledgeBaseAction(search)
	}

	searchHandler =()=>{
		let search = {
			title:this.state.title,
			subTitle: this.state.subTitle,
			category: this.state.id,
		}
		Object.keys(search).forEach((key) => (search[key] == '') && delete search[key]);
		this.props.getKnowledgeBaseAction(search)
	}

	handleSearch =(event)=>{
		this.setState({ [event.target.name]:event.target.value });
	}

	exportPdf = () => {
		html2canvas(document.querySelector("#capture")).then(canvas => {
		   const imgData = canvas.toDataURL('image/png');
		   const pdf = new jsPDF();
		   pdf.addImage(imgData, 'PNG', 0, 0,200,0);
		   pdf.save("knowledgeBase.pdf"); 
	   }); 
	}

	getKnowledgeBasesDetails = ()=>{
		return(
			<div className="card-body" id="receiptDetail">
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Title
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							: 
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedKnowledgeBase.title}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Category
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedKnowledgeBase.cat_name}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							SubTitle:
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedKnowledgeBase.subTitle}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						Slug Url:
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedKnowledgeBase.slugUrl}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Content :
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.removeTag(this.state.selectedKnowledgeBase.context)}
						</div>
					</div>
				</div>
			</div>		
		)
	}
	downloadPdf = (item) => {
		this.setState({
			selectedKnowledgeBase:item,
			pdfSuccess:true
		},()=>{
			html2canvas(document.querySelector("#receiptDetail")).then(canvas => {
				this.setState({pdfSuccess:false})
				const imgData = canvas.toDataURL('image/png');
				const pdf = new jsPDF();
				pdf.addImage(imgData, 'PNG', 0, 0,200,0);
				pdf.save("KnowledgeBase.pdf"); 
			}).catch((e)=>{
				this.setState({pdfSuccess:false})
			}); 
		})
	}

	render() {
		const { activeTab } = this.state
		if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Manage Knowledge Base </h1>
								<ol className="breadcrumb page-breadcrumb">
									<li className="breadcrumb-item">Search</li>
								</ol>
							</div>
							<MDBBtn color="primary" className="btn backBtn" 
							onClick={()=>{this.props.history.push('/institute/manage-knowledgebase')}}>Back</MDBBtn>

						</div>
					</div>
				</div>
				<div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-lg-4 col-md-4 col-sm-6">
												<div className="input-group">
													<input type="text" className="form-control" placeholder="Title" 
													name ="title" value={this.state.title} onChange={this.handleSearch}/>
												</div>
											</div>
											<div className="col-lg-4 col-md-4 col-sm-6">
												<div className="input-group">
													<input type="text" className="form-control" placeholder="Subtitle" 
													name ="subTitle" value={this.state.subTitle} onChange={this.handleSearch}/>
												</div>
											</div>
										</div>
										<div className="btn-container">
													<div>
														<MDBBtn color="primary" className='btn' onClick={this.searchHandler}>Search</MDBBtn> 
														<MDBBtn color="primary" className='btn' onClick={this.clearSearch}>Clear</MDBBtn> 
													</div>
													<div>
																	<MDBBtn color="primary" className='btn' onClick={this.exportPdf}>PDF <MDBIcon  icon="download" size="lg" className='icon' /></MDBBtn>
																	<MDBBtn color="primary" className='btn'>
																		{this.state.exportCsv &&(
																			<CSVLink {...this.state.exportCsv}>CSV<MDBIcon  icon="download" size="lg" className='icon' /></CSVLink>
																		)}
																	</MDBBtn>
													</div>
                                        </div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body"  id="capture">
									<h1 className="page-title">Knowledge Base List</h1>
									{this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={['title']}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching = {false}
                />
              </div>
            )}
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>
				
				</div>

				<div style={{height:'100px'}}>
				
				</div>
				{this.state.pdfSuccess ? this.getKnowledgeBasesDetails() : ``}
			</>
		);
	}
}

const mapStateToProps = state => ({
	knowledgeBases: state.getKnowledgeBaseReducer,
})

const mapDispatchToProps = dispatch => ({
	getKnowledgeBaseAction: (e) => dispatch(getKnowledgeBaseAction(e)),
    clearGetKnowledgeBaseAction: (e) => dispatch(clearGetKnowledgeBaseAction(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeBase)
