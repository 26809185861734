import { GET_FEEDBACK,SHOW_SNACKBAR,SEARCH_FEEDBACK,GET_FEEDBACK_DROPDOWN_DATA, CREATE_FEEDBACK } from '../../Constants/index';
import axios from 'axios';
import { manageFeedback } from '../../../utils/Api'
import { getToken } from '../../../utils/token'

export const getFeedbackAction = (params) => {
    let params2= localStorage.getItem("school") ?{
        ...params,
        school_id:JSON.parse(localStorage.getItem("school")).id
      }
     : params 
	return dispatch => {
        const url =`${manageFeedback.searchFeedback}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params2
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_FEEDBACK,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_FEEDBACK,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearGetFeedbackAction = () => {
	return dispatch => {
        dispatch({
            type: GET_FEEDBACK,
            payload: {
                list : []
            }
        })
    }
}

export const searchFeedbackAction = (params) => {
	return dispatch => {
        const url = `${manageFeedback.searchFeedback}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: SEARCH_FEEDBACK,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: SEARCH_FEEDBACK,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const getFeedbackDropdownAction = () => {
	return dispatch => {
        const url = `${manageFeedback.dropDownData}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_FEEDBACK_DROPDOWN_DATA,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_FEEDBACK_DROPDOWN_DATA,
                    payload: {
                        list : null
                    }
                })
            }
        })
    }
}

export const clearGetFeedbackDropdownAction = () => {
	return dispatch => {
        dispatch({
            type: GET_FEEDBACK_DROPDOWN_DATA,
            payload: {
                list : null
            }
        })
    }
}

export const createFeedbackAction = (data) => {
	return dispatch => {
        const url = `${manageFeedback.createFeedback}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: CREATE_FEEDBACK,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
            }else {
                dispatch({
                    type: CREATE_FEEDBACK,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const clearCreateFeedbackAction = () => {
	return dispatch => {
        dispatch({
            type: CREATE_FEEDBACK,
            payload: {
                list : null
            }
        })
    }
}