import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import Loader from "../../../common/Loader";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import "./style.scss";
import {
  deleteGalleryAction,
  getGalleryAction,
  deletAlleGalleryAction,
} from "../../../../actions/institute/ManageGallery/manageGallery";
import {
  getClassAction,
  getSectionAction,
  getSubjectAction,
} from "../../../../actions/institute/Utils/utilsAction";
import UploadGalleryTeacher from "./UploadGalleryTeacher";

class ManageGalleryTeacher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      tableBody: { columns: [], rows: [] },
      modal: false,
      selectedImage: null,
      deleteModal: false,
      deleteId: {
        ids: []
      },
      openCreateModal: false,
      dropDownDataClass: [],
      dropDownDataSection: [],
      dropDownDataSubject: [],
      search: {},
      view: "list",
      deleteAllModal: false,
    };
  }

  componentDidMount() {
    this.props.getGalleryAction().then((res) => {
      this.constructTableData();
    });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggle = (value) => {
    this.setState({
      modal: true,
      selectedImage: value,
    });
  };

  toggleClose = () => {
    this.setState({
      modal: false,
      selectedImage: null,
      deleteModal: false,
      openCreateModal: false,
      deleteAllModal: false,
    });
  };

  openDeleteModal = (value) => {
    this.setState({
      deleteModal: true,
      deleteId: {...this.state.deleteId,['ids']:[value.id]},
    });
  };

  deleteGallery = async () => {
    await this.props.deleteGalleryAction(this.state.deleteId);
    this.toggleClose();
    await this.props.getGalleryAction().then((res) => {
      this.constructTableData();
    });
  };

  tableColumns = () => [
    {
      label: "#",
      field: "id",
    },
    {
      label: "File Name",
      field: "file",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Name",
      },
    },
    {
      label: "file Type",
      field: "file_type",
      width: 270,
    },
    {
      label: "Event",
      field: "gallery_type",
      width: 270,
    },
    {
      label: "Action",
      field: "action",
      width: 300,
    },
  ];

  constructTableData = () => {
    let tableData = {
      columns: this.tableColumns().filter((ele) => ele),
      rows: this.actionContainer(),
    };

    this.setState({
      tableBody: tableData,
    });
  };

  actionContainer = () => {
    return this.props.galleryList.map((item, i) => {
      let obj = {
        id: i + 1,
        file: item.file_name ? item.file_name : "-",
        file_type: item.file_type ? item.file_type : "-",
        gallery_type: item.gallery_type ? item.gallery_type : "-",
        action: (
          <div
            className="actionContainer"
            style={{ padding: "0px !important" }}
          >
            <MDBIcon
              icon="eye"
              size="lg"
              style={{ margin: "5px",cursor:'pointer' }}
              onClick={() => {
                this.toggle(item);
              }}
            />
            <MDBIcon
              icon="trash"
              size="lg"
              style={{ margin: "5px",cursor:'pointer' }}
              onClick={() => {
                this.openDeleteModal(item);
              }}
            />
          </div>
        ),
      };
      return obj;
    });
  };

  handleSearch = (name, value) => {
    this.setState({ search: { ...this.state.search, [name]: value } });
  };

  searchHandler = async () => {
    let search = {
      file_name: this.state.search.file_name,
      file_type: this.state.search.file_type,
      gallery_type: this.state.search.gallery_type,
    };
    Object.keys(search).forEach(
      (key) => search[key] == "" && delete search[key]
    );
    await this.props.getGalleryAction(search);
    this.constructTableData();
  };

  clearHandler = async () => {
    this.setState({
      search: {},
      dropDownDataClass: [],
    });
    await this.props.getGalleryAction().then((res) => {
      this.constructTableData();
    });
    {
      this.setState({
        search: {
          ...this.state.search,
          ["school_id"]: JSON.parse(localStorage.getItem("school")).id,
        },
      });
    }
  };

  getGallery = () => {
    this.props.getGalleryAction().then((res) => {
      this.constructTableData();
    });
  };

  getCircularDetail = () => {
    return (
      <div className="card-body">
        <div className="row">
          <div className="col-lg-12 col-md-4 col-sm-6">
            {this.state.selectedImage &&
            (this.state.selectedImage.file_type === "Jpeg") ? (
              <img src={this.state.selectedImage.file} alt="imageurl" />
            ) : (
              <i class="fa fa-file-pdf-o fa-5x" aria-hidden="true"></i>
            )}
          </div>
        </div>
      </div>
    );
  };

  deleteAllGallery = async () => {
    await this.props.deletAlleGalleryAction();
    this.toggleClose();
    await this.props.getGalleryAction().then((res) => {
      this.constructTableData();
    });
  };

  deleteAll = () => {
    return (
      <MDBContainer>
        <MDBModal isOpen={this.state.deleteAllModal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggleClose}>
            Delete All Gallery
          </MDBModalHeader>
          <MDBModalBody>
            <div className="">Are you sure to delete all gallery?</div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.deleteAllGallery}>
              Delete
            </MDBBtn>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  };

  forceDownload = (blob, filename) => {
    var a = document.createElement("a");
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  downloadResource = (url, filename) => {
    if (!filename) filename = url.split("\\").pop().split("/").pop();
    fetch(url, {
      headers: new Headers({
        Origin: window.location.origin,
      }),
      mode: "cors",
    })
      .then((response) => response.blob())
      .then((blob) => {
        let blobUrl = window.URL.createObjectURL(blob);
        this.forceDownload(blobUrl, filename);
      })
      .catch((e) => console.error(e));
  };

  searchContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="input-group">
                  <select
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search["gallery_type"]
                    }
                    className="form-control show-tick"
                    onChange={(e) =>
                      this.handleSearch("gallery_type", e.target.value)
                    }
                    required
                  >
                    <option value="">Select Type</option>
                    <option value="Event Name">Event name</option>
                    <option value="School Gallery">School Gallery</option>
                    <option value="Misc Images">Misc</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="input-group">
                  <input
                    type="text"
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search.file_name
                    }
                    onChange={(e) =>
                      this.handleSearch("file_name", e.target.value)
                    }
                    className="form-control"
                    placeholder="File Name"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="input-group">
                  <select
                    className="form-control show-tick"
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search.file_type
                    }
                    onChange={(e) =>
                      this.handleSearch("file_type", e.target.value)
                    }
                  >
                    <option value="">Select File Type</option>
                    <option value="doc">Doc</option>
                    <option value="Jpeg">JPEG</option>
                    <option value="pdf">PDF</option>
                  </select>
                </div>
              </div>
            </div>
            <div className={`row`}>
              <div className="card-body cardBodyContainer">
                <div className={`row btnContainer`}>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <MDBBtn
                      color="primary"
                      className="btn"
                      onClick={this.searchHandler}
                    >
                      Search
                    </MDBBtn>{" "}
                    &nbsp;
                    <MDBBtn
                      color="primary"
                      className="btn"
                      onClick={this.clearHandler}
                    >
                      Clear
                    </MDBBtn>
                  </div>

                  <div className="col-lg-8 col-md-4 col-sm-6 ">
                    <div className="card-body cardBodyContainer">
                      <div className={`row justify-content-end`}>
                        <div className="col-lg-2 col-md-4 col-sm-6 ">
                          <MDBBtn
                            color="primary"
                            className="btn"
                            onClick={() => {
                              this.setState({ openCreateModal: true });
                            }}
                          >
                            Upload
                          </MDBBtn>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 ">
                          <MDBBtn
                            color="primary"
                            className="btn"
                            onClick={() => {
                              this.setState({ deleteAllModal: true });
                            }}
                          >
                            Delete All
                          </MDBBtn>
                        </div>
                        <div className="col-lg-3 col-md-2 col-sm-12">
                          <select
                            className="form-control show-tick"
                            name="view"
                            value={this.state.view}
                            onChange={this.handleChange}
                          >
                            <option value="list" selected>
                              List View
                            </option>
                            <option value="grid">Grid View</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TabPane>
    );
  };

  tableContainer = () => {
    if (this.state.view === "list") {
      return (
        <TabPane tabId={1} className={classnames(["fade show"])}>
          <div className="card">
            <div className="card-body">
            <h1 className="page-title">Gallery List</h1>
            {this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={["title"]}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching={false}
                />
              </div>
            )}
            </div>
          </div>
        </TabPane>
      );
    } else {
      return (
        <section>
          <div class="main-container">
            <ul class="grid-wrapper">
              {this.props.galleryList.map((item) => {
                if (
                  item.file_type === "Jpeg"
                ) {
                  return (
                    <li
                      onClick={() => {
                        this.downloadResource(item.file);
                      }}
                    >
                      <img src={item.file} alt="imageurl" />
                    </li>
                  );
                } else {
                  return (
                    <li
                      onClick={() => {
                        this.downloadResource(item.file);
                      }}
                    >
                      <i class="fa fa-file-pdf-o fa-5x" aria-hidden="true"></i>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </section>
      );
    }
  };

  pageContainer = () => {
    const { activeTab } = this.state;
    return (
      <TabContent activeTab={activeTab}>
        {this.searchContainer()}
        {this.tableContainer()}
      </TabContent>
    );
  };

  modalContainer = () => {
    return (
      <div>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggleClose}></MDBModalHeader>
          <MDBModalBody>
            <div className="">{this.getCircularDetail()}</div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              Close
            </MDBBtn>
            <MDBBtn
              color="secondary"
              onClick={() => {
                this.downloadResource(
                  this.state.selectedImage ? this.state.selectedImage.file : ""
                );
              }}
            >
              Download
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal isOpen={this.state.deleteModal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggleClose}>
            Delete Gallery
          </MDBModalHeader>
          <MDBModalBody>
            <div className="">Are you sure to delete this gallery?</div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.deleteGallery}>
              Delete
            </MDBBtn>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">Manage Gallery</h1>
                <ol className="breadcrumb page-breadcrumb">
                  <li className="breadcrumb-item">Search</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.pageContainer()}</div>
          {this.state.modal && this.modalContainer()}
          {this.state.deleteModal && this.modalContainer()}
          {this.state.openCreateModal && (
            <UploadGalleryTeacher
              toggleClose={this.toggleClose}
              getGallery={this.getGallery}
            />
          )}
        </div>
        {this.state.deleteAllModal && this.deleteAll()}

      </>
    );
  }
}

const mapStateToProps = (state) => ({
  galleryList: state.getGalleryReducer.list,
});

const mapDispatchToProps = (dispatch) => ({
  getGalleryAction: (e) => dispatch(getGalleryAction(e)),
  deleteGalleryAction: (e) => dispatch(deleteGalleryAction(e)),
  deletAlleGalleryAction: (e) => dispatch(deletAlleGalleryAction(e)),
  getSubjectAction: (e) => dispatch(getSubjectAction(e)),
  getSectionAction: (e) => dispatch(getSectionAction(e)),
  getClassAction: (e) => dispatch(getClassAction(e)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageGalleryTeacher);
