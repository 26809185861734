import { GET_ACEDEMICS, 
    CREATE_ACEDEMICS,
    EDIT_ACEDEMICS, 
    GET_ACEDEMICS_BY_ID, 
    SEARCH_ACEDEMICS, UPLOAD_ACEDEMICS,GET_TEACHER} from '../../../actions/Constants/index'

const initialState = {
    list : [],
    teacherList : [],
    editStatus:null,
    createStatus:null,
    detail:null,
    searchedList:[],
    uploadStatus:null,
    uploadFileStatus: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_ACEDEMICS:
        return {
            ...state,
            list : action.payload.list,
        }
        case GET_TEACHER:
        return {
            ...state,
            teacherList : action.payload.list,
        }
        case EDIT_ACEDEMICS:
        return {
            ...state,
            editStatus : action.payload.list,
        }
        case CREATE_ACEDEMICS:
        return {
            ...state,
            createStatus : action.payload.list,
        }
        case GET_ACEDEMICS_BY_ID:
        return {
            ...state,
            detail : action.payload.list,
        }
        case SEARCH_ACEDEMICS:
        return {
            ...state,
            searchedList : action.payload.list,
        }
        case UPLOAD_ACEDEMICS:
        return {
            ...state,
            uploadFileStatus : action.payload.status,
        }
        default:
            return state
    }
}
