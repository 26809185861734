import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { MDBBtn, MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
// import { isLogined } from '../../../utils/token'
// import './style.scss';
import Button from 'reactstrap/lib/Button';
import { clearGetProfileAction, clearUpdatePasswordAction, clearUpdateProfileAction, getProfileAction, updateProfileAction } from '../../actions/Admin/ManageInstitutes/manageInstitutesAction';
import { userChangePasswordAction } from '../../actions/institute/Authentication/userAuthenticationAction';

class MyProfile extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			passwordInput : {
                old_password:'',
				password:'',
				confirm_password:''
			},
			profileInfo:{
				name:'',
				contact_no:'',
			},
			userId:'',
			openModal:false,
			openPasswordModal: false,
			changePasswordFlag: null,
			updateStatus: null,
			role:this.props.role,

		};
	}

	componentWillReceiveProps(nextProps){
		if(this.state.profileInfo !== nextProps.profileInfo.list){
			this.setState({
				profileInfo: nextProps.profileInfo.list
			})
		}
		if(this.state.updateStatus !== nextProps.updateStatus.status){
			this.setState({
				updateStatus : nextProps.updateStatus.status
			})
		}
	}

	componentDidMount(){
		this.props.getProfileAction()
	}
	componentWillUnmount(){
		this.props.clearGetProfileAction()
		this.props.clearUpdateProfileAction()
		this.props.clearUpdatePasswordAction()
	}
	ProfileChangeHandler = event => {
        let profileInfo = this.state.profileInfo
        profileInfo[event.target.name] = event.target.value
		this.setState({ profileInfo:profileInfo });
	};

	changePasswordHandler = event => {
        let passwordInput = this.state.passwordInput
        passwordInput[event.target.name] = event.target.value
		this.setState({ passwordInput:passwordInput });
	};

	toggleClose = ()=>{
		this.setState({
			openModal: false,
		})
	}


	passwordToggleClose = ()=>{
		this.setState({
			openPasswordModal: false,
		})
	}

	infoSubmitHandler = () => {
		let data ={
			"name": this.state.profileInfo.name,
			"contact_no": this.state.profileInfo.contact_no,
		}

		this.props.updateProfileAction({data:data})
		this.toggleClose()
	}

	handleProfileInfoAlert = (event)=>{
		event.preventDefault();
		event.target.className += " was-validated";
		if(event.target.checkValidity()) {
			this.setState({
				openModal : true
			})
		}	
	}

	handleProfileLogoAlert = (event)=>{
		event.preventDefault();
		this.setState({
			openLogoModal : true
		})
	}

	handleProfilePasswordAlert = (event) =>{
		event.preventDefault();
		event.target.className += " was-validated";
		if(event.target.checkValidity()) {
			this.setState({
				openPasswordModal : true
			})
		}
	}


	changePasswordSubmitHandler = () => {
        let data ={
			"password": this.state.passwordInput.password,
			"confirm_password": this.state.passwordInput.confirm_password,
		}
		const status = this.props.userChangePasswordAction(data)
		this.setState({
			changePasswordFlag: status
		},()=>{
			this.passwordToggleClose()
		})
	}

	openDialog = ()=> {
		document.getElementById('fileid').click();
	}

	openLoginDialog = ()=> {
		document.getElementById('loginImage').click();
	}

	tableContainer =()=>{
		return(
			<TabPane tabId={1} className={classnames(['fade show'])}>
				<div className="card" >
					<div className="card-body"  id="capture" >
					<h1 className="page-title">Profile Information</h1>
						<div className="table-responsive">
						<form  onSubmit={this.handleProfileInfoAlert} noValidate>
                            <div className="card-body">
                                <div className="row">
									<div className="col-lg-3 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											Name
										</div>
									</div>
									<div className="col-lg-1 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											:
										</div>
									</div>
									<div className="col-lg-7 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											<input onChange={this.ProfileChangeHandler} value={this.state.profileInfo['name']} name= "name"  type="text" className="form-control" placeholder="Name" required />
											<div className="invalid-feedback">
												required name.
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-3 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											Mobile Number
										</div>
									</div>
									<div className="col-lg-1 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											:
										</div>
									</div>
									<div className="col-lg-7 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											<input onChange={this.ProfileChangeHandler} value={this.state.profileInfo['contact_no']} name= "contact_no"  type="text" className="form-control" placeholder="Mobile Support" required />
											<div className="invalid-feedback">
												required mobile number.
											</div>
										</div>
									</div>
								</div>
								<div className="row justify-content-end">
									<div className="col-lg-3 col-md-4 col-sm-6 ">
										<Button type ="submit" >Update</Button>
									</div>
								</div>
								{this.state.updateStatus &&(
									<div className='imageFlag'>
										 Profile information changed successfully
									 </div>
									)}
							</div>
							</form>
						</div>
					</div>
				</div>
			</TabPane>
		)
	}

	changePasswordContainer = ()=>{
		return(
			<TabPane tabId={1} className={classnames(['fade show'])}>
				<div className="card" >
					<div className="card-body"  id="capture" >
					<h1 className="page-title">Change Password</h1>
						<div className="table-responsive">
						<form  onSubmit={this.handleProfilePasswordAlert} noValidate>
                            <div className="card-body">
								<div className="row">
									<div className="col-lg-3 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											New Password
										</div>
									</div>
									<div className="col-lg-1 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											:
										</div>
									</div>
									<div className="col-lg-7 col-md-4 col-sm-6 inputField">
										<div className="input-group">
											<input value={this.state.passwordInput['password']} onChange={this.changePasswordHandler} name= "password"  type="password"  className="form-control" placeholder="New Password" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$"
											required  />
											<div className="invalid-feedback">
												Min 8 char,Atleast one numaric, special char, alphabet.
											</div>
										</div>
									</div>
								</div>
									<div className="row">
										<div className="col-lg-3 col-md-4 col-sm-6 inputField">
											<div className="input-group">
												Confirm Password
											</div>
										</div>
										<div className="col-lg-1 col-md-4 col-sm-6 inputField">
											<div className="input-group">
												:
											</div>
										</div>
										<div className="col-lg-7 col-md-4 col-sm-6 inputField">
											<div className="input-group">
												<input  value={this.state.passwordInput['confirm_password']} onChange={this.changePasswordHandler} name= "confirm_password"  type="password"  className="form-control" placeholder="Confirm Password" 	pattern={this.state.passwordInput['password']} required />
												<div className="invalid-feedback">
												Password are not matching.
											</div>
										</div>
										</div>
									</div>
									<div className="row justify-content-end">
										<div className="col-lg-6 col-md-4 col-sm-6 ">
											<Button >Change Password</Button>
										</div>
										
									</div>
									{this.state.changePasswordFlag &&(
										<div className='imageFlag'>
											password changed successfully
										</div>
										)}
										{this.state.changePasswordFlag === false &&(
											<div >
											password changed Failed
										</div>
									)}
								</div>
							</form>
						</div>
					</div>
				</div>
			</TabPane>
		)
	}

	modalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={this.state.openModal} toggle={this.toggleClose}>
					<MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
					<MDBModalBody>
						<h5>Are you sure you want to update profile information?</h5>
					</MDBModalBody>
					<MDBModalFooter>
					<MDBBtn color="secondary" onClick={this.infoSubmitHandler}>Confirm</MDBBtn>
						<MDBBtn color="secondary" onClick={this.toggleClose}>Close</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
				<MDBModal isOpen={this.state.openPasswordModal} toggle={this.passwordToggleClose}>
					<MDBModalHeader toggle={this.passwordToggleClose}>Alert</MDBModalHeader>
					<MDBModalBody>
						<h5>Are you sure you want to change password?</h5>
					</MDBModalBody>
					<MDBModalFooter>
					<MDBBtn color="secondary" onClick={this.changePasswordSubmitHandler}>Confirm</MDBBtn>					
						<MDBBtn color="secondary" onClick={this.passwordToggleClose}>Close</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
			</MDBContainer>
		)
	}

	pageContainer =()=> {
		const { activeTab } = this.state
		return(
			<TabContent activeTab={activeTab}>
				<div className="card" >
					<div className="card-body">
						<div className="row">
							<div className="col-lg-6 col-md-4 col-sm-6 inputField">
								{this.tableContainer()}
							</div>
							<div className="col-lg-6 col-md-4 col-sm-6 inputField">
								{this.changePasswordContainer()}
							</div>
						</div>
					</div>
				</div>
			</TabContent>
		)
	}

	render() {
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Manage Profile</h1>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-4">
					<div className="container-fluid">
						{this.pageContainer()}
					</div>
					{this.modalContainer()}
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	updateStatus: state.editProfileReducer,
	profileInfo: state.getProfileReducer,
	profilePassword: state.updatePasswordReducer,
	role: state.settings.role,
})

const mapDispatchToProps = dispatch => ({
	getProfileAction: (e) => dispatch(getProfileAction(e)),
	userChangePasswordAction: (e) => dispatch(userChangePasswordAction(e)),
	updateProfileAction: (e) => dispatch(updateProfileAction(e)),
	clearGetProfileAction: (e) => dispatch(clearGetProfileAction(e)),
	clearUpdatePasswordAction: (e) => dispatch(clearUpdatePasswordAction),
	clearUpdateProfileAction: (e) => dispatch(clearUpdateProfileAction(e)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile)
