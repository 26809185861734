import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { isLogined } from '../../../utils/token'
import './style.scss';
import Ckeditor from '../../common/ckeditor';
import { connect } from 'react-redux';
import { clearCreateKnowledgeBaseAction, clearEditKnowledgeBaseAction, clearKnowledgeBaseByIdAction, createCategoryAction, createKnowledgeBaseAction, editKnowledgeBaseAction, getCategoryAction, getKnowledgeBaseByIdAction } from '../../../actions/Admin/ManageKnowledgeBase/manageKnowledgeBaseAction';
import { MDBBtn, MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

class CreateKnowledgeBase extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
            category:'',
            input:{
                title: '',
                category: '',
                subTitle: '',
                slugUrl: '',
                context:''
            },
            categories:[],
            contextError:false,
            editId: this.props.match.params.id,
            modal: false,
            alertModal:false,
            categoryError:false,
            createCategoryStatus:null
		};
	}

    componentDidMount(){
        this.props.getCategoryAction()
        if(this.props.match.params.id){
            this.props.getKnowledgeBaseByIdAction(this.props.match.params.id)
        }
	}

    componentWillUnmount(){
        this.props.clearKnowledgeBaseByIdAction()
        this.props.clearCreateKnowledgeBaseAction()
        this.props.clearEditKnowledgeBaseAction()
    }

    componentWillReceiveProps(nextProps){
        if(this.state.createCategoryStatus !== nextProps.createCategoryStatus.status){
            this.setState({
                createCategoryStatus: nextProps.createCategoryStatus.status
            },()=>{
                if(this.state.createCategoryStatus.errcode === 0){
                    this.props.getCategoryAction()
                    this.setState({
                        modal:false,
                        category:''
                    })
                }
            })
        }
        if(this.state.input !== nextProps.knowledgeBaseByIdStatus.list){
            this.setState({
                input : nextProps.knowledgeBaseByIdStatus.list
            })
        }
        if(this.state.categories !== nextProps.categories.list){
            this.setState({
                categories : nextProps.categories.list
            })
        }
	}

    alertModalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={ this.state.alertModal } toggle={this.toggle}>
                    <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
                    <MDBModalBody>
                        <div className='alertContainer'>
                            <h4> Do you want to {this.state.editId ? 'Update' : 'save'} it ? </h4>
                            <h6> Please check the information before submitting</h6>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggleClose}>No</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.handleSave}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
			</MDBContainer>
		)
	}
    
    clearData =()=>{
        this.props.clearCreateKnowledgeBaseAction()
        this.props.clearEditKnowledgeBaseAction()
        this.props.clearKnowledgeBaseByIdAction()
        this.props.history.push('/super-admin/knowledge-base')
    }

    saveCategory = e =>{
        e.preventDefault();
		e.target.className += " was-validated";
        if(!this.state.category){
            this.setState({
                categoryError : true
            })
        }
        else{
            this.props.createCategoryAction({
                name:this.state.category
            })
        }
    }

    handleSave= event =>{
        if(this.state.editId){
            this.props.editKnowledgeBaseAction({id : this.state.editId,
                data:this.state.input,history:this.props.history})
        }else{
            this.props.createKnowledgeBaseAction(this.state.input,this.props.history)
        }
    }

    submitHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
        if(!this.state.input.context){
            this.setState({
                contextError : true
            })
        }
		if(event.target.checkValidity()) {
            this.setState({
                alertModal: true,
            })
		}
	}

    onContentChange =(value)=>{
        let input = this.state.input
        input.context = value
		this.setState({ input:input });
    }

    changeHandler = event => {
        let input = this.state.input
        input[event.target.name] = event.target.value
		this.setState({ input:input });
	};

    openCategoryModal = e =>{
        this.setState({
            modal:true
        })
    }

    toggleClose = ()=>{
		this.setState({
			modal: false,
			alertModal: false
		})
	}

    modalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={ this.state.modal } toggle={this.toggle}>
                    <MDBModalHeader toggle={this.toggleClose}>Add Category</MDBModalHeader>
                    <MDBModalBody>
                        <div className='alertContainer'>
                            <form  noValidate>
                                <div className="row">
                                    <div className="col-lg-5 col-md-4 col-sm-6">
                                        <div className="input-group">
                                            Category name
                                        </div>
                                    </div>
                                    <div className="col-lg-1 col-md-4 col-sm-6">
                                        <div className="input-group">
                                            :
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-4 col-sm-6">
                                        <div className="input-group">
                                            <input name= "category" value = {this.state.category} 
                                            onChange={(e)=> {this.setState({category:e.target.value})}} 
                                            type="text" className="form-control" placeholder="Category name" required />
                                        </div>
                                        <div className="invalid-feedback-category">
                                            { this.categoryError ? 'required category name.' : '' } 
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.saveCategory}>Save</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.toggleClose}>Close</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
			</MDBContainer>
		)
	}
    inputFieldContainer = () => {
        const { activeTab } = this.state;
        return (
          <div className="container-fluid">
          <TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
                                <form  onSubmit={this.submitHandler} noValidate>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-4 col-sm-6">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    Category
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    <select className="form-control show-tick" value = {this.state.input['category']} name="category" onChange={this.changeHandler} placeholder="Category" required>
                                                                        <option value="">Select Category</option>
                                                                        {this.state.categories.map(item => <option value={item.id}>{item.name}</option>)}
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required category.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6">
                                                <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-4 col-sm-6">
                                                                <div className="input-group addCategoryLabel" onClick={ this.openCategoryModal }>
                                                                    + Add Category
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6">
                                                <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    Doc Title
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value = {this.state.input['title']} name="title" onChange={this.changeHandler} placeholder="Doc Title" required/>
                                                                    <div className="invalid-feedback">
                                                                        required doc title.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6">
                                                <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    Subtitle
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value = {this.state.input['subTitle']} name="subTitle" onChange={this.changeHandler} placeholder="Sub Title" required/>
                                                                    <div className="invalid-feedback">
                                                                        required sub title.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 col-sm-6">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-2 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                Slug Url
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-1 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-9 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    <input type="url" className="form-control" value = {this.state.input['slugUrl']} name="slugUrl" onChange={this.changeHandler} placeholder="Slug Url" required/>
                                                                    <div className="invalid-feedback">
                                                                        required slug Url.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 col-sm-6">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-2 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    Add Content
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-1 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-9 col-md-4 col-sm-6">
                                                                <div className="input-group">
                                                                    <Ckeditor onChange={this.onContentChange} html={this.state.input.context}/>
                                                                    {! this.state.editId && this.state.contextError && (
                                                                        <div className='expiryDateValidation'>
                                                                            required content.
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 col-sm-6 submitBtn">
                                                    <MDBBtn color="primary" className='btn' onClick= {()=>{
                                                        this.props.history.push('/super-admin/knowledge-base')
                                                    }}>Cancel</MDBBtn>
                                                    <MDBBtn color="primary" type={'submit'} className='btn'>{this.state.editId ? 'Update' : 'Save'}</MDBBtn>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </TabPane>
                        </TabContent>
          </div>
        );
      };
    render(){
        if(!isLogined()) this.props.history.push('/')
        return(
            <>
            <div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">{this.state.editId ? 'Update Knowledge Base' : 'Add New Knowledge Base'}</h1>
							</div>
						</div>
					</div>
				</div>
                <div className="section-body mt-4">
          <div className="container-fluid">{this.inputFieldContainer()}</div>
          {this.state.modal && this.modalContainer()}
          {this.state.alertModal &&this.alertModalContainer()}
        </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    knowledgeBaseByIdStatus: state.getKnowledgeBaseByIdReducer,
    editStatus: state.editKnowledgeBaseReducer,
    categories: state.getCategoryReducer,
    createCategoryStatus: state.createCategoryReducer,
})

const mapDispatchToProps = dispatch => ({
	createKnowledgeBaseAction: (e, history) => dispatch(createKnowledgeBaseAction (e, history)),
    getKnowledgeBaseByIdAction: (e) => dispatch(getKnowledgeBaseByIdAction (e)),
    clearKnowledgeBaseByIdAction: (e) => dispatch(clearKnowledgeBaseByIdAction (e)),
    editKnowledgeBaseAction: (e, history) => dispatch(editKnowledgeBaseAction (e, history)),
    clearEditKnowledgeBaseAction: (e) => dispatch(clearEditKnowledgeBaseAction(e)),
    clearCreateKnowledgeBaseAction: (e) => dispatch(clearCreateKnowledgeBaseAction (e)),
    createCategoryAction: (e) => dispatch(createCategoryAction (e)),
    getCategoryAction: (e) => dispatch(getCategoryAction (e)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateKnowledgeBase)
