import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import './style.scss';
import { connect } from 'react-redux';
import { MDBBtn, MDBFormInline, MDBInput} from 'mdbreact';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { getAssignmenttAction, getClassAction,getExamtAction,getSectionAction, getStudentAction, getSubjectAction, getSubjectForExamAction } from '../../../../actions/institute/Utils/utilsAction';
import { clearCreateGoalAction, clearEditGoalAction, clearSkillByIdAction, createGoalAction, editGoalAction, getGoalByIdAction } from '../../../../actions/institute/Teacherdashboard/ManageGoal/ManageGoalAction';
import moment from 'moment';

class AddGoal extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,

            class_id:'',
            section_id:'',
            subject_id:'',
            student_id:'',
            goal_type:'',
            score_rule:'',
            ExamsOrAssignment:[],
            status:'',

            editId: this.props.match.params.id,
            modal: false,

            classList:[],
            sectionList:[],
            subjectList:[],
            studentList:[],

            assignmentList:[],
            examList:[],

            school_id:'',
            selectAll:false,
			selectAllStudent:false,
            showError:false

		};
	}

    componentDidMount(){
        if(localStorage.getItem('school')){
            let school = JSON.parse(localStorage.getItem('school'))
            this.setState({ school_id:school.id },()=>{
                if(this.state.school_id){
                    let search = {
                        school_id: this.state.school_id
                    }
                    this.props.getClassAction(search)
                }
            });
           
        }
        if(this.state.editId){
            this.props.getGoalByIdAction(this.state.editId)
        }
	}


    componentWillReceiveProps(nextProps){

        if(this.state.subjectList !== nextProps.utills.subjectForExam){
            this.setState({
                subjectList: nextProps.utills.subjectForExam
            })
        }
        if(this.state.sectionList !== nextProps.utills.sectionList){
            this.setState({
                sectionList: nextProps.utills.sectionList
            })
        }
		if(this.state.classList !== nextProps.utills.classList){
			this.setState({
				classList: nextProps.utills.classList
			})
		}
        if(this.props.utills.examList !== nextProps.utills.examList){
            this.setState({
                examList: nextProps.utills.examList
            },()=>{
                this.state.examList.map(o => o.is_selected = false)

                let list = JSON.parse(JSON.stringify(this.state.examList))
                list.map((item,i)=>{
                    if(this.state.ExamsOrAssignment.includes(item.id)){
                        item.is_selected = true
                    }
                })
                this.setState({examList:list})

            })
        }
		if(this.props.utills.assignmentList !== nextProps.utills.assignmentList){
			this.setState({
				assignmentList: nextProps.utills.assignmentList
			},()=>{
                this.state.assignmentList.map(o => o.is_selected = false)


                let list = JSON.parse(JSON.stringify(this.state.assignmentList))
                list.map((item,i)=>{
                    if(this.state.ExamsOrAssignment.includes(item.id)){
                        item.is_selected = true
                    }
                })
                this.setState({assignmentList:list})
            })
		}
        if(this.props.goalState.detail !== nextProps.goalState.detail){
            let data = nextProps.goalState.detail
            if(data.student_id.length === 1){
                this.setState({student_id :data.student_id[0]})
            }
            else{
                this.setState({selectAllStudent : true})
            }
            this.setState({
                class_id : data.class_id,
                section_id : data.section_id,
                subject_id : data.subject_id,
                goal_type : data.goal_type[0],
                score_rule : data.score_rule[0],
                ExamsOrAssignment: data.ExamsOrAssignment.split(',').map(item => Number(item)),
                students: data.students.split(',').map(item => Number(item)),
            })
        }
	}
    componentDidUpdate(prevProp,prevState){
        if(this.state.class_id !== prevState.class_id) {
            if(this.state.class_id){
				let search = {
					class_id: this.state.class_id,
				}
				this.props.getSectionAction(search)
			}
		}
		if(this.state.section_id !== prevState.section_id) {
            if(this.state.class_id){
				let search = {
					section_id: this.state.section_id,
				}
				this.props.getSubjectForExamAction()
                this.props.getStudentAction(search).then((res) => this.setState({ studentList: res }));
			}
		}
        if(this.state.goal_type !== prevState.goal_type) {
            if(this.state.subject_id){
				let search = {
					subject_id: this.state.subject_id,
				}
                if(this.state.goal_type === 'Examination'){
                    this.props.getExamtAction(search)
                }
                if(this.state.goal_type === 'Assignment'){
                    this.props.getAssignmenttAction(search)
                }
			}
		}
	}
    clearData =()=>{
        this.props.clearCreateGoalAction()
        this.props.clearEditGoalAction()
        this.props.clearSkillByIdAction()
        this.toggleClose()
        this.props.history.push('/teacher/manage-goal')
    }

    modalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={ this.state.modal }>
                    <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
                    <MDBModalBody>
                        <div className='alertContainer'>
                            <h4> Do you want to {this.state.editId ? 'Update' : 'save'} it ? </h4>
                            <h6> Please check the information before submitting</h6>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggleClose}>No</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.handleSave}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
			</MDBContainer>
		)
	}
    handleSave=()=>{
        let ids = []
        if(this.state.goal_type === 'Examination'){
            this.state.examList.map((item,i)=>{
                if(item.is_selected){
                    ids.push(item.id)
                }
            })
            if(this.state.selectAll){
                this.state.examList.map((item,i)=>{
                    ids.push(item.id)
                })
            }
        }
        if(this.state.goal_type === 'Assignment'){
            this.state.assignmentList.map((item,i)=>{
                if(item.is_selected){
                    ids.push(item.id)
                }
            })
            if(this.state.selectAll){
                this.state.assignmentList.map((item,i)=>{
                    ids.push(item.id)
                })
            }
        }
        
        let students = []
        if(this.state.selectAllStudent){
            this.state.studentList.map(i => students.push(i.id))
        }
        else{
            students = [this.state.student_id]
        }
        let data = {
            class_id:this.state.class_id,
            section_id:this.state.section_id,
            subject_id:this.state.subject_id,
            students:[...new Set(students)],
            goal_type:this.state.goal_type,
            score_rule:this.state.score_rule,
            ExamsOrAssignment:[...new Set(ids)],
            status:1
        }
        if(data.ExamsOrAssignment.length === 0){
            this.setState({showError:true,modal:false})
        }
        else{
            if(this.state.editId){
                this.props.editGoalAction({id : this.state.editId,
                    data:data, history: this.props.history})
            }else{
                this.props.createGoalAction(data, this.props.history)
            }
        }
        
    }
    submitHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
		if(event.target.checkValidity()) {
            this.setState({
                modal: true,
            })
		}
	}
    changeHandler = event => {
        if(event.target.name === 'selectAll'){
		    this.setState({ selectAll:event.target.checked });
        }
        else if(event.target.name === 'selectAllStudent' ){
		    this.setState({ selectAllStudent:event.target.checked },()=>{
            });
        }
        else{
            this.setState({ [event.target.name]:event.target.value });
        }
        
	};
    handleChkBox = (event,i,name) => {
        if(name === 'exam'){
            let list = JSON.parse(JSON.stringify(this.state.examList))
            list[i].is_selected = event.target.checked
            this.setState({ examList:list });
        }
        else{
            let list = JSON.parse(JSON.stringify(this.state.assignmentList))
            list[i].is_selected = event.target.checked
            this.setState({ assignmentList:list });
        }
	}
    toggleClose = ()=>{
		this.setState({
			modal: false,
            showError:false
		})
	}
    render(){
        const { activeTab } = this.state
        // if(!isLogined()) this.props.history.push('/')
        return(
            <>
            {this.modalContainer()}
            <div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">{this.state.editId ? 'Update New Goal' : 'Add New Goal'} </h1>
							</div>
						</div>
					</div>
				</div>
                <div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
                                <form  onSubmit={this.submitHandler} noValidate>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Class:
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select name="class_id" value={this.state.class_id} 
                                                                    className="form-control show-tick" onChange={this.changeHandler} required
                                                                    >
                                                                        <option value="">Select Class</option>
                                                                        {this.state.classList && this.state.classList.map((item)=>{
                                                                            return <option value={item.id}>{item.name}</option>
                                                                        })}
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required class.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Section : 
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select name="section_id" value={this.state.section_id} 
                                                                    className="form-control show-tick" onChange={this.changeHandler} required
                                                                    disabled={this.state.class_id ? false : true}>
                                                                        <option value="">Select Section</option>
                                                                        {this.state.sectionList && this.state.sectionList.map((item)=>{
                                                                            return <option value={item.id}>{item.name}</option>
                                                                        })}
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required seaction.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Subject : 
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select name="subject_id" value={this.state.subject_id} 
                                                                    className="form-control show-tick" onChange={this.changeHandler} required
                                                                    disabled={this.state.class_id && this.state.section_id ? false : true}>
                                                                        <option value="">Select Subject</option>
                                                                        {this.state.subjectList && this.state.subjectList.map((item)=>{
                                                                            return <option value={item.id}>{item.name}</option>
                                                                        })}
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required subject.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Year :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    {moment().format('YYYY')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select all students of the section : 
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    
                                                                    <MDBInput
                                                                        value = {this.state.selectAllStudent}
                                                                        name ={'selectAllStudent'}
                                                                        onChange =  {this.changeHandler}
                                                                        type='checkbox'
                                                                        id='selectAllStudent'
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    !this.state.selectAllStudent ? 
                                                    <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Student : 
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select name="student_id" value={this.state.student_id} 
                                                                    className="form-control show-tick" onChange={this.changeHandler} required
                                                                    disabled={this.state.class_id && this.state.section_id ? false : true}
                                                                    >
                                                                        <option value="">Select Student</option>
                                                                        {this.state.studentList && this.state.studentList.map((item)=>{
                                                                            return <option value={item.id}>{item.name}</option>
                                                                        })}
                                                                    </select>
                                                                    
                                                                    <div className="invalid-feedback">
                                                                        required student.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 
                                                : ''
                                                }
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Goal Type : 
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select name="goal_type" value={this.state.goal_type} 
                                                                    className="form-control show-tick" onChange={this.changeHandler} required
                                                                    >
                                                                        <option value="">Select</option>
                                                                        <option value="Examination">Examination</option>
                                                                        <option value="Assignment">Assignment</option>
                                                                       
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required goal type.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.goal_type === 'Examination' ? 
                                                    <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                    <div className="input-group">
                                                                        Select Exams
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                    <div className="input-group">
                                                                        :
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                    <div className="input-group">
                                                                        <MDBFormInline>
                                                                            <MDBInput
                                                                                value = {this.state.selectAll}
                                                                                name ={'selectAll'}
                                                                                onChange =  {this.changeHandler}
                                                                                label='Any Exam'
                                                                                type='checkbox'
                                                                                id='anyExam'
                                                                                containerClass='mr-5'
                                                                            />
                                                                            {!this.state.selectAll ? 
                                                                                this.state.examList.map((item,i)=>{
                                                                                return <MDBInput
                                                                                    value = {this.state.examList[i].is_selected}
                                                                                    name ={'examIds'+i}
                                                                                    onChange =  {(e)=>{ this.handleChkBox(e,i,'exam')} }
                                                                                    label={item.name}
                                                                                    checked={this.state.examList[i].is_selected}
                                                                                    type='checkbox'
                                                                                    id={'examIds'+i}
                                                                                    containerClass='mr-5'
                                                                                />
                                                                                }) : ''
                                                                            }
                                                                        </MDBFormInline>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : 
                                                    <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Assignment
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <MDBFormInline>
                                                                            <MDBInput
                                                                                value = {this.state.selectAll}
                                                                                name ={'selectAll'}
                                                                                onChange =  {this.changeHandler}
                                                                                label='Any Assignment'
                                                                                type='checkbox'
                                                                                id='anyAssignment'
                                                                                containerClass='mr-5'
                                                                            />
                                                                        {!this.state.selectAll ?
                                                                            this.state.assignmentList.map((item,i)=>{
                                                                            return <MDBInput
                                                                                value = {this.state.assignmentList[i].is_selected}
                                                                                name ={'assignmentIds'+i}
                                                                                checked={this.state.assignmentList[i].is_selected}
                                                                                onChange =  {(e)=>{ this.handleChkBox(e,i,'assignment')} }
                                                                                label={item.name}
                                                                                type='checkbox'
                                                                                id={'assignmentIds'+i}
                                                                                containerClass='mr-5'
                                                                                
                                                                            />
                                                                            }) : ''
                                                                        }
                                                                    </MDBFormInline>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Score Rule
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select name="score_rule" value={this.state.score_rule} 
                                                                    className="form-control show-tick" onChange={this.changeHandler} required
                                                                    >
                                                                        <option value="">Select</option>
                                                                        <option value="95%">95% Above</option>
                                                                        <option value="90%">90% Above</option>
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required score rule.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-4 col-sm-6 inputField submitBtn">
                                                <MDBBtn color="primary" className='btn' onClick= {()=>{
                                                    this.props.history.push('/teacher/manage-goal')
                                                }}>Cancel</MDBBtn>
                                                <MDBBtn color="primary" type={'submit'} className='btn'>{this.state.editId ? 'Update' : 'Save'}</MDBBtn>
                                            </div>
                                        </div>
                                    </div>
                            
                                </form>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
	utills: state.utillReducer,
	goalState: state.ManageGoalReducer,

})

const mapDispatchToProps = dispatch => ({
    getClassAction: (e) => dispatch(getClassAction(e)),
	getSectionAction: (e) => dispatch(getSectionAction(e)),
	// getSubjectAction: (e) => dispatch(getSubjectAction(e)),
	getStudentAction: (e) => dispatch(getStudentAction(e)),
	getSubjectForExamAction: (e) => dispatch(getSubjectForExamAction(e)),

    getAssignmenttAction: (e) => dispatch(getAssignmenttAction(e)),
	getExamtAction: (e) => dispatch(getExamtAction(e)),

	createGoalAction: (e, history) => dispatch(createGoalAction(e, history)),
	clearCreateGoalAction: (e) => dispatch(clearCreateGoalAction(e)),
	clearEditGoalAction: (e) => dispatch(clearEditGoalAction(e)),
	editGoalAction: (e, history) => dispatch(editGoalAction(e, history)),
	getGoalByIdAction: (e) => dispatch(getGoalByIdAction(e)),
	clearSkillByIdAction: (e) => dispatch(clearSkillByIdAction(e)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddGoal)
