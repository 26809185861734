import { GET_ONLINE_MEETING } from '../../../../actions/Constants/index'

const initialState = []

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_ONLINE_MEETING:
			return action.payload;
        default:
            return state
    }
}

