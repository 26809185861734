import { SUPER_USER_CHANGE_PASSWORD } from '../Constants/index';
import axios from 'axios';
import { superUser } from '../../utils/Api'
import { getToken } from '../../utils/token'

export const superUserChangePasswordAction = (data) => {
	return dispatch => {
        const url = `${superUser.superUserChangePassword}`,    
        options = {
                url,
                headers:{
                    Authorization : getToken(),
                },
                method: 'POST',
                data,
            };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: SUPER_USER_CHANGE_PASSWORD,
                    payload: {
                        status : true
                    }
                })
                return true
            }else {
                dispatch({
                    type: SUPER_USER_CHANGE_PASSWORD,
                    payload: {
                        status : false
                    }
                })
                return false
            }
        })
    }
}
