import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
// import './style.scss';
import { connect } from 'react-redux';
import { MDBBtn} from 'mdbreact';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { getAssignmenttAction, getClassAction,getExamtAction,getSectionAction, getSubjectAction, getSubjectForExamAction } from '../../../../actions/institute/Utils/utilsAction';
import {  MDBFormInline, MDBInput} from 'mdbreact';
import { clearAchievementByIdAction, clearCreateAchievementAction, clearEditAchievementAction, createAchievementAction, editAchievementAction, getAchievementAction, getAchievementBadgeURLAction, getAchievementByIdAction } from '../../../../actions/institute/Teacherdashboard/ManageAchievement/ManageAchievementAction';
import moment from 'moment';

class AddRule extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,

            class_id:'',
            section_id:'',
            subject_id:'',
            rule_name:'',
            rule_for:'',
            rule_condition:'',
            examinationOrAssignment_id:[],
            badge:'',

            school_id:'',

            editId: this.props.match.params.id,
            modal: false,

            classList:[],
            sectionList:[],
            subjectList:[],
            assignmentList:[],
            examList:[],

            selectAll:false,

            badgeList:[],
            badgeModal:false,
            badgeError:false,

            showError:false
		};
	}

    componentDidMount(){
        this.props.getAchievementBadgeURLAction()
        if(localStorage.getItem('school')){
            let school = JSON.parse(localStorage.getItem('school'))
            this.setState({ school_id:school.id },()=>{
                if(this.state.school_id){
                    let search = {
                        school_id: this.state.school_id
                    }
                    this.props.getClassAction(search)
                }
            });
        }
        if(this.state.editId){
            this.props.getAchievementByIdAction(this.state.editId)
        }
	}
    componentDidUpdate(prevProp,prevState){
        if(this.state.class_id !== prevState.class_id) {
            if(this.state.class_id){
				let search = {
					class_id: this.state.class_id,
				}
				this.props.getSectionAction(search)
			}
		}
		if(this.state.section_id !== prevState.section_id) {
            if(this.state.class_id){
				let search = {
					section_id: this.state.section_id,
				}
				this.props.getSubjectForExamAction()
			}
		}
        if(this.state.rule_for !== prevState.rule_for) {
            if(this.state.subject_id){
				let search = {
					subject_id: this.state.subject_id,
				}
                if(this.state.rule_for === 'Examination'){
                    this.props.getExamtAction(search)
                }
                if(this.state.rule_for === 'Assignment'){
                    this.props.getAssignmenttAction(search)
                }
			}
		}
	}

    componentWillReceiveProps(nextProps){
        if(this.state.subjectList !== nextProps.utills.subjectForExam){
            this.setState({
                subjectList: nextProps.utills.subjectForExam
            })
        }
        if(this.state.sectionList !== nextProps.utills.sectionList){
            this.setState({
                sectionList: nextProps.utills.sectionList
            })
        }
		if(this.state.classList !== nextProps.utills.classList){
			this.setState({
				classList: nextProps.utills.classList
			})
		}
        if(this.props.utills.examList !== nextProps.utills.examList){
            this.setState({
                examList: nextProps.utills.examList
            },()=>{
                this.state.examList.map(o => o.is_selected = false)

                let list = JSON.parse(JSON.stringify(this.state.examList))
                list.map((item,i)=>{
                    if(this.state.examinationOrAssignment_id.includes(item.id)){
                        item.is_selected = true
                    }
                })
                this.setState({examList:list})

            })
        }
		if(this.props.utills.assignmentList !== nextProps.utills.assignmentList){
			this.setState({
				assignmentList: nextProps.utills.assignmentList
			},()=>{
                this.state.assignmentList.map(o => o.is_selected = false)


                let list = JSON.parse(JSON.stringify(this.state.assignmentList))
                list.map((item,i)=>{
                    if(this.state.examinationOrAssignment_id.includes(item.id)){
                        item.is_selected = true
                    }
                })
                this.setState({assignmentList:list})
            })
		}
        if(this.props.achievementState.detail !== nextProps.achievementState.detail){
            let data = nextProps.achievementState.detail
            
            this.setState({
                class_id : data.class_id,
                section_id : data.section_id,
                subject_id : data.subject_id,
                rule_for : data.rule_for[0],
                rule_condition : data.rule_condition[0],
                examinationOrAssignment_id: data.ExamsOrAssignment.split(',').map(item => Number(item)),
                rule_name: data.rule_name,
                badge:data.badge
            })
        }
        if(this.state.badgeList !== nextProps.achievementState.Badgelist){
            this.setState({
                badgeList: nextProps.achievementState.Badgelist
            })
        }
	}
    
    clearData =()=>{
        this.props.clearCreateAchievementAction()
        this.props.clearAchievementByIdAction()
        this.props.clearEditAchievementAction()
        this.toggleClose()
        this.props.history.push('/teacher/manage-achievement-rule')
    }

    modalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={ this.state.modal }>
                    <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
                    <MDBModalBody>
                        <div className='alertContainer'>
                            <h4> Do you want to {this.state.editId ? 'Update' : 'save'} it ? </h4>
                            <h6> Please check the information before submitting</h6>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggleClose}>No</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.handleSave}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
			</MDBContainer>
		)
	}
    createAchievement=()=>{

        let ids = []
        if(this.state.rule_for === 'Examination'){
            this.state.examList.map((item,i)=>{
                if(item.is_selected){
                    ids.push(item.id)
                }
            })
        }
        if(this.state.rule_for === 'Assignment'){
            this.state.assignmentList.map((item,i)=>{
                if(item.is_selected){
                    ids.push(item.id)
                }
            })
        }
        if(this.state.rule_for === 'Assignment'){
            this.state.assignmentList.map((item,i)=>{
                if(item.is_selected){
                    ids.push(item.id)
                }
            })
            if(this.state.selectAll){
                this.state.assignmentList.map((item,i)=>{
                    ids.push(item.id)
                })
            }
        }
        let data = {
            class_id:Number(this.state.class_id),
            section_id:Number(this.state.section_id),
            subject_id:Number(this.state.subject_id),
            rule_for:this.state.rule_for,
            rule_name:this.state.rule_name,
            rule_condition:this.state.rule_condition,
            examinationOrAssignment_id:[...new Set(ids)],
            badge: this.state.badge
        }
        if(data.examinationOrAssignment_id.length === 0){
            this.setState({showError:true,modal:false})
        }
        else{
            if(this.state.editId){
                this.props.editAchievementAction({id : this.state.editId,
                    data:data, history: this.props.history})
            }else{
                this.props.createAchievementAction(data, this.props.history)
            }
        }
        
    }
    handleSave=()=>{
        this.createAchievement()
    }
    openBadgeModal=()=>{
        this.setState({
            badgeModal:true
        })
    }
    saveBadge=(url)=>{
        this.setState({
            badge:url,
            badgeModal:false,
            badgeError:false
        })
    }
    modalBadgeContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={ this.state.badgeModal }>
                    <MDBModalHeader toggle={this.toggleClose}>select Badge</MDBModalHeader>
                    <MDBModalBody>
                        <div className='alertContainer'>
                            {
                                this.state.badgeList.map((item,i)=>{
                                    return <img src={item.badge_url} alt="badge" width="10%" onClick={() =>{ this.saveBadge(item.badge_url)}}/>
                                })
                            }
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggleClose}>No</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.handleSave}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
			</MDBContainer>
		)
	}
    submitHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
		if(event.target.checkValidity()) {
            if(!this.state.badge){
                this.setState({badgeError:true})
            }
            else{
                this.setState({
                    modal: true,
                })
            }
		}
	}
    changeHandler = event => {
        if(event.target.name === 'selectAll'){
		    this.setState({ selectAll:event.target.checked });
        }
        else{
            this.setState({ [event.target.name]:event.target.value });
        }
	};
    handleChkBox = (event,i,name) => {
        if(name === 'exam'){
            let list = JSON.parse(JSON.stringify(this.state.examList))
            list[i].is_selected = event.target.checked
            this.setState({ examList:list });
        }
        else{
            let list = JSON.parse(JSON.stringify(this.state.assignmentList))
            list[i].is_selected = event.target.checked
            this.setState({ assignmentList:list });
        }
	}
    toggleClose = ()=>{
		this.setState({
			modal: false,
            badgeModal:false,
            badgeError:false
		})
	}
    handleFile=(event)=>{
        this.setState({file : event.target.files[0]})
    }
    render(){
        const { activeTab } = this.state
        // if(!isLogined()) this.props.history.push('/')
        return(
            <>
            {this.state.modal && this.modalContainer()}
            {this.state.badgeModal && this.modalBadgeContainer()}

            <div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">{this.state.editId ? 'Update Achievement Rule' : 'Add New Achievement Rule'} </h1>
							</div>
						</div>
					</div>
				</div>
                <div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
                                <form  onSubmit={this.submitHandler} noValidate>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Rule Name:
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value = {this.state.rule_name} name="rule_name" 
                                                                        onChange={this.changeHandler} placeholder="Rule Name" required/>
                                                                   
                                                                    <div className="invalid-feedback">
                                                                        required class.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Class:
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                <select name="class_id" value={this.state.class_id} 
                                                                    className="form-control show-tick" onChange={this.changeHandler} required
                                                                    >
                                                                        <option value="">Select Class</option>
                                                                        {this.state.classList && this.state.classList.map((item)=>{
                                                                            return <option value={item.id}>{item.name}</option>
                                                                        })}
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required class.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Section : 
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                <select name="section_id" value={this.state.section_id} 
                                                                    className="form-control show-tick" onChange={this.changeHandler} required
                                                                    disabled={this.state.class_id ? false : true}>
                                                                        <option value="">Select Section</option>
                                                                        {this.state.sectionList && this.state.sectionList.map((item)=>{
                                                                            return <option value={item.id}>{item.name}</option>
                                                                        })}
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required seaction.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Subject : 
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                <select name="subject_id" value={this.state.subject_id} 
                                                                    className="form-control show-tick" onChange={this.changeHandler} required
                                                                    disabled={this.state.class_id && this.state.section_id ? false : true}>
                                                                        <option value="">Select Subject</option>
                                                                        {this.state.subjectList && this.state.subjectList.map((item)=>{
                                                                            return <option value={item.id}>{item.name}</option>
                                                                        })}
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required subject.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Year :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    {moment().format('YYYY')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Rule Type : 
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select name="rule_for" value={this.state.rule_for} 
                                                                    className="form-control show-tick" onChange={this.changeHandler} required
                                                                    >
                                                                        <option value="">Select</option>
                                                                        <option value="Examination">Examination</option>
                                                                        <option value="Assignment">Assignment</option>
                                                                       
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                {
                                                    this.state.rule_for === 'Examination' ? 
                                                    <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                    <div className="input-group">
                                                                        Select Exams
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                    <div className="input-group">
                                                                        :
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                    <div className="input-group">
                                                                        <MDBFormInline>
                                                                            <MDBInput
                                                                                value = {this.state.selectAll}
                                                                                name ={'selectAll'}
                                                                                onChange =  {this.changeHandler}
                                                                                label='Any Exam'
                                                                                type='checkbox'
                                                                                id='anyExam'
                                                                                containerClass='mr-5'
                                                                                
                                                                            />
                                                                            {!this.state.selectAll ? 
                                                                                this.state.examList.map((item,i)=>{
                                                                                return <MDBInput
                                                                                    value = {this.state.examList[i].is_selected}
                                                                                    name ={'examIds'+i}
                                                                                    onChange =  {(e)=>{ this.handleChkBox(e,i,'exam')} }
                                                                                    label={item.name}
                                                                                    checked={this.state.examList[i].is_selected}
                                                                                    type='checkbox'
                                                                                    id={'examIds'+i}
                                                                                    containerClass='mr-5'
                                                                                />
                                                                                }) : ''
                                                                            }
                                                                        </MDBFormInline>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : 
                                                    <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Assignment
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <MDBFormInline>
                                                                        <MDBInput
                                                                            value = {this.state.selectAll}
                                                                            name ={'selectAll'}
                                                                            onChange =  {this.changeHandler}
                                                                            label='Any Assignment'
                                                                            type='checkbox'
                                                                            id='anyAssignment'
                                                                            containerClass='mr-5'
                                                                            
                                                                        />
                                                                        {!this.state.selectAll ?
                                                                            this.state.assignmentList.map((item,i)=>{
                                                                            return <MDBInput
                                                                                value = {this.state.assignmentList[i].is_selected}
                                                                                name ={'assignmentIds'+i}
                                                                                checked={this.state.assignmentList[i].is_selected}
                                                                                onChange =  {(e)=>{ this.handleChkBox(e,i,'assignment')} }
                                                                                label={item.name}
                                                                                type='checkbox'
                                                                                id={'assignmentIds'+i}
                                                                                containerClass='mr-5'
                                                                                
                                                                            />
                                                                            }) : ''
                                                                        }
                                                                    </MDBFormInline>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                }

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="">
                                                                    Examination/Assignemt Rule
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select name="rule_condition" value={this.state.rule_condition} 
                                                                    className="form-control show-tick" onChange={this.changeHandler} required
                                                                    >
                                                                        <option value="">Select</option>
                                                                        <option value="95%">95% Above</option>
                                                                        <option value="90%">90% Above</option>
                                                                        <option value="85%">85% Above</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    
                                                                    <MDBBtn color="primary" className='btn' onClick= {()=>{
                                                                        this.openBadgeModal()
                                                                    }}>Select Badge</MDBBtn>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                {this.state.badge ? <img src={this.state.badge} alt="badge" width="20%"/> : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-4 col-sm-6 inputField submitBtn">
                                                <MDBBtn color="primary" className='btn' onClick= {()=>{
                                                    this.props.history.push('/teacher/manage-achievement-rule')
                                                }}>Cancel</MDBBtn>
                                                <MDBBtn color="primary" type={'submit'} className='btn'>{this.state.editId ? 'Update' : 'Save'}</MDBBtn>
                                            </div>
                                        </div>
                                    </div>
                            
                                </form>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
	utills: state.utillReducer,
	achievementState: state.ManageAchievementReducer,

})

const mapDispatchToProps = dispatch => ({
    getClassAction: (e) => dispatch(getClassAction(e)),
	getSectionAction: (e) => dispatch(getSectionAction(e)),
	// getSubjectAction: (e) => dispatch(getSubjectAction(e)),
	getSubjectForExamAction: (e) => dispatch(getSubjectForExamAction(e)),
    getAssignmenttAction: (e) => dispatch(getAssignmenttAction(e)),
	getExamtAction: (e) => dispatch(getExamtAction(e)),
	createAchievementAction: (e, history) => dispatch(createAchievementAction(e, history)),
	clearCreateAchievementAction: (e) => dispatch(clearCreateAchievementAction(e)),
	clearEditAchievementAction: (e) => dispatch(clearEditAchievementAction(e)),
	editAchievementAction: (e, history) => dispatch(editAchievementAction(e, history)),
	getAchievementByIdAction: (e) => dispatch(getAchievementByIdAction(e)),
	clearAchievementByIdAction: (e) => dispatch(clearAchievementByIdAction(e)), 

	getAchievementBadgeURLAction: (e) => dispatch(getAchievementBadgeURLAction(e)),  
})

export default connect(mapStateToProps, mapDispatchToProps)(AddRule)
