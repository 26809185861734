import { GET_PROFILE_INFO, 
    UPDATE_PROFILE_INFO,
    GET_LOGO_AND_IMAGE, 
    UPDATE_LOGO_AND_IMAGE, 
    PROFILE_LOGIN_IMAGE,
    UPDATE_LOGIN_VIDEO
     } from '../../Constants/index';
import axios from 'axios';
import { manageProfile, manageInstitutes } from '../../../utils/Api'
import { getToken } from '../../../utils/token'


export const getProfileInfoAction = () => {
	return dispatch => {
        const url = `${manageProfile.getProfileInfo}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_PROFILE_INFO,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_PROFILE_INFO,
                    payload: {
                        list : {
                            first_name:'',
                            contact_no:'',
                            email:''
                        }
                    }
                })
            }
        })
    }
}

export const updateProfileInfoAction = (data) => {
	return dispatch => {
        const url = `${manageProfile.updateProfileInfo}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PUT',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: UPDATE_PROFILE_INFO,
                    payload: {
                        list : true
                    }
                })
                return true
            }else {
                dispatch({
                    type: UPDATE_PROFILE_INFO,
                    payload: {
                        list : false
                    }
                })
                return false
            }
        })
    }
}

export const getLogoAndImageAction = () => {
	return dispatch => {
        const url = `${manageProfile.getLogoAndImage}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_LOGO_AND_IMAGE,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_LOGO_AND_IMAGE,
                    payload: {
                        list : null
                    }
                })
            }
        })
    }
}

export const updateLogoAndImageAction = (data) => {
	return dispatch => {
        const url = `${manageProfile.updateLogoAndImage}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PUT',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: UPDATE_LOGO_AND_IMAGE,
                    payload: {
                        list : true
                    }
                })
                return true
            }else {
                dispatch({
                    type: UPDATE_LOGO_AND_IMAGE,
                    payload: {
                        list : false
                    }
                })
                return false
            }
        })
    }
}

export const uploadLoginImageAction = (data) => {
	return dispatch => {
        let formData = new FormData();
        formData.append('image', data);
        const url = `${manageInstitutes.uploadImage}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
                "Content-type": "multipart/form-data",
            },
            method: 'post',
            data: formData
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA.file
                dispatch({
                    type: PROFILE_LOGIN_IMAGE,
                    payload: {
                        status : data
                    }
                })
            }else {
                dispatch({
                    type: PROFILE_LOGIN_IMAGE,
                    payload: {
                        status : null
                    }
                })
            }
        })
    }
}


export const uploadLoginVideoAction = (data) => {
	return dispatch => {
        let formData = new FormData();
        formData.append('video', data);
        const url = `${manageInstitutes.uploadVideo}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
                "Content-type": "multipart/form-data",
            },
            method: 'post',
            data: formData
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA.videoUrl
                dispatch({
                    type: UPDATE_LOGIN_VIDEO,
                    payload: {
                        status : data
                    }
                })
            }else {
                dispatch({
                    type: UPDATE_LOGIN_VIDEO,
                    payload: {
                        status : null
                    }
                })
            }
        })
    }
}
