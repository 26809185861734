import { GET_EXAMINATION, 
    CREATE_EXAMINATION,
    EDIT_EXAMINATION, 
    GET_EXAMINATION_BY_ID,
    PUBLISH_EXAMINATION,
    DELETE_EXAMINATION,
    GET_SUBMITTED_EXAM_DETAIL,
    GET_NOT_SUBMITTED_EXAM_DETAIL,
    GET_SUBMITTED_ANSWER_WITH_PENDING_STATUS,
    GET_SUBMITTED_ANSWER_WITH_EVALUATED_STATUS,
    EVALUATED_MARK_STATUS,
    GET_EXAMINATION_FOR_STUDENT,
    GET_EXAMINATION_FOR_PARENT,
    SUBMIT_EXAMINATION,SHOW_SNACKBAR} from '../../../Constants/index';
import axios from 'axios';
import { manageExamination } from '../../../../utils/Api'
import { getToken,getSchoolId } from '../../../../utils/token'

export const getExaminationAction = (params) => {
	return dispatch => {
        const url = params ? `${manageExamination.searchExamination}` : `${manageExamination.getExamination}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
                school_id:getSchoolId()
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_EXAMINATION,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_EXAMINATION,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearGetExaminationAction = () => {
	return dispatch => {
        dispatch({
            type: GET_EXAMINATION,
            payload: {
                list : []
            }
        })
    }
}

export const createExaminationAction = (data, history) => {
	return dispatch => {
        const url = `${manageExamination.createExamination}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: CREATE_EXAMINATION,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                 history.push("/teacher/manage-examination");
            }else {
                dispatch({
                    type: CREATE_EXAMINATION,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const clearCreateExaminationAction = () => {
	return dispatch => {
        dispatch({
            type: CREATE_EXAMINATION,
            payload: {
                list : null
            }
        })
    }
}

export const clearEditExaminationAction = () => {
	return dispatch => {
        dispatch({
            type: EDIT_EXAMINATION,
            payload: {
                list : null
            }
        })
    }
}

export const editExaminationAction = ({id,data, history}) => {
	return dispatch => {
        const url = `${manageExamination.editExamination(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PATCH',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: EDIT_EXAMINATION,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                 history.push("/teacher/manage-examination");
            }else {
                dispatch({
                    type: EDIT_EXAMINATION,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const getExaminationByIdAction = (params) => {
	return dispatch => {
        const url = `${manageExamination.getExaminationById}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params:params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_EXAMINATION_BY_ID,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_EXAMINATION_BY_ID,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearExaminationByIdAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_EXAMINATION_BY_ID,
            payload: {
                list : {}
            }
        })
    }
}

export const publishExaminationAction = (id) => {
	return dispatch => {
        const url = `${manageExamination.publishExamination(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PATCH',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: PUBLISH_EXAMINATION,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: PUBLISH_EXAMINATION,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearpublishExamAction = (id) => {
	return dispatch => {
        dispatch({
            type: PUBLISH_EXAMINATION,
            payload: {
                list : {}
            }
        })
    }
}

export const deleteExamAction = (data) => {
	return dispatch => {
        const url = `${manageExamination.deleteExamination(data)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'DELETE',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: DELETE_EXAMINATION,
                    payload: {
                        status : flag.status
                    }
                })
            }else {
                dispatch({
                    type: DELETE_EXAMINATION,
                    payload: {
                        status : {}
                    }
                })
            }
        })
    }
}

export const getSubmittedExamDetailAction = (id) => {
	return dispatch => {
        const url = `${manageExamination.getSubmittedExamDetail(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_SUBMITTED_EXAM_DETAIL,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_SUBMITTED_EXAM_DETAIL,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearSubmittedExamDetailAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_SUBMITTED_EXAM_DETAIL,
            payload: {
                list : {}
            }
        })
    }
}

export const getNotSubmittedExamDetailAction = (id) => {
	return dispatch => {
        const url = `${manageExamination.getNotSubmittedExamDetail(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_NOT_SUBMITTED_EXAM_DETAIL,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_NOT_SUBMITTED_EXAM_DETAIL,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearNotSubmittedExamDetailAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_NOT_SUBMITTED_EXAM_DETAIL,
            payload: {
                list : {}
            }
        })
    }
}

export const getSubmittedAnswerWithPendingStatusAction = (id,id1) => {
	return dispatch => {
        const url = `${manageExamination.getSubmittedAnswerWithPendingStatus(id,id1)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_SUBMITTED_ANSWER_WITH_PENDING_STATUS,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_SUBMITTED_ANSWER_WITH_PENDING_STATUS,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const getSubmittedAnswerWithEvaluatedStatusAction = (id,id1) => {
	return dispatch => {
        const url = `${manageExamination.getSubmittedAnswerWithEvaluatedStatus(id,id1)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_SUBMITTED_ANSWER_WITH_EVALUATED_STATUS,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_SUBMITTED_ANSWER_WITH_EVALUATED_STATUS,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const evaluateMarkAction = ({id,id1,data}) => {
	return dispatch => {
        const url = `${manageExamination.evaluateMark(id,id1)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: EVALUATED_MARK_STATUS,
                    payload: {
                        list : flag.status
                    }
                })
            }else {
                dispatch({
                    type: EVALUATED_MARK_STATUS,
                    payload: {
                        list : flag.status
                    }
                })
            }
        })
    }
}

export const clearEvaluateMarkAction = () => {
	return dispatch => {
        dispatch({
            type: EVALUATED_MARK_STATUS,
            payload: {
                list : {}
            }
        })
    }
}

export const getExaminationListForStudentAction = (data) => {
	return dispatch => {
        const url = manageExamination.getExaminationForStudent,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_EXAMINATION_FOR_STUDENT,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_EXAMINATION_FOR_STUDENT,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const cleargetExaminationListForStudentAction = ({id,id1,data}) => {
	return dispatch => {
        dispatch({
            type: GET_EXAMINATION_FOR_STUDENT,
            payload: {
                list : []
            }
        })
    }
}

export const getExaminationListForParentAction = (data) => {
	return dispatch => {
        const url = manageExamination.getExaminationForParent,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_EXAMINATION_FOR_PARENT,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_EXAMINATION_FOR_PARENT,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const cleargetExaminationListForParentAction = () => {
	return dispatch => {
        dispatch({
            type: GET_EXAMINATION_FOR_PARENT,
            payload: {
                list : []
            }
        })
    }
}

export const submitExamByStudentAction = (data) => {
	return dispatch => {
        const url = manageExamination.submitExamByStudent,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: SUBMIT_EXAMINATION,
                    payload: {
                        list : flag.status.msg
                    }
                })
            }else {
                dispatch({
                    type: SUBMIT_EXAMINATION,
                    payload: {
                        list : null
                    }
                })
            }
        })
    }
}

export const clearSubmitExamAction = () => {
	return dispatch => {
        dispatch({
            type: SUBMIT_EXAMINATION,
            payload: {
                list : null
            }
        })
    }
}