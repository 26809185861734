import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import {
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import "./style.scss";
import {
  createSectionAction,
  getSectionEditByIdAction,
  editSectionAction,
} from "../../../actions/institute/ManageSection/manageSectionAction";
import {
  getAllSchoolAction,
  getClassAction,
} from "../../../actions/institute/Utils/utilsAction";

class CreateSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      input: {
        status: "1",
      },
      modal: false,
      editId: this.props.match.params.id,
      dropDownDataClass: [],
      dropDownDataSchool: [],
      isLoggedInRole:
        localStorage.getItem("loginedRole") === "principal" ? true : false,
      
    };
  }

  componentDidMount() {
    if (this.state.isLoggedInRole) {
      var localSchoolId = {
        school_id: JSON.parse(localStorage.getItem("school")).id,
      };
      this.props
        .getClassAction(localSchoolId)
        .then((res) => this.setState({ dropDownDataClass: res }));
    }
    if (this.props.match.params.id) {
      this.props
        .getSectionEditByIdAction(this.props.match.params.id)
        .then((res) => this.setState({ input: res }));
    }
    this.props
      .getAllSchoolAction()
      .then((res) => this.setState({ dropDownDataSchool: res }));
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevState.input.school_id !== this.state.input.school_id) {
      if (this.state.input.school_id) {
        let search = {
          school_id: this.state.input.school_id,
        };
        this.props
          .getClassAction(search)
          .then((res) => this.setState({ dropDownDataClass: res }));
      }
    }
    if (
      this.state.isLoggedInRole &&
      this.state.input.school_id === undefined
    ) {
      this.setState({
        input: {
          ...this.state.input,
          ["school_id"]: localStorage.getItem("school_id"),
        },
      });
    }
  }

  clearData = () => {
    this.setState({
      input: {},
      modal: false,
    });
    this.props.history.push("/institute/manage-section");
  };

  toggle = () => {
    this.setState({
      modal: true,
    });
  };

  toggleClose = () => {
    this.setState({
      modal: false,
    });
  };

  changeHandler = (name, value) => {
    this.setState({ input: { ...this.state.input, [name]: value } });
  };

  submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (event.target.checkValidity()) {
      this.setState({
        modal: true,
      });
    }
  };

  modalContainer = () => {
    return (
      <MDBContainer>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
          <MDBModalBody>
            <div className="alertContainer">
              <h4>
                {" "}
                Do you want to {this.state.editId ? "Update" : "save"} it ?{" "}
              </h4>
              <h6> Please check the information before submitting</h6>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              No
            </MDBBtn>
            <MDBBtn color="secondary" onClick={this.handleSave}>
              Yes
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  };

  handleSave = async () => {
    if (this.props.match.params.id) {
      await this.props.editSectionAction({
        id: this.props.match.params.id,
        data: this.state.input,
        history:this.props.history
      })
    } else {
      await this.props.createSectionAction(this.state.input,this.props.history)
    }
  };

  inputFieldContainer = () => {
    const { activeTab } = this.state;
    return (
      <div className="container-fluid">
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1} className={classnames(["fade show"])}>
            <form onSubmit={this.submitHandler} noValidate>
              <div className="card">
                {!this.state.isLoggedInRole && (
                  <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6">
                          <div className="input-group">School Name</div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6">
                          <div className="input-group">:</div>
                        </div>
                        <div className="col-lg-8 col-md-4 col-sm-6">
                          <div className="input-group">
                            <select
                              value={this.state.input.school_id}
                              onChange={(e) =>
                                this.changeHandler("school_id", e.target.value)
                              }
                              required
                              className="form-control show-tick"
                            >
                               <option value="">Select School</option>
                              {this.state.dropDownDataSchool.map((ele) => (
                                <option value={ele.id}>{ele.name}</option>
                              ))}
                            </select>
                            <div className="invalid-feedback">
                              required class name field.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-group">Class Name</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <div className="input-group">
                          <select
                            value={this.state.input.class_id}
                            onChange={(e) =>
                              this.changeHandler("class_id", e.target.value)
                            }
                            disabled={!this.state.input.school_id}
                            required
                            className="form-control show-tick"
                          >
                            <option value="">Select Class</option>
                            {this.state.dropDownDataClass.map((ele) => (
                              <option value={ele.id}>{ele.name}</option>
                            ))}
                          </select>
                          <div className="invalid-feedback">
                            required class name field.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-group">Section Name</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <div className="input-group">
                          <input
                            value={this.state.input.section_name}
                            placeholder="Section Name"
                            onChange={(e) =>
                              this.changeHandler("section_name", e.target.value)
                            }
                            type="text"
                            className="form-control"
                            required
                          />
                          <div className="invalid-feedback">
                            required section name field.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-group">Status</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6 ">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6 ">
                        <div className="input-group">
                          <select
                            value={this.state.input.status}
                            onChange={(e) =>
                              this.changeHandler("status", e.target.value)
                            }
                            required
                            className="form-control show-tick"
                          >
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                          <div className="invalid-feedback">
                            required status field.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                    {this.state.createStatus ? (
                        <div className="errorFlag">
                            {this.state.createStatus +' *'}
                        </div>
                    ):''}
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3 submitBtn">
                  <MDBBtn
                    color="primary"
                    className="btn"
                    onClick={this.clearData}
                  >
                    Close
                  </MDBBtn>
                  <MDBBtn color="primary" className="btn" type="submit">
                    Save
                  </MDBBtn>
                </div>
              </div>
            </form>
          </TabPane>
        </TabContent>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">
                  {" "}
                  {this.state.editId ? "Update" : "Create"} Section
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.inputFieldContainer()}</div>
          {this.state.modal && this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sectionList: state.getSectionReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createSectionAction: (e,history) => dispatch(createSectionAction(e,history)),
  editSectionAction: (e,history) => dispatch(editSectionAction(e,history)),
  getSectionEditByIdAction: (e) => dispatch(getSectionEditByIdAction(e)),
  getAllSchoolAction: (e) => dispatch(getAllSchoolAction(e)),
  getClassAction: (e) => dispatch(getClassAction(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateSection);
