import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { MDBDataTable, MDBBtn, MDBIcon,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
// import './style.scss';
import moment from 'moment'
import { clearGetFeedbackAction, clearGetFeedbackDropdownAction, getFeedbackAction } from '../../../actions/institute/ManageFeedback/manageFeedback';
import DatePicker from "react-datepicker";
import Loader from "../../common/Loader";
import { getAllSchoolAction, getClassAction,getSectionAction, getSubjectAction, getTeacherAction } from '../../../actions/institute/Utils/utilsAction';


class ManageFeedback extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			show: false,
			feedbackData : [],
			tableBody : { columns :[], rows: []},
			modal: false,
			selectedFeedback: null,
			deleteModal:false,
			deleteStatus:null,
			deleteId:'',
			openCreateModal:false,
			searchFeedback:[],

			school_id:'',
			class_id:'',
			section_id:'',
			subject_id:'',
			type:'',
			year:'',
			createdAt:'',

            school:[],
			role:this.props.role,

			classList:[],
            sectionList:[],
            subjectList:[],
			utills:{},
			isLoggedInRole:
			localStorage.getItem("loginedRole") === "principal" ? true : false,

		};
	}

	componentWillReceiveProps(nextProps){
	
		if(this.state.feedbackData !== nextProps.feedbackData.list){
			this.setState({
				feedbackData: nextProps.feedbackData.list
			},()=>{
				this.constructTableData()
			})
		}
		if(this.state.subjectList !== nextProps.utills.subjectList){
            this.setState({
                subjectList: nextProps.utills.subjectList
            })
        }
        if(this.state.sectionList !== nextProps.utills.sectionList){
            this.setState({
                sectionList: nextProps.utills.sectionList
            })
        }
		if(this.state.school !== nextProps.utills.schoolAllList){
			this.setState({
				school: nextProps.utills.schoolAllList
			})
		}
		if(this.state.classList !== nextProps.utills.classList){
			this.setState({
				classList: nextProps.utills.classList
			})
		}
		
	}
	componentDidUpdate(prevProp,prevState){
		if (this.state.school_id !== prevState.school_id) {
            if(this.state.school_id){
				let search = {
					school_id: this.state.school_id
				}
				this.props.getClassAction(search)
			}
		}
		if(this.state.class_id !== prevState.class_id) {
            if(this.state.school_id && this.state.class_id){
				let search = {
					class_id: this.state.class_id,
				}
				this.props.getSectionAction(search)
			}
		}
		if(this.state.section_id !== prevState.section_id) {
            if(this.state.school_id && this.state.class_id){
				let search = {
					section_id: this.state.section_id,
				}
				this.props.getSubjectAction(search)
			}
		}
	}
	componentDidMount(){
		this.props.getAllSchoolAction()
		this.props.getFeedbackAction()
		let search = {
			school_id: localStorage.getItem("school_id")
		}
		if (
			this.state.isLoggedInRole 
		  ) {
			this.setState({
				school_id: localStorage.getItem("school_id"),
			});
		  }
		{this.state.isLoggedInRole && this.props.getClassAction(search)}
	}
	constructTableData =()=>{
		let col= [
			{
				label: '#',
				field: 'id',
			},
			{
				label: 'Feedback Title',
				field: 'feedback_title',
				width: 150,
				attributes: {
					'aria-controls': 'DataTable',
					'aria-label': 'Name',
				},
			}
		]
		if(this.state.role === 'institute admin'){
			col.push(
				{
					label: 'School Name',
					field: 'school_name',
					width: 270,
				}
			)
		}
		col.push(
			{
				label: 'Class Name',
				field: 'class_name',
				width: 270,
			},
			{
				label: 'Section Name',
				field: 'section_name',
				width: 270,
			},
			{
				label: 'Subject',
				field: 'subject',
				width: 270,
			},
			{
				label: 'Student Name',
				field: 'student_name',
				width: 270,
			},
			{
				label: 'Type',
				field: 'type',
				width: 270,
			},
			{
				label: 'Send Date',
				field: 'createdDate',
				width: 270,
			},
			{
				label: 'Action',
				field: 'action',
				width: 300,
			},
		)
		let tableData = {
			columns: col,
			rows: 
				this.state.feedbackData.map((item,i)=>{
					let obj = {
						id: i+1,
						feedback_title: item.title ? item.title : '-',
						class_name: item.class_name ? item.class_name : '-' ,
						school_name: item.school_name ? item.school_name : '-',
						section_name: item.section_name ? item.section_name: '-',
						subject: item.subject_name ? item.subject_name: '-',
						student_name: item.name ? item.name : '-',
						type: item.user_type ? item.user_type: '-',
						createdDate:item.createdAt ,
						status: item.status ? 'Sent' : 'Draft',
						action: (<div>
							<MDBIcon  icon="eye" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{
								this.toggle(item)
							}} />
						</div>)

					}
					return obj
				})
		}	
		this.setState({
			tableBody: tableData
		})	
	}
	handleChange=(e)=>{
		this.setState({ [e.target.name] : e.target.value})
	}

	handleYear =(value)=>{
		this.setState({ year:value});
    }

	getFeedbackDetail = ()=>{
		return(
			<div className="card-body">
				{
					this.state.role === 'institute admin' ? 
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								School Name
							</div>
						</div>
						<div className="col-lg-2 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								: 
							</div>
						</div>
						<div className="col-lg-6 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								{this.state.selectedFeedback && this.state.selectedFeedback.school_name ? this.state.selectedFeedback.school_name : '-'}                                  
							</div>
						</div>
					</div>:''
				}
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Title
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							{this.state.selectedFeedback && this.state.selectedFeedback.title ? this.state.selectedFeedback.title : '-'}                                  
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Class
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							{this.state.selectedFeedback && this.state.selectedFeedback.class_name ? this.state.selectedFeedback.class_name : '-'}                                  
						</div>
					</div>
				</div>
                
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Section
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group" >
							{this.state.selectedFeedback && this.state.selectedFeedback.section_name ? 
							 this.state.selectedFeedback.section_name 
							: '-'}                                  
						</div>
					</div>
				</div>
                <div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Subject
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							{this.state.selectedFeedback && this.state.selectedFeedback.subject_name ? this.state.selectedFeedback.subject_name : '-'}                                  
						</div>
					</div>
				</div>
                <div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Comments
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							{this.state.selectedFeedback && this.state.selectedFeedback.comment ? this.state.selectedFeedback.comment : '-'}                                  
						</div>
					</div>
				</div>
			</div>		
		)
	}
    toggle = (value) =>{
		this.setState({
		  modal: true,
		  selectedFeedback : value
		});
	}

	componentWillUnmount(){
		this.props.clearGetFeedbackDropdownAction()
		this.props.clearGetFeedbackAction()
	}

	toggleClose = ()=>{
		this.setState({
			modal: false,
			selectedFeedback: null,
			deleteModal: false,
			openCreateModal: false
		})
	}

	handleSearch=()=>{
		let search = {
			school_id: this.state.school_id,
			class_id:this.state.class_id,
			section_id:this.state.section_id,
			subject_id:this.state.subject_id,
			user_type:this.state.type,
			year:this.state.year ?moment(this.state.year).format('YYYY') : null,
			createdAt: this.state.createdAt ? moment(this.state.createdAt).format('YYYY-MM-DD') : '',
		}
		Object.keys(search).forEach((key) => (search[key] == '') && delete search[key]);
		this.props.getFeedbackAction(search)
	}

	clearSearch=()=>{
		this.setState({
            school_id:'',
            class_id:'',
            section_id:'',
            subject_id:'',
            type:'',
            createdAt:'',
			year:'',
		})
		if (
			this.state.isLoggedInRole 
		  ) {
			this.setState({
				school_id: localStorage.getItem("school_id"),
			});
		  }
		this.props.getFeedbackAction()
	}

    handleDate =(value)=>{
		this.setState({ createdAt:value});
    }

	render() {
		const { activeTab } = this.state
		// if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Manage Feedback</h1>
								<ol className="breadcrumb page-breadcrumb">
									<li className="breadcrumb-item">Search</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											{
												this.state.role === 'institute admin' ?
												<div className="col-lg-3 col-md-2 col-sm-12">
													<select className="form-control show-tick" value={ this.state.school_id} onChange={ this.handleChange } name="school_id">
														<option value="">Select School</option>
														{this.state.school.map((item)=>{
																return <option value={item.id}>{item.name}</option>
															})}
													</select>
												</div>
												:''
											}
                                            
											<div className="col-lg-3 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.class_id} 
												 disabled ={!this.state.school_id}
												onChange={ this.handleChange } name="class_id">
                                                    <option value="">Select Class</option>
													{this.state.classList && this.state.classList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
											<div className="col-lg-3 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.section_id} 
												onChange={ this.handleChange } name="section_id" disabled ={this.state.class_id && this.state.school_id ? false : true}>
                                                    <option value="">Select Section</option>
													{this.state.sectionList && this.state.sectionList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
											<div className="col-lg-3 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.subject_id} 
												onChange={ this.handleChange } name="subject_id" disabled ={this.state.section_id && this.state.class_id && this.state.school_id ? false : true}>
                                                    <option value="">Select Subject</option>
                                                    {this.state.subjectList && this.state.subjectList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
											<div  className={`col-lg-3 md-3 col-sm-12 ${
                    !this.state.isLoggedInRole ? "mt-10" : ""
                  }`}>
                                                <select className="form-control show-tick" value={ this.state.type} onChange={ this.handleChange } name="type">
                                                    <option value="">Select Type</option>
                                                    <option value="student">Student</option>
                                                    <option value="parent">Parent</option>
                                                </select>
                                            </div>
											{
												this.state.role === 'principal' ? 
												<div className="col-lg-3 col-md-2 col-sm-12 mt-10">
													<DatePicker
													name="year"
                                                    selected={this.state.year}
                                                    onChange={this.handleYear}
                                                    placeholderText="Select Year"
                                                    className="form-control"
                                                    showYearPicker
                                                    dateFormat="yyyy"
                                                />
												</div> 
												:''
											}
                                            <div className="col-lg-3 col-md-4 col-sm-6 mt-10">
                                                <div className="input-group">
                                                    <DatePicker
                                                        selected={this.state.createdAt}
                                                        onChange={this.handleDate}
                                                        placeholderText="Select date"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
										</div>
                                        <div className={`row`}>
											<div className="card-body cardBodyContainer">
												<div className={`row btnContainer`}>
													<div className="col-lg-4 col-md-4 col-sm-6">
														<MDBBtn color="primary" className='btn' onClick={this.handleSearch}>Search</MDBBtn> &nbsp;
														<MDBBtn color="primary" className='btn' onClick={this.clearSearch}>Clear</MDBBtn>
													</div>
												</div>
											</div>
                                        </div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
									<h1 className="page-title">Feedback List</h1>
									{this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={['title']}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching = {false}
                />
              </div>
            )}
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>

					{this.state.modal &&
					<MDBContainer>
						<MDBModal isOpen={this.state.modal} toggle={this.toggle}>
							<MDBModalHeader toggle={this.toggleClose}>View Feedback</MDBModalHeader>
								<MDBModalBody>
									<div className="card">
										{this.getFeedbackDetail()}
									</div>
								</MDBModalBody>
								<MDBModalFooter>
									<MDBBtn color="secondary" onClick={this.toggleClose}>Close</MDBBtn>
								</MDBModalFooter>
						</MDBModal>
					</MDBContainer>}
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	feedbackData: state.getFeedbackReducer,
	role: state.settings.role,
	utills: state.utillReducer

})

const mapDispatchToProps = dispatch => ({
	getFeedbackAction: (e) => dispatch(getFeedbackAction(e)),
	clearGetFeedbackAction: (e) => dispatch(clearGetFeedbackAction(e)),
	clearGetFeedbackDropdownAction: (e) => dispatch(clearGetFeedbackDropdownAction(e)),
	

	getAllSchoolAction: (e) => dispatch(getAllSchoolAction(e)),
	getClassAction: (e) => dispatch(getClassAction(e)),
	getSectionAction: (e) => dispatch(getSectionAction(e)),
	getSubjectAction: (e) => dispatch(getSubjectAction(e)),
	getTeacherAction: (e) => dispatch(getTeacherAction(e)),

})
export default connect(mapStateToProps, mapDispatchToProps)(ManageFeedback)
