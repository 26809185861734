import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import Ckeditor from "../../../common/ckeditor";
import classnames from "classnames";
import {
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import "./style.scss";
import {
  createAnnouncementAction,
  getAnnouncementEditByIdAction,
  editAnnouncementAction,
} from "../../../../actions/institute/ManageAnnouncement/manageAnnouncementAction";
import {
  getClassAction,
  getSubjectAction,
  getSectionAction,
} from "../../../../actions/institute/Utils/utilsAction";

class CreateAnnouncement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      input: {
        school_id: "",
      },
      modal: false,
      editId: this.props.match.params.id,
      dropDownDataClass: [],
      dropDownDataSection: [],
      dropDownDataSubject: [],
    };
  }

  componentDidMount() {
    let localSchoolId = {
      school_id: JSON.parse(localStorage.getItem("school")).id
    };
    this.props
    .getClassAction(localSchoolId)
    .then((res) => this.setState({ dropDownDataClass: res }));
    if (this.props.match.params.id) {
      this.props
        .getAnnouncementEditByIdAction(this.props.match.params.id)
        .then((res) => this.setState({ input: res }));
    }
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevState.input.class_id !== this.state.input.class_id) {
      if (this.state.input.class_id) {
        let search = {
          class_id: this.state.input.class_id,
        };
        this.props
          .getSectionAction(search)
          .then((res) => this.setState({ dropDownDataSection: res }));
      }
    }
    if (prevState.input.section_id !== this.state.input.section_id) {
      if (this.state.input.section_id) {
        let search = {
          section_id: this.state.input.section_id,
        };
        this.props
          .getSubjectAction(search)
          .then((res) => this.setState({ dropDownDataSubject: res }));
      }
    }
    {
      this.state.input.school_id === "" &&
        this.setState({
          input: {
            ...this.state.input,
            ["school_id"]: JSON.parse(localStorage.getItem("school")).id,
          },
        });
    }
  }

  clearData = () => {
    this.setState({
      input: {},
      modal: false,
    });
    this.props.history.push("/teacher/manage-announcement");
  };

  toggle = () => {
    this.setState({
      modal: true,
    });
  };

  toggleClose = () => {
    this.setState({
      modal: false,
    });
  };

  changeHandler = (name, value) => {
    this.setState({ input: { ...this.state.input, [name]: value } });
  };

  extractContent = (html) => {
    return new DOMParser().parseFromString(html, "text/html").documentElement
      .textContent;
  };

  submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (event.target.checkValidity()) {
      this.setState({
        modal: true,
      });
    }
  };

  handleSave = async () => {
    const updatedInput = {
      title: this.state.input.title,
      content: this.extractContent(this.state.input.content).replace(/\n/g, ''),
      class_id: this.state.input.class_id,
      section_id: this.state.input.section_id,
      subject_id: this.state.input.subject_id,
    };
    if (this.props.match.params.id) {
      await this.props.editAnnouncementAction({
        id: this.props.match.params.id,
        data: updatedInput,
        history: this.props.history
      })
    } else {
      await this.props.createAnnouncementAction(updatedInput,this.props.history)
    }
  };

  modalContainer = () => {
    return (
      <MDBContainer>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
          <MDBModalBody>
            <div className="alertContainer">
              <h4>
                {" "}
                Do you want to {this.state.editId ? "Update" : "save"} it ?{" "}
              </h4>
              <h6> Please check the information before submitting</h6>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              No
            </MDBBtn>
            <MDBBtn color="secondary" onClick={this.handleSave}>
              Yes
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  };

  inputFieldContainer = () => {
    const { activeTab } = this.state;
    return (
      <div className="container-fluid">
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1} className={classnames(["fade show"])}>
            <form onSubmit={this.submitHandler} noValidate>
              <div className="card">
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-group">Class Name</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <div className="input-group">
                          <select
                            value={this.state.input.class_id}
                            onChange={(e) =>
                              this.changeHandler("class_id", e.target.value)
                            }
                            required
                            className="form-control show-tick"
                          >
                            <option value="">Select Class</option>
                            {this.state.dropDownDataClass.map((ele) => (
                              <option value={ele.id}>{ele.name}</option>
                            ))}
                          </select>
                          <div className="invalid-feedback">
                            required class name field.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-group">Section Name</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <div className="input-group">
                          <select
                            value={this.state.input.section_id}
                            onChange={(e) =>
                              this.changeHandler("section_id", e.target.value)
                            }
                            disabled={!this.state.input.class_id}
                            required
                            className="form-control show-tick"
                          >
                            <option value="">Select Section</option>
                            {this.state.dropDownDataSection.map((ele) => (
                              <option value={ele.id}>{ele.name}</option>
                            ))}
                          </select>
                          <div className="invalid-feedback">
                            required section name field.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-group">Subject Name</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <div className="input-group">
                          <select
                            value={this.state.input.subject_id}
                            onChange={(e) =>
                              this.changeHandler("subject_id", e.target.value)
                            }
                            disabled={!this.state.input.section_id}
                            required
                            className="form-control show-tick"
                          >
                            <option value="">Select Subject</option>
                            {this.state.dropDownDataSubject.map((ele) => (
                              <option value={ele.id}>{ele.name}</option>
                            ))}
                          </select>
                          <div className="invalid-feedback">
                            required subject name field.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-group">Announcement Title</div>
                      </div>
                      <div className="col-lg-1 col-md-4 col-sm-6">
                        <div className="input-group">:</div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <div className="input-group">
                          <input
                            value={this.state.input.title}
                            placeholder="Announcement Title"
                            onChange={(e) =>
                              this.changeHandler("title", e.target.value)
                            }
                            type="text"
                            className="form-control"
                            required
                          />
                          <div className="invalid-feedback">
                            required title field.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 p-3">
                  <Ckeditor
                    content={this.state.input.content}
                    html={this.state.input.content}
                    onChange={(value) => {
                      this.changeHandler("content", value);
                    }}
                  />
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3 submitBtn">
                  <MDBBtn
                    color="primary"
                    className="btn"
                    onClick={this.clearData}
                  >
                    Close
                  </MDBBtn>
                  <MDBBtn color="primary" className="btn" type="submit">
                    Save
                  </MDBBtn>
                </div>
              </div>
            </form>
          </TabPane>
        </TabContent>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">
                  {" "}
                  {this.state.editId ? "Update" : "New"} Announcement
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.inputFieldContainer()}</div>
          {this.state.modal && this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createAnnouncementAction: (e,history) => dispatch(createAnnouncementAction(e,history)),
  editAnnouncementAction: (e,history) => dispatch(editAnnouncementAction(e,history)),
  getAnnouncementEditByIdAction: (e) =>
    dispatch(getAnnouncementEditByIdAction(e)),
  getClassAction: (e) => dispatch(getClassAction(e)),
  getSectionAction: (e) => dispatch(getSectionAction(e)),
  getSubjectAction: (e) => dispatch(getSubjectAction(e)),
});

export default connect(null, mapDispatchToProps)(CreateAnnouncement);
