import React, { Component } from "react";
import { connect } from "react-redux";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { CSVLink } from "react-csv";
import Loader from "../../common/Loader";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import {
  getFAQAction,
  searchFAQAction,
  deleteFAQAction,
  getFAQCategoryAction,
} from "../../../actions/Admin/ManageFAQ/manageFAQAction";
import "./style.scss";

class ManageFAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      tableBody: { columns: [], rows: [] },
      modalView: false,
      search: {},
      dropDownDataCategory: [],
      deleteModal: false,
      exportCsv: null,
      selectedData:{}
    };
  }

  componentDidMount() {
    this.props.getFAQAction().then((res) => {
      this.constructTableData();
      this.exportCsv();
    });
    this.props
      .getFAQCategoryAction()
      .then((res) => this.setState({ dropDownDataCategory: res }));
  }

  toggle = (item,institute, i) => () => {
    this.setState({
      modalView: true,
      selectedData:item
    });
  };

  toggleClose = () => {
    this.setState({
      modalView: false,
    });
  };

  handleSearch = (name, value) => {
    this.setState({ search: { ...this.state.search, [name]: value } });
  };

  openDeleteModal = (item) => {
    this.setState({
      deleteModal: true,
      selectedData:item
    });
  };

  handleDeleteFAQ = (id) => async () => {
    await this.props.deleteFAQAction(id);
    this.deleteToggleClose();
    this.props.getFAQAction().then((res) => {
      this.constructTableData();
    });
  };

  exportPdf = () => {
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0, 200, 0);
      pdf.save("ManageFAQ.pdf");
    });
  };

  exportCsv = () => {
    const exportCsvHeaders = [
      { label: "Title", key: "title" },
      { label: "Content", key: "content" },
      { label: "Category", key: "category" },
      { label: "Status", key: "status" },
    ];
    const exportCSVData = this.props.faqList.map((item) => {
      let obj = {
        title: item.title,
        content: this.extractContent(item.content),
        category: item.category_name,
        status: item.status === 1 ? "Active" : "Inactive",
      };
      return obj;
    });
    let csv = {
      data: exportCSVData,
      headers: exportCsvHeaders,
      filename: "manage-FAQ-report.csv",
      style: { color: "white" },
    };
    this.setState({
      exportCsv: csv,
    });
  };

  deleteToggleClose = () => {
    this.setState({
      deleteModal: false,
    });
  };

  searchHandler = async () => {
    let search = {
      title: this.state.search.title,
      category: this.state.search.category,
      status: this.state.search.status,
    };
    Object.keys(search).forEach(
      (key) => search[key] === "" && delete search[key]
    );
    await this.props.searchFAQAction(search);
    this.constructTableData();
  };

  clearHandler = async () => {
    this.setState({ search: {}, dropDownDataCategory: [] });
    await this.props.getFAQAction().then((res) => {
      this.constructTableData();
    });
    await this.props
      .getFAQCategoryAction()
      .then((res) => this.setState({ dropDownDataCategory: res }));
  };

  tableColumns = () => [
    {
      label: "#",
      field: "id",
    },
    {
      label: "Faq title",
      field: "title",
      width: 270,
    },
    {
      label: "Content",
      field: "content",
      width: 270,
    },
    {
      label: "Category",
      field: "category",
      width: 270,
    },
    {
      label: "Status",
      field: "status",
      width: 270,
    },
    {
      label: "Action",
      field: "action",
      width: 270,
    },
  ];

  constructTableData = () => {
    let tableData = {
      columns: this.tableColumns().filter((ele) => ele),
      rows: this.actionContainer(),
    };

    this.setState({
      tableBody: tableData,
    });
  };

  extractContent = (html) => {
    return new DOMParser().parseFromString(html, "text/html").documentElement
      .textContent;
  };

  actionContainer = () => {
    return this.props.faqList.map((item, i) => {
      let obj = {
        id: i + 1,
        title: item.title,
        // content: this.extractContent(item.content),
        content: <div dangerouslySetInnerHTML={{ __html: item.content }}></div>,
        category: item.category_name,
        status: item.status === 1 ? "Active" : "Inactive",
        action: (
          <div className="actionContainer">
            <MDBIcon
              icon="edit"
              size="lg"
              className="icon"
              onClick={() => {
                this.props.history.push(`/super-admin/edit-faq/${item.id}`);
              }}
            />
            <MDBIcon
              icon="eye"
              size="lg"
              className="icon"
              onClick={this.toggle(item, i)}
            />
            <MDBIcon
              icon="trash"
              size="lg"
              className="icon"
              onClick={() => {
                this.openDeleteModal(item);
              }}
            />
          </div>
        ),
      };
      return obj;
    });
  };

  viewModalDetails = (item) => {
    return (
      <>
        <div className="faq-modal-sub-container p-l-30">
          {!this.state.isLoggedInRole && (
            <div>
              <h6 className="font-weight-bold">Category</h6>
              <h6>{item.category_name}</h6>
            </div>
          )}
        </div>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Question</h6>
            <h6>{item.title}</h6>
          </div>
        </div>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Status</h6>
            <h6>{item.status === 1 ? "Active" : "Inactive"}</h6>
          </div>
        </div>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Add Content</h6>
            <h6 dangerouslySetInnerHTML={{ __html: item.content }}></h6>
          </div>
        </div>
      </>
    );
  };

  searchContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="input-group">
                  <input
                    type="text"
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search["title"]
                    }
                    onChange={(e) => this.handleSearch("title", e.target.value)}
                    className="form-control"
                    placeholder="Enter the title"
                  />
                </div>
              </div>
              <div className="col-lg-4 md-3 col-sm-12">
                <select
                  value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search["status"]
                  }
                  onChange={(e) => this.handleSearch("status", e.target.value)}
                  className="form-control show-tick"
                >
                  <option value="">Status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
              <div className="col-lg-4 md-3 col-sm-12">
                <select
                  // value={
                  //   Object.keys(this.state.search).length === 0
                  //     ? ""
                  //     : this.state.search['category']['name']
                  // }
                  onChange={(e) =>
                    this.handleSearch("category", e.target.value)
                  }
                  className="form-control show-tick"
                >
                  <option value="">Select Category</option>
                  {this.state.dropDownDataCategory.map((ele) => (
                    <option value={ele.name}>{ele.category_name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="btn-container">
              <div>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.searchHandler}
                >
                  Search
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.clearHandler}
                >
                  Clear
                </MDBBtn>
              </div>
              <div>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={() => {
                    this.props.history.push("/super-admin/create-faq");
                  }}
                >
                  Create
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.exportPdf}
                >
                  PDF <MDBIcon icon="download" size="lg" className="icon" />
                </MDBBtn>
                <MDBBtn color="primary" className="btn">
                  {this.state.exportCsv && (
                    <CSVLink {...this.state.exportCsv}>
                      CSV
                      <MDBIcon icon="download" size="lg" className="icon" />
                    </CSVLink>
                  )}
                </MDBBtn>
              </div>
            </div>
          </div>
        </div>
      </TabPane>
    );
  };

  tableContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body" id="capture">
            <h1 className="page-title">FAQ List</h1>
            {this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  searching={false}
                  sortRows={["title"]}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                />
              </div>
            )}
          </div>
        </div>
      </TabPane>
    );
  };

  modalContainer = () => {
    return (
      <div>
            <MDBModal
              isOpen={this.state.modalView}
              toggle={this.toggle}
            >
              <MDBModalHeader toggle={this.toggleClose}>
                View FAQ
              </MDBModalHeader>
              <MDBModalBody>{this.viewModalDetails(this.state.selectedData)}</MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggleClose}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
            <MDBModal
              isOpen={this.state.deleteModal}
              toggle={this.toggle}
            >
              <MDBModalHeader toggle={this.deleteToggleClose}>
                Alert
              </MDBModalHeader>
              <MDBModalBody>
                <h5>Are you sure you want to delete .?</h5>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.deleteToggleClose}>
                  No
                </MDBBtn>
                <MDBBtn
                  color="secondary"
                  onClick={this.handleDeleteFAQ(this.state.selectedData.id)}
                >
                  Yes
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
      </div>
    );
  };

  pageContainer = () => {
    const { activeTab } = this.state;
    return (
      <TabContent activeTab={activeTab}>
        {this.searchContainer()}
        {this.tableContainer()}
      </TabContent>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">Manage FAQ</h1>
                <ol className="breadcrumb page-breadcrumb">
                  <li className="breadcrumb-item">Search</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.pageContainer()}</div>
          {this.state.modalView && this.modalContainer()}
          {this.state.deleteModal && this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  faqList: state.getFAQReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getFAQAction: (e) => dispatch(getFAQAction(e)),
  searchFAQAction: (e) => dispatch(searchFAQAction(e)),
  deleteFAQAction: (e) => dispatch(deleteFAQAction(e)),
  getFAQCategoryAction: (e) => dispatch(getFAQCategoryAction(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageFAQ);
