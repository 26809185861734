import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import {
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import "./style.scss";
import {
  createTeacherUserAction,
  getTeacherUserEditByIdAction,
  editTeacherUserAction,
  createStudentUserAction,
  getStudentUserEditByIdAction,
  editStudentUserAction,
  createParentUserAction,
  getParentUserEditByIdAction,
  editParentUserAction,
  getStudentNoParentAction,
} from "../../../actions/institute/ManageUser/manageUserAction";
import {
  getAllSchoolAction,
  getClassAction,
  getSectionAction,
  getSubjectAction,
  getStateAction,
  getCitiesAction,
  getNewSubjectAction,
} from "../../../actions/institute/Utils/utilsAction";
class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      dropDownDataSchool: [],
      dropDownDataClass: [],
      dropDownDataSection: [],
      dropDownDataSubject: [],
      dropDownDataNewSubject: [],
      dropDownDataStudent: [],
      dropDownDataCity: [],
      dropDownDataState: [],
      input: {
        status: "1",
        user_type: this.props.match.params.type,
      },
      modal: false,
      editId: this.props.match.params.id,
      userType: this.props.match.params.type,
      isLoggedInRole:
        localStorage.getItem("loginedRole") === "principal" ? true : false,
    };
  }

  componentDidMount() {
    if (this.state.isLoggedInRole) {
      var localSchoolId = {
        school_id: JSON.parse(localStorage.getItem("school")).id,
      };
      this.props
        .getClassAction(localSchoolId)
        .then((res) => this.setState({ dropDownDataClass: res }));
    }

    if (this.props.match.params.id) {
      {
        this.state.userType === "Teacher" &&
          this.props
            .getTeacherUserEditByIdAction(this.props.match.params.id)
            .then((res) => this.setState({ input: res }));
      }
      {
        this.state.userType === "Student" &&
          this.props
            .getStudentUserEditByIdAction(this.props.match.params.id)
            .then((res) => this.setState({ input: res }));
      }
      {
        this.state.userType === "Parent" &&
          this.props
            .getParentUserEditByIdAction(this.props.match.params.id)
            .then((res) => this.setState({ input: res }));
      }
    }
    this.props
      .getAllSchoolAction()
      .then((res) => this.setState({ dropDownDataSchool: res }));
    this.props
      .getStateAction()
      .then((res) => this.setState({ dropDownDataState: res }));
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevState.input.school_id !== this.state.input.school_id) {
      if (this.state.input.school_id) {
        let search = {
          school_id: this.state.input.school_id,
        };
        this.props
          .getClassAction(search)
          .then((res) => this.setState({ dropDownDataClass: res }));
      }
    }
    if (prevState.input.section_id !== this.state.input.section_id) {
      if (this.state.userType === "Parent" && this.state.input.section_id) {
        let search = {
          section_id: this.state.input.section_id,
        };
        this.props
          .getStudentNoParentAction(search)
          .then((res) => this.setState({ dropDownDataStudent: res }));
      }
    }
    if (prevState.input.class_id !== this.state.input.class_id) {
      if (this.state.input.class_id) {
        let search = {
          class_id: this.state.input.class_id,
          school_id: this.state.input.school_id,
        };
        this.props
          .getSectionAction(search)
          .then((res) => this.setState({ dropDownDataSection: res }));
      }
    }
    if (prevState.input.section_id !== this.state.input.section_id) {
      if (this.state.input.section_id) {
        let search = {
          section_id: this.state.input.section_id,
          class_id: this.state.input.class_id,
          school_id: this.state.input.school_id,
        };
        if (this.state.editId) {
          this.props
            .getSubjectAction(search)
            .then((res) => this.setState({ dropDownDataSubject: res }));
        } else {
          this.props
            .getNewSubjectAction(search)
            .then((res) => this.setState({ dropDownDataNewSubject: res }));
        }
      }
    }
    if (prevState.input.state_id !== this.state.input.state_id) {
      if (this.state.input.state_id) {
        this.props
          .getCitiesAction(this.state.input.state_id)
          .then((res) => this.setState({ dropDownDataCity: res }));
      }
    }

    if (this.state.isLoggedInRole && this.state.input.school_id === undefined) {
      this.setState({
        input: {
          ...this.state.input,
          ["school_id"]: localStorage.getItem("school_id"),
        },
      });
    }
  }

  clearData = () => {
    this.setState({
      input: {},
      modal: false,
    });
    this.props.history.push("/institute/manage-user");
  };

  toggle = () => {
    this.setState({
      modal: true,
    });
  };

  toggleClose = () => {
    this.setState({
      modal: false,
    });
  };

  changeHandler = (name, value) => {
    this.setState({ input: { ...this.state.input, [name]: value } });
  };

  submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (event.target.checkValidity()) {
      this.setState({
        modal: true,
      });
    }
  };

  modalContainer = () => {
    return (
      <MDBContainer>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
          <MDBModalBody>
            <div className="alertContainer">
              <h4>
                {" "}
                Do you want to {this.state.editId ? "Update" : "save"} it ?{" "}
              </h4>
              <h6> Please check the information before submitting</h6>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              No
            </MDBBtn>
            <MDBBtn color="secondary" onClick={this.handleSave}>
              Yes
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  };

  handleSave = async () => {
    if (this.props.match.params.id) {
      {
        this.state.userType === "Teacher" &&
          (await this.props.editTeacherUserAction({
            id: this.props.match.params.id,
            data: this.state.input,
            history:this.props.history
          }));
      }
      {
        this.state.userType === "Student" &&
          (await this.props.editStudentUserAction({
            id: this.props.match.params.id,
            data: this.state.input,
            history:this.props.history
          }));
      }
      {
        this.state.userType === "Parent" &&
          (await this.props.editParentUserAction({
            id: this.props.match.params.id,
            data: this.state.input,
            history:this.props.history
          }));
      }
    } else {
      {
        this.state.userType === "Teacher" &&
          (await this.props.createTeacherUserAction(this.state.input,this.props.history));
      }
      {
        this.state.userType === "Student" &&
          (await this.props.createStudentUserAction(this.state.input,this.props.history));
      }
      {
        this.state.userType === "Parent" &&
          (await this.props.createParentUserAction(this.state.input,this.props.history));
      }
    }
  };

  inputFieldContainer = () => {
    const { activeTab } = this.state;
    return (
      <div className="container-fluid">
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1} className={classnames(["fade show"])}>
            <form onSubmit={this.submitHandler} noValidate>
              <div className="card">
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3 row">
                  <div className="col-lg-6 col-md-4 col-sm-6 ">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 ">
                          <div className="input-group">User Type </div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6 ">
                          <div className="input-group">:</div>
                        </div>
                        <div className="col-lg-8 col-md-4 col-sm-6 ">
                          <div className="input-group">
                            <select
                              value={this.state.input.user_type}
                              onChange={(e) =>
                                this.changeHandler("user_type", e.target.value)
                              }
                              required
                              className="form-control show-tick"
                            >
                              <option value={this.state.userType}>
                                {this.state.userType}
                              </option>
                            </select>
                            <div className="invalid-feedback">
                              required user type field.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-4 col-sm-6 ">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 ">
                          <div className="input-group">Email</div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6 ">
                          <div className="input-group">:</div>
                        </div>
                        <div className="col-lg-8 col-md-4 col-sm-6 ">
                          <div className="input-group">
                            <input
                              value={this.state.input.email}
                              placeholder="Email"
                              onChange={(e) =>
                                this.changeHandler("email", e.target.value)
                              }
                              type="email"
                              className="form-control"
                              required
                            />
                            <div className="invalid-feedback">
                              Enter a valid email address.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-4 col-sm-6 ">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 ">
                          <div className="input-group">Name</div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6 ">
                          <div className="input-group">:</div>
                        </div>
                        <div className="col-lg-8 col-md-4 col-sm-6 ">
                          <div className="input-group">
                            <input
                              value={this.state.input.name}
                              placeholder="Enter Name"
                              onChange={(e) =>
                                this.changeHandler("name", e.target.value)
                              }
                              type="text"
                              className="form-control"
                              required
                            />
                            <div className="invalid-feedback">
                              required Name field.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-4 col-sm-6 ">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 ">
                          <div className="input-group">Mobile</div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6 ">
                          <div className="input-group">:</div>
                        </div>
                        <div className="col-lg-8 col-md-4 col-sm-6 ">
                          <div className="input-group">
                            <input
                              value={this.state.input.mobile}
                              placeholder="Mobile Number"
                              onChange={(e) =>
                                this.changeHandler("mobile", e.target.value)
                              }
                              type="text"
                              className="form-control"
                              required
                            />
                            <div className="invalid-feedback">
                              required mobile number field.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!this.state.isLoggedInRole && (
                    <div className="col-lg-6 col-md-4 col-sm-6 ">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-3 col-md-4 col-sm-6 ">
                            <div className="input-group">School</div>
                          </div>
                          <div className="col-lg-1 col-md-4 col-sm-6 ">
                            <div className="input-group">:</div>
                          </div>
                          <div className="col-lg-8 col-md-4 col-sm-6 ">
                            <div className="input-group">
                              <select
                                value={this.state.input.school_id}
                                disabled={this.state.editId ? true : false}
                                onChange={(e) =>
                                  this.changeHandler(
                                    "school_id",
                                    e.target.value
                                  )
                                }
                                required
                                className="form-control show-tick"
                              >
                                <option value="">Select School</option>
                                {this.state.dropDownDataSchool.map((ele) => (
                                  <option value={ele.id}>{ele.name}</option>
                                ))}
                              </select>
                              <div className="invalid-feedback">
                                required schools field.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-lg-6 col-md-4 col-sm-6 ">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 ">
                          <div className="input-group">Class</div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6 ">
                          <div className="input-group">:</div>
                        </div>
                        <div className="col-lg-8 col-md-4 col-sm-6 ">
                          <div className="input-group">
                            <select
                              value={this.state.input.class_id}
                              onChange={(e) =>
                                this.changeHandler("class_id", e.target.value)
                              }
                              disabled={
                                this.state.editId
                                  ? true
                                  : !this.state.input.school_id
                              }
                              required
                              className="form-control show-tick"
                            >
                              <option value="">Select Class</option>
                              {this.state.dropDownDataClass.map((ele) => (
                                <option value={ele.id}>{ele.name}</option>
                              ))}
                            </select>
                            <div className="invalid-feedback">
                              required class field.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-4 col-sm-6 ">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 ">
                          <div className="input-group">Section</div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6 ">
                          <div className="input-group">:</div>
                        </div>
                        <div className="col-lg-8 col-md-4 col-sm-6 ">
                          <div className="input-group">
                            <select
                              value={this.state.input.section_id}
                              onChange={(e) =>
                                this.changeHandler("section_id", e.target.value)
                              }
                              disabled={
                                this.state.editId
                                  ? true
                                  : !this.state.input.class_id
                              }
                              required
                              className="form-control show-tick"
                            >
                              <option value="">Select Section</option>
                              {this.state.dropDownDataSection.map((ele) => (
                                <option value={ele.id}>{ele.name}</option>
                              ))}
                            </select>
                            <div className="invalid-feedback">
                              required section field.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.userType === "Teacher" && (
                    <div className="col-lg-6 col-md-4 col-sm-6 ">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-3 col-md-4 col-sm-6 ">
                            <div className="input-group">Select Subject</div>
                          </div>
                          <div className="col-lg-1 col-md-4 col-sm-6 ">
                            <div className="input-group">:</div>
                          </div>
                          <div className="col-lg-8 col-md-4 col-sm-6 ">
                            <div className="input-group">
                              <select
                                value={this.state.input.subject_id}
                                onChange={(e) =>
                                  this.changeHandler(
                                    "subject_id",
                                    e.target.value
                                  )
                                }
                                disabled={
                                  this.state.editId
                                    ? true
                                    : !this.state.input.section_id
                                }
                                required
                                className="form-control show-tick"
                              >
                                <option value="">Subject</option>
                                {this.state.editId
                                  ? this.state.dropDownDataSubject.map(
                                      (ele) => (
                                        <option value={ele.id}>
                                          {ele.name}
                                        </option>
                                      )
                                    )
                                  : this.state.dropDownDataNewSubject.map(
                                      (ele) => (
                                        <option value={ele.id}>
                                          {ele.name}
                                        </option>
                                      )
                                    )}
                              </select>
                              <div className="invalid-feedback">
                                required Subject Name field.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.userType === "Parent" && (
                    <div className="col-lg-6 col-md-4 col-sm-6 ">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-3 col-md-4 col-sm-6 ">
                            <div className="input-group">Student</div>
                          </div>
                          <div className="col-lg-1 col-md-4 col-sm-6 ">
                            <div className="input-group">:</div>
                          </div>
                          <div className="col-lg-8 col-md-4 col-sm-6 ">
                            <div className="input-group">
                              <select
                                value={this.state.input.student_id}
                                onChange={(e) =>
                                  this.changeHandler(
                                    "student_id",
                                    e.target.value
                                  )
                                }
                                disabled={
                                  this.state.editId
                                    ? true
                                    : !this.state.input.section_id
                                }
                                required
                                className="form-control show-tick"
                              >
                                <option value="">Select Student</option>
                                {this.state.dropDownDataStudent.map((ele) => (
                                  <option value={ele.id}>{ele.name}</option>
                                ))}
                              </select>
                              <div className="invalid-feedback">
                                required Student Name field.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-lg-6 col-md-4 col-sm-6 ">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 ">
                          <div className="input-group">Address 1</div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6 ">
                          <div className="input-group">:</div>
                        </div>
                        <div className="col-lg-8 col-md-4 col-sm-6 ">
                          <div className="input-group">
                            <input
                              value={this.state.input.address_one}
                              placeholder="Address 1"
                              onChange={(e) =>
                                this.changeHandler(
                                  "address_one",
                                  e.target.value
                                )
                              }
                              type="text"
                              className="form-control"
                              required
                            />
                            <div className="invalid-feedback">
                              required Address 1 field.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-4 col-sm-6 ">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 ">
                          <div className="input-group">Address 2</div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6 ">
                          <div className="input-group">:</div>
                        </div>
                        <div className="col-lg-8 col-md-4 col-sm-6 ">
                          <div className="input-group">
                            <input
                              value={this.state.input.address_two}
                              placeholder="Address 2"
                              onChange={(e) =>
                                this.changeHandler(
                                  "address_two",
                                  e.target.value
                                )
                              }
                              type="text"
                              className="form-control"
                            />
                            <div className="invalid-feedback">
                              required Address 2 field.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-4 col-sm-6 ">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 ">
                          <div className="input-group">State </div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6 ">
                          <div className="input-group">:</div>
                        </div>
                        <div className="col-lg-8 col-md-4 col-sm-6 ">
                          <div className="input-group">
                            <select
                              value={this.state.input.state_id}
                              onChange={(e) =>
                                this.changeHandler("state_id", e.target.value)
                              }
                              required
                              className="form-control show-tick"
                            >
                              <option value="">State</option>
                              {this.state.dropDownDataState.map((ele) => (
                                <option value={ele.id}>{ele.name}</option>
                              ))}
                            </select>
                            <div className="invalid-feedback">
                              required state field.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-4 col-sm-6 ">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 ">
                          <div className="input-group">City</div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6 ">
                          <div className="input-group">:</div>
                        </div>
                        <div className="col-lg-8 col-md-4 col-sm-6 ">
                          <div className="input-group">
                            <select
                              value={this.state.input.city_id}
                              disabled={!this.state.input.state_id}
                              onChange={(e) =>
                                this.changeHandler("city_id", e.target.value)
                              }
                              required
                              className="form-control show-tick"
                            >
                              <option value="">City</option>
                              {this.state.dropDownDataCity.map((ele) => (
                                <option value={ele.id}>{ele.name}</option>
                              ))}
                            </select>
                            <div className="invalid-feedback">
                              required city field.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-4 col-sm-6 ">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 ">
                          <div className="input-group">UserName</div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6 ">
                          <div className="input-group">:</div>
                        </div>
                        <div className="col-lg-8 col-md-4 col-sm-6 ">
                          <div className="input-group">
                            <input
                              value={this.state.input.username}
                              placeholder="username"
                              onChange={(e) =>
                                this.changeHandler("username", e.target.value)
                              }
                              type="text"
                              className="form-control"
                              required
                            />
                            <div className="invalid-feedback">
                              required User Name field.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-4 col-sm-6 ">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 ">
                          <div className="input-group">ZIP</div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6 ">
                          <div className="input-group">:</div>
                        </div>
                        <div className="col-lg-8 col-md-4 col-sm-6 ">
                          <div className="input-group">
                            <input
                              value={this.state.input.zip}
                              placeholder="ZIP"
                              onChange={(e) =>
                                this.changeHandler("zip", e.target.value)
                              }
                              type="number"
                              className="form-control"
                              required
                            />
                            <div className="invalid-feedback">
                              required ZIP field.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-4 col-sm-6 ">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6">
                          <div className="input-group">Status</div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6">
                          <div className="input-group">:</div>
                        </div>
                        <div className="col-lg-8 col-md-4 col-sm-6">
                          <div className="input-group">
                            <select
                              value={this.state.input.status}
                              onChange={(e) =>
                                this.changeHandler("status", e.target.value)
                              }
                              required
                              className="form-control show-tick"
                            >
                              <option value="1">Active</option>
                              <option value="0">Inactive</option>
                            </select>
                            <div className="invalid-feedback">
                              required Status field.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!this.state.editId && (
                    <div className="col-lg-6 col-md-4 col-sm-6 ">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-3 col-md-4 col-sm-6 ">
                            <div className="input-group">Password</div>
                          </div>
                          <div className="col-lg-1 col-md-4 col-sm-6 ">
                            <div className="input-group">:</div>
                          </div>
                          <div className="col-lg-8 col-md-4 col-sm-6 ">
                            <div className="input-group">
                              <input
                                value={this.state.input.password}
                                placeholder="password"
                                onChange={(e) =>
                                  this.changeHandler("password", e.target.value)
                                }
                                type="password"
                                className="form-control"
                                required
                              />
                              <div className="invalid-feedback">
                                required password field.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 submitBtn">
                  <MDBBtn
                    color="primary"
                    className="btn"
                    onClick={this.clearData}
                  >
                    Close
                  </MDBBtn>
                  <MDBBtn color="primary" className="btn" type="submit">
                    Save
                  </MDBBtn>
                </div>
              </div>
            </form>
          </TabPane>
        </TabContent>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">
                  {" "}
                  {this.state.editId ? "Update" : "Create"} User
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.inputFieldContainer()}</div>
          {this.state.modal && this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createTeacherUserAction: (e,history) => dispatch(createTeacherUserAction(e,history)),
  getTeacherUserEditByIdAction: (e) =>
    dispatch(getTeacherUserEditByIdAction(e)),
  editTeacherUserAction: (e,history) => dispatch(editTeacherUserAction(e,history)),
  createStudentUserAction: (e,history) => dispatch(createStudentUserAction(e,history)),
  getStudentUserEditByIdAction: (e) =>
    dispatch(getStudentUserEditByIdAction(e)),
  editStudentUserAction: (e,history) => dispatch(editStudentUserAction(e,history)),
  createParentUserAction: (e,history) => dispatch(createParentUserAction(e,history)),
  getParentUserEditByIdAction: (e) => dispatch(getParentUserEditByIdAction(e)),
  editParentUserAction: (e,history) => dispatch(editParentUserAction(e,history)),
  getAllSchoolAction: (e) => dispatch(getAllSchoolAction(e)),
  getClassAction: (e) => dispatch(getClassAction(e)),
  getSectionAction: (e) => dispatch(getSectionAction(e)),
  getSubjectAction: (e) => dispatch(getSubjectAction(e)),
  getNewSubjectAction: (e) => dispatch(getNewSubjectAction(e)),
  getStudentNoParentAction: (e) => dispatch(getStudentNoParentAction(e)),
  getStateAction: (e) => dispatch(getStateAction(e)),
  getCitiesAction: (e) => dispatch(getCitiesAction(e)),
});

export default connect(null, mapDispatchToProps)(CreateUser);
