import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import moment from 'moment'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CSVLink } from "react-csv";
import { MDBDataTable, MDBBtn, MDBIcon, MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import Dropzone from '../../common/DropzoneExample';
import Loader from "../../common/Loader";
import { isLogined } from '../../../utils/token'
import './style.scss';
import { searchPrincipalAction,uploadPrincipalFileAction,clearUploadPrincipalFileAction,getPrincipalNameAction } from '../../../actions/institute/ManagePrincipal/managePrincipalAction';
import {getSchoolAction} from '../../../actions/institute/Utils/utilsAction'

const csvSampleTempleteHeaders = [
	{ label: "school_name", key: "school_name" },
	{ label: "principal_name", key: "principal_name" },
	{ label: "email", key: "email" },
	{ label: "mobile", key: "mobile" },
	{ label: "address_one", key: "address_one" },
	{ label: "address_two", key: "address_two" },
	{ label: "city", key: "city" },
	{ label: "state", key: "state" },
	{ label: "zip", key: "zip" },
	{ label: "username", key: "username" },
	{ label: "password", key: "password" },
	{ label: "status", key: "status" },
  ];
   
  const csvSampleTempleteData = [
	{ school_name: "xyz", principal_name: "xyz",email: "xyz@gmail.com",mobile:"1234567890", address_one: "xyz", address_two: "xyz", 
	city:"xyz", state: "xyz",zip:'600000', username: "xyz",password:"xyz", status:1},
  ];

  let  csvSampleTemplete = {
	data: csvSampleTempleteData,
	headers: csvSampleTempleteHeaders,
	filename: 'sample-principal.csv',
  };
  

class ManagePrincipal extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			show: false,
			tableBody : { columns :[], rows: []},
			selectedPrincipal: null,
			uploadModal: false,
			exportCsv:null,

			school_name:'',
			principal_name: '',
			status: '',
			createdAt: '',
			
			uploadFile:null,
			uploadFileStatus: null,
			principalList:[],
			principalNameList: [],
			schoolList:[]
		};
	}

	componentWillReceiveProps(nextProps){
		if(this.state.uploadFileStatus !== nextProps.uploadFileStatus){
			this.setState({
				uploadFileStatus : nextProps.uploadFileStatus
			},()=>{
				if(this.state.uploadFileStatus && this.state.uploadFileStatus.errcode === 0){
					this.props.searchPrincipalAction()
					this.uploadToggleClose()
				}
			})
		}

		if(this.state.principalList !== nextProps.principalList){
			this.setState({
				principalList: nextProps.principalList
			},()=>{
				this.constructTableData()
				this.exportCsv()
			})
		}
		if(this.state.principalNameList !== nextProps.principalNameList){
			this.setState({
				principalNameList: nextProps.principalNameList
			})
		}
		if(this.state.schoolList !== nextProps.schoolList){
			this.setState({
				schoolList: nextProps.schoolList
			})
		}
	}

	componentDidMount(){
		this.props.searchPrincipalAction()
		this.props.getPrincipalNameAction()
		this.props.getSchoolAction()
	}

	uploadToggle = () =>{
		this.setState({
			uploadModal: true,
		});
	}
	
	uploadToggleClose = ()=>{
		this.props.clearUploadPrincipalFileAction()
		this.setState({
			uploadModal: false,
			uploadFile: null,
		})
	}

	exportPdf = () => {
		html2canvas(document.querySelector("#capture")).then(canvas => {
		   const imgData = canvas.toDataURL('image/png');
		   const pdf = new jsPDF();
		   pdf.addImage(imgData, 'PNG', 0, 0,200,0);
		   pdf.save("principal.pdf"); 
	   }); 
	}

	exportCsv =()=> {
		const exportCsvHeaders = [
			{ label: "school_name", key: "school_name" },
			{ label: "principal_name", key: "principal_name" },
			{ label: "createdAt", key: "createdAt" },
			{ label: "status", key: "status" },
		];
		const exportCSVData = this.state.principalList.map((item)=>{
			let obj = {
				school_name:item.school_name,
				principal_name: item.principal_name,
				createdAt: item.createdAt,
				status: item.status ? 'Active' : 'Inactive'
			}
			return obj
		})
		let csv = {
			data: exportCSVData,
			headers: exportCsvHeaders,
			filename: 'Principal-report.csv',
			style: {color:'white'}
		};
		this.setState({
			exportCsv: csv
		})
	}

	handleSearch =(event)=>{
		this.setState({ [event.target.name]:event.target.value });
	}

	handleDate =(value)=>{
		this.setState({ createdAt:value });
    }

	handleSearchStatus = (event)=>{
		this.setState({ [event.target.name]:event.target.value });
    }

	searchHandler =()=>{
		let search = {
			school_id:this.state.school_id,
			principal_id: this.state.principal_id,
			status: this.state.status ,
			createdAt: this.state.createdAt ? moment(this.state.createdAt, 'DD/MM/YYYY').format("YYYY-MM-DD") : ''
		}
		Object.keys(search).forEach((key) => (search[key] == '' || search[key] == undefined) && delete search[key]);
		this.props.searchPrincipalAction(search)
	}

	searchClear =()=>{
		this.setState({
			school_id: '',
			status: '',
			createdAt: '',
			principal_id: '',
		},()=>{
			this.props.searchPrincipalAction()
		})
	}
	

	handleFile=(event)=>{
        this.setState({uploadFile : event.target.files[0]})
    }

	handleUploadFile = event => {
		event.preventDefault();
		event.target.className += " was-validated";
        if(this.state.uploadFile){
			this.props.uploadPrincipalFileAction(this.state.uploadFile)
        }
	}

	constructTableData =()=>{
		let tableData = {
			columns: [
				{
					label: '#',
					field: 'id',
				},
				{
					label: 'School Name',
					field: 'school_name',
					width: 150,
					attributes: {
						'aria-controls': 'DataTable',
						'aria-label': 'Name',
					},
				},
				{
					label: 'Principal Name',
					field: 'principal_name',
					width: 270,
				},
				
				{
					label: 'Created Date',
					field: 'createdDate',
					width: 270,
				},
				
				{
					label: 'Status',
					field: 'status',
					width: 270,
				},
				{
					label: 'Action',
					field: 'action',
					width: 270,
				},
			],
			rows: this.actionContainer()			
		}	
		this.setState({
			tableBody: tableData
		})	
	}

	actionContainer =()=>{
		return this.state.principalList.map((item,i)=>{
			let obj = {
				id: i+1,
				school_name: item.school_name,
				principal_name: item.principal_name,
				createdDate:item.createdAt,
				status: item.status ? (<div style={{color:'green'}}>Active</div>) : <div style={{color:'red'}}>Inactive</div>,
				action: (<div className ='actionContainer'>
					<MDBIcon  icon="edit" size="lg" className='icon' onClick={()=>{
						this.props.history.push(`/institute/edit-principal/${item.id}`)
					}} />
					<MDBIcon  icon="eye" size="lg" className='icon' onClick={()=>{
						this.props.history.push(`/institute/view-principal/${item.id}`)
					}} />
				</div>)
			}
			return obj
		})
	}

	getPrincipalDetails = ()=>{
		return(
			<div className="card-body">
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Institutes name
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedPrincipal.institute_Name}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Address 1
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedPrincipal.address1}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Address 2
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedPrincipal.address2}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							City
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedPrincipal.city}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							State
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedPrincipal.state}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Zip
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedPrincipal.zip}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Contact Person
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedPrincipal.contact_Person}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Email
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedPrincipal.email}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Mobile
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedPrincipal.mobile}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							User name
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedPrincipal.username}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							License Expiry
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{moment(new Date(this.state.selectedPrincipal.licency_expiry), 'DD/MM/YYYY').format("DD MMM YYYY")}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Status
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedPrincipal.isActive ? 'Active' : 'Inactive'}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Institute URL
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							<a href ={this.state.selectedPrincipal.domain_url} target="_blank" >{this.state.selectedPrincipal.domain_url}</a>
						</div>
					</div>
				</div>
			</div>		
		)
	}

	searchContainer =()=>{
		return(
			<TabPane tabId={1} className={classnames(['fade show'])}>
				<div className="card">
					<div className="card-body">
						<div className="row">
							<div className="col-lg-3 col-md-2 col-sm-12">
								<select name ='school_id' value={this.state.school_id} onChange={this.handleSearch} className="form-control show-tick">
									<option value="">School Name</option>
									{this.state.schoolList.map((item,i)=>{
										return (<option value={item.id}>{item.name}</option>)
									})}
								</select>
							</div>
							<div className=" col-lg-3 col-md-2 col-sm-12">
								<select name ='principal_id' value={this.state.principal_id} onChange={this.handleSearch} className="form-control show-tick">
									<option value="">Principal Name</option>
									{this.state.principalNameList.map((item,i)=>{
										return (<option value={item.id}>{item.name}</option>)
									})}
								</select>
							</div>
							<div className="col-lg-3 col-md-2 col-sm-12">
								<select name ='status' value={this.state.status} onChange={this.handleSearchStatus} className="form-control show-tick">
									<option value="">Status</option>
									<option value={1}>Active</option>
									<option value={0}>Inactive</option>
								</select>
							</div>
							<div className="col-lg-3 col-md-4 col-sm-6">
								<div className="input-group">
									<DatePicker
										selected={this.state.createdAt}
										onChange={this.handleDate}
										placeholderText="Date"
										className="form-control"
									/>
								</div>
							</div>
						</div>
							<div className="btn-container">
									<div>
										<MDBBtn color="primary" className='btn' onClick={this.searchHandler}>Search</MDBBtn>
										<MDBBtn color="primary" className='btn' onClick={this.searchClear}>Clear</MDBBtn>
									</div>
											<div>
													<MDBBtn color="primary" className='btn' onClick={()=>{
														this.props.history.push('/institute/create-principal')
													}}>Create</MDBBtn>
													<MDBBtn color="primary" className='btn' onClick={()=>{
														this.uploadToggle()
													}}>Bulk Upload</MDBBtn>
													<MDBBtn color="primary" className='btn' onClick={this.exportPdf}>PDF <MDBIcon  icon="download" size="lg" className='icon' /></MDBBtn>
													<MDBBtn color="primary" className='btn'>
														{this.state.exportCsv &&(
															<CSVLink {...this.state.exportCsv}>CSV<MDBIcon  icon="download" size="lg" className='icon' /></CSVLink>
														)}
													</MDBBtn>
											</div>
								</div>
					</div>
				</div>
			</TabPane>
		)
	}

	tableContainer =()=>{
		return(
			<TabPane tabId={1} className={classnames(['fade show'])}>
				<div className="card" >
					<div className="card-body"  id="capture" >
					<h1 className="page-title">User List</h1>
					{this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={['title']}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching = {false}
                />
              </div>
            )}
					</div>
				</div>
			</TabPane>
		)
	}

	modalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={this.state.uploadModal} toggle={this.uploadToggle}>
					<MDBModalHeader toggle={this.uploadToggleClose}>Upload your files</MDBModalHeader>
					<form onSubmit={this.handleUploadFile} noValidate>
						<MDBModalBody>
						<div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Upload Files
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                :
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="">
                                                <input type="file" className="form-control"
                                                    onChange={this.handleFile}  required accept = ".csv"/>
                                                <div className="invalid-feedback">
                                                    required file.
                                                </div>
                                                <div className="">
                                                    {this.state.file ? '' : this.state.url}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
							<small id="fileHelp" className="form-text text-muted dowloadLable">	
								<div style={{'color':'red', marginBottom: '10px'}}>
									{this.state.uploadFileStatus ? this.state.uploadFileStatus.msg +' *' : ''}
								</div>
								<MDBBtn color="primary">
                    <CSVLink style={{color:'white'}} {...csvSampleTemplete}>
                      Download Sample Template
                      <MDBIcon icon="download" size="lg" className="icon" />
                    </CSVLink>
                </MDBBtn>
							</small>
						</MDBModalBody>
						<MDBModalFooter>
							<MDBBtn color="primary" type="submit">Upload</MDBBtn>
							<MDBBtn color="secondary" onClick={this.uploadToggleClose}>Close</MDBBtn>
						</MDBModalFooter>
						</form>
				</MDBModal>
			</MDBContainer>
		)
	}

	pageContainer =()=> {
		const { activeTab } = this.state
		return(
			<TabContent activeTab={activeTab}>
				{this.searchContainer()}
				{this.tableContainer()}
			</TabContent>
		)
	}

	render() {
		if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Manage Principal</h1>
								<ol className="breadcrumb page-breadcrumb">
									<li className="breadcrumb-item">Search</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-4">
					<div className="container-fluid">
						{this.pageContainer()}
					</div>
					{this.state.uploadModal ? this.modalContainer() : ''}
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	principalList :  state.managePrincipalReducer.list,
	uploadFileStatus: state.managePrincipalReducer.uploadFileStatus,
	principalNameList : state.managePrincipalReducer.principalNameList,
	schoolList: state.utillReducer.schoolList
})

const mapDispatchToProps = dispatch => ({
	searchPrincipalAction: (e) => dispatch(searchPrincipalAction(e)),
	uploadPrincipalFileAction: (e) => dispatch(uploadPrincipalFileAction(e)),
	clearUploadPrincipalFileAction: (e) => dispatch(clearUploadPrincipalFileAction(e)),
	getPrincipalNameAction: (e) => dispatch(getPrincipalNameAction(e)),
	getSchoolAction: (e) => dispatch(getSchoolAction(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ManagePrincipal)
