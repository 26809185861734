import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import Loader from "../../common/Loader";
import moment from "moment";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { searchStudentAchievementsAction } from "../../../actions/institute/StudentsAchievement/studentAcheivementAction";
import "./style.scss";
import {
  getAllSchoolAction,
  getClassAction,
  getSectionAction,
  getSubjectAction,
} from "../../../actions/institute/Utils/utilsAction";

class StudentsAchievements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      tableBody: { columns: [], rows: [] },
      modalId: null,
      search: {},
      dropDownDataClass: [],
      dropDownDataSection: [],
      dropDownDataSchool: [],
      dropDownDataSubject: [],
      isLoggedInRole:
      localStorage.getItem("loginedRole") === "principal" ? true : false,
    };
  }

  componentDidMount() {
    let search = {};
    this.props.searchStudentAchievementsAction(search).then((res) => {
      this.constructTableData();
    });
    this.props
      .getAllSchoolAction()
      .then((res) => this.setState({ dropDownDataSchool: res }));
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevState.search.school_id !== this.state.search.school_id) {
      if (this.state.search.school_id) {
        let search = {
          school_id: this.state.search.school_id,
        };
        this.props
          .getClassAction(search)
          .then((res) => this.setState({ dropDownDataClass: res }));
      }
    }
    if (prevState.search.class_id !== this.state.search.class_id) {
      if (this.state.search.class_id) {
        let search = {
          class_id: this.state.search.class_id,
        };
        this.props
          .getSectionAction(search)
          .then((res) => this.setState({ dropDownDataSection: res }));
      }
    }
    if (prevState.search.section_id !== this.state.search.section_id) {
      if (this.state.search.section_id) {
        let search = {
          section_id: this.state.search.section_id,
        };
        this.props
          .getSubjectAction(search)
          .then((res) => this.setState({ dropDownDataSubject: res }));
      }
    }
    if (
      this.state.isLoggedInRole &&
      this.state.search.school_id === undefined
    ) {
      this.setState({
        search: {
          ...this.state.search,
          ["school_id"]: localStorage.getItem("school_id"),
        },
      });
    }
  }

  toggle = (institute, i) => () => {
    this.setState({
      modalId: i,
    });
  };

  toggleClose = () => {
    this.setState({
      modalId: null,
    });
  };

  handleSearch = (name, value) => {
    this.setState({ search: { ...this.state.search, [name]: value } });
  };

  searchHandler = async () => {
    let search = {
      school_id: this.state.search.school_id,
      class_id: this.state.search.class_id,
      section_id: this.state.search.section_id,
      subject_id: this.state.search.subject_id,
      rule_for: this.state.search.rule_for,
      year: this.state.search.year
        ? moment(this.state.search.year).format("YYYY")
        : null,
    };
    Object.keys(search).forEach(
      (key) => search[key] == "" && delete search[key]
    );
    await this.props.searchStudentAchievementsAction(search);
    this.constructTableData();
  };

  clearHandler = async () => {
    this.setState({
      search: {},
      dropDownDataClass: [],
      dropDownDataSection: [],
      dropDownDataSchool: [],
      dropDownDataSubject: [],
    });
    await this.props.searchStudentAchievementsAction().then((res) => {
      this.constructTableData();
    });
    await this.props
      .getAllSchoolAction()
      .then((res) => this.setState({ dropDownDataSchool: res }));
  };

  tableColumns = () => [
    {
      label: "#",
      field: "id",
    },
    !this.state.isLoggedInRole && {
      label: "School Name",
      field: "school",
      width: 270,
    },
    {
      label: "Student Name",
      field: "student",
      width: 270,
    },
    {
      label: "Class Name",
      field: "class",
      width: 270,
    },
    {
      label: "Section Name",
      field: "section",
      width: 270,
    },
    {
      label: "Subject",
      field: "subject",
      width: 270,
    },
    {
      label: "Session",
      field: "session",
      width: 270,
    },
    {
      label: "Examination Name",
      field: "exam_name",
      width: 270,
    },
    {
      label: "Examination Date",
      field: "exam_date",
      width: 270,
    },
    {
      label: "Examination %",
      field: "exam_precentage",
      width: 270,
    },
    {
      label: "Achievement",
      field: "achievement",
      width: 270,
    },
    {
      label: "Rewards",
      field: "action",
      width: 270,
    },
  ];

  constructTableData = () => {
    let tableData = {
      columns: this.tableColumns().filter((ele) => ele),
      rows: this.actionContainer(),
    };

    this.setState({
      tableBody: tableData,
    });
  };

  actionContainer = () => {
    return this.props.studentAchievementsList.map((item, i) => {
      let obj = {
        id: i + 1,
        school: item.school_name,
        student: item.student_name,
        class: item.class_name,
        section: item.section_name,
        subject: item.subject_name,
        session: item.year,
        exam_name: item.name,
        exam_date: item.exam_date,
        exam_precentage: item.percentage,
        achievement: item.badge ? <img src={item.badge} alt="badge" width="20%"/> : '-',
        action: (
          <div className="actionContainer">
            <MDBIcon
              icon="eye"
              size="lg"
              className="icon"
              onClick={this.toggle(item, i)}
            />
          </div>
        ),
      };
      return obj;
    });
  };

  viewModalDetails = (item) => {
    return (
      <>
       <div className="faq-modal-sub-container p-l-30">
       {!this.state.isLoggedInRole &&   <div>
              <h6 className="font-weight-bold">School Name</h6>
              <h6>{item.school_name}</h6>
            </div>
        }
          <div>
            <h6 className="font-weight-bold">Student Name</h6>
            <h6>{item.student_name}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Class Name</h6>
            <h6>{item.class_name}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Section Name</h6>
            <h6>{item.section_name}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Subject Name</h6>
            <h6>{item.subject_name}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Examination Name</h6>
            <h6>{item.name}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Examination Date</h6>
            <h6>{item.exam_date}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Percentage</h6>
            <h6>{item.percentage}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Session</h6>
            <h6>{item.year}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Reward</h6>
            <h6>{item.badge ? <img src={item.badge} alt="badge" width="20%"/> : '-'}</h6>
          </div>
        </div>
      </>
    );
  };

  searchContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body">
            <div className="row">
            {!this.state.isLoggedInRole && <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="input-group">
                  <select
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search.school_id
                    }
                    onChange={(e) =>
                      this.handleSearch("school_id", e.target.value)
                    }
                    className="form-control show-tick"
                  >
                    <option value="">School Name</option>
                    {this.state.dropDownDataSchool.map((ele) => (
                      <option value={ele.id}>{ele.name}</option>
                    ))}
                  </select>
                </div>
              </div>}
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="input-group">
                  <select
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search.class_id
                    }
                    onChange={(e) =>
                      this.handleSearch("class_id", e.target.value)
                    }
                    disabled={!this.state.search.school_id}
                    className="form-control show-tick"
                  >
                    <option value="">Select Class</option>
                    {this.state.dropDownDataClass.map((ele) => (
                      <option value={ele.id}>{ele.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="input-group">
                  <select
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search.section_id
                    }
                    onChange={(e) =>
                      this.handleSearch("section_id", e.target.value)
                    }
                    disabled={!this.state.search.class_id}
                    className="form-control show-tick"
                  >
                    <option value="">Select Section</option>
                    {this.state.dropDownDataSection.map((ele) => (
                      <option value={ele.id}>{ele.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="input-group">
                  <select
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search.subject_id
                    }
                    onChange={(e) =>
                      this.handleSearch("subject_id", e.target.value)
                    }
                    className="form-control show-tick"
                    disabled={!this.state.search.section_id}
                  >
                    <option value="">Subject</option>
                    {this.state.dropDownDataSubject.map((ele) => (
                      <option value={ele.id}>{ele.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div
                className={`col-lg-3 md-3 col-sm-12 ${
                  !this.state.isLoggedInRole ? "mt-10" : ""
                }`}
              >
                <select
                  value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search.rule_for
                  }
                  onChange={(e) =>
                    this.handleSearch("rule_for", e.target.value)
                  }
                  className="form-control show-tick"
                >
                  <option value="">Select Type</option>
                  <option value="Assignment">Assignment</option>
                  <option value="Examination">Examination</option>
                </select>
              </div>
              <div className="col-lg-3 md-3 col-sm-12 mt-10">
                <DatePicker
                  name="year"
                  selected={this.state.search.year}
                  onChange={(value) => this.handleSearch("year", value)}
                  placeholderText="Select Year"
                  className="form-control"
                  showYearPicker
                  dateFormat="yyyy"
                />
              </div>
            </div>
            <div className={`row`}>
              <div className="card-body cardBodyContainer">
                <div className={`row btnContainer`}>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <MDBBtn
                      color="primary"
                      className="btn"
                      onClick={this.searchHandler}
                    >
                      Search
                    </MDBBtn>{" "}
                    &nbsp;
                    <MDBBtn
                      color="primary"
                      className="btn"
                      onClick={this.clearHandler}
                    >
                      Clear
                    </MDBBtn>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TabPane>
    );
  };

  tableContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body" id="capture">
            <h1 className="page-title">Achievements List</h1>
            {this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  searching={false}
                  sortRows={["title"]}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                />
              </div>
            )}
          </div>
        </div>
      </TabPane>
    );
  };

  modalContainer = () => {
    return (
      <div>
        {this.props.studentAchievementsList.map((item, i) => {
          return (
            <MDBModal
              isOpen={this.state.modalId === i ? true : false}
              toggle={this.toggle}
            >
              <MDBModalHeader toggle={this.toggleClose}>
                View Rewards
              </MDBModalHeader>
              <MDBModalBody>{this.viewModalDetails(item)}</MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggleClose}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          );
        })}
      </div>
    );
  };

  pageContainer = () => {
    const { activeTab } = this.state;
    return (
      <TabContent activeTab={activeTab}>
        {this.searchContainer()}
        {this.tableContainer()}
      </TabContent>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">Students Achievements</h1>
                <ol className="breadcrumb page-breadcrumb">
                  <li className="breadcrumb-item">Search</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.pageContainer()}</div>
          {this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  studentAchievementsList: state.getStudentAchievements,
});

const mapDispatchToProps = (dispatch) => ({
  searchStudentAchievementsAction: (e) =>
    dispatch(searchStudentAchievementsAction(e)),
  getAllSchoolAction: (e) => dispatch(getAllSchoolAction(e)),
  getClassAction: (e) => dispatch(getClassAction(e)),
  getSectionAction: (e) => dispatch(getSectionAction(e)),
  getSubjectAction: (e) => dispatch(getSubjectAction(e)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentsAchievements);
