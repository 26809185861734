import { GET_RECEIPTS, 
    CREATE_RECEIPTS,
    EDIT_RECEIPTS, 
    GET_RECEIPTS_BY_ID, 
    SHOW_SNACKBAR,
    GET_INVOICE_NUM } from '../../Constants/index';
import axios from 'axios';
import { manageReceipts } from '../../../utils/Api'
import { getToken } from '../../../utils/token'

export const getReceiptsAction = (params) => {
	return dispatch => {
        const url =  `${manageReceipts.getReceipts}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_RECEIPTS,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_RECEIPTS,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearGetReceiptsAction = () => {
	return dispatch => {
        dispatch({
            type: GET_RECEIPTS,
            payload: {
                list : []
            }
        })
    }
}

export const createReceiptsAction = ({data, history}) => {
	return dispatch => {
        const url = `${manageReceipts.createReceipts}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: CREATE_RECEIPTS,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                 history.push("/super-admin/manage-receipts");
            }else {
                dispatch({
                    type: CREATE_RECEIPTS,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const clearCreateReceiptsAction = () => {
	return dispatch => {
        dispatch({
            type: CREATE_RECEIPTS,
            payload: {
                list : null
            }
        })
    }
}

export const editReceiptsAction = ({id,data,history}) => {
	return dispatch => {
        const url = `${manageReceipts.editReceipts(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PATCH',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: EDIT_RECEIPTS,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                 history.push("/super-admin/manage-receipts");
            }else {
                dispatch({
                    type: EDIT_RECEIPTS,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const clearEditReceiptsAction = () => {
	return dispatch => {
        dispatch({
            type: EDIT_RECEIPTS,
            payload: {
                list : null
            }
        })
    }
}

export const getReceiptsByIdAction = (id) => {
	return dispatch => {
        const url = `${manageReceipts.getReceiptsById(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_RECEIPTS_BY_ID,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_RECEIPTS_BY_ID,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearReceiptsByIdAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_RECEIPTS_BY_ID,
            payload: {
                list : {}
            }
        })
    }
}

export const getInvoiceNumAction = () => {
	return dispatch => {
        const url = `${manageReceipts.getInvoiceNum}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_INVOICE_NUM,
                    payload: {
                        number : data 
                    }
                })
            }else {
                dispatch({
                    type: GET_INVOICE_NUM,
                    payload: {
                        number : null
                    }
                })
            }
        })
    }
}