import React, { Component } from "react";
import { connect } from "react-redux";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { CSVLink } from "react-csv";
import Loader from "../../common/Loader";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBInput,
} from "mdbreact";
import {
  getSupportAdminAction,
  searchAdminSupportAction,
  getInstituteNameAction,
  getUserNameAction,
} from "../../../actions/institute/ManageCustomerSupport/manageCustomerSupportAction";
import "./style.scss";
import { replyByIdAction } from "../../../actions/Admin/CustomerSupport/customerSupportAction";
import { uploadImageAction } from "../../../actions/institute/ManageNewsLetter/manageNewsLetterAction";

class CustomerSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      tableBody: { columns: [], rows: [] },
      modalId: null,
      modalImageId: null,
      search: {},
      dropDownDataUserName: [],
      dropDownDataInstituteName: [],
      exportCsv: null,
      isView: false,
      adminInput: "",
      uploadFile: "",
      isOpen: false,

      imageError:false,
      textError:false,

    };
  }

  componentDidMount() {
    this.props.getSupportAdminAction().then((res) => {
      this.constructTableData();
      this.exportCsv();
    });
    this.props
      .getInstituteNameAction()
      .then((res) => this.setState({ dropDownDataInstituteName: res }));
    this.props
      .getUserNameAction()
      .then((res) => this.setState({ dropDownDataUserName: res }));
  }

  toggle = (flag, item, i) => () => {
    this.setState({
      isView: flag,
      modalId: i,
    });
  };

  toggleImage = (flag, id) => () => {
    this.setState({
      isOpen: flag,
      modalImageId: id,
      modalId: null,
    });
  };

  toggleClose = () => {
    this.setState({
      modalId: null,
      isOpen: false,
      textError:false,
      imageError:false,
      adminInput:"",
      uploadFile:""
    });
  };

  toggleImageClose = (id) => {
    this.setState({
      modalId: id,
      modalImageId: null,
      isOpen: true,
    });
  };

  handleSearch = (name, value) => {
    this.setState({ search: { ...this.state.search, [name]: value } });
  };

  handleReply = (id) => async () => {
    if(!this.state.uploadFile){
      this.setState({
        imageError : true
      })
    }
    if(!this.state.adminInput){
      this.setState({
        textError : true
      })
    }
    else{
      await this.props.replyByIdAction({
        id: id,
        reply: this.state.adminInput,
        status: 1,
        image_url: this.state.uploadFile,
      });
      await this.props.getSupportAdminAction().then((res) => {
        this.constructTableData();
        this.exportCsv();
      });
      this.toggleClose();
    }
  };

  handleImage = async (event) => {
    const image = event.target.files[0];
    const res = await this.props.uploadImageAction(image);
    this.setState({
      uploadFile: res,
    });
  };

  exportCsv = () => {
    const exportCsvHeaders = [
      { label: "Username", key: "Username" },
      { label: "Institute Name", key: "institute_name" },
      { label: "Subject", key: "subject" },
      { label: "Description", key: "description" },
      { label: "status", key: "status" },
    ];
    const exportCSVData = this.props.supportList.map((item) => {
      let obj = {
        subject: item.subject,
        description: item.description,
        status: item.reply === null ? "Open" : "Sent",
        Username: item.username,
        institute_name: item.institute_name,
      };
      return obj;
    });
    let csv = {
      data: exportCSVData,
      headers: exportCsvHeaders,
      filename: "customersupport.csv",
      style: { color: "white" },
    };
    this.setState({
      exportCsv: csv,
    });
  };

  searchHandler = async () => {
    let search = {
      subject: this.state.search.subject,
      description:this.state.search.description,
      status: this.state.search.status,
      user_id: this.state.search.user_id,
      institute_id: this.state.search.institute_id,
    };
    Object.keys(search).forEach(
      (key) => search[key] === "" && delete search[key]
    );
    await this.props.searchAdminSupportAction(search);
    this.constructTableData();
  };

  handleInput = (event) => {
    this.setState({ adminInput: event.target.value });
  };
  clearHandler = async () => {
    this.setState({
      search: {},
      dropDownDataUserName: [],
      dropDownDataInstituteName: [],
    });
    await this.props.getSupportAdminAction().then((res) => {
      this.constructTableData();
    });
    await this.props
      .getInstituteNameAction()
      .then((res) => this.setState({ dropDownDataInstituteName: res }));
    await this.props
      .getUserNameAction()
      .then((res) => this.setState({ dropDownDataUserName: res }));
  };

  exportPdf = () => {
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0, 200, 0);
      pdf.save("CustomerSupport.pdf");
    });
  };

  constructTableData = () => {
    let tableData = {
      columns: [
        {
          label: "#",
          field: "id",
        },
        {
          label: "Username",
          field: "user_name",
          width: 200,
        },
        {
          label: "Institute Name",
          field: "institute_name",
          width: 200,
        },
        {
          label: "Subject",
          field: "subject",
          width: 270,
        },

        {
          label: "Description",
          field: "description",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Status",
          field: "status",
          width: 270,
        },

        {
          label: "Action",
          field: "action",
          width: 270,
        },
      ],
      rows: this.actionContainer(),
    };

    this.setState({
      tableBody: tableData,
    });
  };

  actionContainer = () => {
    return this.props.supportList.map((item, i) => {
      let obj = {
        id: i + 1,
        user_name: item.username ,
        institute_name: item.institute_name,
        description: item.description,
        subject: item.subject,
        status: item.reply === null ? "Open" : "Sent",
        action: (
          <div className="actionContainer">
            {item.reply === null ? (
              <MDBBtn
                icon="edit"
                size="lg"
                className="icon"
                onClick={this.toggle(false, item, i)}
              >
                {" "}
                Reply{" "}
              </MDBBtn>
            ) : (
              <MDBBtn
                icon="eye"
                size="lg"
                className="icon"
                onClick={this.toggle(true, item, i)}
              >
                {" "}
                View{" "}
              </MDBBtn>
            )}
          </div>
        ),
      };
      return obj;
    });
  };

  viewModalDetails = (item) => {
    return (
      <>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Username</h6>
            <h6>{item.username}</h6>
          </div>
        </div>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Institute Name</h6>
            <h6>{item.institute_name }</h6>
          </div>
        </div>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Subject </h6>
            <h6>{item.subject}</h6>
          </div>
        </div>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Description</h6>
            <h6>{item.description}</h6>
          </div>
        </div>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Image upload</h6>
            {item.reply === null ? (<>
              <input
                ref="file"
                onChange={this.handleImage}
                type="file"
                className="form-control"
                placeholder="upload Image"
                required
                accept=".image/*,.doc, .docx,image/gif, image/jpeg,.png"
              />
              {this.state.imageError ?(
                  <div className='expiryDateValidation'>
                      required.
                  </div>
              ):''}
              </>
            ) : 
            (
              <div>
                <img
                  src={item.image_url}
                  onClick={this.toggleImage(true, item.id - 1)}
                  alt="no image"
                />
              </div>
                
            )}
          </div>
          
        </div>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Super Admin Reply</h6>
            {item.reply !== null ? (
              <h6>{item.reply}</h6>
            ) : (<>
              <MDBInput
                className="form-control"
                type="textarea"
                rows="5"
                value={this.state.adminInput}
                onChange={this.handleInput}
                required
              />
              {this.state.textError ?(
                  <div className='expiryDateValidation'>
                      required.
                  </div>
              ):''}
            </>
            )}
          </div>
        </div>
      </>
    );
  };

  viewImageDetails = (item) => {
    return (
      <>
        <div>
          <img className="viewImage" src={item.image_url} alt="no image" />
        </div>
      </>
    );
  };

  searchContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <select
                  onChange={(e) => this.handleSearch("user_id", e.target.value)}
                  className="form-control show-tick"
                >
                  <option value="">Select Username</option>
                  {this.state.dropDownDataUserName.length
                    ? this.state.dropDownDataUserName.map((ele) => (
                        <option value={ele.id}>{ele.username}</option>
                      ))
                    : null}
                </select>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <select
                  onChange={(e) =>
                    this.handleSearch("institute_id", e.target.value)
                  }
                  className="form-control show-tick"
                >
                  <option value="">Select Institute</option>
                  {this.state.dropDownDataInstituteName.map((ele) => (
                    <option value={ele.id}>{ele.institute_name}</option>
                  ))}
                </select>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="input-group">
                  <input
                    type="text"
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search["subject"]
                    }
                    onChange={(e) =>
                      this.handleSearch("subject", e.target.value)
                    }
                    className="form-control"
                    placeholder="Subject"
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="input-group">
                  <input
                    type="text"
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search["description"]
                    }
                    onChange={(e) =>
                      this.handleSearch("description", e.target.value)
                    }
                    className="form-control"
                    placeholder="Description"
                  />
                </div>
              </div>
              <div className="col-lg-3 md-3 col-sm-12 mt-10">
                <select
                  value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search["status"]
                  }
                  onChange={(e) => this.handleSearch("status", e.target.value)}
                  className="form-control show-tick"
                >
                  <option value="">Status</option>
                  <option value="0">Open</option>
                  <option value="1">Sent</option>
                </select>
              </div>
            </div>
            <div className="btn-container">
              <div>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.searchHandler}
                >
                  Search
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.clearHandler}
                >
                  Clear
                </MDBBtn>{" "}
              </div>
              <div>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.exportPdf}
                >
                  PDF <MDBIcon icon="download" size="lg" className="icon" />
                </MDBBtn>
                <MDBBtn color="primary" className="btn">
                  {this.state.exportCsv && (
                    <CSVLink {...this.state.exportCsv}>
                      CSV
                      <MDBIcon icon="download" size="lg" className="icon" />
                    </CSVLink>
                  )}
                </MDBBtn>
              </div>
            </div>
          </div>
        </div>
      </TabPane>
    );
  };

  tableContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body" id="capture">
            <h1 className="page-title">Customer Support List</h1>
            {this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  searching={false}
                  sortRows={["title"]}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                />
              </div>
            )}
          </div>
        </div>
      </TabPane>
    );
  };

  modalContainer = () => {
    return (
      <div>
        {this.props.supportList.map((item, i) => {
          return (
            <MDBModal
              isOpen={this.state.modalId === i ? true : false}
              toggle={this.toggle}
            >
              <MDBModalHeader toggle={this.toggleClose}>
                View Support
              </MDBModalHeader>
              <MDBModalBody>{this.viewModalDetails(item)}</MDBModalBody>
              <MDBModalFooter>
                {!this.state.isView && (
                  <MDBBtn
                    color="secondary"
                    type="submit"
                    onClick={this.handleReply(item.id)}
                  >
                    Reply
                  </MDBBtn>
                )}
                <MDBBtn color="secondary" onClick={this.toggleClose}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          );
        })}
        {this.props.supportList.map((item, i) => {
          return (
            <MDBModal
              isOpen={this.state.modalImageId === i ? true : false}
              toggle={this.toggleImage}
              className="view-modal"
            >
              <MDBModalHeader toggle={() => this.toggleImageClose(i)}>
                View Image
              </MDBModalHeader>
              <MDBModalBody>{this.viewImageDetails(item)}</MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  color="secondary"
                  onClick={() => this.toggleImageClose(i)}
                >
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          );
        })}
      </div>
    );
  };

  pageContainer = () => {
    const { activeTab } = this.state;
    return (
      <TabContent activeTab={activeTab}>
        {this.searchContainer()}
        {this.tableContainer()}
      </TabContent>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">Manage Customer Support</h1>
                <ol className="breadcrumb page-breadcrumb">
                  <li className="breadcrumb-item">Search</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.pageContainer()}</div>
          {this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  supportList: state.getCustomerSupportInstituteReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getSupportAdminAction: () => dispatch(getSupportAdminAction()),
  searchAdminSupportAction: (e) => dispatch(searchAdminSupportAction(e)),
  replyByIdAction: (e) => dispatch(replyByIdAction(e)),
  getInstituteNameAction: () => dispatch(getInstituteNameAction()),
  uploadImageAction: (e) => dispatch(uploadImageAction(e)),
  getUserNameAction: () => dispatch(getUserNameAction()),
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerSupport);
