import { CREATE_QUESTION,
    UPDATE_QUESTION_LIST,CLEAR_QUESTION} from '../../../Constants/index';

export const createQuestionAction = (payload) => {
	return {
        type:CREATE_QUESTION,
        payload:payload
    }
}
export const clearQuestionAction = (payload) => {
	return {
        type:CLEAR_QUESTION,
        payload:[]
    }
}
export const updateQuestionListAction = (payload) => {
	return {
        type:UPDATE_QUESTION_LIST,
        payload:payload
    }
}