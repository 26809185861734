import React, { Component } from 'react'
import { MDBBtn,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import CSVReader from 'react-csv-reader'
import { updateQuestionListAction } from '../../../../actions/institute/Teacherdashboard/ManageQuestions/ManageQuestionAction';
import { connect } from 'react-redux';

class ImportQuestions extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
            createModal:true,
            quesArr:[]
		};
	}
    componentWillUnmount(){
        this.props.toggleClose()
    }
    toggleClose = ()=>{
		this.props.toggleClose()
	}

    saveData=(data)=>{
        let arr = []
        for (let index = 1; index < data.length; index++) {
            const element = data[index];
            if(data[0][3] === 'Question Type'){
                if(element[3] === "Descriptive"){
                    arr.push({
                        is_selected:false,
                        name:element[0],
                        image_url:'',
                        video_url:'',
                        marks:Number(element[1]),
                        is_descriptive:Number(element[2])
                    })
                }
                if(element[3] === "True False"){
                    let options = [
                        {
                            name:element[4],
                            is_correct:0
                        },
                        {
                            name:element[5],
                            is_correct:0
                        }
                    ]
                    if(element[9] === element[4]){
                        options[0].is_correct = 1
                    }
                    else{
                        options[1].is_correct = 1
                    }
                    arr.push({
                        is_selected:false,
                        name:element[0],
                        image_url:'',
                        video_url:'',
                        marks:Number(element[1]),
                        is_descriptive:Number(element[2]),
                        options:options
                    })
                }
                if(element[3] === "MCQ"){
                    let options = [
                        {
                            name:element[4],
                            is_correct:0
                        },
                        {
                            name:element[5],
                            is_correct:0
                        },
                        {
                            name:element[6],
                            is_correct:0
                        },
                        {
                            name:element[7],
                            is_correct:0
                        },
                        {
                            name:element[8],
                            is_correct:0
                        }
                    ]
                    options.map(item =>{
                        if(item.name === element[9]){
                            item.is_correct = 1
                        }
                    })
                    arr.push({
                        is_selected:false,
                        name:element[0],
                        image_url:'',
                        video_url:'',
                        marks:Number(element[1]),
                        is_descriptive:Number(element[2]),
                        options:options
                    })
                }

            }
        }
        this.setState({quesArr:arr})
        
    }
    handleSave=()=>{
        this.props.updateQuestionListAction(this.state.quesArr)
        this.props.toggleClose()
    }
    render() {
        return (
            <MDBContainer>
                <MDBModal isOpen={ this.state.createModal } >
                    <MDBModalHeader toggle={this.toggleClose}>Import Questions</MDBModalHeader>
                    <form noValidate>
                        <MDBModalBody>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Upload Files
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                :
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="">
                                                <CSVReader onFileLoaded={ this.saveData } />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="secondary" onClick={this.toggleClose}>Close</MDBBtn>
                            <MDBBtn color="secondary" type="button" onClick={this.handleSave}>Save</MDBBtn>
                        </MDBModalFooter>
                    </form>
                
                </MDBModal>
            </MDBContainer>
        )
    }
}

const mapStateToProps = state => ({
	questionState: state.ManageQuestionReducer
})

const mapDispatchToProps = dispatch => ({
	updateQuestionListAction: (e) => dispatch(updateQuestionListAction(e)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ImportQuestions)
