import { GET_LOGO_AND_IMAGE } from '../../../actions/Constants/index'

const initialState = {
    list : {
        logo: "",
        image: "",
        vedio: "",
        type: "",
    }
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_LOGO_AND_IMAGE:
			return {
				...state,
                list : action.payload.list,
			}
        default:
            return state
    }
}
