import { GET_ASSIGNMENT, 
    CREATE_ASSIGNMENT,
    EDIT_ASSIGNMENT, 
    GET_ASSIGNMENT_BY_ID,
    PUBLISH_ASSIGNMENT,
    DELETE_ASSIGNMENT,
    GET_SUBMITTED_ASSIGNMENT_DETAIL,
    GET_NOT_SUBMITTED_ASSIGNMENT_DETAIL,
    GET_SUBMITTED_ASSIGNMENT_ANSWER_WITH_PENDING_STATUS,
    GET_SUBMITTED_ASSIGNMENT_ANSWER_WITH_EVALUATED_STATUS,
    ASSIGNMENT_EVALUATED_MARK_STATUS,
    GET_ASSIGNMENT_FOR_STUDENT,
    GET_EXPIRED_ASSIGNMENT_FOR_STUDENT,GET_ASSIGNMENT_FOR_PARENT,
    SUBMIT_ASSIGNMENT, SHOW_SNACKBAR} from '../../../Constants/index';
import axios from 'axios';
import { manageAssignment } from '../../../../utils/Api'
import { getToken,getSchoolId } from '../../../../utils/token'

export const getAssignmentAction = (params) => {
	return dispatch => {
        const url = params ? `${manageAssignment.searchAssignment}` : `${manageAssignment.getAssignment}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
                school_id:getSchoolId()
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_ASSIGNMENT,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_ASSIGNMENT,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearGetAssignmentAction = () => {
	return dispatch => {
        dispatch({
            type: GET_ASSIGNMENT,
            payload: {
                list : []
            }
        })
    }
}

export const createAssignmentAction = (data, history) => {
	return dispatch => {
        const url = `${manageAssignment.createAssignment}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: CREATE_ASSIGNMENT,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                 history.push("/teacher/manage-assignment");
            }else {
                dispatch({
                    type: CREATE_ASSIGNMENT,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const clearCreateAssignmentAction = () => {
	return dispatch => {
        dispatch({
            type: CREATE_ASSIGNMENT,
            payload: {
                list : null
            }
        })
    }
}

export const clearEditAssignmentAction = () => {
	return dispatch => {
        dispatch({
            type: EDIT_ASSIGNMENT,
            payload: {
                list : null
            }
        })
    }
}

export const editAssignmentAction = ({id,data, history}) => {
	return dispatch => {
        const url = `${manageAssignment.editAssignment(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PATCH',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: EDIT_ASSIGNMENT,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                 history.push("/teacher/manage-assignment");
            }else {
                dispatch({
                    type: EDIT_ASSIGNMENT,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const getAssignmentByIdAction = (params) => {
	return dispatch => {
        const url = `${manageAssignment.getAssignmentById}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params:params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_ASSIGNMENT_BY_ID,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_ASSIGNMENT_BY_ID,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearAssignmentByIdAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_ASSIGNMENT_BY_ID,
            payload: {
                list : {}
            }
        })
    }
}

export const publishAssignmentAction = (id) => {
	return dispatch => {
        const url = `${manageAssignment.publishAssignment(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PATCH',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: PUBLISH_ASSIGNMENT,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: PUBLISH_ASSIGNMENT,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearpublishExamAction = (id) => {
	return dispatch => {
        dispatch({
            type: PUBLISH_ASSIGNMENT,
            payload: {
                list : {}
            }
        })
    }
}

export const deleteAssignmentAction = (data) => {
	return dispatch => {
        const url = `${manageAssignment.deleteAssignment(data)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'DELETE',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: DELETE_ASSIGNMENT,
                    payload: {
                        status : flag.status
                    }
                })
            }else {
                dispatch({
                    type: DELETE_ASSIGNMENT,
                    payload: {
                        status : {}
                    }
                })
            }
        })
    }
}

export const getSubmittedAssignmentDetailAction = (id) => {
	return dispatch => {
        const url = `${manageAssignment.getSubmittedAssignmentDetail(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_SUBMITTED_ASSIGNMENT_DETAIL,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_SUBMITTED_ASSIGNMENT_DETAIL,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearSubmittedAssignmentDetailAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_SUBMITTED_ASSIGNMENT_DETAIL,
            payload: {
                list : {}
            }
        })
    }
}

export const getNotSubmittedAssignmentDetailAction = (id) => {
	return dispatch => {
        const url = `${manageAssignment.getNotSubmittedAssignmentDetail(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_NOT_SUBMITTED_ASSIGNMENT_DETAIL,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_NOT_SUBMITTED_ASSIGNMENT_DETAIL,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearNotSubmittedAssignmentDetailAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_NOT_SUBMITTED_ASSIGNMENT_DETAIL,
            payload: {
                list : {}
            }
        })
    }
}

export const getSubmittedAssignmentAnswerWithPendingStatusAction = (id,id1) => {
	return dispatch => {
        const url = `${manageAssignment.getSubmittedAssignmentAnswerWithPendingStatus(id,id1)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_SUBMITTED_ASSIGNMENT_ANSWER_WITH_PENDING_STATUS,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_SUBMITTED_ASSIGNMENT_ANSWER_WITH_PENDING_STATUS,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const getSubmittedAssignmentAnswerWithEvaluatedStatusAction = (id,id1) => {
	return dispatch => {
        const url = `${manageAssignment.getSubmittedAssignmentAnswerWithEvaluatedStatus(id,id1)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_SUBMITTED_ASSIGNMENT_ANSWER_WITH_EVALUATED_STATUS,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_SUBMITTED_ASSIGNMENT_ANSWER_WITH_EVALUATED_STATUS,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const assignmentEvaluateMarkAction = ({id,id1,data}) => {
	return dispatch => {
        const url = `${manageAssignment.assignmentEvaluateMark(id,id1)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: ASSIGNMENT_EVALUATED_MARK_STATUS,
                    payload: {
                        list : flag.status
                    }
                })
            }else {
                dispatch({
                    type: ASSIGNMENT_EVALUATED_MARK_STATUS,
                    payload: {
                        list : flag.status
                    }
                })
            }
        })
    }
}

export const clearEvaluateMarkAction = () => {
	return dispatch => {
        dispatch({
            type: ASSIGNMENT_EVALUATED_MARK_STATUS,
            payload: {
                list : {}
            }
        })
    }
}


export const getAssignmenForStudenttAction = (data) => {
	return dispatch => {
        const url = manageAssignment.getAssignmentForStudent,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
                school_id:getSchoolId()
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_ASSIGNMENT_FOR_STUDENT,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_ASSIGNMENT_FOR_STUDENT,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const cleargetAssignmenForStudenttAction = () => {
	return dispatch => {
        dispatch({
            type: GET_ASSIGNMENT_FOR_STUDENT,
            payload: {
                list : []
            }
        })
    }
}

export const getExpiredAssignmenForStudenttAction = (data) => {
	return dispatch => {
        const url = manageAssignment.getExpiredAssignmentForStudent,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
                school_id:getSchoolId()
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_EXPIRED_ASSIGNMENT_FOR_STUDENT,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_EXPIRED_ASSIGNMENT_FOR_STUDENT,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const cleargetExpiredAssignmenForStudenttAction = () => {
	return dispatch => {
        dispatch({
            type: GET_EXPIRED_ASSIGNMENT_FOR_STUDENT,
            payload: {
                list : []
            }
        })
    }
}

export const getAssignmenForParentAction = (data) => {
	return dispatch => {
        const url = manageAssignment.getAssignmentForParent,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
                school_id:getSchoolId()
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_ASSIGNMENT_FOR_PARENT,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_ASSIGNMENT_FOR_PARENT,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const cleargetAssignmenForParentAction = () => {
	return dispatch => {
        dispatch({
            type: GET_ASSIGNMENT_FOR_PARENT,
            payload: {
                list : []
            }
        })
    }
}

export const submitAssignmentByStudentAction = (data) => {
	return dispatch => {
        const url = manageAssignment.submitAssignmentByStudent,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: SUBMIT_ASSIGNMENT,
                    payload: {
                        list : flag.status.msg
                    }
                })
            }else {
                dispatch({
                    type: SUBMIT_ASSIGNMENT,
                    payload: {
                        list : null
                    }
                })
            }
        })
    }
}

export const clearSubmitAssignmentAction = (data) => {
	return dispatch => {
        dispatch({
            type: SUBMIT_ASSIGNMENT,
            payload: {
                list : null
            }
        })
    }
}