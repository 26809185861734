import { GET_INVOICE_NUM } from '../../../actions/Constants/index'

const initialState = {
    number : null
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_INVOICE_NUM:
			return {
				...state,
                number : action.payload.number,
			}
        default:
            return state
    }
}
