import { INSTITUTE_USER_LOGIN,
    INSTITUTE_USER_LOGOUT } from '../../../actions/Constants/index'

const initialState = {
    isLogined : null,
    userInfo : null,
    password_state: null,
    access_token: null,
    refresh_token: null,
    role: null
}

export default (state = initialState, action) => {
	switch (action.type) {
		case INSTITUTE_USER_LOGIN:
			return {
				...state,
                isLogined : action.payload.isLogined,
                userInfo : action.payload.userInfo,
                password_state: action.payload.password_state,
                access_token: action.payload.access_token,
                refresh_token: action.payload.refresh_token,
                role: action.payload.role
			}
        case INSTITUTE_USER_LOGOUT:
            return {
                ...state,
                isLogined : null,
                userInfo : null
            }
        default:
            return state
    }
}
