import { GET_SKILL, 
    CREATE_SKILL,
    EDIT_SKILL, 
    GET_SKILL_BY_ID,GET_STUDENT_SKILL,UPLOAD_SKILL_IMAGE } from '../../../../actions/Constants/index'

const initialState = {
    list : [],
    editStatus:null,
    createStatus:null,
    detail:null,
    studentSkillList:[],
    uploadStatus:null
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_SKILL:
        return {
            ...state,
            list : action.payload.list,
        }
        case EDIT_SKILL:
        return {
            ...state,
            editStatus : action.payload.list,
        }
        case CREATE_SKILL:
        return {
            ...state,
            createStatus : action.payload.list,
        }
        case GET_SKILL_BY_ID:
        return {
            ...state,
            detail : action.payload.list,
        }
        case GET_STUDENT_SKILL:
        return {
            ...state,
            studentSkillList : action.payload.list,
        }
        case UPLOAD_SKILL_IMAGE:
        return {
            ...state,
            uploadStatus : action.payload.list,
        }
        default:
            return state
    }
}
